import moment from 'moment';

const format = {
	/**
	 * @param {*} time - the timestamp to display
	 * @returns Formatted time relative to current time (ex. 5 hours ago)
	 */
	timeElapsed: (time) => moment(time).fromNow(),
	/**
	 * @param {*} time - the timestamp to display
	 * @returns Formatted time with full date and hours (YYYY/MM/DD HH:mm)
	 */
	fullDate: (time, timezone) => moment(time).tz(timezone || moment.tz.guess()).format('HHmm M/D/YYYY'),
	inputDate: (time) => moment(time).format('yyyy-MM-DDTHH:mm'),
	date: (time) => moment(time).format('MM-DD-YYYY'),
	time: (time) => moment(time).format('HHmm'),
	toHHMMSS: (timeInSeconds) => {
    var sec_num = parseInt(timeInSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
		var hoursText = hours;
		var minutesText = minutes;
		var secondsText = seconds;

    if (hours   < 10) {hoursText   = "0"+hours;}
    if (minutes < 10) {minutesText = "0"+minutes;}
    if (seconds < 10) {secondsText = "0"+seconds;}
		var text = [];
		if(hours > 0) text.push(hoursText);
		text.push(minutesText);
		text.push(secondsText);
    return text.join(":");
	},
	invoiceId: (time) => moment(time).format('YYMMDD'),
	currency: (num) => {
		if(typeof(num) !== typeof(2)) {
			return "--";
		}
		return `$${num.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
	},
	/**
	 * @param {Number} num - the number to format
	 * @param {Number} precision - the number of decimals to display
	 * @returns Formatted time with full date and hours (YYYY/MM/DD HH:mm)
	 */
	number: (num = 0, precision = 0) => {
		if(typeof(num) !== typeof(2)) {
			return "--";
		}
		return `${num.toLocaleString("en-US", {minimumFractionDigits: precision, maximumFractionDigits: precision})}`;
	},
	toTitleCase: (str = "") => {
		return str.replace(
			/\w\S*/g,
			function(txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			}
		);
	},
	toPercent: (n, precision) => {
		if(typeof(n) !== typeof(2))
			return "N/A";
		return `${(n * 100).toFixed(precision)}%`;
	}
};

export default format;