import React, { useEffect, useState } from "react";
import { Form, Row, Col, Tabs, Tab, Button, Alert, Modal, Card, InputGroup, Badge } from "react-bootstrap";
import { FaMapMarkerAlt, FaTrash, FaTimes, FaAngleDoubleRight, FaAngleDoubleLeft, FaExclamationTriangle } from "react-icons/fa";
import { AncillariesForm, AsyncButton} from "../Shared";
import { AddressSelector, EmailListInput, Validation } from "../Form";
import MapPinSelector from "../Shared/MapPinSelector";
import marketsApi from "../../api/marketsApi";
import { useParams } from "react-router-dom";
import format from "utils/format";
import MarketLookup from "./MarketLookup";
import marketApi from "../../api/marketsApi";
import { BadRequest } from "Errors";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const initialState = {
	fields: {
		name: "",
		billing: { 
			name: "", phone: "", ein: "", cycle: "", fax: "",
			email: "", supplierNumber: "", paymentTerm: "",
			address: {
				street1: "", city: "",
				state: "", zip: ""
			},
			contractTerms: {
				mileageRate: "", mileageIncluded: "",
				waitTime: "", includedWaitTime: "",	
				weight: "",	includedWeight: "",
				afterHours: "",	weekend: "",
				holiday: "",
				secondMan: "",	pickAndHold: "",
				jobMinimum: "",	fuelSurcharge: "",
				sweepPrice: "",
				useCustomerMiles: false
			}
		},
		contact: {		
			name: "",	phone: "",
			fax: "", website: "",
			email: "", podEmail: ""
		},
		centroidLat: null,
		centroidLon: null,
		region: ""
	}
};

const TextInput = ({
	label,
	value,
	onChange,
	placeholder,
	sm,
	fieldName,
	isInvalid,
	validation=[]
}) => {
	return(
		<Form.Group size="sm" as={Row}>
			<Form.Label column sm={sm || 4}>{label}</Form.Label>
			<Col>
				<InputGroup hasValidation>
					<Form.Control size="sm"
						type="text" 
						value={value} 
						onChange={onChange}
						placeholder={placeholder}
						isValid={isInvalid}
					/>
					<Validation validation={validation} names={[fieldName]} />
				</InputGroup>
			</Col>
		</Form.Group>);
}

const MarketForm = ({
	clientId,
	marketId: marketIdFromProps,
	market: initialMarket = {},
	onChange,
	activeUser
}) => {
	const { marketId: marketIdFromParams } = useParams();
	const marketId = marketIdFromProps || marketIdFromParams;
	const [market, setMarket] = useState(initialMarket);
	const [fields, setFields] = useState({...initialState.fields, ...market?.fields});
	const [locationSelectorVisible, setLocationSelectorVisibility] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [validation, setValidation] = useState([]);
	
	const removeValidationError = (names = []) => {
		setValidation((v) => v.filter(e => !names.includes(e.param)));
	}

	useEffect(() => {
		if(!isLoaded && !!marketId) {
			async function fetchData() {
				const marketResponse = await marketsApi.read(clientId, marketId);
				setMarket(marketResponse);
				setIsLoaded(true);
				if(!!marketResponse?._id) {
					setFields(f => ({...f, ...marketResponse}))
				}
			}
			fetchData();
		}
	}, [isLoaded, clientId, marketId])
	
	if(!isPermitted(activeUser, "editMarkets"))
		return <NotAuthorizedError redirect="/markets" redirectMessage="Back to market list" />

	const handleLocationSet = (lat, lon) => {
		if(!!lat && !!lon) {
			setFields((f) => ({...f, centroidLat: lat, centroidLon: lon}))
		}

		setLocationSelectorVisibility(false);
	}

	const updateBilling = (newBilling) => {
		removeValidationError(Object.keys(newBilling).map(k => k.startsWith(`billing.${k}`)));
		setFields((f) => ({...f, billing: { ...f.billing, ...newBilling }}));
	};

	const updateContact = (newContact) => {
		removeValidationError(Object.keys(newContact).map(k => `contact.${k}`));
		setFields((f) => ({...f, contact: { ...f.contact, ...newContact }}))
	}

	const updateTerms = (newTerms) => {
		setFields((f) => ({...f, billing: { ...f.billing, contractTerms: { ...f.billing.contractTerms, ...newTerms }}}))
	}

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		if(!!marketId) {
			delete fields.jobs;
			delete fields.updatedAt;
			delete fields.createdAt;
			delete fields.__v;
			delete fields._id;
			if(!fields.region) delete fields.region;
			await marketsApi.update({clientId, marketId, fields});
			window.location = `/markets/${marketId}`;
		}
		else {
			try {
				const result = await marketsApi.create({clientId, fields});
				console.log(result);
				if(!!result?._id) {
					window.location = `/markets/${result?._id}`;
					return;
				}
				throw new Error("Failed to create new market. Please try again.");
			} catch(error) {
				if(error instanceof BadRequest) {
					setValidation(error?.validationErrors?.errors || []);
				}
				throw error;
			}
		}
	}

	if(!market) {
		return(
			<Card>
				<Card.Header>
					<strong>404. Not Found</strong>
				</Card.Header>
				<Card.Body>
					<p>Unable to locate the requested market. Please make sure this market exists.</p>
					<a href="/markets"><FaAngleDoubleLeft /> Back to the list of markets.</a>
				</Card.Body>
			</Card>
		);
	}

	if(!!locationSelectorVisible) {
		return (
			<MapPinSelector 
				marker={{name: "Current location", position: { lat: fields.centroidLat, lng: fields.centroidLon }}}
				initialCenter={{ lat: fields.centroidLat || 39.8283, lng: fields.centroidLon || -98.5795 }}
				height={400}
				zoom={!!fields.centroidLat ? 7 : 4}
				callback={handleLocationSet} 
			/>
		);
	}

	const contactErrorCount = validation.filter(e => e?.param?.startsWith("contact") || false).length;
	const billingErrorCount = validation.filter(e => (e?.param?.startsWith("billing") && !e?.param?.startsWith("billing.contractTerms")) || false).length;
	const contractTermsErrorCount = validation.filter(e => e?.param?.startsWith("billing.contractTerms") || false).length;

	return (<>
		<Form>
			<Form.Group as={Row}>
				<Form.Label column sm={1}>Name</Form.Label>
				<Col>
					<InputGroup hasValidation>
						<Form.Control
							type="text" 
							value={fields.name} 
							onChange={e => setFields((f) => ({...f, name: e.target.value }))}
							placeholder="Enter market's name"
							isInvalid={validation.find(v => v.param === `name`)}
						/>
						<Validation validation={validation} names={[`name`]} />
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group className="mt-2" as={Row}>
				<Form.Label column sm={1}>Code</Form.Label>
				<Col>
					<InputGroup hasValidation>
						<Form.Control 
							type="text" 
							value={(fields?.code || "")} 
							onChange={e => setFields((f) => ({...f, code: (e.target.value).replace(/[^a-zA-Z\d:]/, "").substring(0, 3).toUpperCase() }))}
							placeholder="Enter a market code: XX"
							isInvalid={validation.find(v => v.param === `code`)}
						/>
						<Validation validation={validation} names={[`code`]} />
					</InputGroup>
					<Form.Text className="text-muted">
						For use in compound numbers (invoice IDs). Must be unique and no more than three alphanumeric characters.
					</Form.Text>
				</Col>
			</Form.Group>
			<Row className="mb-3 mt-3">
				<Col className="offset-md-4 text-center">
					<Button onClick={() => setLocationSelectorVisibility(true)}>
						<FaMapMarkerAlt /> Choose market location
					</Button>
				</Col>
			<Col>
					{!!fields.centroidLat && <><small>Lat: {format.number(fields.centroidLat, 4)}</small><br /></>}
					{!!fields.centroidLon && <><small>Lon: {format.number(fields.centroidLon, 4)}</small></>}
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs>
						<Tab eventKey="contact" title={<>Contact Information {!!contactErrorCount && <Badge bg="danger" className="ms-2" ><FaExclamationTriangle /> {contactErrorCount}</Badge>}</>}>
							<div className="mt-3">
								<TextInput
									sm={3} 
									label="Name"
									value={fields.contact.name}
									onChange={e => updateContact({name: e.currentTarget.value})}
									isInvalid={validation.find(v => v.param === `contact.name`)}
									fieldName="contact.name"
									validation={validation}
								/>
								<TextInput
									sm={3} 
									label="Phone #" 
									value={fields.contact.phone}
									onChange={e => updateContact({phone: e.currentTarget.value})}
									isInvalid={validation.find(v => v.param === `contact.phone`)}
									fieldName="contact.phone"
									validation={validation}
									/>
								<TextInput 
									sm={3} label="Fax #" 
									value={fields.contact.fax} 
									onChange={e => updateContact({fax: e.currentTarget.value})} 
									isInvalid={validation.find(v => v.param === `contact.fax`)}
									fieldName="contact.fax"
									validation={validation}
								/>
								<TextInput 
									sm={3} 
									label="Website" 
									value={fields.contact.website} 
									onChange={e => updateContact({website: e.currentTarget.value})}
									isInvalid={validation.find(v => v.param === `contact.website`)}
									fieldName="contact.website"
									validation={validation}
									/>
								<Form.Group className="mb-2" size="sm" as={Row}>
									<Form.Label column sm={3}>Email</Form.Label>
									<Col>
										<EmailListInput 
											value={fields.contact.email} 
											onChange={email => updateContact({email})} 
											fieldName="contact.email"
											validation={validation}
										/>
									</Col>
								</Form.Group>
								<Form.Group size="sm" as={Row}>
									<Form.Label column sm={3}>POD email</Form.Label>
									<Col>
										<EmailListInput value={fields.contact.podEmail} onChange={podEmail => updateContact({podEmail})} />
									</Col>
								</Form.Group>
							</div>
						</Tab>
						<Tab eventKey="billing" title={<>Billing {!!billingErrorCount && <Badge bg="danger" className="ms-2" ><FaExclamationTriangle /> {billingErrorCount}</Badge>}</>}>
							<div className="mt-3">
								<Row>
									<Col>
										<TextInput 
											label="Name" 
											value={fields.billing.name} 
											onChange={e => updateBilling({name: e.currentTarget.value})} 
											isInvalid={validation.find(v => v.param === `billing.name`)}
											fieldName="billing.name"
											validation={validation}
										/>
										<TextInput 
											label="Phone #" 
											value={fields.billing.phone} 
											onChange={e => updateBilling({phone: e.currentTarget.value})}  
											isInvalid={validation.find(v => v.param === `billing.phone`)}
											fieldName="billing.phone"
											validation={validation}
										/>
										<AddressSelector address={fields.billing.address} onChange={(addr = {}) => updateBilling({address: { ...fields.billing.address, ...addr}})} />
									</Col>
									<Col>
										<Form.Group size="sm" as={Row} className="mb-2">
											<Form.Label column sm={4}>Email</Form.Label>
											<Col>
												<EmailListInput 
													value={fields.billing.email} 
													onChange={email => updateBilling({email})}
													isInvalid={validation.find(v => v.param === `billing.email`)}
													fieldName="billing.email"
													validation={validation}													
												/>
											</Col>
										</Form.Group>
										<TextInput 
											label="EIN" 
											value={fields.billing.ein} 
											onChange={e => updateBilling({ein: e.currentTarget.value})} 
											isInvalid={validation.find(v => v.param === `billing.ein`)}
											fieldName="billing.ein"
											validation={validation}		
										/>
										<TextInput 
											label="Billing cycle" 
											value={fields.billing.cycle} 
											onChange={e => updateBilling({cycle: e.currentTarget.value})} 
											isInvalid={validation.find(v => v.param === `billing.cycle`)}
											fieldName="billing.cycle"
											validation={validation}		
										/>
										<TextInput 
											label="Fax #" 
											value={fields.billing.fax} 
											onChange={e => updateBilling({fax: e.currentTarget.value})}
											isInvalid={validation.find(v => v.param === `billing.fax`)}
											fieldName="billing.fax"
											validation={validation}	
										/>
										<TextInput 
											label="Supplier #" 
											value={fields.billing.supplierNumber} 
											onChange={e => updateBilling({supplierNumber: e.currentTarget.value})}
											isInvalid={validation.find(v => v.param === `billing.supplierNumber`)}
											fieldName="billing.supplierNumber"
											validation={validation}	
										/>
										<TextInput 
											label="Payment term" 
											value={fields.billing.paymentTerm} 
											onChange={e => updateBilling({paymentTerm: e.currentTarget.value})}
											isInvalid={validation.find(v => v.param === `billing.paymentTerm`)}
											fieldName="billing.paymentTerm"
											validation={validation}	
										/>
									</Col>
								</Row>
							</div>
						</Tab>
						<Tab eventKey="contract" title={<>Contract Terms {!!contractTermsErrorCount && <Badge bg="danger" className="ms-2" ><FaExclamationTriangle /> {contractTermsErrorCount}</Badge>}</>}>
							<div className="mt-3">
								<AncillariesForm
									name={"billing.contractTerms"}
									onChange={updateTerms}
									validation={validation}
									clearValidation={removeValidationError}
									{...fields.billing.contractTerms} 
								/>
							</div>
						</Tab>
					</Tabs>
				</Col>
			</Row>
			<hr />
			<div className="d-flex">
				<AsyncButton onClick={handleSubmit}>{!!marketId ? "Save Changes" : "Add Market"}</AsyncButton>
				<span style={{marginLeft:"auto"}}>
					{!!marketId && <Button onClick={() => setDeleteModalOpen(!deleteModalOpen)} variant="outline-danger"><FaTrash /> Delete</Button>}
				</span>
			</div>
		</Form>	
		{deleteModalOpen && <DeleteMarketModal market={market} show={deleteModalOpen} onHide={() => setDeleteModalOpen(false)} />}
	</>);
};

const DeleteMarketModal = (props) => {
	const {
		market,
		onHide = () => {},
		show = false
	} = props;

	const [acknowledged, setAcknowledged] = useState(false);
	const [targetMarket, setTargetMarket] = useState();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(<></>);

	const handleDelete = async () => {
		setError(<></>);
		await marketApi.delete({ marketId: market?._id, targetMarketId: targetMarket?._id });
		setSuccess(true);
		setError(<Alert className="mt-3" variant="success">Market {market.name} was successfully deleted. In a 3 seconds, you will be taken back to the market list. <a href="/markets">Go now <FaAngleDoubleRight /></a></Alert>);
		setTimeout(() => window.location.href="/markets", 3000);
	}

	const handleError = (e) => {
		if(!e) {
			setError(<></>);
			return
		}

		setError(<Alert className="mt-3" variant="danger">{e.message}</Alert>)
	}

	return (<Modal onHide={onHide} show={show} size="lg" aria-labelledby={`change-{name}-filter`} centered>
		<Modal.Header closeButton>
			<Modal.Title>Permenantly delete {market.name}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{!!market?.jobs?.length && <>
			<p><strong>Jobs need to be transferred to a new market. Select the target market:</strong></p>
			<MarketLookup value={targetMarket} onChange={setTargetMarket} />
			<p className="text-muted mt-1"><small>Please note that job level contract details and charges will need to be updated separately if necessary.</small></p>
			</>}
			<Form.Check value={acknowledged} onChange={setAcknowledged} type="checkbox" label="I understand that deleting a market is irreversible and this market will be permenantly." />
			{error}
		</Modal.Body>
		<Modal.Footer>
			{!success && <>
			<AsyncButton onError={handleError} size="sm" variant="danger" disabled={!acknowledged} onClick={handleDelete}><FaTrash /> Delete</AsyncButton>
			<Button variant="secondary" size="sm" onClick={onHide}><FaTimes /> Cancel</Button>
			</>}
		</Modal.Footer>
	</Modal>);
}

export default MarketForm;