import { apiFetch } from "./api";

const mapApi = {
	addressAutoComplete: async ({query}) => {
		if(!query)
			return [];
			
		query.replace(/[^aA-zZ0-9\\s]/, "");
		query.replace(/\s+/, " ");
		if(query.length <= 1) {
			return [];
		}
		const url = `/maps/addressautocomplete?input=${query}`;
		const response = await apiFetch(url);
		return response;
	},
	computeDistance: async ({ origin, destination }) => {
		if(!origin || !destination) {
			return {};
		}

		const url = `/maps/computedistance?origin=${origin}&destination=${destination}`;
		const response = await apiFetch(url);
		const { miles } = response;
		
		return miles;
	},
	placedetails: async (id) => {
		if(!id || typeof(id) !== "string") {
			return {};
		}
		const url = `/maps/placedetails?id=${id}`;
		const response = await apiFetch(url);
		return response;
	}
};

export default mapApi;