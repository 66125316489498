import React, { useState } from "react";
import { Typeahead as BasicTypeahead, UserLookup } from "../Form";
import { Button, ButtonGroup, Form, InputGroup, Image } from "react-bootstrap";
import { AsyncButton } from "../Shared";
import { FaTimes } from "react-icons/fa";
import { ClientLookup } from "../Client";
import { MarketLookup } from "../Market";

export function TypeaheadFilter({
	name = "Typeahead",
	value,
	onChange = () => {},
	renderSelection = () => "",
	Typeahead = BasicTypeahead,
	...props
}) {
	const [selection, update] = useState(value);
	const [edit, setEdit] = useState(false);

	const submit = () => { setEdit(false); onChange(selection); }
	const cancel = () => { update(value); setEdit(false); };
	const clear = () => { update(undefined); onChange(undefined);}

	if(!edit) {
		return (
			<ButtonGroup>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					{name}{!!value && <>: {renderSelection(value)}</>}
				</Button>
				{ !!value && <Button size="sm" variant="outline-secondary" onClick={clear}><FaTimes /></Button>}
			</ButtonGroup>
		)
	}
	
	return (
		<Form.Group className="typeahead-filter">
			<InputGroup>
				<Typeahead value={selection} autoFocus size="sm" onChange={(e) => update(e)} {...props} />
				<AsyncButton variant="outline-success" size="sm" onClick={submit} />
				<Button variant="outline-danger" size="sm" onClick={cancel}><FaTimes /></Button>
			</InputGroup>
		</Form.Group>
	);
}



export function UserFilter({ name = "User", ...props }) {
	return <TypeaheadFilter 
		name={name}
		Typeahead={UserLookup}
		renderSelection={u => <>{!!u.picture && <Image referrerPolicy="no-referrer" style={{marginTop: "-3px"}} className="me-1" roundedCircle height="16px" src={u.picture}  />}{u.name}</>}
		{...props}
	/> 
}

export function ClientFilter({ name = "Client", ...props }) {
	return <TypeaheadFilter
		name={name}
		Typeahead={ClientLookup}
		renderSelection={c => c.name}
		{...props}
	/>
}

export function MarketFilter({ name = "Market", ...props }) {
	return <TypeaheadFilter
		name={name}
		Typeahead={MarketLookup}
		renderSelection={m => m.name}
		{...props}
	/>
}