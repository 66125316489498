import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import { JobFilter } from ".";
import JobsApi from "../../api/jobsApi";
import useQuery from "../../utils/useQuery";
import { LoadingTable, Pagination } from "../Shared";
import JobFields from "./JobFields";

const DEFAULT_PROJECTION = { 
	_id: 1, 
	market: 1,
	customerMiles: 1,
	calculatedMiles: 1,
	jobNumber: 1,
	pieces: 1,
	weight: 1,
	status: 1,
	createdAt: 1
}

const DEFAULT_ORDER = ["_id", "jobNumber", "status", "market", "pieces", "weight", "customerMiles"];

const THead = ({
	order = []
}) => {
	return <thead>
		<tr>
			{order.map((o,i) => JobFields[o].header({ key: `prop-${i}` }))}
		</tr>
	</thead>
}

const TBody = ({ jobs = [], order=[], loading = false, filter }) => {
	return <tbody>
		{!!loading && <LoadingTable rows={filter.limit} columns={order.length} /> }
		{ !loading && !jobs.length && <tr><td className="text-center text-muted" colSpan={100}><FaExclamationTriangle /> No jobs found.</td></tr>}
		{!loading && jobs.map((job) => {
			const tds = [];
			order.map((prop,i) => tds.push(JobFields[prop].render({ val: job[prop], key: `field-${i}`})));
			return <tr key={`job-${job._id}`}>{tds}</tr>
		})}
	</tbody>
}

const TFoot = ({ order = [], filter, result, update }) => {
	return <tfoot>
		<tr>
			<td colSpan={order.length} className="text-center">
				<div style={{display: "inline-block"}}>
					<Pagination
						skip={filter.skip || 0}
						limit={filter.limit || 10}
						totalCount={result?.totalCount}
						setSkip={(s) => update({...filter, skip: s})}
					/>
				</div>
			</td>
		</tr>
	</tfoot>
}

const JobSearch = ({activeUser = {}}) => {
	const query = useQuery();
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [jobs, setJobs] = useState([]);
	const [state, setState] = useState({
		filter: { ...query },
		projection: DEFAULT_PROJECTION,
		order: DEFAULT_ORDER
	});
	const [sortOptions, setSortOptions] = useState([]);

	const update = (f, p = state.projection, o = state.order) => {
		setState({ filter: f, projection: p, order: o }) 
	};
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const drivers = state?.filter?.drivers?.map(d => d._id) ?? [];
				const res = await JobsApi.filter({ ...state.filter, drivers, projection: state?.projection }, true);
				setJobs(res.jobs);
				setTotalCount(res.totalCount);
				setSortOptions(res.sortOptions);
				setLoading(false);
			}
			catch(err) {
				console.error(err);
				setLoading(false);
			}
		}

		fetchData();
	}, [state.filter, state.projection])

	const { filter, projection, order } = state;

	return <>
	<Row>
		<Col>
			<Breadcrumb>
				<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
				<Breadcrumb.Item href={`/jobs`}>Job Search</Breadcrumb.Item>
			</Breadcrumb>  
		</Col>
	</Row>
	<Row>
		<Col>
			<h1>Jobs {!!totalCount && <>({totalCount || 0})</>}</h1>
			<hr />
		</Col>
	</Row>
		<Row>
			<Col>
				<JobFilter sortOptions={sortOptions} filter={filter} onChange={update} projection={projection} order={order} />
			</Col>
		</Row>
		<Row>
			<Col>
				<Table className="align-middle">
					<THead {...{ order }} />
					<TBody {...{ jobs, order, loading, filter }} />
					<TFoot { ...{ order, filter, result: { totalCount }, update }} />
				</Table>
			</Col>
		</Row>
	</>
}

export default JobSearch;