import React, {useEffect, useState} from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaPlus, FaTimesCircle, FaRegClock, FaEdit, FaTimes } from "react-icons/fa";
import { SaveButton } from '../Form';

const timeZoneList = {
    "Local": "current",
    "Honolulu": "Pacific/Honolulu",
    "Anchorage": "America/Anchorage",
    "Los Angeles": "America/Los_Angeles",
    "Seattle": "America/Los_Angeles",
    "Phoenix": "America/Phoenix",
    "Denver": "America/Denver",
    "Chicago": "America/Chicago",
    "New York": "America/New_York"
}

let interval;

export default function Clock({ timeZones: initialTimeZones = ["Local"], maxTimezones = 3, onChange = () => {}, ...additionalProps }){
    const timeTableDefault = [{ timeZone: "current", name: "Local", time: new Date().toLocaleTimeString("en-US", "") }];
    const [timeZones, setTimeZones] = useState(initialTimeZones);
    const [timeTable, setTimeTable] = useState(timeTableDefault);
		const [adding, setAdding] = useState(false);
		const [editing, setEditing] = useState(false);
		const [tempTimeZones, setTempTimeZones] = useState(initialTimeZones);
		
    const updateTimeTable = () => {
        const times = tempTimeZones.map((tz) => {
            if (tz === "Local"){
                return {timeZone: tz, name: "Local", time: new Date().toLocaleTimeString("en-US", { hour12: false })}
            }
            return { timeZone: timeZoneList[tz], name: tz, time: new Date().toLocaleTimeString("en-US", {timeZone: timeZoneList[tz],  hour12: false}) }
        })
        setTimeTable(times);
    }

    useEffect(() => {
				clearInterval(interval);
        updateTimeTable();
        interval = setInterval(updateTimeTable, 1000);
        return () => clearInterval(interval);
    }, [tempTimeZones]);

    const add = (event) => {
        setTempTimeZones([...tempTimeZones, event.target.value])
        updateTimeTable();
				setAdding(false);
    }

		const remove = (index) => {
			const newTimeZones = tempTimeZones.filter((_,i) => i !== index);
			setTempTimeZones(newTimeZones);
		}

		const save = () => {
			setTimeZones(tempTimeZones);
			setEditing(false);
			setAdding(false);
			onChange(tempTimeZones);
		}

		const cancel = () => {
			setTempTimeZones(timeZones);
			setEditing(false);
			setAdding(false);
		}

		const edit = () => {
			setEditing(true);
		}

    return (
        <div {...additionalProps}>
            <Table className="multiclock" striped bordered size="sm">
							<thead>
								<tr>
									<th colSpan={2}>
										<FaRegClock className="me-1" /> Clock
										<div className="float-end">
											{!editing && <FaEdit title="Edit" onClick={edit} style={{ cursor: "pointer" }} />}
											{editing && <SaveButton useButtons={false} className="me-1" onClick={save} onError={() => {}} onComplete={() => setEditing(false)}></SaveButton>}
											{editing && <FaTimes title="Cancel" onClick={cancel} style={{ cursor: "pointer" }} />}
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
									{timeTable.map((t, i) => 
										<tr key={`time-${i}`} >
											<td>{editing && <FaTimesCircle onClick={() => remove(i)} className="me-1" style={{ cursor: "pointer" }} />}{t.name}</td>
											<td className="text-end">{t.time}</td>
										</tr>)
									}
									{ editing && adding && <tr>
											<td colSpan={2}>
												<FaTimesCircle onClick={() => setAdding(false)} className="me-1" style={{ cursor: "pointer" }} />
												<select onChange={add}>
														<option>Choose a timezone...</option>
														{Object.keys(timeZoneList).map((name) => (<option key={name} value={name}>{name}</option>))}
												</select>
											</td>
									</tr> }
									{ editing && !adding && tempTimeZones.length < maxTimezones && 
									<tr>
										<td colSpan={5} className="text-center border-top">
											<Button size="sm" onClick={() => setAdding(true)}><FaPlus /> Add timezone</Button>
										</td></tr> }
							</tbody>
            </Table>
        </div>
    )
};