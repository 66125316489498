import React from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { SelectFilter, DateRangeFilter, RangeFilter } from "../Filter";
import { FilterBar } from "../Shared";

// selectedStartDate
// selectedEndDate
// grossRevenueMin
// grossRevenueMax
// status
const PayrollFilter = ({ 
	filter = {}, 
	onChange = () => {} 
}) => {
	const hasFilter = Object.keys(filter).find(k => ["skip", "limit"].indexOf(k) === -1);
	return (
		<FilterBar>
			<SelectFilter 
				name="Status"
				value={filter.status}
				options={["new", "ready", "paid"]}
				onChange={(status) => onChange({...filter, status})}
			/>
			<RangeFilter 
				name="Gross Revenue" 
				value={{min: filter.grossRevenueMin, max: filter.grossRevenueMax}} 
				onChange={({min, max}) => onChange({...filter, grossRevenueMin: min, grossRevenueMax: max})} 
			/>
			<RangeFilter 
				name="Total Payout" 
				value={{min: filter.payoutMin, max: filter.payoutMax}} 
				onChange={({min, max}) => onChange({...filter, payoutMin: min, payoutMax: max})} 
			/>
			<DateRangeFilter 
				name="End Date"
				value={{ start: filter.selectedStartDate, end: filter.selectedEndDate }}
				onChange={({start: selectedStartDate, end: selectedEndDate}) => onChange({...filter, selectedStartDate, selectedEndDate})}
			/>
			{!!hasFilter && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
		</FilterBar>
	);
}

export default PayrollFilter;