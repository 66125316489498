import { JobTemplateSelector } from "Components/Form";
import JobTemplateForm from "Components/JobTemplate/JobTemplateForm";
import React, { createContext, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaAngleDoubleLeft, FaPlusSquare, FaStamp, FaRegCopy } from "react-icons/fa";
import { HiTemplate } from "react-icons/hi";
import format from "../../../utils/format";
import { JobCreationWizard } from "..";

const JobWizardContext = createContext();

const JobWizardProvider = ({ children }) => {
	const [section, setSection] = useState("path");
	const [history, setHistory] = useState([]);
	const [client, setClient] = useState();
	const [market, setMarket] = useState();
	const [job, setJob] = useState();
	const [template, setTemplate] = useState();

	const updateSection = (newSection) => {
		setHistory(h => [section, ...h])
		setSection(newSection);
	}

	const back = (evt) => {
		if(evt) { evt.stopPropagation(); evt.preventDefault(); }
		const newSection = history.shift();
		setHistory(history);
		setSection(newSection);
	}

	const handleSetTemplate = (t) => {
		setTemplate(t);
		setSection("wizard");
	}

	const state = { 
		client, setClient,
		market, setMarket,
		job, setJob,
		section, setSection: updateSection,
		history, back,
		template, setTemplate: handleSetTemplate
	};

	return (
		<JobWizardContext.Provider value={state}>
			{children}
		</JobWizardContext.Provider>
	)
} 

const withJobWizard = (Child) => (props) => (
  <JobWizardContext.Consumer>
    {(context) => <Child {...props} {...context} />}
  </JobWizardContext.Consumer>
);

const JobCreationWizardPage = ({activeUser = {}}) => {	
	return (
		<JobWizardProvider>
			<h1>Job Creation Wizard</h1>
			<hr />
			<JobSectionManager activeUser={activeUser} />
		</JobWizardProvider>
	)
};

const JobTemplateSelectionPage = ({ value, onChange }) => {
	const [selectedTemplate, setSelectedTemplate] = useState(value);

	const onSubmit = () => {
		onChange(selectedTemplate);
	}

	const onHandleTempalteSelection = (t) => {
		setSelectedTemplate(t);
	}
	
	return (
		<>
			<Row>
				<Col className="justify-content-md-center">
					<h3>Select a template to use</h3>
					<p>A template provides preset details for a job to speed up job creation. Job templates can be created from an existing job by visiting that job's details page. Use the <a href="/jobs/templates/create">job template creation form</a> to create a brand new template.</p>
				</Col>
			</Row>
			<Row className="justify-content-md-center"><Col>
				<JobTemplateSelector {...{ value, onChange: onHandleTempalteSelection }} />
			</Col></Row>
			{!!selectedTemplate?._id && <Row className="mt-3 justify-content-md-center"><Col className="text-center">
				<Button onClick={onSubmit}>Use this template</Button>
			</Col></Row>}
			{!!selectedTemplate?._id && <Row className="mt-3"><Col>
				<h4>Selected Template Details</h4>
				<JobTemplateForm value={selectedTemplate} disabled={true} />
			</Col></Row>}
		</>
	)
}

const JobSectionManager = withJobWizard(({ activeUser = {}, section, setSection, history, back, template, setTemplate }) => {
	const supported = ["path", "wizard", "templateSelection"].includes(section);

	if(template?.pickup?.readyAt) {
		const templateDate = new Date(template?.pickup?.readyAt);
		templateDate.setDate(new Date().getDate());
		template.pickup.readyAt = format.inputDate(templateDate);
	}

	if(template?.dropoff?.deliverBy) {
		const templateDate = new Date(template?.dropoff?.deliverBy);
		templateDate.setDate(new Date().getDate());
		template.dropoff.deliverBy = format.inputDate(templateDate);
	}

	return (
		<>
			{!!history.length && <Row>
				<Col><a href="#" onClick={back}><FaAngleDoubleLeft /> Back</a></Col>
			</Row>}
			<Row className="justify-content-md-center">
				<Col md={9}>
					{section === "path" && <JobWizardStart />}
					{section === "wizard" && <JobCreationWizard activeUser={activeUser} job={template} onClose={() => setSection("path")} />}
					{section === "templateSelection" && <JobTemplateSelectionPage {...{ value: template, onChange: setTemplate }} />}
					{ !supported && <SectionNotSupported />}
				</Col>
			</Row>

		</>
	)
});

const JobWizardStart = withJobWizard(({ setSection }) => {
	return (
		<div className="text-center">
			<h2>What do you want to do?</h2>
			<Row className="mt-5 mb-3">
				<Col><Button variant="primary" size="lg" onClick={() => setSection("wizard")}><FaPlusSquare /> Create a new Job from scratch</Button></Col>
			</Row>
			<Row className="mb-3">
				<Col><Button variant="secondary" size="lg" onClick={() => setSection("templateSelection")}><HiTemplate /> Create a Job from a template</Button></Col>
			</Row>
			<Row className="mb-3">
				<Col><Button disabled variant="secondary" size="lg" onClick={() => setSection("jobSelection")}><FaStamp /> Clone existing Job</Button></Col>
			</Row>
		</div>
	)
});

const SectionNotSupported = () => {
	return (
		<div className="text-center">
			<h2>Coming Soon!</h2>
		</div>
	);
}

export default JobCreationWizardPage;