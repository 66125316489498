export const toDataUrl = (src, outputFormat) => new Promise((resolve, reject) => {
	try {
		const img = new Image();
		img.crossOrigin = 'Anonymous';
		img.onload = () => {
			const canvas = document.createElement('CANVAS');
			const ctx = canvas.getContext('2d');
			canvas.height = img.naturalHeight;
			canvas.width = img.naturalWidth;
			ctx.drawImage(img, 0, 0);
			const dataURL = canvas.toDataURL(outputFormat);
			resolve(dataURL);
		};
		img.src = src;
		if (img.complete || img.complete === undefined) {
			img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
			img.src = src;
		}
	}
	catch(err) {
		reject(err);
	}
});