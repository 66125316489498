import React, { useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { ConfirmButtons } from "../Form";
import format from "../../utils/format";

const buildText = (name, selections) => {
	let output = name;
	
	if(selections.length === 1) {
		output += `: ${format.toTitleCase(selections[0])}`
	}

	if(selections.length > 1) {
		output += `: ${selections.length}`;
	}

	return output;
}

const SelectFilter = ({
	name = "Select",
	options = [],
	value = [],
	onChange = (values:string[]|undefined) => {},
	display = (option) => option,
	...props
}) => {
	const [edit, setEdit] = useState(false);
	const [selections, setSelections] = useState(value);

	const selectAll = () => setSelections(options);
	const empty = () => setSelections([]);
	const clear = () => { setSelections([]); onChange(undefined); };
	const reset = () => { setSelections(value); setEdit(false) };
	const change = (o) => {
		const index = selections.indexOf(o);
		const newSelections = [...selections];
		if(index >= 0) {
			newSelections.splice(index,1);
		}
		else {
			newSelections.push(o);
		}
		setSelections(newSelections);
	}
	const submit = () => {
		setEdit(false);
		onChange(selections);
	}

	return (
		<>
			<ButtonGroup {...props}>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					{ buildText(name, value.map(v => display(v))) }
				</Button>
				{ !!value?.length && <Button size="sm" variant="outline-secondary" onClick={clear}><FaTimes /></Button>}
			</ButtonGroup>
			{!!edit && 
			 <Modal onHide={reset} show={edit} size="lg" aria-labelledby={`change-{name}-filter`} centered>
				<Modal.Header closeButton>
					<Modal.Title>{name} Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						{ options.map((o, i) => 
							<Form.Check
								onChange={() => change(o)} 
								value={o} 
								checked={selections.indexOf(o) >= 0} 
								type="checkbox" 
								id={`option-${o}-${i}`} 
								key={`option-${o}-${i}`}
								label={format.toTitleCase(display(o))} 
							/>
						)}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button className="float-start" variant="link" size="sm" onClick={empty}>Clear</Button>			
					<Button className="float-start" variant="link" size="sm" onClick={selectAll}>Select all</Button>			
					<ConfirmButtons onConfirm={submit} onCancel={reset} />
				</Modal.Footer>
			</Modal>
			}
		</>
	)
}

export default SelectFilter;