import React from "react";
import { Badge } from "react-bootstrap";

export default ({
	roles = []
}) => {
	if(!roles.length) {
		return <>No roles assigned.</>
	}

	return <>
		{roles.map((r,i) => <Badge bg="light" text="dark" size="sm" key={`rolebadge-${i}`}>{r.name}</Badge>)}
	</>
}