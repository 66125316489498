import React from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { FaAngleDoubleLeft, FaExclamationTriangle } from "react-icons/fa";

type Props = {
	message: string,
	redirect: string,
	redirectMessage: string
};

const NotFoundError = (props:Props) => {
	const { message = "Unable to locate the request resource.", redirect, redirectMessage } = props;

	return (
		<Row>
			<Col md={{ span: 8, offset: 2}}>
				<Alert variant="warning">
					<strong className="mb-2 fs-4"><FaExclamationTriangle /> 404. Not Found</strong>
					<p className="mb-0">{message}</p>
				</Alert>
				<Button size="sm" variant="outline-dark" href={redirect}><FaAngleDoubleLeft /> {redirectMessage}</Button>
			</Col>
		</Row>
	);
}

export default NotFoundError;