import { apiFetch } from "./api";
import { buildUrlParams, filterParameters } from "./helpers";

const marketApi = {
	delete: async (props = {}) => {
		const { marketId, targetMarketId, clientId } = props;
		const response = await apiFetch(
			`${!!clientId ? `/customers/${clientId}` : '' }/markets/${marketId}`,
			{
				method: "DELETE",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({targetMarketId})
			}
		);
		return response;
	},
	lookup: async (text) => {
		if(text.length < 3) {
			return [];
		}
		const response = await apiFetch(`/markets/lookup?text=${text}`);
		return response.map(u => ({id : u.value, text : u.text}));
	},
	filter: async ({ clientId, ...apiParams } = {}) => {
		apiParams.limit = Math.max(1, Math.min(apiParams.limit || 10, 500));
		apiParams.skip = Math.max(0, apiParams.skip || 0);
		apiParams.sort = apiParams.sort || "default";
		
		if(apiParams?.regions) {
			apiParams.regionId = apiParams.regions.map(r => r._id);
			delete apiParams.regions;
		}

		const qs = buildUrlParams(apiParams);
		const response = await apiFetch(`${!!clientId ? `/customers/${clientId}` : '' }/markets?${qs}`);

		return response;
	},
	read: async (clientId, marketId, projection = "default") => {
		const response = await apiFetch(`${!!clientId ? `/customers/${clientId}` : '' }/markets/${marketId}?projection=${projection}`
		);
		return response;
	},
	create: async ({
		fields,
		clientId
	}) => {
		const response = await apiFetch(
			`${!!clientId ? `/customers/${clientId}` : '' }/markets`,
			{
				method: "POST",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({clientId, ...fields})
			}
		);
		return response;
	},
	update: async ({
		clientId,
		marketId,
		fields
	}) => {
		if(!marketId && !fields.name) {
			return {};
		}

		const response = await apiFetch(
			`${!!clientId ? `/customers/${clientId}` : '' }/markets/${marketId}`,
			{
				method: "PUT",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(fields)
			}
		);
		return response;
	}
}

export default marketApi;