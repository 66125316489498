import { DateRangeFilter, RadioFilter, SelectFilter } from "Components/Filter";
import { AVAILABLE_STATUSES } from "Components/Job/JobFilter";
import { getDisplay } from "Components/Job/JobStatus";
import { ReportApi } from "api";
import { Report as ReportType } from "api/Types/Report";
import React, { useEffect, useState } from "react";
import Report from "./Report";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import useQuery from "utils/useQuery";
import format from "utils/format";
import { useParams } from "react-router-dom";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";
import { isPermitted } from "utils/permissions";

type QueryParams = {
	startDate?: string,
	endDate?: string,
	status?: string,
	includeUndelivered?: boolean,
	reportName?: keyof DefaultReportMap
}

type DefaultReportMap = {
	[key:string]:DefaultReport
}

type DefaultReport = {
	name: string,
	order: number,
	getStartDate?: () => Date,
	status?: string[],
	getEndDate?: () => Date,
	includeUndelivered?: boolean,
	description: string
}

export const defaultReports:DefaultReportMap = {
	"yesterday-billing-summary": {
		order: 2,
		name: "Yesterday's Billing Summary",
		description: "A market summary of all jobs that were completed yesterday.",
		getStartDate: () => new Date(new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() - 1)),
		getEndDate: () => new Date(new Date().setHours(0,0,0,0)),
		status: ['complete', 'bill', 'archive']
	},
	"today-billing-summary": {
		order: 1,
		name: "Today's Billing Summary",
		description: "A market summary of all jobs that have been completed so far today.",
		getStartDate: () => new Date(new Date().setHours(0,0,0,0)),
		status: ['complete', 'bill', 'archive']
	},
	"this-month-billing-summary": {
		order: 3,
		name: "This Month's Billing Summary",
		description: "A market summary of all jobs that have been completed so far this month.",
		getStartDate: () => new Date(new Date(new Date().setDate(1)).setHours(0,0,0,0)),
		status: ['complete', 'bill', 'archive']
	},
	"ytd-billing-summary": {
		order: 4,
		name: "Billing Summary YTD",
		description: "A market summary of all jobs that have been completed so far this year.",
		getStartDate: () => new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
		status: ['complete', 'bill', 'archive']
	}
}

function isValidDate(d:Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

const ReportView = ({ activeUser }:{activeUser?:any}) => {
	let {
		startDate: initialStartDate,
		endDate: initialEndDate,
		status: initialStatus,
		includeUndelivered: initialIncludeUndelivered = false,
		reportName
	}:QueryParams = useQuery();

	reportName = useParams().reportName ?? reportName;

	let sd:Date|undefined = new Date(initialStartDate ?? "");
	if(!isValidDate(sd)) sd = undefined;
	let ed:Date|undefined = new Date(initialEndDate ?? "");
	if(!isValidDate(ed)) ed = undefined;

	const precannedReport = !!reportName ? defaultReports[reportName] : undefined;
	
	const [startDate, setStartDate] = useState<Date|undefined>(precannedReport?.getStartDate?.() ?? sd);
	const [endDate, setEndDate] = useState<Date|undefined>(precannedReport?.getEndDate?.() ?? ed);
	const [status, setStatus] = useState<string[]|undefined>(precannedReport?.status ?? initialStatus?.split(',') ?? []);
	const [includeUndelivered, setIncludeUndelivered] = useState<boolean>(precannedReport?.includeUndelivered ?? initialIncludeUndelivered ?? false);
	const [report, setReport] = useState<ReportType>({});

	useEffect(() => {
		const fetchData = async () => {
			if(!!startDate || !!endDate || !!status) {
				const newReport = await ReportApi.preview({ startDate, endDate, status, includeUndelivered });
				if(!!precannedReport?.name) {
					newReport.name = precannedReport.name;
				}
				setReport(newReport);

				if (!!window?.history?.pushState) {
					const params:any = {};
					if(!precannedReport?.name) {
						if(!!startDate) params.startDate = format.date(startDate);
						if(!!endDate) params.endDate = format.date(endDate);
						if(!!status?.length) params.status = status.join(',');
						if(!!includeUndelivered) params.includeUndelivered = true;
					}
					
					const qs = new URLSearchParams(params);
					if(Object.keys(qs).length) {
						var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qs}`;
						window.history.pushState({path:newurl},'',newurl);
					}
				}
			}
		};

		fetchData();
	}, [startDate, endDate, status, includeUndelivered])

	if(!isPermitted(activeUser, "viewReports")) {
		return <NotAuthorizedError redirect={`/`} redirectMessage="Back to Home" />
	}

	return (
		<>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/reports`}>Reports</Breadcrumb.Item>
						<Breadcrumb.Item href={`/reports/view`}>View</Breadcrumb.Item>
						{!!precannedReport?.name && <Breadcrumb.Item href={`/reports/view/${reportName}`}>{precannedReport?.name}</Breadcrumb.Item>}
					</Breadcrumb>  
				</Col>
			</Row>
			{!precannedReport?.name &&
			<Row>
				<Col>
					<DateRangeFilter value={{start:startDate, end: endDate}} onChange={({start, end}:{start:Date, end:Date}) => { setStartDate(start); setEndDate(end)}} />
					<SelectFilter
						className="ms-2"
						name="Status" 
						value={status} 
						onChange={setStatus} 
						options={AVAILABLE_STATUSES}
						display={getDisplay}
					/>
					<RadioFilter 
						className="ms-2" 
						onChange={b => setIncludeUndelivered(b === "true")} 
						options={[{	key: "true",name: "True"},{	key: "false",name: "False"}]} 
						value={includeUndelivered ? "true": "false"} 
						name="Include Undelivered"
					/>
				</Col>
			</Row>}
			<Row className="mt-3">
				<Col>
					<Report report={report} />
				</Col>
			</Row>
		</>
	);
};

export default ReportView;