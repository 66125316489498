import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCog } from "react-icons/fa";
import { ConfirmButtons, SelectAndOrder } from "../Form";

export default ({
	options = [],
	onChange = () => {},
	projection: initialProjection,
	...props
}) => {
	const [edit, setEdit] = useState(false);
	const [projection, setProjection] = useState(initialProjection);
	
	const update = p => setProjection(p);
	const open = () => setEdit(true);
	const close = () => { reset(); setEdit(false); };
	const reset = () => setProjection(initialProjection);
	const submit = () => { onChange(projection); setEdit(false); };

	return <>
		<Button variant="outline-primary" size="sm"><FaCog onClick={open} /></Button>
		{!!edit && 
			 <Modal onHide={close} show={edit} size="lg" aria-labelledby={`change-table-settings`} centered>
				<Modal.Header closeButton>
					<Modal.Title>Table Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<SelectAndOrder 
						onChange={update}
						value={projection}
						options={options}
						allowDuplicates={false}
						renderOption={o => o.name}
						compare={(a, b) => a.key === b.key}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button className="float-start" variant="link" size="sm" onClick={reset}>Reset</Button>			
					<ConfirmButtons onConfirm={submit} onCancel={close} />
				</Modal.Footer>
			</Modal>
			}
	</>
}