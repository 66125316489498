import { AsyncButton, NavigationBar } from "Components/Shared";
import { BadRequest } from "Errors";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaBackward, FaForward, FaSave } from "react-icons/fa";
import { useParams } from "react-router-dom";
import findDifferences from "utils/findDifferences";
import shortId from "utils/shortId";
import { JobForm } from ".";
import { JobsApi } from "../../api";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import { canEditJob, isPermitted } from "utils/permissions";

const JobEdit = ({activeUser = {}}) => {
	const jobId = useParams().jobId;

	const [loading, setLoading] = useState(true);
	const [job, setJob] = useState({});
	const [changes, setChanges] = useState({});
	const [formStage, setFormStage] = useState("stage2");
	const [validation, setValidation] = useState([]);
	const [error, setError] = useState();

	const links = [{
		text: "Contract Terms",
		value: "stage1",
		active: formStage === "stage1",
		validationFields: ["ancillaries"],
		permission: "editJobContractTerms"
	},{
		text: "Pickup",
		value: "stage2",
		active: formStage === "stage2",
		validationFields: ["pickup"]
	},{
		text: "Dropoff",
		value: "stage3",
		active: formStage === "stage3",
		validationFields: ["dropoff"]
	},{
		text: "Details",
		value: "stage4",
		active: formStage === "stage4",
		validationFields: ["meta", "flightNumber", "airwayBill", "billOfLading", "jobNumber", "weight", "pieces"]
	}];

	useEffect(() => {
		const fetchData = async () => {
				setLoading(true);
				try {
						const { calculatedMiles, customerMiles, pieces, weight, jobNumber, meta, flightNumber, airwayBill, billOfLading, dropoff, pickup, ancillaries, additionalDetails, status } = await JobsApi.read(jobId);
						const job = { calculatedMiles, customerMiles, pieces, weight, jobNumber, meta, flightNumber, airwayBill, billOfLading, dropoff, pickup, ancillaries, additionalDetails, status };
						setJob(() => job);
						setChanges(() => ({ ...job }));
				}
				catch(err) {
						console.error(err);
						setError(err);
				}
				finally {
						setLoading(false);
				}
		}
		fetchData();
	}, []);

	if(!canEditJob(activeUser, job.status) || error instanceof NotAuthorizedErrorType ) {
			return <NotAuthorizedError redirect={`/jobs/${jobId}`} redirectMessage="Back to Job" />
	}
	
	const handleNextStage = () => {
		const curr = links.findIndex(l => l.value === formStage);
		setFormStage(links[curr + 1].value);
	}

	const handlePreviousStage = () => {
		const curr = links.findIndex(l => l.value === formStage);
		setFormStage(links[curr - 1].value);
	}

	const removeValidationError = (names = []) => {
		setValidation((v) => v.filter(e => !names.includes(e.param)));
	}

	const handleSubmit = async () => {
		setValidation([]);
		const diff = findDifferences(changes, job);
		console.log({diff});
		if(!Object.keys(diff).length) {
			throw new Error("No changes to save.");
		}
		try {
			await JobsApi.update(jobId, diff);
		} catch(err) {
			if(err instanceof BadRequest)
			{
				setValidation(err.validationErrors?.errors || []);
			}
		}
	}

	if(!!loading) {
		return <>Loading...</>
	}
	
	const stages = links.filter(l => l.value.startsWith("stage") && !!isPermitted(activeUser, l.permission));
	const isLastStage = stages.length === stages.findIndex(l => l.value === formStage) + 1;
	const isFirstPage = links.findIndex(l => l.value === formStage) === 0;

	return (<>
		<Row className="justify-content-md-center">
			<Col xs="10">
				<h1 className="mb-3">Editing job <a href={`/jobs/${jobId}`}>{shortId(jobId)}</a></h1>
			</Col>
		</Row>
		<Row className="justify-content-md-center">
			<Col xs="10">
				<NavigationBar onClick={setFormStage} links={stages} className="mb-3" validation={validation} />
				<JobForm {...{activeUser, job: changes, stage: formStage, validation, clearValidation: removeValidationError, onChange: setChanges }} />
			</Col>
		</Row>
		<Row className="mt-3">
			<Col><Button variant="link" onClick={handlePreviousStage} disabled={isFirstPage}><FaBackward /> Previous</Button></Col>
			<Col className="text-center">
				<AsyncButton onClick={handleSubmit}><FaSave /> Save Changes</AsyncButton>
			</Col>
			<Col className="text-end"><Button variant="link" onClick={handleNextStage} disabled={isLastStage}>Next <FaForward /></Button></Col>
		</Row>
	</>);
}

export default JobEdit;