import React, { useState, useEffect } from "react";
import { Placeholder, Card, Row, Col, Breadcrumb } from "react-bootstrap";
import Pagination from "../Shared/Pagination";
import MarketCard from "./MarketCard"
import { MarketsApi } from "../../api";
import MarketFilter from "./MarketFilter";
import { FaExclamationTriangle } from "react-icons/fa";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const MarketLoadingCard = () => {
	return (
		<Placeholder animation="glow">
			<Card>
				<Card.Header><Placeholder xs={3} /></Card.Header>
				<Card.Body>
					<Placeholder style={{ height:"95px", width: "100%" }} />
				</Card.Body>
			</Card>
		</Placeholder>
	)
}

const MarketList = ({activeUser}) => {
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState([]);
	const [sortOptions, setSortOptions] = useState([]);
	const [filter, setFilter] = useState({});
	const [pagination, setPagination] = useState({ skip: 0, limit: 10 });

	useEffect(() => {
		const fetchData = async () => {
			try {
				const marketResult = await MarketsApi.filter({...pagination, ...filter, clientId: filter?.client?._id, projection: "snapshot"});
				setResults(marketResult);
				setSortOptions(marketResult.sortOptions);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		}
		fetchData();
	}, [filter, pagination]);

	if(!isPermitted(activeUser, "viewMarkets"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	const markets = results?.markets || [];

	return (
		<>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/markets`}>Markets</Breadcrumb.Item>
					</Breadcrumb>  
				</Col>
			</Row>
			<Row>
				<Col>
					<h1>Markets {!!results?.totalCount && <>({results?.totalCount || 0})</>}</h1>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col>
					<MarketFilter filter={filter} sortOptions={sortOptions} onChange={setFilter} />
				</Col>
			</Row>
			<Row className="mt-3">
				<Col>
					{ !loading && !!markets?.length && markets.map((m, i) => <MarketCard {...m} key={`market-${m._id}`} />)}
					{ !loading && !markets?.length && <p className="text-muted text-center m-0 p-3"><FaExclamationTriangle /> No markets found.</p>}
					{ !!loading && [...Array(10)].map((_,i) => <MarketLoadingCard key={`market-placeholder-${i}`} />)}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Pagination 
							loading={!!loading} 
							skip={pagination.skip || 0}
							limit={pagination.limit || 10}
							totalCount={results?.totalCount}
							setSkip={(s) => setPagination(p => ({...p, skip: s}))}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default MarketList;