import React, { useEffect, useState } from "react";
import format from "../../utils/format";
import "./JobHistory.css";
import { FaSyncAlt, FaRobot } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { LoadingIndicator } from "../Shared";
import { JobsApi } from "../../api";

const getMessage = (action, changes) => {
    if(!!changes.driverId && !!changes.name) {
        action = action.replace("{driverName}", `<a href="/users/${changes.driverId}">${changes.name}</a>`);
    }
    return action;
};

const HistoryEntry = ({
    action = "",
    timestamp = "",
    changes = {},
    user: {
        name: userName = "",
        picture: userPicture = "",
        _id: userId = ""
    } = {}
}) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleShowDetails = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowDetails(!showDetails)
    }

    return <>
        <div className="d-flex">
            <div className="flex-shrink-0">
                {!!userName &&
                <a href={`/users/${userId}`}>
                    <img className="me-3 rounded-circle" src={userPicture} alt={`${userName}'s picture`} height="40px" />
                </a>}
                {!userName && <FaRobot className="me-3" style={{height: "40px", width: "40px"}} />}
            </div>
            <div className="flex-grow-1 ms-3">
                <div>
                    {!!userName && <strong className="mt-0"><a className="text-dark" href={`/users/${userId}`}>{userName}</a></strong>}
                    {!userName && <strong className="mt-0">System</strong>}
                    <small className="text-secondary ms-2" title={format.fullDate(timestamp)}><strong>{format.timeElapsed(timestamp)}</strong></small>
                </div>
                <p>
                    <span dangerouslySetInnerHTML={{__html: getMessage(action, changes)}} /> <a href="#" onClick={handleShowDetails}>Show details</a>
                </p>
                {showDetails && <pre>
                    {JSON.stringify(changes, null, 2)}
                </pre>}
            </div>
        </div>
    </>
}

const JobHistory = ({
    jobId = "",
    events: initialEvents = []
}) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState(initialEvents);
    const [total, setTotal] = useState(0);

    useEffect(async () => {
        setLoading(true);
        const history = await JobsApi.getHistory({ jobId, skip: 0, take: 5 });
        setEvents(history?.events || []);
        setTotal(history?.total);
        setLoading(false);
    }, []);

    const resetHistory = async () => {
        setLoading(true);
        setEvents([]);
        setTotal(0);
        const history = await JobsApi.getHistory({ jobId, skip: 0, take: 5 });
        setEvents(history.events);
        setTotal(history.total);
        setLoading(false);
    }

    const loadMoreEvents = async () => {
        setLoading(true);
        const nextEventSet = await JobsApi.getHistory({ jobId, skip: events.length - 1, take: 5 });
        setEvents((e) => [...e, ...nextEventSet.events]);
        setLoading(false);
    }

    return (
        <div className="history-list">
            <Button variant="light" onClick={resetHistory} disabled={loading}><FaSyncAlt className={loading && `animate-pulse`} /></Button>
            <ul className = "mt-3">
                {events.map((h, i) => <li key={`history-${i}`}><HistoryEntry {...h} /></li>)}
                {!!loading && <li><LoadingIndicator /></li>}
            </ul>
			<div className="text-center">
				{ events.length < total && <Button onClick={loadMoreEvents}>Load more events</Button> }
			</div>
        </div>
	);
};

export default JobHistory;