import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import * as regionApi from "../../api/regionApi";
import { LoadingIndicator } from "../Shared";
import { FaExclamationTriangle, FaPlusSquare } from "react-icons/fa";
import RegionCard from "./RegionCard";

const RegionList = ({
	activeUser = {},
	regions: initialRegions = { total: 0, results: [] }
}) => {
	const [regions, setRegions] = useState(initialRegions);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const regionResult = await regionApi.filter();
				setRegions(regionResult);
			}
			catch (error) {

			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	return (
		<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/regions`}>Regions</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<h1>Regions {!loading && regions?.totalCount && <>({regions.totalCount})</>} <Button href="/regions/new" size="sm" className="float-end"><FaPlusSquare /> Add New Region</Button></h1>
				<hr />
			</Col>
		</Row>
		<Row>
			<Col>
				{ !!loading && <div className="text-center"><LoadingIndicator /></div>}
				{ !loading && !regions?.results?.length && <p className="text-muted text-center"><FaExclamationTriangle /> No regions found.</p> }
				{ !loading && !!regions?.results?.length && regions.results.map((r,i) => <RegionCard region={r} key={`region-${i}`} />)}
			</Col>
		</Row>
		</>
		
	);
};

export default RegionList;