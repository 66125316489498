import React from "react";
import { Form } from "react-bootstrap";

const Validation = ({
	name = "",
	names = [],
	validation = []
}) => {
	const validationErrors = validation.filter(e => e.param === name || names.includes(e.param));
	return(
		<Form.Control.Feedback type="invalid">
			<ul className="mb-3">
				{validationErrors.map((v,i) => <li key={`error-${i}`}>{v.msg}</li>)}
			</ul>
		</Form.Control.Feedback>
	)
}

export default Validation;