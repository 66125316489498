import React from "react";
import { Outlet } from "react-router-dom";

const UsersPageSwitch = ({activeUser = {}}) => {
  return (
		<div className="user-page">
			<Outlet />
		</div>
  );
};

export default UsersPageSwitch;