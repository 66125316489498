import React, { useState, useEffect } from "react";
import format from "../../utils/format";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { Form, Button } from "react-bootstrap";
import { ConfirmButtons } from "../Form";
import AutogrowTextarea from "./AutogrowTextarea";

const Note = ({
	_id: noteId, createdAt, updatedAt,
	text: initialText, 
	currentUser = {},
	user: {
			_id: userId,
			name: userName,
			picture: userPicture
	} = {},
	onChange = (noteId, text) => console.log("Edit note success", noteId, text),
	onDelete = (noteId) => console.log("Delete note success", noteId)
}) => {
	const [newText, setNewText] = useState(initialText);
	const [text, setText] = useState(initialText);
	const [deleteMode, setDeleteMode] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [deleted, setDeleted] = useState(false);

	const handleDelete = async () => {
		await onDelete(noteId);
		setDeleted(true);
	}

	const handleSubmit = async () => {
		await onChange({ noteId, text: newText });
		setText(newText);
		setEditMode(false);
	}

	const canModify = (new Date() - new Date(createdAt)) < 600000;

	let content;
	if(editMode) {
		content = (<>
			<Form>
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<AutogrowTextarea placeholder="Add a note..." text={newText} onChange={setNewText} />
				</Form.Group>
				<div className="float-end">
					<ConfirmButtons onConfirm={handleSubmit} onCancel={() => setEditMode(false)} />
				</div>
			</Form>
		</>)
	}
	else if(deleted) {
		content = (<>
			<i className="text-muted">message deleted</i>
		</>)
	}
	else {
		content = (<>
			{text}
			{
				currentUser._id === userId && canModify &&
				<div className="mt-3 float-end bd-highlight">
					 { !deleteMode && 
					 		<>
							<Button size="sm" variant="light" className="me-1" onClick={() => setEditMode(true)}><FaRegEdit /></Button> 
							<Button size="sm" variant="light" onClick={() => setDeleteMode(true)}><FaRegTrashAlt /></Button>
							</>
						}
						{
							!!deleteMode && <ConfirmButtons onConfirm={handleDelete} onCancel={() => setDeleteMode(false)} />
						}
				</div>
			}
		</>)
	}

	return (<>
		<div className="d-flex">
			<div className="flex-shrink-0">
				<a href={`/users/${userId}`}>
					<img className="me-3 rounded-circle" referrerPolicy="no-referrer" src={userPicture} alt={`${userName}'s picture`} height="40px" />
				</a>
			</div>
			<div className="flex-grow-1 ms-3">
				<div>
					<strong className="mt-0"><a className="text-dark" href={`/users/${userId}`}>{userName}</a></strong> 
					<small className="text-secondary ms-2" title={format.fullDate(createdAt)}><strong>{format.timeElapsed(createdAt)}</strong></small>
				</div>
				{content}
			</div>
		</div>
	</>);
}

export default Note;