import { TimezoneContextProps, withTimezone } from "Components/GlobalState/TimezoneContext";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaGlobeAmericas } from "react-icons/fa";
import moment from "moment";
import 'moment-timezone';
import ConfirmButtons from "./ConfirmButtons";
import Typeahead from "./Typeahead";

const supportedTimezones = [
	"US/Mountain",
	"US/Pacific",
	"US/Eastern",
	"US/Central"
];

const TimezoneInput = withTimezone((props:TimezoneContextProps) => {
	const { 
		timezone = moment.tz.guess(), 
		onChangeTimezone = () => console.error("Failed to change timezone, no onChange handler defined.")
	} = props;

	const [edit, setEdit] = useState(false);
	const [selection, setSelection] = useState(timezone);

	const options = supportedTimezones;

	const empty = () => setSelection(moment.tz.guess());
	const reset = () => { setSelection(timezone); setEdit(false); };
	const change = (newValue:string) => setSelection(newValue);
	const submit = () => {
		setEdit(false);
		onChangeTimezone(selection);
	}

	return <>
		<Button size="sm" onClick={() => setEdit(true)}>
			<FaGlobeAmericas /> {props.timezone}
		</Button>
		{!!edit && 
			<Modal onHide={reset} show={edit} size="lg" aria-labelledby={`change-timezone-filter`} centered>
				<Modal.Header closeButton>
					<Modal.Title><FaGlobeAmericas /> Set Timezone</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						{ options.map((o, i) => 
								<Form.Check
									onChange={() => change(o || "")} 
									value={o} 
									checked={selection === o} 
									type="radio" 
									id={`option-${o}-${i}`} 
									key={`option-${o}-${i}`}
									label={o} 
								/>
						)}
					</Form.Group>
					<Form.Group className="mt-2">
						<Typeahead 
							fetchAllOnOpen={true}
							getSuggestions={() => Promise.resolve(moment.tz.names().map(t => ({id: t, text: t})))}
							getDetails={(id) => Promise.resolve(id)}
							onChange={(o) => change(o as string)}
							value={{id: selection, text: selection}}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button className="float-start" variant="link" size="sm" onClick={empty}>Clear</Button>	
					<ConfirmButtons onConfirm={submit} onCancel={reset} />
				</Modal.Footer>
			</Modal>
		}
	</>
	});

export default TimezoneInput;