import React, { useEffect, useState } from "react";
import { Card, Col, Row, Badge, Container, Button, Tabs, Tab, Modal, Form, Breadcrumb } from "react-bootstrap";
import { FaRegClock, FaEdit } from "react-icons/fa";
import { Address, LoadingIndicator, NoteList } from "../Shared";
import format from "../../utils/format";
import DeliveryInformation from "./DeliveryInformation";
import JobHistory from "./JobHistory"
import ChargeList from "./ChargeList";
import { JobsApi, NotesApi } from "../../api";
import NoteForm from "../Shared/NoteForm";
import JobAssignment from "./JobAssignment";
import { useParams } from "react-router-dom";
import "./JobDetails.css";
import { getNumber } from "../../utils/formHelpers";
import { PublishData } from "../Syndicated";
import { HiTemplate } from "react-icons/hi";
import Mileage from "./Mileage";
import DateTimeSelect from "Components/Form/DateTimeSelect";
import { FullDate } from "Components/Shared/Date";
import JobStatus, { getDisplay } from "./JobStatus";
import { canEditJob, isPermitted } from "utils/permissions";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";

const JobDetails = ({
    jobId: _id = "",
    drivers: initialDrivers = [],
    charges: initialCharges = [],
    totalCharges: initialTotalCharges = 0,
    job: initialJob = {},
    activeUser = {}
}) => {
    const jobId = useParams().jobId;
    const [showModal, setShowModal] = useState(false);
    const [targetStatus, setTargetStatus] = useState("review");
    const [note, setNote] = useState("");
    const [charges, setCharges] = useState(initialCharges)
    const [totalCharges, setTotalCharges] = useState(initialTotalCharges);
    const [drivers, setDrivers] = useState(initialDrivers);
    const [loading, setLoading] = useState(false);
    const [job, setJob] = useState(initialJob);
    const [timestamp, setTimestamp] = useState(new Date());
    const [pod, setPod] = useState("");
    const [minutesWaiting, setMinutesWaiting] = useState();
    const notesApi = NotesApi(`Jobs/${jobId}`);
    const [error, setError] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const job = await JobsApi.read(jobId);
                setJob(job);
                setCharges(job.charges);
                setDrivers(job.drivers);
            }
            catch(err) {
                setError(err);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    if(error instanceof NotAuthorizedErrorType) {
        return <NotAuthorizedError redirect="/" redirectMessage="Back to Home" />
    }

    const changeStatus = async (newStatus) => {
        setLoading(true);
        const statusChangeResult = await JobsApi.updateStatus({ jobId, newStatus, timestamp, pod, minutesWaiting });
        setJob(statusChangeResult.job);
        setLoading(false);
    }

    const changeStatusRequest = async (newStatus) => {
        setShowModal(true);
        setTargetStatus(newStatus);
    }

    const handleModalCancel = () => {
        setTargetStatus(status);
        setShowModal(false);
    }

    const handleModalSubmit = async () => {
        let saveNotePromise;
        if(!!note) {
            saveNotePromise = await notesApi.create({ text: note })
        }
        await Promise.all([changeStatus(targetStatus), saveNotePromise]);
        setTargetStatus(status);
        setShowModal(false);
    }

    const canDeleteDriver = (driverId) => !charges.some(d => !!d.forUser && d.forUser === driverId);

    const saveDrivers = async (newDrivers = []) => {
        await JobsApi.updateDrivers({ jobId, drivers: newDrivers });
        setDrivers(newDrivers);
        return newDrivers;
    }

    if(!job._id || !!loading) {
        return (<LoadingIndicator />);
    }

    const {
        jobNumber,
        market = {},
        pickup = {},
        dropoff = {},
        customerMiles = 0,
        calculatedMiles = 0,
        ancillaries: { useCustomerMiles = false },
        workflow = {},
        meta = {},
        status = "review",
        additionalDetails
    } = job;

    meta.requiresVan = meta.requiresVan || job?.weight >= 50;

    const currentWorkflow = workflow[status];

    const editUrl = `/jobs/${jobId}/edit`;
    const canEdit = canEditJob(activeUser, job?.status);

	return (
        <>
        <Container className="mb-1">
            <Row>
                <Col>
                    <Breadcrumb>
                        {!!market?.customer?._id && <Breadcrumb.Item href={`/clients/${market?.customer?._id}`}>{market?.customer?.name}</Breadcrumb.Item>}
                        {!!market?.region?._id && <Breadcrumb.Item href={`/regions/${market?.region?.url}`}>{market?.region?.name}</Breadcrumb.Item>}
                        {!!market?._id && <Breadcrumb.Item href={`/markets/${market?._id}`}>{market?.name}</Breadcrumb.Item>}
                        {(!!job?.invoice?.invoiceId || !!job?.invoice?._id) && <Breadcrumb.Item href={`/invoices/${job?.invoice?.invoiceId || job?.invoice?._id}`}>Invoice {!!job?.invoice?.invoiceId || job?.invoice?._id}</Breadcrumb.Item> }
                    </Breadcrumb>  
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <span className="h3">Job {jobNumber} <JobStatus status={status} /></span>
                        </Card.Header>
                        <Card.Body>                                    
                            {<>Ready <Badge bg="warning" className="text-dark me-1"><FaRegClock /> <FullDate date={pickup.readyAt} /></Badge></>}
                            {pickup?.pickedupAt && <>Picked up <Badge bg="success"><FaRegClock /> <FullDate date={pickup.pickedupAt} /></Badge></>}
                            <Address {...pickup} />
                            {<>Deliver by <Badge bg="warning" className="text-dark me-1"><FaRegClock /> <FullDate date={dropoff.deliverBy} /></Badge></>}
                            {dropoff?.dropoffAt && <>Delivered <Badge bg="success"><FaRegClock /> <FullDate date={dropoff.dropoffAt} /></Badge></>}
                            <Address {...dropoff} />
                            <Mileage {...{ customerMiles, calculatedMiles, useCustomerMiles }} />
                            {!!additionalDetails && <div className="mt-2">
                                <strong>Extra Details:</strong>
                                <p>{additionalDetails}</p>
                            </div>}
                        </Card.Body>
                    </Card>
                    <div className="mt-2">
                        { canEdit && <Button className="me-1" href={editUrl}><FaEdit /> Edit job</Button> }
                        { isPermitted(activeUser, "createJobTemplate") && <Button className="me-1" href={`/jobs/templates/create?jobId=${job?._id}`}><HiTemplate /> Save as template</Button> }
                    </div>
                </Col>
                <Col>
                    <Row className="mb-2">
                        <Col>
                            <JobAssignment canEdit={canEdit} activeUser={activeUser} drivers={drivers} onChange={saveDrivers} canDeleteDriver={canDeleteDriver}  requiresTSANumber={job?.meta?.requiresTSANumber} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DeliveryInformation { ...meta } {...job} activeUser={activeUser} totalCharges={totalCharges} />
                        </Col>
                    </Row>
                    { canEdit && !!currentWorkflow.next && currentWorkflow.next.map((n, i) => 
                        <div key={`next-${i}`} className="d-grid gap-2 mt-2">
                            <Button variant={ i === 0 ? "primary" : "outline-primary" } className="btn-lg btn-block" onClick={() => changeStatusRequest(n)}>
                                Move job to {format.toTitleCase(getDisplay(n))} status
                            </Button>
                        </div>
                    )}
                    { canEdit && !!currentWorkflow.previous && currentWorkflow.previous.map((n, i) => 
                        <div key={`previous-${i}`} className="d-grid mt-2 gap-2">
                            <Button variant={ i === 0 ? "danger" : "outline-danger" } className="btn-lg btn-block" onClick={() => changeStatusRequest(n)}>
                                Go back to {format.toTitleCase(getDisplay(n))} status
                            </Button>
                        </div>
                    )}
                    { canEdit && status !== 'cancelled' && status !== 'bill' && status !== 'archive' && <>
                        <div className="d-grid mt-2 gap-2">
                            <Button variant="outline-danger" className=" btn-lg btn-block" onClick={() => changeStatusRequest('cancelled')}>
                                Cancel job
                            </Button>
                        </div>
                    </>}
                </Col>
            </Row>
            {isPermitted(activeUser, "viewJobMoney") && <Row className="mt-2">
                <Col>
                    <ChargeList canEdit={canEdit} job={job} drivers={drivers.map(d => d.driver)} charges={charges} onChange={({charges, total}) => {
                        setTotalCharges(total);
                        setCharges(charges);
                    }} jobId={jobId} />
                </Col>
            </Row>}
            <Row>
                <Col>
                    <Tabs defaultActiveKey="notes" id="info" className="mt-2" onSelect={(key) => key === "history"}>
                        <Tab eventKey="notes" title="Notes">
                            <div className="mt-4">
                                <NoteList 
                                    notesApi={notesApi}
                                    currentUser={activeUser}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="history" title="Change Log">
                            <JobHistory jobId={jobId} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PublishData {...job} />
                </Col>
            </Row>
        </Container>
        <Modal show={showModal} onHide={handleModalCancel}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Status Change to {format.toTitleCase(getDisplay(targetStatus))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You are about to change the status of the job to {format.toTitleCase(getDisplay(targetStatus))}. Optionally include a note 
                explaining the change.</p>
                <NoteForm currentUser={activeUser} onChange={setNote} />
                { targetStatus === "complete" && <>
                    <Form.Group>
                        <Form.Label>Delivery Date and Time  <span className="text-danger">(required)</span></Form.Label>
                        <div  className="form=control">
                            <DateTimeSelect value={timestamp} onChange={setTimestamp} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>POD <span className="text-danger">(required)</span></Form.Label>
                        <Form.Control 
                            type="text"
                            onChange={(e) => setPod(e.currentTarget.value)} 
                            placeholder="Enter proof of delivery"
                            value={pod}
                            name="pod"
                        />
                    </Form.Group>
                </> }
                { targetStatus === "deliver" && <>
                    <Form.Group>
                        <Form.Label>Pickup Date and Time</Form.Label>
                        <div className="form=control">
                            <DateTimeSelect value={timestamp} onChange={setTimestamp} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>Minutes waiting</Form.Label>
                        <Form.Control 
                            type="text"
                            onChange={(e) => setMinutesWaiting(getNumber(e))} 
                            placeholder="Enter the minutes waited (e.g. 2 hours = 180)"
                            value={minutesWaiting}
                        />
                    </Form.Group>
                </> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Close</Button>
                <Button variant="primary" onClick={handleModalSubmit} disabled={targetStatus === "complete" && (!pod || !timestamp)}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
        </>
	);
};

export default JobDetails;