import { apiFetch } from "./api";
import { buildUrlParams } from "./helpers";
import { Client, ClientFilter, IApiFilterResult } from "./Types";

const clientsApi = {
	filter: async (props:ClientFilter):Promise<IApiFilterResult<Client>> => {
		const { 
			sort = "default", 
			limit = 10, 
			skip = 0, 
			projection = "default"
		} = props;
		const qs = buildUrlParams({...props, sort, limit, skip, projection});
		const response = await apiFetch(
			`/customers?${qs}`
		);
		return response;
	},
	read: async (clientId:string, projection = "default") => {
		const response = await apiFetch(
			`/customers/${clientId}?projection=${projection}`
		);
		return response;
	},
	update: async (clientId:string, params:Client) => {
		const response = await apiFetch(
			`/customers/${clientId}`,
			{
				method: "PUT",
				cache: "no-cache",
				body: JSON.stringify(params)
			}
		);

		return response;
	},
	create: async (params:Client) => {
		const response = await apiFetch(
			`/customers`,
			{
				method: "POST",
				cache: "no-cache",
				body: JSON.stringify(params)
			}
		);

		return response;
	},
	delete: async (params:Client) => {
		const { _id } = params;
		const response = await apiFetch(
			`/customers/${_id}`,
			{
				method: "DELETE",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				}
			}
		);
		return response;
	}
}

export default clientsApi;