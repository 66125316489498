import React, { useState } from "react";
import { Button, Image, Table } from "react-bootstrap";
import { FaRobot } from "react-icons/fa";
import { Link } from "react-router-dom";
import format from "../../utils/format";
import shortId from "../../utils/shortId";

const PayeeDetails = ({
	user = {},
	total = 0,
	grossRevenue = 0,
	jobs = []
}) => {
	const [showMore, setShowMore] = useState(false);

	const image = !!user?._id ? <Image referrerPolicy="no-referrer" roundedCircle height="32px" src={user.picture} /> : <FaRobot style={{height: "32px", width: "32px"}} />

	return (
		<>
			<tr>
				<td colSpan="3">{image} {user.name} <Button variant={`outline-${showMore ? "secondary" : "primary"}`} className="float-end" size="sm" onClick={() => setShowMore(!showMore)}>{showMore ? "Hide" : "View"} Job Details</Button></td>
				<td colSpan="1" className="text-end">{format.toPercent(user.jobCut)}</td>
				<td colSpan="1" className="text-end">{format.currency(grossRevenue)}</td>
				<td colSpan="1" className="text-end">{format.currency(total)}</td>
			</tr>
			{ !!showMore && 
				<>
					<tr>
						<th><small>Job #</small></th>
						<th><small>Cust #</small></th>
						<th className="text-end"><small>Commission Basis</small></th>
						<th className="text-end"><small>Completion %</small></th>
						<th className="text-end"><small>Commission</small></th>
						<th className="text-end"><small>Reimbursement</small></th>
						<th className="text-end"><small>Total</small></th>
					</tr>
					{jobs.map((j, i) => 
						<tr key={`jd-${i}`}>
							<td><small><Link target="_blank" to={`/jobs/${j.jobId}`} rel="noreferrer">{shortId(j.jobId)}</Link></small></td>
							<td><small><Link target="_blank" to={`/jobs/${j.jobId}`} rel="noreferrer">{j.jobNumber}</Link></small></td>
							<td className="text-end"><small>{format.currency(j.commissionBasis)}</small></td>
							<td className="text-end"><small>{format.toPercent(j.completion)}</small></td>
							<td className="text-end"><small>{format.currency(j.commission)}</small></td>
							<td className="text-end"><small>{format.currency(j.reimbursement)}</small></td>
							<td className="text-end"><small>{format.currency(j.total)}</small></td>
						</tr>
						)} 
				</>
			}
		</>
	)
} 

const Payroll = ({ payroll = {}, activeUser = {} }) => {
	const {
		payees = [],
		grossRevenue,
		total
	} = payroll;

	let subGross = 0;
	let subTotal = 0;

	const systemId = payees.findIndex(p => !p?.user?._id);
	const hasSystem = systemId >= 0;
	if(hasSystem) {
		payees[systemId].user = { name: "system" };
		const system = payees.splice(systemId, 1)[0];
		subGross = payees.reduce((acc,v) => acc+v.grossRevenue,0);
		subTotal = payees.reduce((acc,v) => acc+v.total,0);
		payees.push(system);
	}

	return (
	<Table bordered>
		<thead>
			<tr>
				<th colSpan="3">Driver</th>
				<th colSpan="1" className="text-end">Job Cut</th>
				<th colSpan="1" className="text-end">Gross Revenue</th>
				<th colSpan="1" className="text-end">Total Earned</th>
			</tr>
		</thead>
		<tbody>
			{payees.map((p,i) => <PayeeDetails key={`payee-${i}`} {...{...p}} activeUser={activeUser} />)}
		</tbody>
		<tfoot>
			{hasSystem && <tr>
				<td colSpan="3" className="text-end">Subtotal:</td>
				<td colSpan="1" className="text-end">{format.toPercent(subTotal/subGross,2)}</td>
				<td colSpan="1" className="text-end">{format.currency(subGross)}</td>
				<td colSpan="1" className="text-end">{format.currency(subTotal)}</td>
			</tr>}
			<tr>
				<td colSpan="3"></td>
				<td colSpan="1"></td>
				<td colSpan="1" className="text-end text-bold">{format.currency(grossRevenue)}</td>
				<td colSpan="1" className="text-end text-bold">{format.currency(total)}</td>
			</tr>
		</tfoot>
	</Table>);
}

export default Payroll;