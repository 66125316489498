import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { Payroll } from ".";
import { JobsApi, PayrollApi } from "../../api";
import { DateSelectionCalendar, SaveButton } from "../Form";
import JobCard from "../Job/JobCard";
import { AsyncButton, LoadingIndicator, Pagination, RefreshButton, Widget } from "../Shared";

const PayrollForm = ({activeUser}) => {	
	const [stage, setStage] = useState(1);
	const [cutoffDate, setCutoffDate] = useState();
	const [jobsResult, setJobsResult] = useState([]);
	const [limit, setLimit] = useState(10);
	const [skip, setSkip] = useState(0);
	const [loadingJobs, setLoadingJobs] = useState(false);
	const [payroll, setPayroll] = useState({});
	const [excludedJobs, setExcludedJobs] = useState([]);

	const getJobs = async () => {
		if(!cutoffDate) {
			return;
		}
		setLoadingJobs(true);
		const date = cutoffDate;
		date.setHours(23);
		date.setMinutes(59);
		date.setSeconds(59);
		const jobResult = await JobsApi.filter({ 
			limit, 
			skip, 
			projection: "dashboard",
			dropoffAtEndDate: date.getTime(),
			status: ["complete", "bill", "archive"],
			hasPayroll: false
		});
		setJobsResult(jobResult);
		setLoadingJobs(false);
	};

	const getPreview = async () => {
		const payrollResult = await PayrollApi.preview(excludedJobs, cutoffDate.getTime());
		setPayroll(payrollResult);
	}

	const handleGetPreview = async () => {
		await getPreview();
		setStage(3);
	}

	const handleJobCheck = (id) => {
		setExcludedJobs(j => {
			const idx = j.indexOf(id);
			if(idx >= 0) {
				const newJobs = [...j];
				newJobs.splice(idx, 1);
				return newJobs;
			}
			return [...j, id];
		});
	}

	const handleGetJobs = async () => {
		setStage(2);
		await getJobs();
	}

	useEffect(() => {
		getJobs();
	}, [skip])

	const submit = async () => {
		const payrollResult = await PayrollApi.submit(excludedJobs, cutoffDate.getTime());
		if(!!payrollResult?._id) {
			window.location.href = `/payroll/${payrollResult._id}`;
		}
	}

	return <>
		<h1>Begin new Payroll</h1>
		<hr />
		{ stage === 1 && 
			<Widget title="Step 1: Select last day of payroll period">
				<Container>
					<Row>
						<Col md={{span:6, offset: 3}}>
							<DateSelectionCalendar date={cutoffDate} onChange={setCutoffDate} />
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button className="float-end" size="sm" onClick={handleGetJobs} disabled={!cutoffDate}>Review Jobs <FaAngleDoubleRight /></Button>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
		{
			stage === 2 &&
			<Widget title={<>Step 2: Review jobs included in this payroll <RefreshButton className="float-end" size="sm" onClick={getJobs}>Refresh</RefreshButton></>}>
				<Container>
					<Row>
						<Col>
							{ !!loadingJobs && <LoadingIndicator /> }
							<Table borderless>
								<tbody>
									{ !jobsResult.jobs?.length && <tr><td className="text-center">No jobs ready for payroll. Try a different date.</td></tr> }
									{ !!jobsResult.jobs?.length && jobsResult.jobs.map((job, i) => 
										<tr key={`job-${i}`}>
											<td className="align-middle"><Form.Check checked={excludedJobs.indexOf(job._id) === -1} onClick={() => handleJobCheck(job._id)} /></td>
											<td><JobCard {...job} activeUser={activeUser} /></td>
										</tr>
									)}
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2" className="text-center"> 
											<div style={{display: "inline-block"}}>
												{ !!jobsResult.jobs?.length && <Pagination {...{limit, skip, totalCount: jobsResult?.totalCount, setSkip}} /> }
											</div>
										</td>
									</tr>
								</tfoot>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button variant="link" className="float-left" size="sm" onClick={() => setStage(1)}><FaAngleDoubleLeft /> Change date</Button>
							<AsyncButton className="float-end" size="sm" onClick={handleGetPreview} disabled={!jobsResult.jobs?.length}>Preview payroll <FaAngleDoubleRight /></AsyncButton>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
		{
			stage === 3 && 
			<Widget title={<>Step 3: Preview payroll <RefreshButton className="float-end" size="sm" onClick={getPreview}>Refresh</RefreshButton></>}>
				<Container>
					<Row>
						<Col>
							<Payroll payroll={payroll} activeUser={activeUser} />
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button variant="link" className="float-left" size="sm" onClick={() => setStage(2)}><FaAngleDoubleLeft /> Preview jobs</Button>
							<AsyncButton className="float-end" size="sm" onClick={submit} disabled={!jobsResult.jobs?.length}>Confirm Payroll</AsyncButton>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
	</>;
}

export default PayrollForm;