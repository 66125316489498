import React, { useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { ConfirmButtons, DateSelectionCalendar } from "../Form";
import format from "../../utils/format";

const buildText = (name, dateRange) => {
	if(!!dateRange.start && !dateRange.end) {
		return `${name}: after ${format.date(dateRange.start)}`;
	}
	if(!!dateRange.end && !dateRange.start) {
		return `${name}: before ${format.date(dateRange.end)}`;
	}

	if(!!dateRange.start && !!dateRange.end) {
		return `${name}: ${format.date(dateRange.start)} to ${format.date(dateRange.end)}`;
	}

	return name;
}

const DateRangeFilter = ({
	name = "Date Range",
	value = {},
	onChange = ({}:{start:Date,end:Date}) => {},
	...props
}) => {
	const { start, end } = value;
	const [edit, setEdit] = useState(false);
	const [dateRange, setDateRange] = useState({start, end});
	
	const clear = () => { setDateRange({}); onChange({}); };
	const reset = () => { setDateRange(value); setEdit(false) };
	const submit = () => {
		setEdit(false);
		onChange(dateRange);
	};
	
	const onKeyDown = (evt) => {
		switch(evt.keyCode) {
			case 13: // Enter
				evt.preventDefault(); 
				evt.stopPropagation();
				submit();
				break;
			default:
				break;
		}
	}

	const hasValue = !!(value?.start || value?.end);

	return (
		<>
			<ButtonGroup>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					{ buildText(name, value) }
				</Button>
				{ !!hasValue && <Button size="sm" variant="outline-secondary" onClick={clear}><FaTimes /></Button>}
			</ButtonGroup>
			{!!edit && 
			 <Modal onKeyDown={onKeyDown} onHide={reset} show={edit} size="lg" aria-labelledby="add-market-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title>{name} Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<DateSelectionCalendar range={dateRange} rangeSelection={true} onChange={setDateRange} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<ConfirmButtons onConfirm={submit} onCancel={reset} />
				</Modal.Footer>
			</Modal>
			}
		</>
	)
}

export default DateRangeFilter;