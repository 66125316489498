import React from "react";
import { FilterBar } from "../Shared";
import { Button } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { RadioFilter } from "Components/Filter";
import type { RoleFilter as RoleApiFilter } from "../../api/Types/Role";
import { IOption } from "api/Types";

type RoleFilterProps = {
	filter:RoleApiFilter
	sortOptions:IOption[],
	onChange: (filter:RoleApiFilter) => void 
}

const RoleFilter = (props:RoleFilterProps) => {
	const { filter = {}, sortOptions = [], onChange = () => {} } = props;
	const filterKeys = Object.keys(filter)?.filter(k => typeof(filter[(k as keyof RoleApiFilter)]) !== "undefined");
	return (<>
		<FilterBar>
			<span style={{marginLeft: "auto"}}>
				{!!sortOptions.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions} value={filter.sort || sortOptions[0].key} name="Sort" />}
				<Button className="float-end" href="/roles/new" variant="primary" size="sm"><FaPlusSquare /> Create Role</Button>
			</span>
		</FilterBar>
	</>)
}

export default RoleFilter;