import React from "react";
import { JobTemplate } from "api/Types"
import { Card, Col, Row } from "react-bootstrap";
import { PublishData } from "Components/Syndicated";

type Props = {
	template: JobTemplate
}

export const Placeholder = () => {
	return (<></>);
}

const JobTemplateCard = ({ template = {} }:Props) => {
	const { name, description, _id } = template;
	return (<>
		<Card>
			<Card.Body>
				<Card.Title><a href={`/jobs/templates/${_id}`} className="stretched-link">{name}</a></Card.Title>
				<p>{description}</p>
				<PublishData size="sm" {...template} />
			</Card.Body>
		</Card>
	</>);
}

export default JobTemplateCard;