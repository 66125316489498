import React from "react";
import { ClientSelector } from "../Client";
import { SelectFilter, DateRangeFilter, RangeFilter } from "../Filter";
import { FilterBar } from "../Shared";
import { FaTimes } from "react-icons/fa";
import { Button } from "react-bootstrap";

const InvoiceFilter = ({ 
	filter = {}, 
	onChange = () => {} 
}) => {
	const hasFilter = Object.keys(filter).find(k => ["skip", "limit"].indexOf(k) === -1);
	return (<>
		<FilterBar>
			<SelectFilter 
				name="Status" 
				value={filter.status} 
				onChange={(status) => onChange({...filter, status})} 
				options={["new", "sent", "paid"]} 
			/>
			<ClientSelector 
				value={filter.client} 
				onChange={(client) => onChange({...filter, client})} 
				collapsed={true} 
			/>
			<RangeFilter 
				name="Total"
				value={{min: filter.totalMin, max: filter.totalMax}}
				onChange={({min: totalMin, max: totalMax}) => onChange({...filter, totalMin, totalMax})}
			/>
			<DateRangeFilter 
				name="End Date"
				value={{ start: filter.selectedStartDate, end: filter.selectedEndDate }}
				onChange={({start: selectedStartDate, end: selectedEndDate}) => onChange({...filter, selectedStartDate, selectedEndDate})}
			/>
			{!!hasFilter && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
		</FilterBar>
	</>)
}

export default InvoiceFilter;