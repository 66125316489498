import React from "react";
import UserBadge from "./UserBadge";
import session from "../../utils/session";

const propTypes = {};

const LoginForm = ({ activeUser = {}, setActiveUser = (u) => {} }) => {
	const handleLogin = (user) => {
		setActiveUser(user);
		const { href = "/" } = session.getCookie("pulsar.location");
		session.setCookie("pulsar.location", {});
		window.location.href = href.indexOf("users/login") ? "/" : href;
	}

	return (
		<>
			<div className="jumbotron">
				<h1 className="display-4">Session expired!</h1>
				<p className="lead">For security purposes, a user session will only persist for 48 hours. Once expired, the session must be restarted by logging in again.</p>
				<hr className="my-4" />
				<p>Your session has expired. Please log in again to pick up where you left off.</p>
				<p className="lead">
					<UserBadge activeUser={activeUser} setActiveUser={handleLogin} />
				</p>
			</div>
		</>
	)	
}

LoginForm.propTypes = propTypes;

export default LoginForm;