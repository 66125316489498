import React, { createContext, useState } from "react";
import timezoneCookieAPI from "../../utils/timezone";

export type TimezoneContextProps = {
	timezone?: string,
	onChangeTimezone?: React.Dispatch<React.SetStateAction<string>>
};

export const TimezoneContext = createContext<TimezoneContextProps>({});

const TimezoneProvider = (props:TimezoneContextProps & { children?: React.ReactElement[]}) => {
	const [timezone, setTimezone] = useState(timezoneCookieAPI.get());

	const onChangeTimezone = ((newTZ:string) => {
		console.log({newTZ});
		setTimezone(newTZ);
		timezoneCookieAPI.set(newTZ)
	}) as React.Dispatch<React.SetStateAction<string>>

	const state = { timezone, onChangeTimezone };

	return (
		<TimezoneContext.Provider value={state}>
			{props.children}
		</TimezoneContext.Provider>
	)
} 

export const withTimezone = (Child: React.ComponentType<TimezoneContextProps & any>) => (childProps:any) => (
  <TimezoneContext.Consumer>
    {(context) => <Child {...childProps} {...context} /> }
  </TimezoneContext.Consumer>
);

export default TimezoneProvider;