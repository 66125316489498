import React, { useRef, useState } from "react";
import {Button, Overlay} from "react-bootstrap";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { LoadingIndicator } from "./";

const AsyncButton = (props) => {
	const {
		onClick = async () => {},
		onComplete = () => {},
		onError,
		popoverPlacement = "bottom",
		...additionalProps
	} = props;
  const target = useRef(null);
	const [state, setState] = useState("ready");
	const [error, setError] = useState(undefined);

	const handleOnClick = async (event) => {
		setState("loading");
		try {
			await onClick(event);
			setState("loaded");
		} catch(e) {
			console.error(e);
			setState("error");
			typeof(onError) === typeof(() => {}) ? onError(e) : setError(e);
		} finally {
			setTimeout(() => {
				if(typeof(onError) === typeof(() => {})) {
					setError(undefined);
				}
				setState("ready"); 
				onComplete();
			}, 2500);
		}
	}

	if(state === "loaded")
		return (<Button title="Successfully loaded" {...additionalProps} variant="success" disabled={true}>
			<FaCheck />{!!props.children ? " " : ""}{props.children}
		</Button>);

	if(state === "error")
		return (<>
			<Button ref={target} title="Unable to load" {...additionalProps} variant="danger" disabled={true}>
				<FaExclamationTriangle />{!!props.children ? " " : ""}{props.children}
			</Button>
			
      <Overlay target={target} show={true} placement="bottom">
        {(props) => (
          <div className="p2" {...props} style={{position: 'absolute', background: "white", ...props.style}}>
            <small className="text-danger"><FaExclamationTriangle /> {error?.message}</small>
          </div>
        )}
      </Overlay>
		</>)

	if(state === "loading") 
		return (<Button title="Loading..." {...additionalProps} variant="info" disabled={true}>
			<LoadingIndicator />{!!props.children ? " " : ""}{props.children}
		</Button>);
		
	return (<Button {...additionalProps} onClick={handleOnClick} type="submit">
			{!!props.children ? props.children : <FaCheck />}
		</Button>);
}
			
export default AsyncButton;