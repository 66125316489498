import React from "react";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

const NavigationBar = ({
    className,
    links = [],
    validation = [],
    onClick = () => {}
}) => {
    return (<>
      <ButtonGroup className={className} size="sm">
        {links.map((l,i) => {
            const { validationFields = [] } = l;
            const errorCount = validation.filter(e => validationFields.find(vf => vf === e.param || e.param.startsWith(vf))).length;
            return (
                <Button key={`navbutton-${i}`} onClick={() => onClick(l.value)} disabled={l.disabled} variant={l.active ? "primary" : "secondary"}>
                    {l.text}
                    {!!errorCount && <Badge bg="danger" className="ms-2" ><FaExclamationTriangle /> {errorCount}</Badge>}
                </Button>
            )
        }
        )}
    </ButtonGroup>
    </>);
}

export default NavigationBar;