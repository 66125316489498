import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button, Form, InputGroup } from "react-bootstrap";
import { FaPlusSquare, FaTimesCircle } from "react-icons/fa";
import { AsyncButton } from "Components/Shared";
import Validation from "./Validation";

const propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.string
};

const validateEmail = (email) => {
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
  {
    return true
  }
    return false
}

const EmailListInput = (props) => {
	let {
		onChange = () => {},
		placeholder = "Add emails...",
		value = [],
		className = "",
		allowDupes = false,
		disabled = false,
		fieldName,
		validation = [],
		...formControlProps
	} = props;

	if(typeof(value) !== typeof([])) {
		value = [value];
	}

	const [text, setText] = useState("");

	const handleSubmit = () => {
		const v = text.toLowerCase().trim();

		if(!validateEmail(v)) {
			throw new Error("Invalid email.");
		}

		if(!allowDupes && value.includes(v)) {
			throw new Error("Already added to the list.");
		}
		
		// update the email list
		onChange([...value, v]);

		// remove the user inputs
		setText("");
	}

	const removeEmail = (index) => () => {
		delete value[index];
		onChange([...value.filter(e => !!e)]);
	}

	return(<div className={`email-list-input ${className}`}>
		{ !disabled && <InputGroup size="sm" hasValidation>
			<Form.Control
				required
				placeholder={placeholder}
				type="email"
				name="email"
				value={text}
				onChange={(e) => setText(e.currentTarget.value)}
				disabled={disabled}
				{...formControlProps}
			/>
			<AsyncButton onClick={handleSubmit} disabled={!validateEmail(text)}><FaPlusSquare /></AsyncButton>
			<Validation validation={validation} names={[fieldName]} />
		</InputGroup> }
		{!!value.length && 
			<div className="d-flex flex-wrap">
				{value.filter(e => !!e).map((e, i) => 
					<Badge className="mt-2 ms-1" size="sm" bg="secondary" key={`email-${i}`}>
						{e} { !disabled && <FaTimesCircle style={{cursor: "pointer"}} onClick={removeEmail(i)} /> }
					</Badge>)}
			</div>
		}
	</div>);
}

EmailListInput.propTypes = propTypes;

export default EmailListInput;