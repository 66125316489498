import { Client, ClientFilter as ClientFilterType, IApiFilterResult, SortOption } from "api/Types";
import { LoadingIndicator, Pagination } from "Components/Shared";
import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { FaExclamationTriangle, FaPlusSquare, FaRedoAlt } from "react-icons/fa";
import ClientsApi from "../../api/clientsApi";
import ClientCard from "./ClientCard";
import ClientFilter from "./ClientFilter";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const ClientList = ({activeUser}:any) => {
	const [clients, setClients] = useState<Client[]>([]);
	const [count, setCount] = useState<number>(0);
	const [sortOptions, setSortOptions] = useState<SortOption[]>([]);
	const [fetching, setFetching] = useState(false);
	const [filter, setFilter] = useState<ClientFilterType>({});
	const [error, setError] = useState<React.ReactElement>();

	const fetchData = async () => {
		try {
			setError(undefined);
			setFetching(true);
			const result = await ClientsApi.filter(filter) as IApiFilterResult<Client>;
			setCount(result?.totalCount || 0);
			setClients(result?.results || []);
			setSortOptions(result?.sortOptions || []);
			setFetching(false);
		}
		catch(err) {
			if(err instanceof Error) 
				setError(<>{(err as Error).message}</>);
			else if(typeof(err) === typeof(""))
				setError(<>err</>);
			else {
				console.error(err);
				setError(<>An unknown error occurred.</>);
			}
			setFetching(false);
		}
	};

  useEffect(() => {
		fetchData();
  }, [filter]);

	if(!isPermitted(activeUser, "viewCustomers"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	const renderError = (<>
		<Row>
			<Col md={{ span: 8, offset: 2}}>
				<Alert variant="warning">
					<FaExclamationTriangle /> {error}
				</Alert>
				<Button className="float-end" size="sm" variant="outline-dark" onClick={() => fetchData()}><FaRedoAlt /> Retry</Button>
			</Col>
		</Row>
	</>);

	const content = (<>
		<Row className="mb-2">
			<Col>
				<ClientFilter {...{ filter, onChange: (filter) => setFilter(filter || {}), sortOptions }} />
			</Col>
		</Row>
		<Row>
			<Col>
				{clients.map((client, i) => (<ClientCard key={`client-${i}`} {...{client}} />))}
				{!!fetching && <p className="text-center"><LoadingIndicator /> Loading clients...</p>}
			</Col>
		</Row>
		<Row className="mb-3 mt-3">
			<Col className="d-flex justify-content-center">
				<Pagination skip={filter.skip || 0} limit={filter.limit || 10} totalCount={count} setSkip={(s) => setFilter(f => ({...filter, skip: s}))} />
			</Col>
		</Row>
	</>);

	return (
		<>
			<Row>
				<Col>
					<h1>Clients {!!clients.length && <>({count})</>} {isPermitted(activeUser, "createCustomers") && <Button size="sm" className="float-end" href="clients/add"><FaPlusSquare /> Add New Client</Button>}</h1>
					<hr className="my-2" />
				</Col>
			</Row>
			{!!error ? renderError : content
			}
		</>
	);
};

export default ClientList;