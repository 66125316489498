import React, { useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { ConfirmButtons } from "../Form";
import { IOption } from "api/Types";

const buildText = (name: string, selection: string|undefined) => {
	let output = name;
	
	if(!!selection) {
		output += `: ${selection}`;
	}

	return output;
}

type RadioFilterProps = {
	name?: string,
	options?: IOption[],
	value?: string,
	defaultValue?: string,
	onChange?:(newValue:string) => void,
	className?: string
};

const RadioFilter = (props:RadioFilterProps) => {
	const {
		name = "Radio",
		options = [],
		value = "",
		onChange = () => {},
		defaultValue = "",
		className=""
	} = props
	const [edit, setEdit] = useState(false);
	const [selection, setSelection] = useState(value);

	const empty = () => setSelection(defaultValue);
	const reset = () => { setSelection(value); setEdit(false); };
	const change = (newValue:string) => setSelection(newValue);
	const submit = () => {
		setEdit(false);
		onChange(selection);
	}

	return (
		<>
			<ButtonGroup {...{className}}>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					{ buildText(name, options?.find(o => o.key === value)?.name) }
				</Button>
			</ButtonGroup>
			{!!edit && 
			<Modal onHide={reset} show={edit} size="lg" aria-labelledby={`change-{name}-filter`} centered>
				<Modal.Header closeButton>
					<Modal.Title>{name} Filter</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						{ options.map((o, i) => 
								<Form.Check
									onChange={() => change(o.key || "")} 
									value={o.key} 
									checked={selection === o.key} 
									type="radio" 
									id={`option-${o}-${i}`} 
									key={`option-${o}-${i}`}
									label={o.name} 
								/>
						)}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button className="float-start" variant="link" size="sm" onClick={empty}>Clear</Button>	
					<ConfirmButtons onConfirm={submit} onCancel={reset} />
				</Modal.Footer>
			</Modal>
			}
		</>
	)
}

export default RadioFilter;