import { apiFetch } from "./api";

export const read = async({ id, skip = 0, take = 5}) => {
	const params = [
		["skip", skip],
		["take", take]
	];
	const qs = new URLSearchParams(params).toString();
	const history = await apiFetch(`/history/${id}?${qs}`);
	return history;
}