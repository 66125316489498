import React, { useState, useEffect } from "react";
import JobCard from "./JobCard";
import JobsApi from "../../api/jobsApi";
import { Col, Row, Button, ButtonGroup } from "react-bootstrap";
import {JobBoardFilter} from "./";
import useQuery from "../../utils/useQuery";
import MultiTimeClock from "../Shared/MultiTimeClock";
import multiTimeClockApi from "../../utils/multiTimeClock";
import { FaEllipsisV, FaPause, FaPlay, FaPlusSquare } from "react-icons/fa";
import { LoadingIndicator } from "Components/Shared";
import { isPermitted } from "utils/permissions";
import format from "utils/format";
import InfiniteScroll from "react-infinite-scroll-component";

const REFRESH_INTERVAL = 300;
let interval;
let autoRefreshOn = true;
const defaultFilter = { limit: 25 };

const getJobs = async (filter) => {
	return JobsApi.filter({ 
		...filter, 
		driver: filter?.driver?._id,
		status: !filter?.status || !filter?.status?.length ? ["review", "assign", "pickup", "waiting", "deliver", "hold"] : filter.status,
		projection: "dashboard"
	});
}

const JobBoard = ({
	filter: initialFilter = defaultFilter,
	activeUser = {},
	basic = false,
	infiniteScroll = false
}) => {
	const query = useQuery();
	const [isFetching, setIsFetching] = useState(true);
	const [jobs, setJobs] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [filter, setFilter] = useState({ ...initialFilter, ...query });
	const [sortOptions, setSortOptions] = useState([]);
	const [activeSearch, setActiveSearch] = useState("");
	const [lastRefresh, setLastRefresh] = useState();
	const [lastRefreshText, setLastRefreshText] = useState();
	const [timeToAutoRefresh, setTimeToAutoRefresh] = useState(REFRESH_INTERVAL);
	const [isPaused, setIsPaused] = useState(!autoRefreshOn); 

	const refresh = async () => {
		try {
			setIsFetching(true);
			const res = await getJobs(filter);
			setSortOptions(res.sortOptions || []);
			setJobs(res.jobs);
			setTotalCount(res.totalCount);
		}
		catch(err) {
			console.error(err);
		} finally {
			clearInterval(interval);
			interval = setInterval(updateTime, 1000);
			setIsFetching(false);
			setLastRefresh(new Date());
			setTimeToAutoRefresh(REFRESH_INTERVAL);
			return () => clearInterval(interval);
		}
	}

	const updateTime = () => {
		setTimeToAutoRefresh(t => {
			if(autoRefreshOn && t <= 0) {
				refresh();
				return 0;
			}
			if(autoRefreshOn)	return t - 1;
			return t;
		});
	}

	useEffect(() => {
		refresh();
	}, [filter]);

	const loadMore = async () => {
		try {
			setIsFetching(true);
			const res = await getJobs({...filter, skip: jobs.length});
			setJobs(j => ([...j, ...res.jobs]));
		} catch(err) {
			console.error(err);
		}
		finally {
			setIsFetching(false);
		}
	};

	if(isFetching && (!jobs || !jobs.length)) {
		return (<p>fetching jobs...</p>);
	}

	const PREDEFINED_SEARCHES = [
		{ name: "Assigned to me", filter: { ...filter, driver: activeUser }, inverse: { ...filter, driver: undefined }}
	];

	const handlePredefinedSearch = (search) => {
		if(activeSearch === search.name) {
			setActiveSearch("");
			setFilter(search.inverse);
			return;
		}

		setActiveSearch(search.name);
		setFilter(search.filter);
	}

	const updateFilter = (filter) => {
		setActiveSearch();
		setFilter(filter);
	}

	const toggleAutoRefresh = () => {
		autoRefreshOn = !autoRefreshOn;
		setIsPaused(!autoRefreshOn);
	}

	const scrollToTop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		window.scrollTo({top: 0, behavior: "smooth"});
	}

	return (
		<>
			<Row>
				<Col>
					{ !basic && 
					<Row>
						<Col>
							{PREDEFINED_SEARCHES.map((s, i) => 
								<Button 
									key ={`searches-${i}`}
									size="sm" 
									variant={s.name === activeSearch ? "secondary" : "outline-secondary"} 
									onClick={() => handlePredefinedSearch(s)}>
										{s.name}
								</Button>
							)}
							{ isPermitted(activeUser, "createJobs") && <Button size="sm" href="/jobs/new" className="float-end"><FaPlusSquare /> Create Job</Button> }
						</Col>
					</Row> }
					<Row className="mt-2">
						<Col>
								<JobBoardFilter filter={filter} sortOptions={sortOptions} onChange={updateFilter} basic={basic} />
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<p>Jobs found: {totalCount}</p>
							<InfiniteScroll 
								dataLength={jobs.length}
								next={infiniteScroll ? loadMore : () => {}}
								loader={<>{infiniteScroll && <p className="text-center"><LoadingIndicator /> Loading more jobs...</p>}</>}
								hasMore={totalCount > jobs.length}
								style={{overflow: "visible"}}
							>
								{ jobs.map((job, i) => <JobCard {...job} activeUser={activeUser} key={`job-${i}`} />)}
								{ !infiniteScroll && <div className="text-center">
										{!!isFetching && <div className="mt-3"><LoadingIndicator /> Fetching more jobs...</div>}
										{ totalCount > jobs.length && <div className="mt-3"><Button onClick={loadMore}><FaEllipsisV /> Load More</Button></div> }
									</div>}
								{
									!!(jobs.length >= 20) && <p className="text-center mt-3"><a href="" onClick={scrollToTop}>Back to top</a></p>
								}
							</InfiniteScroll>
						</Col>
					</Row>
				</Col>
				{ !basic && <Col xs={2}>
					<div className="sticky-top">
						<MultiTimeClock timeZones={multiTimeClockApi.get()} onChange={(tz) => multiTimeClockApi.set(tz)} />
						<div className="d-grid gap-0 mt-3">
							<ButtonGroup>
								<Button onClick={refresh} disabled={isFetching}>{isFetching ? "Refreshing..." : `Refresh (${format.toHHMMSS(timeToAutoRefresh)})`}</Button>
								{ !isPaused && <Button onClick={toggleAutoRefresh} variant="secondary" title="Pause automatic refreshing."><FaPause /></Button> }
								{ isPaused && <Button onClick={toggleAutoRefresh} variant="success" title="Enable automatic refreshing."><FaPlay /></Button> }
							</ButtonGroup>
							<div className="text-muted"><small>Last refresh: {isFetching ? "now" : (format.fullDate(lastRefresh) ?? "now")}</small></div>
						</div>
					</div>
				</Col> }
			</Row>
		</>
	);
}

export default JobBoard;