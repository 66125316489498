import React, { useEffect, useState } from "react";
import { PermissionMap, Role } from "api/Types/Role";
import { RoleApi } from "api";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "Components/Shared";
import { Breadcrumb, Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FaCog, FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { IApiFilterResult, User } from "api/Types";

const CreateRoleForm = () => {
	const roleId = useParams().roleId || "";
	const [role, setRole] = useState<Role>({});
	const [permissionMap, setPermissionMap] = useState<PermissionMap>({})
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState<IApiFilterResult<User>>({});

	const loadRole = async (id:string) => {
		setLoading(true);
		try {
			const [roleResult, permissionMapResult] = await Promise.all([
				RoleApi.read(id),
				RoleApi.getPermissions()
			]);
			setRole(roleResult);
			setPermissionMap(permissionMapResult);
		}
		catch(err) {
				console.error(err);
		}
		finally {
				setLoading(false);
		}
	}

	useEffect(() => { loadRole(roleId); }, []);

	if(!!loading) {
		return <><LoadingIndicator /></>
	}

	return <>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles`}>Roles</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles/${roleId}`}>{role?.name ?? roleId}</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<Dropdown className="float-end">
					<Dropdown.Toggle variant="link" id="options">
						<FaCog />
					</Dropdown.Toggle>
					<Dropdown.Menu align="end">
						<Dropdown.Header><strong>{role?.name}</strong></Dropdown.Header>
						<Dropdown.Divider />
						<Dropdown.Item href={`/roles/${roleId}/edit`}><FaEdit /> Edit</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<h1>{role.name}</h1>
				<hr />
			</Col>
		</Row>
		<Row>
			<Col sm={12} md={6}>
				<h2>Permissions</h2>
				<Table striped bordered hover size="sm">
					<thead><tr><th>Name</th><th>Description</th></tr></thead>
					<tbody>
						{!role.permissions?.length && <tr><td colSpan={2}><p className="text-muted text-center m-0 p-3"><FaExclamationTriangle /> No permissions found.</p></td></tr>}
						{role.permissions?.map(p => { 
						const permission = permissionMap[p];
							return <tr>
								<td>{permission.name}</td>
								<td>{permission.description}</td>
							</tr>
						})}
					</tbody>
					<tfoot></tfoot>
				</Table>
			</Col>
			<Col sm={12} md={6}>
				<h2>Users</h2>
				<Table striped bordered hover size="sm">
					<thead><tr><th>Name</th><th>Email</th><th>Lost Login</th></tr></thead>
					<tbody>
						{!users?.totalCount && <tr><td colSpan={3}><p className="text-muted text-center m-0 p-3"><FaExclamationTriangle /> No users found.</p></td></tr>}
					</tbody>
					<tfoot></tfoot>
				</Table>
			</Col>
		</Row>
	</>
}

export default CreateRoleForm;