import { apiFetch, generateOptions, API_DOMAIN } from "./api";
import { filterParameters, updateWindowLocation } from "./helpers";

const invoicesApi = {
	read: async (invoiceId) => {
		const response = await apiFetch(
			`/invoices/${invoiceId}`
		)
		return response;
	},
	filter: async (apiParams) => {
		const params = [];

		Object.keys(apiParams).forEach(k => {
			if(apiParams[k] instanceof Date) {
				apiParams[k] = apiParams[k].toISOString();
				return;
			}
		});


		Object.entries(apiParams)
			.filter(filterParameters)
			.forEach(([key,value]) => params.push([key, value]));

		const qs = new URLSearchParams(params).toString();

		const response = await apiFetch(`/invoices?${qs}`);
		
		updateWindowLocation(qs);

		return response;
	},
	create: async (params) => {
		const response = await apiFetch(
			`/invoices`,
			{
				method: "POST",
				cache: "no-cache",
				body: JSON.stringify(params)
			}
		);

		return response;
	},
	update: async (invoiceId, params) => {
		const response = await apiFetch(
			`/invoices/${invoiceId}`,
			{
				method: "PUT",
				cache: "no-cache",
				body: JSON.stringify(params)
			}
		);

		return response;
	},
	downloadCsv: async (invoiceId) => {
		const response = await fetch(`${API_DOMAIN}/invoices/${invoiceId}/csv`, generateOptions({}));
		return response.text();
	},
	downloadExcel: async (invoiceId) => {
		const response = await fetch(`${API_DOMAIN}/invoices/${invoiceId}/excel`, generateOptions({}));
		return response.blob();
	},
	sendEmail: async (params) => {
		const response = await apiFetch(
			`/invoices/sendemail`,
			{
				method: "POST",
				cache: "no-cache",
				body: JSON.stringify(params)
			}
		);

		return response;
	}
}

export default invoicesApi;