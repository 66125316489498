import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, Row, Col, Tabs, Tab, Card, Alert, ButtonGroup, InputGroup, Breadcrumb, Button, Badge } from "react-bootstrap";
import { FaCog, FaEdit, FaPhoneSquareAlt, FaFax, FaEnvelope, FaExternalLinkAlt, FaExclamationTriangle } from "react-icons/fa";
import { MarketsApi } from "api";
import { AncillariesForm, AsyncButton, EmailList } from "Components/Shared";
import { PublishData } from "Components/Syndicated";
import { JobBoard } from "Components/Job";
import NotFoundError from "Components/Shared/NotFoundError";
import RegionLookup from "Components/Form/RegionLookup";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const MarketProfile = ({activeUser}) => {
	const { marketId } = useParams();
	const [loading, setLoading] = useState(false);
	const [market, setMarket] = useState({});
	const [newRegion, setNewRegion] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const result = await MarketsApi.read("", marketId);
				setMarket(result);
			} catch(err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, []);

	if(!isPermitted(activeUser, "viewMarkets"))
		return <NotAuthorizedError redirect="/markets" redirectMessage="Back to market list" />

	if(!market) {
		return(<NotFoundError
			message="Unable to locate the requested market. Please make sure this market exists."
			redirect="/markets" 
			redirectMessage="Back to the list of markets."
		/>);
	}

	const assignRegion = async () => {
		if(!newRegion?._id) {
			throw new Error("No valid region selected.");
		}
		if(!!market?.region?._id) {
			throw new Error("This market is already associated with a region.");
		}
		await MarketsApi.update({ marketId: market._id, fields: { region: newRegion._id }});
		setNewRegion({});
		setMarket((market) => ({...market, region: newRegion}));
	}

	const unassignRegion = async () => {
		if(window.confirm(`Are you sure you want to remove the ${market.name} market from the ${market?.region?.name} region?`)) {
			await MarketsApi.update({ marketId: market._id, fields: { region: "" }});
			setTimeout(() => {
				setMarket(m => ({ ...m, region: {}}));
			}, 500);			
			return;
		}
		throw new Error("User cancelled.");
	}

	const { contact = {}, billing = {}, region } = market;
	const contract = billing?.contractTerms;
	
	return (
		<>
			{ !!loading && <p>Loading market...</p> }
			{ !loading && market._id && <>
			<Row className="mb-1">
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/clients`}>Clients</Breadcrumb.Item>
						<Breadcrumb.Item href={`/clients/${market?.customer?._id}`}>{market?.customer?.name}</Breadcrumb.Item>
						{!!market?.region?._id && <Breadcrumb.Item href={`/regions`}>Regions</Breadcrumb.Item>}
						{!!market?.region?._id && <Breadcrumb.Item href={`/regions/${market?.region?.url}`}>{market?.region?.name}</Breadcrumb.Item>}
						<Breadcrumb.Item href={`/markets`}>Markets</Breadcrumb.Item>
						<Breadcrumb.Item href={`/markets/${market?._id}`}>{market?.name}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col>
					<Dropdown className="float-end">
						<Dropdown.Toggle variant="link" id="options">
							<FaCog />
						</Dropdown.Toggle>
						<Dropdown.Menu align="right">
							<Dropdown.Header><strong>{market.name}</strong></Dropdown.Header>
							<Dropdown.Divider />
							<Dropdown.Item href={`/markets/${market._id}/edit`}><FaEdit /> Edit</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<h1>{market.name} {!!market?.code && <span className="text-muted">({market.code})</span>}</h1>
					<hr />
				</Col>
			</Row>
				<Row>
					<Col>
						<h2>Contact</h2>
						{contact.name && <><strong>{contact.name}</strong><br /></>}
						{contact.phone && <><FaPhoneSquareAlt /> {contact.phone}<br /></>}
						{contact.fax && <><FaFax /> {contact.fax} <br /></>}
						{contact.email && <><FaEnvelope /> <EmailList emails={contact.email} /><br /></>}
						{contact.website && <><a href={contact.website} target="_blank" rel="noreferrer"><FaExternalLinkAlt /> {contact.website}</a></>}
					</Col>
					<Col>
						<h2>Billing</h2>
						{billing.name && <>{billing.name}<br /></>}
						{billing.ein && <>EIN: {billing.ein}<br /></>}
						{billing.cycle && <>Cycle: {billing.cycle}<br /></>}
						{billing.phone && <><FaPhoneSquareAlt /> {billing.phone}<br /></>}
						{billing.fax && <><FaFax /> {billing.fax}<br /></>}
						{billing.email && <><FaEnvelope /> <EmailList emails={billing.email} /><br /></>}
					</Col>
					<Col>
						<h2>Region</h2>
						{!region?._id && <>
							<Alert variant="warning"><FaExclamationTriangle /> No region assigned. Assign one now.</Alert>
							<InputGroup>
								<RegionLookup value={newRegion} onChange={(r) => setNewRegion(r || {})} />
								<AsyncButton disabled={!newRegion?._id} size="sm" onClick={assignRegion}>Assign</AsyncButton>
							</InputGroup>
						</>}
						{!!region?._id && <>
							<Card>
								<Card.Body>
									<Row className="d-flex align-items-center justify-content-between">
										<Col className="text-start mb-2 mb-md-0">
											<Card.Title className="mb-0"><a className="text-decoration-none" href={`/regions/${region.url}`}>{region.name}</a></Card.Title>
										</Col>
										<Col className="text-center text-md-end">
											<ButtonGroup className="mb-2">
												<AsyncButton onClick={unassignRegion} className="mt-1 float-end" size="sm" variant="outline-danger">Unassign</AsyncButton>
											</ButtonGroup>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</>}
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs defaultActiveKey="jobs" id="info" className="mt-2">
							<Tab eventKey="jobs" title="Jobs">
								<div className="mt-3">
									<JobBoard filter={{ markets: [market] }} activeUser={activeUser} basic />
								</div>
							</Tab>
							<Tab eventKey="contract" title="Contract Terms">
								<Row className="mt-3">
									<Col sm="7">
										<AncillariesForm {...contract} disabled={true} />
									</Col>
								</Row>
							</Tab>
						</Tabs>
					</Col>
				</Row>
				<Row>
					<Col>
						<PublishData {...market} />
					</Col>
				</Row>
			</>}
		</>
	);
};

export default MarketProfile;