import React from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import { abbreviate } from "../../utils/abbreviate";
import { stateNameMap } from "../../utils/unitedstates";

const renderMapPin = (googleMapUrl) => {
	if(!googleMapUrl) {
		return (<></>)
	}
	return (<a href={`https://www.google.com/maps/place/${googleMapUrl}`} target="_blank" rel="noreferrer"><FaMapMarkedAlt /></a>);
}

const Address = ({condensed = false, name = "", street1 = "", street2 = "", city = "", state: rawState = "", zip = "", lat = "", lon = "", note="", pod=""}) => {
	const state = stateNameMap[rawState] || rawState;
	const cityStateZip = `${city}${!!city ? ", " : ""}${state}${!!city || !!state ? " " : ""}${(zip || "").substring(0,5)}`;
	const street = [abbreviate(street1 || ""), abbreviate(street2 || "")].join(" ");
	let googleMapUrl = [street,city,state,zip].filter(s => !!s).join(" ");
	
	if(!!lat && !!lon) {
		googleMapUrl += `/@${lat},${lon}`
	}

	if(!!condensed && !!name) {
		return (<address>
			{name && <><strong>{name}</strong> {renderMapPin(googleMapUrl)}<br /></>}
			{note && <><i>{note}</i><br /></>}
		</address>);
	}

	return (
		<address>
			{name && <><strong>{name}</strong> {renderMapPin(googleMapUrl)}<br /></>}
			{note && <><i>{note}</i><br /></>}
			{pod && <><i><strong>POD:</strong> {pod}</i><br /></>}
			{street && <>{street} <br /></>}
			{cityStateZip && <>{cityStateZip}</>}
		</address>
	);
};

export default Address;