import { JobMeta } from "api/Types";
import React from "react";
import { Form } from "react-bootstrap";
import { FaBiohazard, FaShuttleVan, FaWeightHanging } from "react-icons/fa";
import { GiBroom, GiPoliceBadge } from "react-icons/gi";
import { HiOutlineIdentification } from "react-icons/hi";
import { IoIosCloudyNight } from "react-icons/io";
import { MdMoneyOff } from "react-icons/md";
import { isChecked } from "utils/formHelpers";

type Props = {
	value?: JobMeta,
	onChange?: (meta:JobMeta) => void,
	disabled?: boolean
};

const JobMetaForm = ({ value = {}, onChange = () => {}, disabled = false}:Props) => {
	const handleChange = (changes:JobMeta) => {
		onChange({...value, ...changes});
	}
	
	return (<>
		<Form.Group className="mb-3">
			<Form.Check disabled={disabled} type="switch" checked={value?.isBig || false} label={<><FaWeightHanging /> Oversized</>} onChange={e => handleChange({ isBig: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.requiresVan || false} label={<><FaShuttleVan /> Requires Van</>} onChange={e => handleChange({ requiresVan: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.isIdCheck || false} label={<><HiOutlineIdentification /> Requires ID Check</>} onChange={e => handleChange({ isIdCheck: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.requiresTSANumber || false} label={<><GiPoliceBadge /> Requires TSA number</>} onChange={e => handleChange({ requiresTSANumber: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.isQS || false} label={<>Is QS</>} onChange={e => handleChange({ isQS: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.isPickAndHold || false} label={<><IoIosCloudyNight /> Hold overnight</>} onChange={e => handleChange({ isPickAndHold: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.isSweepJob || false} label={<><GiBroom /> Sweep job</>} onChange={e => handleChange({ isSweepJob: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.hazardous || false} label={<><FaBiohazard /> Requires handling hazardous material</>} onChange={e => handleChange({ hazardous: isChecked(e) })} />
			<Form.Check disabled={disabled} type="switch" checked={value?.noCharge || false} label={<><MdMoneyOff /> No charge</>} onChange={e => handleChange({ noCharge: isChecked(e) })} />
		</Form.Group>
	</>);
}

export default JobMetaForm;