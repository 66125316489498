import React from "react";
import { Outlet } from "react-router-dom";

const PayrollPage = ({activeUser}) => {	
  return (
		<div className="invoice-page">
			<Outlet />
		</div>
  );
};

export default PayrollPage;