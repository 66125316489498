import { Modal,  Button, Row, Col } from "react-bootstrap";
import { FaPlusSquare, FaBackward, FaForward } from "react-icons/fa";
import React, { useState } from "react";
import { ClientSelector } from "../Client";
import { MarketSelector } from "../Market";
import JobForm from "./JobForm";
import { NavigationBar } from "../Shared";
import { JobsApi } from "../../api";
import { BadRequest } from "Errors";

const JobCreationWizard = ({
	client: initialClient,
	market: initialMarket,
	job: initialJob = {
		meta: {},
		pickup: {},
		dropoff: {}
	},
	activeUser = {},
	onClose = () => {}
}) => {
	const [client, setClient] = useState(initialClient);
	const [market, setMarket] = useState(initialMarket);
	const [job, setJob] = useState(initialJob);
	const [formStage, setFormStage] = useState("client");
	const [isSaving, setIsSaving] = useState(false);
	const [saved, setIsSaved] = useState(false);
	const [validation, setValidation] = useState([]);

	const hasClient = !!client && !!client._id;
	const hasMarket = !!hasClient && !!market && !!market._id;

	if(!!hasMarket && !job.ancillaries) {
		job.ancillaries = market.billing.contractTerms;
	}

	const links = [{
		text: "Select client",
		value: "client",
		active: formStage === "client"
	},{
		text: "Select market",
		value: "market",
		active: formStage === "market",
		disabled: !hasClient
	},{
		text: "Contract Terms",
		value: "stage1",
		active: formStage === "stage1",
		disabled: !hasMarket,
		validationFields: ["ancillaries"]
	},{
		text: "Pickup",
		value: "stage2",
		active: formStage === "stage2",
		disabled: !hasMarket,
		validationFields: ["pickup"]
	},{
		text: "Dropoff",
		value: "stage3",
		active: formStage === "stage3",
		disabled: !hasMarket,
		validationFields: ["dropoff"]
	},{
		text: "Details",
		value: "stage4",
		active: formStage === "stage4",
		disabled: !hasMarket,
		validationFields: ["meta", "flightNumber", "airwayBill", "billOfLading", "jobNumber", "weight", "pieces"]
	}];

	const handleStageClick = (value) => {
		console.log(value);
		setFormStage(value);
	}

	const handleNextStage = () => {
		const curr = links.findIndex(l => l.value === formStage);
		setFormStage(links[curr + 1].value);
	}

	const handlePreviousStage = () => {
		const curr = links.findIndex(l => l.value === formStage);
		setFormStage(links[curr - 1].value);
	}

	const handleSubmit = async () => {
		setValidation([]);
		setIsSaving(true);
		try {
			const res = await JobsApi.create(client._id, market._id, job);
			if(!!res?._id) {
				setJob(res);
				setIsSaved(true);
			}
		} catch(err) {
			console.log({err})
			if(!!err?.validationErrors?.errors)
			{
				setValidation(err?.validationErrors?.errors || []);
			}
			else {
				formStage("error");
			}
			setIsSaved(false);
		} finally {
			setIsSaving(false);
		}
	}

	const handleStartFresh = () => {
		setFormStage("client");
		setIsSaved(false);
		setJob(initialJob);
		setClient(initialClient);
		setMarket(initialMarket);
	}

	const handleNewClientJob = () => {
		setFormStage("market");
		setIsSaved(false);
		setJob(initialJob);
		setMarket(initialMarket);
	}

	const handleNewMarketJob = () => {
		setFormStage("stage2");
		setIsSaved(false);
		setJob(initialJob);
	}

	const removeValidationError = (names = []) => {
		setValidation((v) => v.filter(e => !names.includes(e.param)));
	}

	const renderStage = () => {
		if(!hasClient || formStage === "client") 
			return (<ClientSelector onChange={(c) => {setClient(c); setMarket(); setFormStage("market");}} />);

		if(formStage === "market")
			return (<MarketSelector clientId={client._id} markets={client.markets} onChange={(m) => {setMarket(m); setFormStage("stage2")}} />);

		if(!!hasMarket && !!hasClient && formStage.startsWith("stage")) {
			const stages = links.filter(l => l.value.startsWith("stage"));
			const isLastStage = stages.length === stages.findIndex(l => l.value === formStage) + 1;
			console.log(formStage);
			return (<>
				<JobForm className="mt-3" validation={validation} clearValidation={removeValidationError} onChange={setJob} job={job} stage={formStage} activeUser={activeUser} />
				<Row className="mt-3">
					<Col><Button variant="link" onClick={handlePreviousStage}><FaBackward /> Previous</Button></Col>
					<Col className="text-center"><Button onClick={handleSubmit}>Create Job</Button></Col>
					<Col className="text-end"><Button variant="link" onClick={handleNextStage} disabled={isLastStage}>Next <FaForward /></Button></Col>
				</Row>
			</>);
		}

		if(formStage === "error")
			return (<p>An unexpected error occurred when attempting to create the job. Please review all the inputs and try again.</p>);
	}

	const renderBody = () => {
		if(!!saved) {
			return (
				<>
					<h3>Successfully created job! <a target="_blank" href={`/jobs/${job._id}`}>View it here</a>.</h3>
					<h4>What do you want to do next?</h4>
					<ul>
						<li><Button onClick={handleNewClientJob} variant="link">Create new job for {client.name}.</Button></li>
						<li><Button onClick={handleNewMarketJob} variant="link">Create new job for {client.name} in {market.name}.</Button></li>
						<li><Button onClick={handleStartFresh} variant="link">Start a fresh job.</Button></li>
						<li><Button onClick={() => { handleStartFresh(); onClose(); }} variant="link">Close wizard.</Button></li>
					</ul>
				</>
			)
		}
		
		return (<>
			<NavigationBar onClick={handleStageClick} links={links} className="mb-3" validation={validation} />
			{ renderStage() }
		</>);
	}

	return (
		<>
			<Row className="justify-content-md-center">
				<Col xs="10">
					<h1 className="mb-3">
						{!hasClient && <>Select a client</>}
						{!!hasClient && !hasMarket && <>Select the target market for <strong>{client.name}</strong>'s job.</>}
						{!!hasClient && !!hasMarket && <>Creating job for <strong>{client.name}</strong> in the <strong>{market.name}</strong> market.</>}
					</h1>
				</Col>
			</Row>
			<Row className="justify-content-md-center">
				<Col xs="10">{renderBody()}</Col>
			</Row>
		</>
	);
};

export default JobCreationWizard;