import React, { useEffect, useState } from "react";
import RoleForm from "./RoleForm";
import { Role } from "api/Types/Role";
import { RoleApi } from "api";
import { useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "react-bootstrap";

const CreateRoleForm = () => {
	const roleId = useParams().roleId || "";
	const [role, setRole] = useState<Role>({});
	const [loading, setLoading] = useState(true);

	const loadRole = async (id:string) => {
		setLoading(true);
		try {
				const result = await RoleApi.read(id);
				setRole(result);
		}
		catch(err) {
				console.error(err);
		}
		finally {
				setLoading(false);
		}
	}

	useEffect(() => { loadRole(roleId); }, []);

	const handleSubmit = async () => {
		try {
			const result = await RoleApi.update(role._id, role);
			if(result?._id) {
				setTimeout(() => window.location.href = `/roles/${result.url}`, 1000);
			}
		} catch(error) {
			console.error(error);
		}
	}

	const handleChange = (newRole:Role) => setRole(r => ({...r, ...newRole}));

	return <>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles`}>Roles</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles/${roleId}`}>{role?.name ?? roleId}</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles/${roleId}/edit`}>Edit</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
		</Row>
		<Row>
			<Col>
				<RoleForm value={role} disabled={false} isEdit={true} onSubmit={handleSubmit} onChange={handleChange} />
			</Col>
		</Row>
	</>;
}

export default CreateRoleForm;