import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Col, Button, InputGroup, FormControl, Row } from "react-bootstrap";
import addressApi from "../../api/addressApi"
import mapApi from "../../api/mapApi";
import Typeahead from "./Typeahead";
import { FaMapMarkerAlt, FaRegBookmark, FaBookmark, FaTimes, FaCheck } from "react-icons/fa";
import {LoadingIndicator} from "../Shared"
import { Validation } from ".";
import AddressBookmark from "./AddressBookmark";

const propTypes = {
	address: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool
}

/**
 * @param {Object} props - address selector properties
 * @param {import("api/Types").Address} props.address - starting address
 * @param {func} props.onChange - Callback function for handling when the address changes
 * @returns 
 */
const AddressSelector = (props) => {
	const {
		name: formName,
		validation = [],
		address = {
			street1: "",
			street2: "",
			city: "",
			state: "",
			zip: "",
			formattedAddress: "",
			lat: undefined,
			lon: undefined
		},
		disabled = false,
		onChange = () => {}
	} = props;
	const getName = (n) => [formName,n].filter(s => !!s).join('.');

	const getText = ({ text, value: { name, street1, street2, city, state, zip } = {}}) => {
		const items = [];
		if(!!name) items.push(name);
		if(!!street1) { 
			items.push([street1, street2, `${city}, ${state}, ${zip}`].filter(s => !!s).join(" "));
		}
		if(!name && !street1) items.push(text);
		return items.join(" | ");
	}

	return (<>
		<Form autoComplete="off">
			<Form.Group>
				<Form.Label className="me-1">Street</Form.Label>
				<AddressBookmark {...{address, disabled, onChange}} />
				{!!address.lat && !!address.lon && <Form.Text className="text-muted float-end"><FaMapMarkerAlt /> {address.lat}, {address.lon}</Form.Text>}
				<InputGroup hasValidation>
					<Typeahead
						disabled={disabled}
						isInvalid={validation.find(v => v.param === getName("street1"))}
						className={validation.find(v => v.param === getName("street1")) ? "is-invalid" : ""}
						placeholder="Start typing a street address..."
						value={{ text: address.street1 }}
						inputDelay={1500}
						maxSuggestions={5}
						fetchAllOnOpen={false}
						onChange={onChange}
						applyFilterAndHighlight
						getDetails={async (id) => {
							if(typeof(id) === typeof({})){
								return id;
							}
								return await mapApi.placedetails(id);
						}}
						getSuggestions={async (query) => (await mapApi.addressAutoComplete({ query })).map(a => ({id : a.value, text: getText(a)}))}
					/>
					<Validation validation={validation} name={getName("street1")} />
				</InputGroup>
			</Form.Group>
			<Form.Group as={Col}>
				<Form.Label>Ste, Flr, Apt</Form.Label>
				<InputGroup hasValidation>
					<Form.Control disabled={disabled} isInvalid={validation.find(v => v.param === getName("street2"))} placeholder="ex: Suite 100" type="text" value={address.street2} onChange={(evt) => onChange({ ...address, street2: evt.target.value })} />
					<Validation validation={validation} name={getName("street2")} />
				</InputGroup>
			</Form.Group>
			<Row>
				<Form.Group as={Col} xs={6}>
					<Form.Label>City</Form.Label>
					<InputGroup hasValidation>
						<Form.Control disabled={disabled} isInvalid={validation.find(v => v.param === getName("city"))} placeholder="City" type="text" value={address.city} onChange={(evt) => onChange({ ...address, city: evt.target.value })} />
						<Validation validation={validation} name={getName("city")} />
					</InputGroup>
				</Form.Group>
				<Form.Group as={Col} xs={3}>
					<Form.Label>State</Form.Label>
					<InputGroup hasValidation>
						<Form.Control disabled={disabled} isInvalid={validation.find(v => v.param === getName("state"))} placeholder="State" type="text" value={address.state} onChange={(evt) => onChange({ ...address, state: evt.target.value })} />
						<Validation validation={validation} name={getName("state")} />
					</InputGroup>
				</Form.Group>
				<Form.Group as={Col} xs={3}>
					<Form.Label>Zip</Form.Label>
					<InputGroup hasValidation>
						<Form.Control disabled={disabled} isInvalid={validation.find(v => v.param === getName("zip"))}  placeholder="Zip" type="text" value={address.zip} onChange={(evt) => onChange({ ...address, zip: evt.target.value })} />
						<Validation validation={validation} name={getName("zip")} />
					</InputGroup>
				</Form.Group>
			</Row>
		</Form>
	</>);
}

AddressSelector.propTypes = propTypes;

export default AddressSelector;