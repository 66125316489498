import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FaSave, FaCheck, FaTimes } from "react-icons/fa";
import { LoadingIndicator } from "../Shared";
import * as regionApi from "../../api/regionApi";
import convertToSlug from "utils/convertToSlug";

const RegionForm = ({activeUser = {}}) => {
	const { regionId } = useParams();
	const [region, setRegion] = useState({});
	const [loading, setLoading] = useState(false);
	const [saved, setSaved] = useState(false);
	const [error, setError] = useState();
	const isEdit = !!regionId;
	
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const regionResult = await regionApi.read(regionId);
				setRegion(regionResult);
			}
			catch (error) {

			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		setError();
		setLoading(true);

		try {
			if(isEdit) {
				const result = await regionApi.update(region._id, { name: region.name });
				setLoading(false);
				if(!!result?.success) {
					setSaved(true);
					setTimeout(() => {window.location = `/regions/${convertToSlug(region.name)}`;}, 1000);
					return;
				}
				throw new Error(result.message);
			}

			if(!isEdit) {
				const result = await regionApi.create(region);
				setLoading(false);
				if(!!result?.url) {
					setSaved(true);
					setTimeout(() => {window.location = `/regions/${result.url}`;}, 1000);
					return;
				}

				throw new Error("An unknown error occurred.");
			}
			throw new Error()
		} catch(e) {
			console.error(e);
			setError(`Failed to ${isEdit ? "save changes" : "create region"}: ${e.message} Please try again.`);
			setTimeout(() => setError(), 3500);
		} finally {
			setLoading(false);
		}
	}

	return (<>
		<h1>{isEdit ? "Edit" : "Add New"} Region</h1>
		<hr />
		<Form onSubmit={handleSubmit}>
			<Form.Group controlId="regionNameControl">
				<Form.Label>Name</Form.Label>
				<Form.Control 
					type="text" 
					value={region.name} 
					onChange={e => setRegion((r) => ({...r, name: e.target.value }))} 
					placeholder="Enter region's name" 
				/>
				<Form.Text>
					Set the friendly name for the region.
				</Form.Text>
			</Form.Group>
			<Form.Group className="mt-3">
				<Form.Label>Region url (based on name)</Form.Label>
				<Form.Control
					type="text"
					disabled
					value={convertToSlug(region.name || "")}
					placeholder="Start typing a name to see what the url will look like"
				/>
			</Form.Group>
			<div className="mt-3">
				<Button variant={loading ? "info" : !!saved ? "success" : (!!error ? "danger" : "primary")} type="submit" disabled={loading || saved}>
					{!!loading && <><LoadingIndicator /> {!!loading && !!isEdit ? "Loading..." : "Saving..."}</>}
					{!!error && <><FaTimes /> Failed</> }
					{!!saved && <><FaCheck /> Saved!</>}
					{!!isEdit && !loading && !saved && !error && <><FaSave /> Save Changes</>}
					{!isEdit && !loading && <>Add Region</>}
				</Button>
				{!!isEdit && <> <Button variant="link" href={`/regions/${regionId}`}>Back</Button></>}
			</div>
			{!!error && <Alert className="mt-3" variant={"danger"}>{error}</Alert>}
		</Form>
	</>)
}

export default RegionForm;