import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import { LoadingIndicator, Pagination } from "../Shared";
import type { Role, RoleFilter as RoleApiFilter } from "../../api/Types/Role";
import { IApiFilterResult } from "api/Types";
import { FaEdit, FaExclamationTriangle, FaPencilAlt } from "react-icons/fa";
import { RoleApi } from "api";
import RoleFilter from "./RoleFilter";

const initialState = {	
	users: [],
	sort: "default", 
	limit: 25, 
	skip: 0, 
	projection: "default"
};

const RoleList = (props = initialState) => {
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState<RoleApiFilter>({ limit: 25, skip: 0});
	const [result, setResult] = useState<IApiFilterResult<Role>>({});

	useEffect(() => {
		async function fetchData() {
			try {
				const roleResult = await RoleApi.filter(filter);
				setResult(roleResult);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		}

		fetchData();
	}, [filter]);

	return (<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/roles`}>Roles</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<h1>Roles {!!result?.totalCount && <>({result?.totalCount || 0})</>}</h1>
				<hr />
			</Col>
		</Row>
		<Row>
			<Col>
				<RoleFilter filter={filter} sortOptions={[]} onChange={(f:RoleApiFilter) => setFilter(f)} />
			</Col>
		</Row>
		<Row>
			<Col>
				<Table className="align-middle">
					<thead>
						<tr>
							<th>Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{!!loading && <tr><td className="text-center text-muted"><LoadingIndicator /> Loading roles...</td></tr>}
						{!loading && !result?.results?.length && <tr><td className="text-muted text-center"><FaExclamationTriangle /> No roles found.</td></tr>}
						{!loading && !!result?.results?.length && result.results.map(r => 
							<tr key={`${r.url}`}>
								<td>{r.name}</td>
								<td className="text-end">
									<ButtonGroup size="sm">
										<Button href={`/roles/${r.url}`}>View</Button>
										<Button variant="secondary" href={`/roles/${r.url}/edit`}><FaEdit /> Edit</Button>
									</ButtonGroup>
								</td>
							</tr>)}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={4} className="text-center">
								<div style={{display: "inline-block"}}>
									<Pagination
										skip={filter?.skip}
									limit={filter?.limit}
										totalCount={result?.totalCount}
										setSkip={(s) => setFilter((f) => ({...f, skip: s}))}
									/>
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			</Col>
		</Row>
	</>)
}

export default RoleList;