import React, { useState, useEffect } from "react";
import { Badge, Breadcrumb, Button, Col, Row, Table } from "react-bootstrap";
import { ClientsApi, InvoicesApi } from "../../api";
import useQuery from "../../utils/useQuery";
import { LoadingIndicator } from "../Shared";
import format from "../../utils/format";
import { Link } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";
import { InvoiceFilter } from ".";
import Pagination from "../Shared/Pagination";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const InvoiceRow = ({invoice = {}}) => {
	const id = invoice?.invoiceId || invoice._id;
	return (
		<tr>
			<td><Link to={`/invoices/${id}`} target="_blank" rel="noreferrer">{id.slice(-8).toUpperCase()}</Link></td>
			<td>{invoice?.market?.name}</td>
			<td>{format.date(invoice?.endDate)}</td>
			<td className="text-end">{format.currency(invoice?.total)}</td>
			<td className="text-end"><Badge>{invoice?.status}</Badge></td>
		</tr>
	)
}

const defaultFilter = {limit: 10, skip: 0};

const InvoiceSearch = ({activeUser}) => {
	const query = useQuery();

	if(!!query.selectedStartDate)
		query.selectedStartDate = new Date(query.selectedStartDate);
	if(!!query.selectedEndDate)
		query.selectedEndDate = new Date(query.selectedEndDate);
		
	const [result, setResult] = useState([]);
	const [filter, setFilter] = useState({...defaultFilter, ...query});
	const [isLoading, setIsLoading] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [error, setError] = useState();

	const updateFilter = (newFilter) => setFilter(f => ({ ...defaultFilter,  ...newFilter }));

	useEffect(() => {
		const fetchData = async () => {
			try {
				if(!query.clientid) {
					return;
				}
				const requests = [];
				requests.push(query.clientid ? ClientsApi.read(query.clientid) : {});
	
				const [client] = await Promise.all(requests);
	
				setFilter({client});
			}
			catch(error) {
				setError(error);
			}
			finally {
				setMounted(true);
			}
		};

		fetchData();
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const {
					client: { _id: clientId } = {}, 
					...restFilters
				} = filter;
				if(!mounted) {
					return;
				}

				const r = await InvoicesApi.filter({...restFilters, clientId});
				setResult(r);
				setIsLoading(false);
			}
			catch(err) {
				setError(err);
			}
			finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [filter, mounted]);

	if(!!isLoading && !!result?.invoices?.length) {
		return (<LoadingIndicator />);
	}

	if(error instanceof NotAuthorizedErrorType || !isPermitted(activeUser, "viewInvoices"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	return (
		<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/invoices`}>Invoices</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<InvoiceFilter onChange={updateFilter} filter={filter} />
				{isPermitted(activeUser, "createInvoices") && <Button className="float-end" type="primary" size="sm" href={"/invoices/new"}><FaPlusSquare /> Add Invoice</Button> }
				<Table>
					<thead>
						<tr>
							<th>#</th>
							<th>Market</th>
							<th>End Date</th>
							<th className="text-end">Total</th>
							<th className="text-end">Status</th>
						</tr>
					</thead>
					<tbody>
						{ !!isLoading && !result?.invoices?.length && <tr><td className="text-center" colSpan={6}><LoadingIndicator /></td></tr> }
						{ !isLoading && !result?.invoices?.length && <tr><td className="text-center" colSpan={6}>No invoices found. Please refine your search.</td></tr> }
						{ !isLoading && !!result?.invoices?.length && result.invoices.map(i => <InvoiceRow invoice={i} key={`invoiceRow-${i._id}`} />) }
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="5" className="text-center">
								<div style={{display: "inline-block"}}>
									<Pagination
										skip={filter.skip || 0}
										limit={filter.limit || 10}
										totalCount={result?.totalCount}
										setSkip={(skip) => updateFilter({ skip })}
									/>
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			</Col>
		</Row>
		</>
	);
}

export default InvoiceSearch;