import React, { useState, useEffect } from "react";
import { JobsApi, JobTemplateApi } from "../../api";
import { JobTemplate, User, Validation } from "api/Types";
import { AsyncButton } from "Components/Shared";
import { Col, Row } from "react-bootstrap";
import JobTemplateForm from "./JobTemplateForm";
import { BadRequest } from "Errors";
import useQuery from "../../utils/useQuery";

type Props = {
	activeUser?: User
}

type JobCreateQueryString = {
	jobId?: string
}

const JobTemplateCreate = (props:Props) => {
	const { jobId = "" } = useQuery() as JobCreateQueryString;

	useEffect(() => {
		const fetchData = async () => {
			const job = await JobsApi.read(jobId);
			if(!!job._id) {
				setTemplate({...job});
			}
		}
		fetchData();
	}, [jobId]);

	const [template, setTemplate] = useState({} as JobTemplate);
	const [validation, setValidation] = useState([] as Validation[]);

	const handleSubmit = async () => {
		setValidation([]);
		try {
			const result = await JobTemplateApi.create(template);
			setTemplate(result);

			if(!result?._id) {
				throw new Error("Job template creation appeared to work but the system is unable to redirect to the template's details.");
			}
			window.location.href = `/jobs/templates/${result._id}`;

		} catch(error) {
			console.error(error);
			if(error instanceof BadRequest)
			{
				setValidation(error.validationErrors?.errors || []);
				throw new Error("Invalid job template provided. Please review the template and try again.");
			}
		}
	}

	return <>
		<Row>
			<Col>
				<h2>Create a new job template</h2>
				<JobTemplateForm validation={validation} value={template} onChange={(t = {}) => setTemplate(t)} />
			</Col>
		</Row>
		<Row className="mt-3 mb-3">
			<Col>
				<AsyncButton onClick={handleSubmit}>Create Template</AsyncButton>
			</Col>
		</Row>
	</>
}

export default JobTemplateCreate;