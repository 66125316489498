import { Dropoff, Validation as ResultValidation } from "api/Types";
import React from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { getValue } from "utils/formHelpers";
import AddressSelector from "./AddressSelector";
import DateTimeSelect from "./DateTimeSelect";
import Validation from "./Validation";

type Props = {
	value?: Dropoff,
	onChange?: (dropoff?: Dropoff) => void,
	validation?: ResultValidation[],
	clearValidation?: (names?:string[]) => void,
	disabled?: boolean,
	name?: string,
	readyAt?:Date
}

const addHours = (date:Date, hours:number) => new Date(new Date(date).setHours(date.getHours() + hours));

const DropoffForm = ({ 
	value = {}, 
	onChange = () => ({} as Dropoff),
	validation = [] as ResultValidation[],
	clearValidation = () => {},
	disabled = false,
	name = "",
	readyAt
}:Props) => {
	const handleChange = (p:Dropoff) => {
		onChange({...value, ...p});
	}
	const buildName = (n: string) => [name || "", n].filter(s => !!s).join(".");

	return (<>
		<h3>Dropoff</h3>
		<Form.Group className="mb-3">
			<Form.Label>Ready at</Form.Label>
			<InputGroup hasValidation>
				<DateTimeSelect 
					value={value?.deliverBy} 
					onChange={(date:Date) => handleChange({ deliverBy: date })} 
					isInvalid={!!validation?.find(v => v.param === buildName("deliverBy"))}
					disabled={disabled}
				/>
				<Validation validation={validation} names={[buildName("deliverBy")]} />
			</InputGroup>
			{readyAt && !disabled &&
				<div className="mt-2 mb-2">
					<ButtonGroup>
						<Button onClick={() => handleChange({ deliverBy: addHours(readyAt, 1) })} size="sm" variant="outline-primary">1 hour later</Button>
						<Button onClick={() => handleChange({ deliverBy: addHours(readyAt, 2) })} size="sm" variant="outline-primary">2 hours later</Button>
						<Button onClick={() => handleChange({ deliverBy: addHours(readyAt, 3) })} size="sm" variant="outline-primary">3 hours later</Button>
					</ButtonGroup>
				</div>
			}
			<Form.Label>Delivery Note</Form.Label>
			<InputGroup hasValidation>
				<Form.Control
					placeholder="Add a note specifically for delivery, ex: 'ATTN: John Doe'"
					type="text"
					value={value?.note || ""}
					onChange={evt => handleChange({ note: getValue(evt) })}
					isInvalid={!!validation?.find(v => v.param === buildName("note"))}
					disabled={disabled}
				/>
				<Validation validation={validation} names={[buildName("note")]} />
			</InputGroup>
			<AddressSelector {...{
				address: {...value}, 
				validation, 
				name: name || "",
				key: "dropoff",
				onChange: a => handleChange({ ...a }),
				disabled
			}} />
		</Form.Group>
	</>)
}

export default DropoffForm;