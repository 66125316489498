import React from "react";
import { Card, Col, Row, Badge, Image } from "react-bootstrap";
import { FaRegClock, FaPlane, FaBox, FaMapMarkerAlt, FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Address } from "../Shared";
import format from "../../utils/format";
import JobStatus from "./JobStatus";
import { PublishData } from "Components/Syndicated";
import calculateDifference from "utils/calculateDifference";
import Mileage from "./Mileage";
import { FullDate } from "Components/Shared/Date";
import { isPermitted } from "utils/permissions";
import "./JobCard.css";

const DriverBadge = ({drivers = []}) => {
	if(!drivers?.length) {
		return <></>;
	}

	const currentDriver = drivers.find(d => d.isCurrent) || drivers[0];

	if(!currentDriver?._id) {
		return <></>;
	}

	const {_id: driverId, name, picture} = currentDriver.driver;
	
	return <small><a href={`/users/${driverId}`}>
		<Badge title="Current assigned driver" bg="dark" style={{lineHeight: "20px"}}>
			<Image referrerPolicy="no-referrer" className="me-2" roundedCircle height="16px" src={picture} />
			{name}
		</Badge>
	</a></small>;
}

const JobCard = ({
	_id, 
	jobNumber, 
	pickup = {}, 
	dropoff = {},
	flightNumber,
	airwayBill,
	weight,
	pieces,
	customerMiles,
	market = {},
	totalCharges,
	drivers = [],
	ancillaries: { useCustomerMiles = false } = {},
	calculatedMiles,
	activeUser,
	...job
}) => {
	return (
		<Card className="job-card">
			<Card.Header>
				<small>
					{!!jobNumber && <Badge bg="dark" className="ms-0">{jobNumber}</Badge>}
					<JobStatus className="ms-1" status={job.status} /> 
					<Badge pill bg="success" className="ms-1">{market.name}</Badge>
					{!!market?.region?.name && <Badge pill bg="secondary" className="ms-1">{market?.region?.name}</Badge>}
				</small>
				<small className="float-end">
					{ isPermitted(activeUser, "viewJobMoney") && <strong className="ms-2 me-2">{format.currency(totalCharges)}</strong> }
					<DriverBadge drivers={drivers} /> 
				</small>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						{ (!!flightNumber || airwayBill) && <><FaPlane /> {flightNumber} <em>{airwayBill}</em> <br /></> }
						{!!weight && !!pieces && <FaBox /> } {weight && <>{weight} lbs.</>} {!!weight && !!pieces && <>as</>} {!!pieces && <>{pieces} piece{pieces > 1 ? "s" : ""}</>} {(!!weight || !!pieces) && <br />}
						<Mileage {...{ customerMiles, calculatedMiles, useCustomerMiles}} />
						<Link className="mx-1 stretched-link" target="_blank" to={`/jobs/${_id}`}></Link>
					</Col>
					<Col>
						{pickup?.pickedupAt && <>Picked up <Badge bg="success"><FaRegClock /> <FullDate date={pickup.pickedupAt} /></Badge></>}
						{!pickup?.pickedupAt && <>Ready <Badge bg="warning" className="text-dark"><FaRegClock /> <FullDate date={pickup.readyAt} /></Badge></>}
						<Address condensed={true} {...pickup} />
					</Col>
					<Col>
						{dropoff?.dropoffAt && <>Delivered <Badge bg="success"><FaRegClock /> <FullDate date={dropoff.dropoffAt} /></Badge></>}
						{!dropoff?.dropoffAt && <>Deliver by <Badge bg="warning" className="text-dark"><FaRegClock /> <FullDate date={dropoff.deliverBy} /></Badge></>}
						<Address condensed={true} {...dropoff} />
					</Col>
				</Row>
				{/* <Row>
					<Col>
						<PublishData size="sm" {...job} />
					</Col>
				</Row> */}
			</Card.Body>
		</Card>
	);
};

export default JobCard;