import React, { useState } from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { getNumber } from "../../utils/formHelpers";
import { AsyncButton } from "../Shared";
import "./RangeFilter.css";

const buildText = (name, min, max) => [min, name, max].filter(s => typeof(s) !== typeof(undefined) && s !== "").join(' < ');

const RangeFilter = ({
	name = "Range",
	value = {},
	onChange = () => {},
	...props
}) => {
	const { min, max } = value;
	const [edit, setEdit] = useState(false);
	const [range, setRange] = useState({min, max});

	const hasValue = typeof(min) === typeof(2) || typeof(max) === typeof(2);

	if(!edit) {
		return (
			<ButtonGroup>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					{ buildText(name, min, max) }
				</Button>
				{ hasValue && <Button size="sm" variant="outline-secondary" onClick={() => { setRange({}); onChange({});}}><FaTimes /></Button>}
			</ButtonGroup>
		)
	}

	const handleChangeRange = () => {
		if(!!range?.min && !!range.max && range.min > range.max) {
			throw new Error("Minmum greater than maximum");
		}
		setEdit(false); 
		onChange(range);
	}

	const handleFocus = (event) => event.target.select();
	const cancel = () => { setRange({min, max}); setEdit(false); };
	
	const onKeyDown = (evt) => {
		switch(evt.keyCode) {
			case 13: // Enter
				evt.preventDefault(); 
				evt.stopPropagation();
				handleChangeRange();
				break;
			case 27: // Escape
				cancel();
				break;
			default:
				break;
		}
	}

	return (
		<Form.Group onKeyDown={onKeyDown} className="range-filter">
			<InputGroup>
				<Form.Control onFocus={handleFocus} autoFocus value={range.min} size="sm" type="number" onChange={(e) => setRange(r => ({...r, min: getNumber(e)}))}></Form.Control>
				<InputGroup.Text size="sm"  id="basic-addon2">&lt;</InputGroup.Text>
				<InputGroup.Text size="sm"  id="basic-addon2">{name}</InputGroup.Text>
				<InputGroup.Text size="sm"  id="basic-addon2">&lt;</InputGroup.Text>
				<Form.Control onFocus={handleFocus} value={range.max} size="sm" type="number" onChange={(e) => setRange(r => ({...r, max: getNumber(e)}))}></Form.Control>
				<AsyncButton variant="outline-success" size="sm" onClick={handleChangeRange} />
				<Button variant="outline-danger" size="sm" onClick={cancel}><FaTimes /></Button>
			</InputGroup>
		</Form.Group>
	);
}

export default RangeFilter;