import React, { useState } from "react";
import { AddressApi } from "api";
import type { Address, Validation as ValidationType } from "api/Types";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { BadRequest } from "Errors";
import ConfirmButtons from "./ConfirmButtons";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { AsyncButton } from "Components/Shared";
import Validation from "./Validation";

type Props = {
	address: Address,
	disabled: boolean,
	onChange?: (address?: Address) => (Promise<void> | void)
}
const isAddressComplete = (address:Address) => !!address?.formattedAddress;
const isValidName = (name?:string) => name?.length || 0 >= 3;

const AddressBookmark = (props:Props) => {
	const { address, disabled = false, onChange = () => {} } = props;

	const [nameInput, setName] = useState<string|undefined>();
	const [validation, setValidation] = useState<ValidationType[]>([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showSaveAddressForm, setShowSaveAddressForm] = useState(false);

	const reset = () => {
		setName("");
		setShowDeleteConfirmation(false);
	}

	const handleSubmit = async () => {
		setValidation([]);
		try {
			const result = await AddressApi.create({...address, name: nameInput});

			if(!result?._id)
				throw new Error("Failed to save address. Please try again.");

			setShowSaveAddressForm(false);
			onChange(result);
		} catch(error) {
			if(error instanceof BadRequest)
			{
				const errors = error?.validationErrors?.errors || [];
				setValidation(errors);
				throw new Error(errors[0]?.msg || "Invalid name provided. Please try again.");
			}
			throw error;
		}
	}

	const handleDeleteAddress = async() => {
		await AddressApi.delete(address?._id);
		setShowDeleteConfirmation(false);
		onChange({...address, name: undefined, _id: undefined, slug: undefined });
		reset();
	}
	
	if (!!showDeleteConfirmation) {
		return(
			<>
				<ConfirmButtons size="sm" onConfirm={handleDeleteAddress} onCancel={() => setShowDeleteConfirmation(false)} />
				<span className="ms-2">Delete saved address <strong>{address?.name}</strong>, are you sure?</span>
			</>
		);
	}

	if (!!address?._id){
		return(
			<>
				<FaBookmark style={{cursor: "pointer"}} onClick={() => setShowDeleteConfirmation(true)} /> <strong>{address?.name}</strong>
			</>
		);
	}
	if (!!showSaveAddressForm){
		return (
			<div className="mt-1" style={{display: "inline-block"}}>
				<InputGroup size="sm" hasValidation>
					<FormControl disabled={disabled} placeholder="Address name" type="text" value={nameInput} onChange={(evt) => setName(a => evt.target.value)} />
					<AsyncButton size="sm" variant="primary" onClick={handleSubmit} disabled={!isValidName(nameInput)}>Save</AsyncButton>
					<Button size="sm" variant="light" onClick={() => setShowSaveAddressForm(false)}>Cancel</Button>
					<Validation validation={validation} names={["name"]} />
				</InputGroup>
			</div>
		)
	}

	if(!!isAddressComplete(address)) {
		return <FaRegBookmark style={{cursor: "pointer"}} onClick={() => setShowSaveAddressForm(true)}/>;
	}

	return <></>;
}

export default AddressBookmark;
