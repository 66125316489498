import { apiFetch } from "./api";

const notesApi = (path) => ({
	delete: async ({noteId}) => {
		await apiFetch(
			`/${path}/notes/${noteId}`,
			{
				method: "DELETE",
				cache: "no-cache"
			}
		);
	},
	update: async ({
		noteId,
		text
	}) => {
		await apiFetch(
			`/${path}/notes/${noteId}`,
			{
				method: "PUT",
				cache: "no-cache",
				body: JSON.stringify({text})
			}
		);
	},
	create: async ({ text }) => {
		const response = await apiFetch(
			`/${path}/notes`,
			{
				method: "POST",
				cache: "no-cache",
				body: JSON.stringify({text})
			}
		);
		return response;
	},
	read: async ({
		skip = 0,
		take = 10
	}) => {
		const params = [
			["skip", skip],
			["take", take]
		];

		const qs = new URLSearchParams(params).toString();
		const response = await apiFetch(`/${path}/notes?${qs}`);
		return response;
	}
})

export default notesApi;