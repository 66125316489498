import { Validation } from "Components/Form";
import { Validation as RequestValidation } from "api/Types";
import { AsyncButton } from "Components/Shared";
import { Permission, Role } from "api/Types/Role";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { getValue } from "utils/formHelpers";
import { RoleApi } from "api";
import PermissionsSelector from "Components/Form/PermissionsSelector";
import convertToSlug from "utils/convertToSlug";

type RoleFormProps = {
	value?:Role
	onChange: (role:Role) => void,
	onSubmit:() => Promise<Role | void>
	isEdit:boolean
	disabled:boolean
	validation?: RequestValidation[]
}

const RoleForm = (props:RoleFormProps) => {
	const { validation = [], onChange = () => {}, disabled = false, isEdit = false, onSubmit = () => {}, value } = props;

	const [permissions, setPermissions] = useState<Permission[]>([]);
	
	useEffect(() => {
		const fetchData = async () => {
			const permissionList = await RoleApi.getPermissions();
			setPermissions(Object.entries(permissionList).map<Permission>(([key, value]) => ({key, ...value})));
		}
		fetchData();
	}, []);

	return <>
		<Form>
			<Form.Group className="mb-3">
				<Form.Label>Role Name</Form.Label>
				<InputGroup size="lg" hasValidation>
					<Form.Control
						placeholder="Name this role"
						type="text"
						name="name"
						value={value?.name || ""}
						onChange={evt => onChange({ name: getValue(evt) })}
						isInvalid={!!(validation?.find(v => v.param === `name`))}
						disabled={disabled}
					/>
					<Validation validation={validation} names={[`name`]} />
				</InputGroup>
			</Form.Group>
			<Form.Group className="mt-3">
				<Form.Label>Role url (based on name)</Form.Label>
				<Form.Control
					size="sm"
					type="text"
					disabled
					value={convertToSlug(value?.name || "")}
					placeholder="Start typing a name to see what the url will look like"
				/>
			</Form.Group>
			<Form.Group className="mt-3">
				<Form.Label>Permissions</Form.Label>
				<PermissionsSelector onChange={(permissions) => onChange({permissions})} options={permissions} value={value?.permissions} />
			</Form.Group>
			<Form.Group className="mt-3">
				<AsyncButton onClick={onSubmit}><FaSave /> {isEdit ? "Save Changes" : "Create Role"}</AsyncButton>
			</Form.Group>
		</Form>
	</>;
}

export default RoleForm;