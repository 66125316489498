import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import { FaSave, FaSpinner, FaCheck, FaTrash, FaAngleDoubleRight, FaTimes, FaExclamationTriangle } from "react-icons/fa";
import ClientsApi from "../../api/clientsApi";
import clientsApi from "../../api/clientsApi";
import { AsyncButton } from "Components/Shared";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

// amount of time to disable save button
const SAVE_DELAY = 3000;

let id;

const fetchData = async (clientId, updateState) => {
	if(!!clientId) {
		try {
			updateState((s) => ({ ...s, isFetching: true }));
			const client = await ClientsApi.read(clientId);
			console.log({client});
			updateState((s) => ({ ...s, markets: client.markets, fields: { name: client.name }, isFetching: false, initialLoad: false }));
		}
		catch(err) {
			console.error(err);
			updateState((s) => ({ ...s, isFetching: false }));
		}
	}
}

const ClientForm = ({activeUser}) => {
	const { clientId } = useParams();
	const isEdit = !!clientId;
	const [state, setState] = useState({
		fields: { name: "" },
		isFetching: false,
		saved: false,
		initialLoad: true
	});
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const {
		fields, isFetching, saved, initialLoad
	} = state;


	id = clientId;

  useEffect(() => {
		fetchData(id, setState);
  }, []);

	if(isEdit && !isPermitted(activeUser, "editCustomers")
		|| !isEdit && !isPermitted(activeUser, "createCustomers"))
		return <NotAuthorizedError redirect="/clients" redirectMessage="Back to client list" />

	const handleSubmit = async (evt) => {
		evt.preventDefault();		
		setState((s) => ({ ...s, isFetching: true }));
		if(!!isEdit) {
			const client = await ClientsApi.update(clientId, { ...state.fields });
			setState((s) => ({ ...s, client, isFetching: false, saved: true }));
			setTimeout(() => setState((s) => ({...s, saved: false})), SAVE_DELAY);
		} else {
			const client = await ClientsApi.create({ ...state.fields });
			if(!!client && !!client._id) {
				window.location = `/clients/${client._id}`;
			}
		}
	}

	return (
		<>
			<h1>{!!isEdit ? "Edit" : "Add New"} Client</h1>
			<hr />
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="clientNameControl">
					<Form.Label>Name</Form.Label>
					<Form.Control 
						type="text" 
						value={fields.name} 
						onChange={e => setState((s) => ({...s, fields: { name: e.target.value }}))} 
						placeholder="Enter client's name" 
					/>
					<Form.Text>
						This is a friendly name for clients.
					</Form.Text>
				</Form.Group>
				<hr />
				<div className="d-flex">
					<Button variant={isFetching ? "info" : !!saved ? "success" : "primary"} type="submit" disabled={isFetching || saved}>
						{!!isFetching && <><FaSpinner className="animate-pulse" /> {!!initialLoad && !!isEdit ? "Loading..." : "Saving..."}</>}
						{!!saved && <><FaCheck /> Saved!</>}
						{!!isEdit && !isFetching && !saved && <><FaSave /> Save Changes</>}
						{!isEdit && !isFetching && <>Add Client</>}
					</Button>
					{!!isEdit && <Button href={`/clients/${clientId}`} variant="link">Back to profile</Button>}
					<span style={{marginLeft:"auto"}}>
						{!!clientId && <Button onClick={() => setDeleteModalOpen(!deleteModalOpen)} variant="outline-danger"><FaTrash /> Delete</Button>}
					</span>
				</div>
			</Form>
			<DeleteClientModal value={{...state.fields, markets: state.markets, clientId}} show={deleteModalOpen} onHide={() => setDeleteModalOpen(false)} />
		</>
	);
};

const DeleteClientModal = (props) => {
	console.log(props);
	const {
		value: { name, clientId, markets = [] } = {},
		onHide = () => {},
		onDelete = {},
		show = false
	} = props;

	const [acknowledged, setAcknowledged] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(<></>);

	const handleDelete = async () => {
		setError(<></>);
		const response = await clientsApi.delete({ _id: clientId });
		setSuccess(true);
		setError(<Alert className="mt-3" variant="success">Client "{name}" was successfully deleted. In a 3 seconds, you will be taken back to the client list. <a href="/clients">Go now <FaAngleDoubleRight /></a></Alert>);
		setTimeout(() => window.location.href="/clients", 3000);
	}

	const handleError = (e) => {
		if(!e) {
			setError(<></>);
			return
		}

		setError(<Alert className="mt-3" variant="danger">{e.message}</Alert>)
	}

	return (<Modal onHide={onHide} show={show} size="lg" aria-labelledby={`change-{name}-filter`} centered>
		<Modal.Header closeButton>
			<Modal.Title>Permenantly delete {name}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{!!markets?.length && <Alert variant="danger"><FaExclamationTriangle /> All markets on the client must be deleted before the client can be deleted.</Alert>}
			<Form.Check disabled={!!markets.length} value={acknowledged} onChange={setAcknowledged} type="checkbox" label="I understand that deleting a client is irreversible and this market will be permenantly." />
			{error}
		</Modal.Body>
		<Modal.Footer>
			{!success && <>
			<AsyncButton onError={handleError} size="sm" variant="danger" disabled={!!markets?.length || !acknowledged} onClick={handleDelete}><FaTrash /> Delete</AsyncButton>
			<Button variant="secondary" size="sm" onClick={onHide}><FaTimes /> Cancel</Button>
			</>}
		</Modal.Footer>
	</Modal>);
}

export default ClientForm;