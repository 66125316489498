import { JobTemplateApi } from "api";
import { JobTemplate, User, Validation } from "api/Types";
import { ConfirmButtons } from "Components/Form";
import { AsyncButton, LoadingIndicator } from "Components/Shared";
import { PublishData } from "Components/Syndicated";
import { BadRequest } from "Errors";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaEdit, FaRedo, FaTimes, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import findDifferences from "utils/findDifferences";
import JobTemplateForm from "./JobTemplateForm";

type Props = {
	activeUser?: User
}

const JobTemplateDetails = (props:Props) => {
	const templateId = useParams().templateId;

	const [loading, setLoading] = useState<boolean>(true);
	const [edit, setEdit] = useState<boolean>(false);
	const [template, setTemplate] = useState<JobTemplate>({});
	const [validation, setValidation] = useState<Validation[]>([]);
	const [initialTemplate, setInitialTemplate] = useState<JobTemplate>({});

	const reload = async () => {
		try {
			setLoading(true);
			const result = await JobTemplateApi.read(templateId || "");

			if(!result?._id) {
				throw new Error("Template not found.");
			}

			setTemplate(result);
			setInitialTemplate(result);
		} catch(error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => { reload() }, [templateId]);

	const enableEdit = () => setEdit(true);
	const cancel = async () => { 
		setValidation([]); 
		setEdit(false); 
		setTemplate(initialTemplate); 
	};
	const saveChanges = async () => {
		try {
			const differences = findDifferences(initialTemplate, template);
			if(!Object.keys(differences).length) {
				throw new Error("No changes to save.");
			}
			JobTemplateApi.update(templateId || "", differences);
		} catch(error) {
			console.error(error);
			if(error instanceof BadRequest)
			{
				setValidation(error.validationErrors?.errors || []);
			}
		} finally {

		}
	};

	const deleteTemplate = async () => {
		if(!window.confirm("Permenantly delete this template?"))
		{
			throw new Error("User cancelled the request.");
		}
		await JobTemplateApi.deleteTemplate(templateId);
		window.location.href = "/jobs/templates";
	}

	if(loading) {
		return (<>
			<LoadingIndicator /> Loading template...
		</>);
	}

	return (<>
		<Row>
			<Col>
				<span className="float-end align-middle">
					{!edit && <Button title="Edit template" variant="secondary" size="sm" onClick={enableEdit}><FaEdit /> Edit</Button>}
					{!!edit && <ConfirmButtons onConfirm={saveChanges} onCancel={cancel} />}
					<Button className="ms-1" title="Reload template" variant="primary" size="sm" onClick={reload}><FaRedo /> Reload</Button>
					<AsyncButton className="ms-1" title="Delete template" variant="danger" size="sm" onClick={deleteTemplate}><FaTrash /> Delete</AsyncButton>
				</span>
			</Col>
		</Row>
		<Row>
			<Col>
				<JobTemplateForm validation={validation} value={template} disabled={!edit} onChange={(t) => setTemplate({ ...t })} />
			</Col>
		</Row>
		<Row>
			<Col>
				<PublishData {...template } />
			</Col>
		</Row>

	</>)
}

export default JobTemplateDetails;