import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AncillariesForm } from "../Shared";
import { AddressSelector, JobMetaForm, Validation } from "../Form";
import { getNumber, getValue } from "../../utils/formHelpers";
import { MapsApi } from "../../api";
import LoadingIndicator from "../Shared/LoadingIndicator";
import DateTimeSelect from "Components/Form/DateTimeSelect";
import { isPermitted } from "utils/permissions";

const JobForm = ({
	activeUser,
	onChange = () => {},
	stage = "stage1",
	job: initialJob = {
		customerMiles: "",
		pieces: 1,
		meta: {},
		pickup: {},
		dropoff: {}
	},
	validation = [],
	clearValidation = (names = []) => console.log(`Unable to clear ${names.join("|")} as no clear validation method was provided.`)
}) => {
	const [job, setJob] = useState(initialJob);
	const [isCalculatingDistance, setIsCalculatingDistance] = useState(false);
	
	useEffect(() => {
		onChange(job);
	}, [job])

	const hasBothLocations = job?.dropoff?.lat && job?.dropoff?.lon && job?.pickup?.lat && job?.pickup?.lon;

	const setMeta = (meta) => {
		clearValidation(Object.keys(meta).map(k => `meta.${k}`));
		setJob(j => ({ ...j, meta: { ...j.meta, ...meta }}))
	}

	const setPickup = (pickup) => {
		clearValidation(Object.keys(pickup).map(k => `pickup.${k}`));
		setJob(j => ({ ...j, pickup: { ...j.pickup, ...pickup }}));
	}

	const setDropoff = (dropoff) => {
		clearValidation(Object.keys(dropoff).map(k => `dropoff.${k}`));
		setJob(j => ({ ...j, dropoff: { ...j.dropoff, ...dropoff }}));
	}

	const setAncillaries = (ancillaries) => {
		setJob(j => ({ ...j, ancillaries }));
	}

	const setNumber = (event) => {
		const name = event.target.name;
		const min = event.target.min;
		const max = event.target.max;
		clearValidation([name]); 
		setJob((j) => ({...j, [name]: getNumber(event, min, max) }));
	}

	const setValue = (event) => {
		const name = event.target.name;
		clearValidation([name]); 
		setJob((j) => ({...j, [name]: getValue(event) }));
	}

	const computeDistance = async () => {
		if(!hasBothLocations) {
			return;
		}

		setIsCalculatingDistance(true);

		const miles = await MapsApi.computeDistance({
			origin: `${job.pickup.lat}, ${job.pickup.lon}`,
			destination: `${job.dropoff.lat}, ${job.dropoff.lon}`
		})
		
		setJob((j) => ({...j, calculatedMiles: miles}))
		setIsCalculatingDistance(false);
	}


	const readyAt = job?.pickup?.readyAt;
	const deliverBy = job?.dropoff?.deliverBy;

	const addHours = (date, hours) => new Date(new Date(date).setHours(date.getHours() + hours));

	if(!isPermitted(activeUser, "editJobContractTerms") && stage === "stage1") {
		stage = "stage2";
	}

	switch(stage) {
		case "stage1": 
			return	(<AncillariesForm name={"ancillaries"} validation={validation} clearValidation={clearValidation} onChange={setAncillaries} {...job.ancillaries} />)
		case "stage2":
			return (<>
				<h3>Pickup</h3>
				<Form.Label>Ready at</Form.Label>
				<InputGroup hasValidation>
				<DateTimeSelect 
						value={job?.pickup?.readyAt} 
						onChange={date => setPickup({ readyAt: date })} 
						isInvalid={validation.find(v => v.param === `pickup.readyAt`)}
					/>
					<Validation validation={validation} names={[`pickup.readyAt`]} />
				</InputGroup>

				{!!job?.pickup?.pickedupAt && <>
					<Form.Label>Picked up at</Form.Label>
					<InputGroup hasValidation>
					<DateTimeSelect 
							value={job?.pickup?.pickedupAt} 
							onChange={date => setPickup({ pickedupAt: date })} 
							isInvalid={validation.find(v => v.param === `pickup.pickedupAt`)}
						/>
						<Validation validation={validation} names={[`pickup.pickedupAt`]} />
					</InputGroup>
				</>}
				
				<Form.Label>Pickup Note</Form.Label>
					<InputGroup hasValidation>
						<Form.Control
							placeholder="Add a note specifically for pickup"
							type="text"
							value={job?.pickup?.note}
							onChange={evt => setPickup({ note: getValue(evt) })}
							isInvalid={validation.find(v => v.param === `pickup.note`)}
						/>
						<Validation validation={validation} names={[`pickup.note`]} />
					</InputGroup>
				<AddressSelector validation={validation} key="pickup" name="pickup" address={job?.pickup} onChange={a => setPickup(a)} />
			</>);
		case "stage3": return (<>
			<Row>
				<Col>
					<h3>Drop off</h3>
					<Form.Label>Deliver by</Form.Label>
					<InputGroup hasValidation>
						<DateTimeSelect 
							value={job?.dropoff?.deliverBy} 
							onChange={date => setDropoff({ deliverBy: date })} 
							isInvalid={validation.find(v => v.param === `dropoff.deliverBy`)}
						/>
						<Validation validation={validation} names={[`dropoff.deliverBy`]} />
					</InputGroup>
					{readyAt && 
						<div className="mt-2 mb-2">
							<ButtonGroup>
								<Button onClick={() => setDropoff({ deliverBy: addHours(readyAt, 1) })} size="sm" variant="outline-primary">1 hour later</Button>
								<Button onClick={() => setDropoff({ deliverBy: addHours(readyAt, 2) })} size="sm" variant="outline-primary">2 hours later</Button>
								<Button onClick={() => setDropoff({ deliverBy: addHours(readyAt, 3) })} size="sm" variant="outline-primary">3 hours later</Button>
							</ButtonGroup>
						</div>
					}
				
					{!!job?.dropoff?.dropoffAt && <>
						<Form.Label>Dropped off at</Form.Label>
						<InputGroup hasValidation>
						<DateTimeSelect 
								value={job?.dropoff?.dropoffAt} 
								onChange={date => setDropoff({ dropoffAt: date })} 
								isInvalid={validation.find(v => v.param === `dropoff.dropoffAt`)}
							/>
							<Validation validation={validation} names={[`dropoff.dropoffAt`]} />
						</InputGroup>
					</>}

					<Form.Label>Delivery Note</Form.Label>
					<InputGroup hasValidation>
						<Form.Control
							placeholder="Add a note specifically for delivery, ex: 'ATTN: John Doe'"
							type="text"
							value={job?.dropoff?.note}
							onChange={evt => setDropoff({ note: getValue(evt) })}
							isInvalid={validation.find(v => v.param === `dropoff.note`)}
						/>
						<Validation validation={validation} names={[`dropoff.note`]} />
					</InputGroup>
					<AddressSelector validation={validation} key="dropoff" name="dropoff" address={job?.dropoff} onChange={a => setDropoff(a)} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label>Customer miles</Form.Label>
					<InputGroup size="sm" hasValidation>
						<Form.Control 
							className="text-end"
							type="text" 
							value={job?.customerMiles} 
							name="customerMiles"
							min={0}
							onChange={setNumber}
							placeholder="Customer reported mileage"
							isInvalid={validation.find(v => v.param === `customerMiles`)}
						/>
						<InputGroup.Text>miles</InputGroup.Text>
						<Validation validation={validation} names={[`customerMiles`]} />
					</InputGroup>	
				</Col>
				<Col>
					<Form.Label>Calculated miles</Form.Label>
					<InputGroup size="sm">
						<Form.Control 
							disabled
							className="text-end"
							type="text" 
							name="calculatedMiles"
							min={0}
							value={!!job?.calculatedMiles ? job?.calculatedMiles.toFixed(0) : ""} 
							onChange={setNumber}
							placeholder="Click button to calculate mileage"
						/>
						<InputGroup.Text>miles</InputGroup.Text>
						{isCalculatingDistance && <Button disabled>Calculating... <LoadingIndicator /></Button> }
						{!isCalculatingDistance && <Button onClick={computeDistance} disabled={!hasBothLocations}>Calculate</Button>}
					</InputGroup>	
				</Col>
			</Row>
			</>);
		case "stage4":
			return (<>
				<Form.Group as={Row} className="mb-3">
					<Col>
						<Form.Label>Pieces</Form.Label>
						<InputGroup size="sm">
							<Form.Control 
								className="text-end"
								type="text" 
								value={job?.pieces}
								min={1}
								name="pieces"
								onChange={setNumber}
								placeholder="# of pieces"
								isInvalid={validation.find(v => v.param === `pieces`)}
							/>
							<Validation validation={validation} names={[`pieces`]} />
						</InputGroup>
					</Col>
					<Col>
						<Form.Label>Weight</Form.Label>
						<InputGroup size="sm">
							<Form.Control 
								className="text-end"
								type="text" 
								value={job?.weight}
								min={0}
								name="weight"
								onChange={setNumber}
								placeholder="Total weight in pounds"
								isInvalid={validation.find(v => v.param === `weight`)}
							/>
							<InputGroup.Text>lbs</InputGroup.Text>
							<Validation validation={validation} names={[`weight`]} />
						</InputGroup>		
					</Col>	
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Col>
						<Form.Label>Job #</Form.Label>
						<InputGroup>
							<Form.Control 
								className="text-end"
								type="text" 
								name="jobNumber"
								value={job?.jobNumber} 
								onChange={setValue}
								placeholder="ex: 123456789"
								isInvalid={validation.find(v => v.param === `jobNumber`)}
							/>
							<Validation validation={validation} names={[`jobNumber`]} />
						</InputGroup>
					</Col>
					<Col>
						<Form.Label>BOL #</Form.Label>
						<InputGroup>
							<Form.Control 
								className="text-end"
								type="text" 
								name="billOfLading"
								value={job?.billOfLading} 
								onChange={setValue}
								placeholder="ex: 123456789"
								isInvalid={validation.find(v => v.param === `billOfLading`)}
							/>
							<Validation validation={validation} names={[`billOfLading`]} />
						</InputGroup>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Col>
						<Form.Label>Air Waybill</Form.Label>
						<InputGroup>
							<Form.Control 
								className="text-end"
								type="text"
								name="airwayBill"
								value={job?.airwayBill} 
								onChange={setValue}
								placeholder="ex: 12345678901"
								isInvalid={validation.find(v => v.param === `airwayBill`)}
							/>
							<Validation validation={validation} names={[`airwayBill`]} />
						</InputGroup>
					</Col>
					<Col>
						<Form.Label>Flight #</Form.Label>
						<InputGroup>
							<Form.Control 
								className="text-end"
								type="text" 
								name="flightNumber"
								value={job?.flightNumber} 
								onChange={setValue}
								placeholder="ex: BA2490"
								isInvalid={validation.find(v => v.param === `flightNumber`)}
							/>
							<Validation validation={validation} names={[`flightNumber`]} />
						</InputGroup>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Form.Label>Extra Details</Form.Label>
					<InputGroup>
						<Form.Control 
							as="textarea"
							name="additionalDetails"
							value={job?.additionalDetails}
							onChange={setValue}
							placeholder="Add any extra details."
							isInvalid={validation.find(v => v.param === `additionalDetails`)}
							rows={5}
						/>
						<Validation validation={validation} names={[`additionalDetails`]} />
					</InputGroup>
				</Form.Group>
				<JobMetaForm value={job?.meta} onChange={setMeta} />
			</>);
	}
};

export default JobForm;