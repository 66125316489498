import React from "react";
import { Button, OverlayTrigger, Popover, Tooltip, TooltipProps } from "react-bootstrap";
import { FaExclamationTriangle, FaMapMarkerAlt } from "react-icons/fa";
import format from "utils/format";
import calculateDifference from "utils/calculateDifference";

const TOLERANCE = 0.1;

type Props = {
	customerMiles?: number,
	calculatedMiles?: number,
	useCustomerMiles?: boolean
};

const Mileage = (props:Props) => {
	const { customerMiles = 0, calculatedMiles = 0, useCustomerMiles = false } = props;
	
	const displayCustomer = useCustomerMiles && !!customerMiles;

	const renderMileage = (mileage:number) => {
		return <>{mileage.toFixed(2)} mile{customerMiles === 1 ? "" : "s"}</>
	}

	const popover = (
		<Popover>
			<Popover.Header as="h3"><FaExclamationTriangle /> Mileage Discrepency</Popover.Header>
			<Popover.Body>
				Customer miles reported as {format.number(customerMiles,0)} but the mileage was calculated to be {format.number(calculatedMiles,0)}. 
				Please review as this is greater than the {format.number(TOLERANCE * 100)}% tolerance.
			</Popover.Body>
		</Popover>
	);

	const difference = calculateDifference(customerMiles, calculatedMiles);
	const showWarning = useCustomerMiles && customerMiles + calculatedMiles > 2 && difference > TOLERANCE;
	
	return <>
		<FaMapMarkerAlt className="me-1" />
		{!!displayCustomer && renderMileage(customerMiles)}
		{!displayCustomer && !!calculatedMiles && renderMileage(calculatedMiles)}
		{!!showWarning && <OverlayTrigger trigger="click" placement="right" overlay={popover}>
			<span style={{cursor: "pointer"}}><FaExclamationTriangle className="ms-1 text-danger" /></span>
		</OverlayTrigger>}
	</>;
}

export default Mileage;