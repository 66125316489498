import React from "react";
import { Outlet } from "react-router-dom";

const ReportPageSwitch = () => {
  return (
		<div className="report-page">
			<Outlet />
		</div>
  );
};

export default ReportPageSwitch;