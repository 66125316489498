import React from "react";
import { useLocation } from "react-router-dom";

const buildObjectFromSearch = (query) => {
  const params = new URLSearchParams(query);
  const entries = params.entries();
  const output = {};
  for(const [key, value] of entries) {
    const type = typeof(output[key]);
    switch(type) {
      case typeof(undefined):
        output[key] = value;
        break;
      case typeof([]):
        output[key].push(value);
        break;
      default:
        output[key] = [output[key], value];
    }
  }
  
  return output
}

export default () => {
  const { search } = useLocation();
  return React.useMemo(() => buildObjectFromSearch(search));
};