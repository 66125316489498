import React from "react";
import { FaPlusSquare } from "react-icons/fa";
import { Widget } from "../Shared";
import { InvoicesApi } from "../../api";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import format from "../../utils/format";

const InvoiceWidget = ({
	clientId,
	marketId,
	invoices: initialInvoices = {}
}) => {
	const [invoices, setInvoices] = useState(initialInvoices);

	useEffect(() => {
		const fetchData = async () => {
			if(!!clientId?.length || !!marketId?.length) {
					const filter = { clientId, marketId };
					const requests = [
						InvoicesApi.filter({ ...filter, limit: 5, status: ["new"] }),
						InvoicesApi.filter({ ...filter, limit: 5, status: ["sent"] }),
						InvoicesApi.filter({ ...filter, limit: 5, status: ["paid"] })
					];
		
					const [newInvoices, sentInvoices, paidInvoices] = await Promise.all(requests);
					setInvoices({ new: newInvoices, sent: sentInvoices, paid: paidInvoices });
				}
			}

		fetchData();
	}, []);

	const addInvoiceHref = `/invoices/new${!!clientId ? `?clientid=${clientId}` : ""}`;
	return (
		<Widget title={<>Invoices <Button className="float-end" href={`/invoices?clientid=${clientId}`} size="sm" type="link">See all</Button></>}>
			<ul className="m-0 p-0" style={{ listStyleType: "none" }}>
				<li><strong>Pending Invoices</strong> <a className="float-end" href={`/invoices?clientid=${clientId}&statuses=new`}>See all</a></li>
				{ !!invoices?.new?.invoices?.length &&  invoices.new.invoices.map(i => <InvoiceRow key={`invoice-${i._id}`} invoice={i} />) }
				{ !invoices?.new?.invoices?.length && <li className="text-center">No pending invoices</li> }
				<li className="mt-3"><strong>Unpaid Invoices</strong> <a className="float-end" href={`/invoices?clientid=${clientId}&statuses=sent`}>See all</a></li>
				{ !!invoices?.sent?.invoices?.length &&  invoices.sent.invoices.map(i => <InvoiceRow key={`invoice-${i._id}`} invoice={i} />) }
				{ !invoices?.sent?.invoices?.length && <li className="text-center">No unpaid invoices</li> }
				<li className="mt-3"><strong>Paid Invoices</strong> <a className="float-end" href={`/invoices?clientid=${clientId}&statuses=paid`}>See all</a></li>
				{ !!invoices?.paid?.invoices?.length &&  invoices.paid.invoices.map(i => <InvoiceRow key={`invoice-${i._id}`} invoice={i} />) }
				{ !invoices?.paid?.invoices?.length && <li className="text-center">No paid invoices</li> }
				<li className="text-center mt-3">
					<Button type="primary" size="sm" href={addInvoiceHref}><FaPlusSquare /> Add Invoice</Button>
				</li>
			</ul>
		</Widget>
	)
}

const InvoiceRow = ({ invoice = {} }) => {
	return(<li><a href={`/invoices/${invoice._id}`} target="_blank" rel="noreferrer">{invoice?.market?.name} {format.date(new Date(invoice?.endDate))}</a></li>)
}

export default InvoiceWidget;