import React, { useEffect, useState, useImperativeHandle } from "react";
import jobsApi from "../../api/jobsApi";
import JobCard from "../Job/JobCard";
import { Invoice } from ".";
import { LoadingIndicator } from "../Shared";
import { SaveButton } from "../Form";
import { Accordion, Form, Table, Row, Col, Alert } from "react-bootstrap";
import { FaTimesCircle, FaToggleOff, FaToggleOn } from "react-icons/fa";
import invoicesApi from "../../api/invoicesApi";
import marketApi from "../../api/marketsApi";
import format from "utils/format";

const InvoicePreview = React.forwardRef(({
	client = {},
	market: initialMarket = {},
	jobs: initialJobs = [],
	cutoffDate = new Date(),
	eventKey
}, ref) => {
	const [jobs, setJobs] = useState([...initialJobs]);
	const [market, setMarket] = useState(initialMarket);
	const [loading, setLoading] = useState(false);
	const [editJobs, setEditJobs] = useState(false);
	const [selectedJobs, setSelectedJobs] = useState([]);
	const [saving, setSaving] = useState(false);
	const [serialNumber, setSerialNumber] = useState();

	useEffect(() => {
		async function fetchData () {
			setLoading(true);
			const date = cutoffDate;
			date.setHours(23);
			date.setMinutes(59);
			date.setSeconds(59);
	
			const requests = [];
			requests.push(marketApi.read(client._id, market._id));
			requests.push(jobsApi.filter({
				marketId: [market._id],
				dropoffAtEndDate: date.getTime(),
				status: ["complete"],
				limit: 500
			}));
			
			const [marketResult, jobsResult] = await Promise.all(requests);
	
			setMarket(marketResult);
			setJobs([...jobsResult.jobs]);
			setSelectedJobs(jobsResult.jobs.map(j => j._id));
			setLoading(false);
		}
		fetchData();
	}, []);

	const handleJobCheck = (id) => {
		setSelectedJobs(j => {
			const idx = j.indexOf(id);
			if(idx >= 0) {
				const newJobs = [...j];
				newJobs.splice(idx, 1);
				return newJobs;
			}
			return [...j, id];
		});
	};

	const save = async () => {
		setSaving(true);

		const invoice = await invoicesApi.create({
			endDate: cutoffDate,
			invoiceId: `${format.invoiceId(cutoffDate)}${market.code}`,
			jobs: jobs.map(j => j._id),
			market: market._id,
			total: jobs.reduce((acc, j) => acc+=j.totalCharges, 0)
		});

		setSerialNumber(invoice._id);
		setSaving(false);
	};

	useImperativeHandle(ref, save);

	const jobsForInvoice = jobs.filter(j => selectedJobs.indexOf(j._id) >= 0);

	const renderBody = () => {
		if(!!loading)
			return (<LoadingIndicator />);

		if(!!serialNumber)
			return (<>Invoice created! See it here: <a href={`/invoices/${serialNumber}`}>{serialNumber.slice(-6).toUpperCase()}</a></>);

		if(!jobs?.length)
			return (<>No jobs found for this market in the specified time range.</>)

		if(!editJobs) {
			return (<>
				{!market?.code && <Row>
					<Col>
						<Alert variant="danger"><FaTimesCircle /> Market {market?.name} does not have a code designated. Please designate a code before creating this invoice.</Alert>
					</Col>
				</Row>}
				<Row>
					<Col>
						<Invoice {...{ _id: serialNumber, jobs: jobsForInvoice, market }} />
					</Col>
				</Row>
				<Row><Col><SaveButton disabled={!market?.code} onClick={save}>Save</SaveButton></Col></Row>
			</>);
		}

		if(!!editJobs && !!jobs?.length) {
			return (<Table borderless>
				<tbody>
					{ !!loading && <tr><td className="text-center"><LoadingIndicator /></td></tr> }
					{ !jobs?.length && <tr><td className="text-center">No complete jobs ready to bill. Try different markets.</td></tr> }
					{ !!jobs?.length && jobs.map((job, i) => 
						<tr key={`job-check-${i}`}>
							<td className="align-middle"><Form.Check checked={selectedJobs.indexOf(job._id) >= 0} onClick={() => handleJobCheck(job._id)} /></td>
							<td><JobCard {...job} /></td>
						</tr>
					)}
				</tbody>
			</Table>);
		}

		return <>Failed to generate invoice.</>
	}

	return (<div ref={ref}>
		<Accordion.Item eventKey={eventKey}>
			<Accordion.Header>{market.name} {!!saving && <><LoadingIndicator/> Saving...</>}</Accordion.Header>
			<Accordion.Body>
				{!!jobs.length && !loading && 
					<Row>
						<Col className="text-end">
							<span style={{cursor: "pointer"}} onClick={() => setEditJobs(!editJobs)} className="float-end">{!!editJobs && <><FaToggleOn /> Preview Invoice</> }{!editJobs && <><FaToggleOff /> Select Jobs</>}</span>
						</Col>
					</Row>
				}
				{renderBody()}
			</Accordion.Body>
		</Accordion.Item>
	</div>)
});

export default InvoicePreview;