import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Overlay, Popover, Row } from "react-bootstrap";
import { FaCalendar, FaClock, FaGlobeAmericas } from "react-icons/fa";
import { disableScroll, enableScroll } from "utils/manageScrolling";
import format from "utils/format";
import { DateSelectionCalendar, RolodexNumberSelect } from ".";
import "./DateTimeSelect.css";
import moment from "moment";
import addDays from "utils/addDays";
import { withTimezone } from "Components/GlobalState/TimezoneContext";
import TimezoneInput from "./TimezoneInput";

const DateTimeSelect = withTimezone((props) => {
	let { value, onChange = () => {}, disabled = false, timezone, setTimezone } = props;
	value = !(value instanceof Date) && !!value ? new Date(value) : value;
	const [calendarOpen, setCalendarOpen] = useState(false);
	const [clockOpen, setClockOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
	const [typedDate, setTypedDate] = useState();
	const [typedTime, setTypedTime] = useState();


	const openCalendar = () => { closeClock(); setCalendarOpen(true); }
	const openClock = () => { closeCalendar(); disableScroll(); setClockOpen(true); }
	const closeCalendar = () => setCalendarOpen(false);
	const closeClock = () => { enableScroll(); setClockOpen(false); }

  const handleClick = (f) => (event) => {
    setTarget(event.target);
		f();
  };

	const handleHourChange = (hours) => {
		const date = value || new Date();
		setTypedTime();
		date.setHours(hours);
		onChange(new Date(date));
	}
	const handleMinuteChange = (minutes) => {
		const date = value || new Date();
		setTypedTime();
		date.setMinutes(minutes);
		onChange(new Date(date));
	}

	const handleTimezoneChange = () => {

	}

	const handleTimeChange = (newTime) => {
		const date = value || newTime;
		date.setHours(newTime.getHours());
		date.setMinutes(newTime.getMinutes());
		
		onChange(new Date(date));
	}

	const handleChangeDate = (newDate) => {
		const date = value || newDate;

		if(date instanceof Date) {
			setTypedDate();
			date.setDate(newDate.getDate());
			date.setMonth(newDate.getMonth());
			date.setYear(newDate.getFullYear());
		}
		
		onChange(new Date(date));
	}

	const handleTypedDate = (e) => {
		const typedValue = e.target.value;
		moment.tz.setDefault(timezone);
		const date = moment(typedValue, "MM-DD-YYYY", true);
		if(date.isValid()) {
			handleChangeDate(date.toDate());
			setTypedDate();
			return;
		}
		setTypedDate(e.target.value);
	}

	const handleTypedTime = (e) => {
		const typedValue = e.target.value;
		moment.tz.setDefault(timezone);
		const time = moment(typedValue, "HHmm", true).tz(timezone);
		if(time.isValid()) {
			handleTimeChange(time.toDate());
			setTypedTime();
			return;
		}
		setTypedTime(e.target.value);
	}

	const isDate = value instanceof Date;
	const date = isDate ? format.date(value) : undefined;
	const time = isDate ? format.time(value) : undefined;
	const hours = isDate ? value.getHours() : undefined;
	const minutes = isDate ? value.getMinutes() : undefined;

	const calendarPopover = (<>
		<Row className="mb-1">
			<Col>
				<Button onClick={() => handleChangeDate(new Date())} className="me-1" size="sm" variant="outline-primary">Today</Button>
				<Button onClick={() => handleChangeDate(addDays(new Date(), 1))} size="sm" variant="outline-primary">Tomorrow</Button>
			</Col>
		</Row>
		<Row>
			<Col>
				<DateSelectionCalendar size="sm" date={value} onChange={handleChangeDate} />
			</Col>
		</Row>
	</>);

	const clockPopover = (<>
		<Row>
			<Col>
				<RolodexNumberSelect value={hours} min={0} max={23} name="hour" label="Hours" onChange={handleHourChange} />
			</Col>
			<Col>
				<RolodexNumberSelect value={minutes} min={0} max={59} name="minute" label="Minutes" onChange={handleMinuteChange} />
			</Col>
		</Row>
		<Row className="mt-2">
			<Col>
				<FaGlobeAmericas /> {timezone}
			</Col>
		</Row>
	</>);

  return (
    <>
			<InputGroup ref={ref}>
				<Form.Control disabled={disabled} onFocus={handleClick(openCalendar)} onChange={handleTypedDate} placeholder="MM-DD-YYYY" value={typedDate ?? date} />
				<Button variant={calendarOpen ? "secondary" : "outline-secondary"} onClick={handleClick(openCalendar)}><FaCalendar /></Button>
				<Form.Control disabled={disabled} onFocus={handleClick(openClock)} onChange={handleTypedTime} placeholder="HHmm"  value={typedTime ?? time} />
				<Button variant={clockOpen ? "secondary" : "outline-secondary"} onClick={handleClick(openClock)}><FaClock /></Button>
			</InputGroup>

      <Overlay
        show={calendarOpen || clockOpen}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
				rootClose
				rootCloseEvent="mousedown"
				onHide={() => {closeClock(); closeCalendar()}}
      >
        <Popover id="popover-contained">
          <Popover.Body>
						{calendarOpen && calendarPopover}
						{clockOpen && clockPopover}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
})

export default DateTimeSelect;