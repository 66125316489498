import { StatisticList } from 'Components/Shared/Statistic';
import { PublishData } from 'Components/Syndicated';
import React from 'react'
import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import type { Region } from "../../api/Types";

type Props = {
	region: Region
}

const ClientCard = (props:Props) => {
	const { region } = props;
	const { name, url, totalMarkets = 0, totalUsers = 0 } = region;

	const statistics = [
		{ label: "Total Markets", value: totalMarkets },
		{ label: "Total Drivers", value: totalUsers }
	]

	return (
		<Card className="region-card">
			<Card.Body>
				<Row className="d-flex align-items-center justify-content-between">
					<Col md="3" className="text-start mb-2 mb-md-0">
						<Card.Title className="mb-0"><a className="text-decoration-none" href={`/regions/${url}`}>{name}</a></Card.Title>
					</Col>
					<Col className="flex-grow-1 mb-2 mb-md-0">
						<StatisticList {...{statistics}} />
					</Col>
					<Col md="3" className="text-center text-md-end">
						<ButtonGroup className="mb-2">
							<Button size="sm" href={`/regions/${url}`}>View</Button>
						</ButtonGroup>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default ClientCard