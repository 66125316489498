import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { FaPlane, FaBox, FaWeightHanging, FaBiohazard, FaShuttleVan } from "react-icons/fa";
import { HiOutlineIdentification, HiOutlineDocumentText } from "react-icons/hi";
import { IoIosCloudyNight } from "react-icons/io";
import { GiBroom, GiPoliceBadge } from "react-icons/gi";
import { MdMoneyOff } from "react-icons/md";
import { isPermitted } from "utils/permissions";

const DeliveryInformation = ({
    isBig,
    isIdCheck,
    requiresTSANumber,
    isPickAndHold,
    isQS,
    isSweepJob,
    weight,
    pieces,
    bol,
    flightNumber,
    airwayBill,
    requiresVan = false,
    totalCharges = 0,
    hazardous,
    noCharge = false,
    activeUser
}) => {
    if(typeof totalCharges !== typeof 2) {
        totalCharges = 0;
    }

    const values = [];
    
    if(isPermitted(activeUser, "viewJobMoney"))
        values.push(<strong>Total Charges: {`$${totalCharges.toFixed(2)}`}</strong>);
    values.push(<><FaBox /> {weight} lbs. as {pieces} piece{pieces > 1 ? "s" : ""}</>);
    values.push(<><FaPlane /> {flightNumber || "N/A"} {airwayBill}</>);
    if(!!requiresVan) values.push(<><FaShuttleVan /> Requires Van</>);
    if(!!bol) values.push(<><HiOutlineDocumentText />Bill of Lading: {bol}</>)
    if(isBig) values.push(<><FaWeightHanging /> Oversized</>);
    if(isIdCheck) values.push(<><HiOutlineIdentification /> Requires ID Check</>);
    if(requiresTSANumber) values.push(<><GiPoliceBadge /> Driver must have a TSA Number</>);
    if(isQS) values.push(<>Is QS</>);
    if(isPickAndHold) values.push(<><IoIosCloudyNight /> Hold overnight</>);
    if(isSweepJob) values.push(<><GiBroom /> Sweep Job</>);
    if(hazardous) values.push(<><FaBiohazard /> Requires handling hazardous material</>);
    if(noCharge) values.push(<><MdMoneyOff /> No charge</>);

	return (
        <ListGroup>
            {values.map((v,i) => <ListGroupItem key={`details-${i}`}>{v}</ListGroupItem>)}
        </ListGroup>
	);
};

export default DeliveryInformation;