import { apiFetch } from "./api";
import session from "../utils/session";

const sessionsApi = {
	createSession: async (googleToken) => {
		if(!googleToken) {
			return {};
		}
		const response = await apiFetch(
			`/users/authorize`,
			{
				method: "POST",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ googleToken })
			}
		);	
		session.setSessionCookie(response);
		return response;
	},
	getSession: () => {
		return session.getSessionCookie();
	},
	logout: () => {
		session.setSessionCookie({});
	}
};

export default sessionsApi;