import React from "react";
import { Report as ReportType } from "api/Types/Report";
import { Table } from "react-bootstrap";
import format from "utils/format";

type Props = {
	report: ReportType
}

const getDateRangeText = (start?:Date, end?:Date) => {
	if(!start && !end) {
		return;
	}
	return `${format.date(start)} thru ${format.date(end)}`
}

const Report = (props:Props) => {
	const dateRangeText = getDateRangeText(props.report.startDate, props.report.endDate);

	return <>
		<h1 className="text-center">{props?.report?.name || "Job Summary by Market"}</h1>
		{!!dateRangeText && <h2 className="text-center text-muted">{dateRangeText}</h2>}
		<Table striped>
			<thead>
				<tr>
					<th>Bill To</th>
					<th className="text-end">Runs</th>
					<th className="text-end">Average</th>
					<th className="text-end">Charges</th>
				</tr>
			</thead>
			<tbody>
				{props.report?.details?.map(d => (
					<tr key={d.name}>
						<td><a href={d.url}>{d.name}</a></td>
						<td className="text-end">{d.count}</td>
						<td className="text-end">{format.currency(d.average)}</td>
						<td className="text-end">{format.currency(d.total)}</td>
					</tr>
				))}
			</tbody>
			<tfoot>
				<tr>
					<td>Total for {dateRangeText}</td>
					<td className="text-end">{props.report?.count}</td>
					<td className="text-end">{format.currency(props.report?.average)}</td>
					<td className="text-end">{format.currency(props.report?.total)}</td>
				</tr>
			</tfoot>
		</Table>
	</>
}

export default Report;