import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Dropdown, Button, Modal, Card } from "react-bootstrap";
import { ClientsApi } from "../../api";
import MarketsApi from "../../api/marketsApi";
import { FaEdit, FaCog, FaPlusSquare, FaAngleDoubleLeft } from "react-icons/fa";
import {MarketForm, MarketCard} from "../Market";
import LoadingIndicator from "../Shared/LoadingIndicator";
import { Widget } from "../Shared";
import InvoiceWidget from "../Invoice/InvoiceWidget";
import { PublishData } from "../Syndicated";
import NotFoundError from "Components/Shared/NotFoundError";

const ClientProfile = ({activeUser}) => {
	const { clientId } = useParams();
	const [client, setClient] = useState();
	const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
		const fetchData = async () => {
			try {
				setIsFetching(true)
				const requests = [ClientsApi.read(clientId)];
		
				const [client] = await Promise.all(requests);
				setClient(c => ({...c, ...client}))
			}
			catch(err) {
				console.error(err);
				setClient({});
			}
			finally {
				setIsFetching(false);
			}
		}
		fetchData();
  }, []);

	if(!!isFetching) {
		return (<><LoadingIndicator /> Fetching client profile..</>);
	}

	console.log({isFetching, client});

	if(!isFetching && !!client && !client?._id) {
		return(
			<NotFoundError 
				message="Unable to locate the requested client. Please make sure this client exists." 
				redirect="/clients" 
				redirectMessage="Back to the list of clients." 
			/>
		);
	}

	return (
		<>
			<Dropdown className="float-end">
				<Dropdown.Toggle variant="link" id="options">
					<FaCog />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header><strong>{client?.name}</strong></Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Item href={`/clients/${clientId}/edit`}><FaEdit /> Edit</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<h1>{client?.name}</h1>
			<hr />
			<Row>
				<Col>
					<Widget title="Markets">
						<ul className="m-0 p-0" style={{ listStyleType: "none" }}>
						{client?.markets.map((m, i) => 
							<li className="mb-2" key={`market-${i}`} ><MarketCard
								{...{...m, clientId}}
							/></li>
						)}
							<li className="text-center">
								<Button size="sm" href="/markets/new"><FaPlusSquare /> Add Market</Button>
							</li>
						</ul>
					</Widget>
				</Col>
				<Col>
					<InvoiceWidget clientId={clientId} />
				</Col>
			</Row>
			<Row>
				<Col>
				</Col>
			</Row>
			<Row>
				<Col>
					<PublishData {...client} />
				</Col>
			</Row>
		</>
	);
};

export default ClientProfile;