import { apiFetch } from "./api";
import { buildUrlParams } from "./helpers";
import { IApiFilter, IApiFilterResult, JobTemplate } from "./Types";

export type JobTemplateFilter = IApiFilter & {
	text?: string | null
};

export const filter = async (filter:JobTemplateFilter) : Promise<IApiFilterResult<JobTemplate>> => {
	const defaults:JobTemplateFilter = {
		sort: "default",
		projection: "default",
		limit: 25,
		skip: 0,
		text: null
	};

	const apiParams = { ...defaults, ...filter };
	const qs = buildUrlParams(apiParams);
	const response:IApiFilterResult<JobTemplate> = await apiFetch(`/JobTemplates?${qs}`);

	return response;
}

export const create = async (template:JobTemplate) : Promise<JobTemplate> => {
	const response:JobTemplate = await apiFetch(`/JobTemplates`,
		{
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(template)
		}
	);

	return response;
}

export const read = async (id:string) : Promise<JobTemplate> => {
	if(!id) {
		return {};
	}
	
	const response:JobTemplate = await apiFetch(`/JobTemplates/${id}`);
	return response;
}

export const update = async (id?:string, changes?: JobTemplate) => {
	const response = await apiFetch(
		`/JobTemplates/${id}`,
		{
			method: "PUT",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(changes)
		}
	);
	return response;
}

export const deleteTemplate = async (id?: string) => {
	const response = await apiFetch(
		`/JobTemplates/${id}`,
		{
			method: "DELETE",
			cache: "no-cache"
		}
	);

	return response;
}