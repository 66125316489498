import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { FaPhoneSquareAlt, FaFax, FaEnvelope, FaExternalLinkAlt, FaEdit, FaTrash } from "react-icons/fa";
import format from "../../utils/format";
import { AsyncButton, EmailList } from "Components/Shared";

const propTypes = {
	_id: PropTypes.string,
	clientId: PropTypes.string,
	name: PropTypes.string,
	totalJobs: PropTypes.number,
	contact: PropTypes.object,
	updatedAt: PropTypes.string,
	createdAt: PropTypes.string,
	onEdit: PropTypes.func
}

const MarketCard = ({
		_id,
		name, 
		totalJobs,
		contact,
		updatedAt,
		createdAt,
		onRemove
	}) => {
	return (
		<Card>
			<Card.Header>
				<Row>
					<Col className="text-start"><a href={`/markets/${_id}`} variant="link" size="sm" className="text-decoration-none">{name}</a></Col>
					<Col className="text-end">
							{ !!onRemove && <AsyncButton onClick={onRemove} size="sm" variant="outline-danger"><FaTrash /> Remove</AsyncButton> }
							{ !onRemove && <a href={`/markets/${_id}/edit`} variant="link" size="sm" className="text-decoration-none"><FaEdit /> Edit</a> }
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						{contact.name && <><strong>{contact.name}</strong><br /></>}
						{contact.phone && <><FaPhoneSquareAlt /> {contact.phone}<br /></>}
						{contact.fax && <><FaFax /> {contact.fax} <br /></>}
						{contact.email && <><FaEnvelope /> <EmailList emails={contact.email} expandable={false} /><br /></>}
						{contact.website && <><a href={contact.website} target="_blank" rel="noreferrer"><FaExternalLinkAlt /> {contact.website}</a></>}
					</Col>
					<Col>
						<ul>
							<li>Job count: {totalJobs}</li>
							<li><small className="text-muted">Created: {format.fullDate(createdAt)}</small></li>
							<li><small className="text-muted">Last modified: {format.timeElapsed(updatedAt)}</small></li>
						</ul>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

MarketCard.propTypes = propTypes;

export default MarketCard;