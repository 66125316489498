import React, { useState, useEffect } from "react";
import { Button, Form, Table, Alert, OverlayTrigger, Tooltip, FormGroup, InputGroup, FormControl } from "react-bootstrap"
import { FaPlus, FaTimesCircle, FaCalculator } from "react-icons/fa";
import { getNumber } from "../../utils/formHelpers";
import jobsApi from "../../api/jobsApi";
import { Widget } from "../Shared";
import { ConfirmButtons } from "../Form";
import currency from "currency.js";

const newCharge = { note: "New charge", commission: "standard", unitPrice: 1, units: 0, isNew: true };
const iconStyle = {cursor: "pointer", display: "inline-block"};

const calculateTotal = (charges) => (charges || []).reduce((acc, val) => acc.add(currency(val.amount || 0)), currency(0)).value;

const getCommission = ({ 
	excludeFromCommission = false, 
	forUser
} = {}) => excludeFromCommission ? "exclude" : !!forUser ? forUser : "standard";

const displayCommission = (commission, drivers) => {
	if(commission === "" || commission === "standard") {
		return "";
	}
	if(commission === "exclude") {
		return "Excluded";
	}
	if(!!commission?.name) {
		return commission?.name;
	}
	const driver = drivers.find(d => d._id === commission);
	if(!!driver?._id) {
		return driver?.name;
	}
	return "Unknown driver";
}

const getMinByChargeType = (chargeType, job) => {
	let min = 0;
	switch(chargeType) {
		case "attempt":
			min = job?.ancillaries?.attempt ?? 0;
			break;
		case "vanAttempt":
			min = job?.ancillaries?.vanAttempt ?? 0;
			break;
		case "stop":
			min = job?.ancillaries?.stop ?? 0;
			break;
		case "secondStopAirport":
			min = job?.ancillaries?.secondStopAirport ?? 0;
			break;
		default:
			return 0;
	}
	return Math.max(min, 0);
}

const chargeTypeDisplay = {
	attempt: "Attempt",
	vanAttempt: "Van Attempt",
	stop: "Stop",
	secondStopAirport: "2nd Airport Stop"
}

const CalculatedTooltip = ({ button = false }) => (
	<OverlayTrigger
		placement="top"
		overlay={
			<Tooltip id={`tooltip-top`}>
				This charge was automatically calculated.
			</Tooltip>
		}
	>
		<span>
			{!!button && <Button size="sm" variant="light" className="me-2"><FaCalculator /></Button>}
			{!button && <FaCalculator />}
		</span>
	</OverlayTrigger>
);		

const ChargeRow = (props) => {
	const {
		unitPrice: initialUnitPrice = 0,
		units: initialUnits = 0, 
		note: initalNote = "Undefined",
		isNew = false,
		deleted = false,
		edit: isEditMode = false,
		drivers = [],
		commission: initialCommission = "",
		onChange = async () => {},
		onDelete = async () => {},
		chargeType: initialChargeType = "",
		calculator,
		job
	} = props;
	const [unitPrice, setUnitPrice] = useState(initialUnitPrice);
	const [units, setUnits] = useState(initialUnits);
	const [note, setNote] = useState(initalNote);
	const [commission, setCommission] = useState(initialCommission)
	const [isDeleteMode, setIsDeleteMode] = useState(false);
	const [chargeType, setChargeType] = useState(initialChargeType);
	const [min, setMin] = useState(getMinByChargeType(chargeType, job));

	useEffect(() => {
		const charge = { note, units, unitPrice, amount: currency(unitPrice).multiply(units).value, calculator, chargeType };
		if(commission === "exclude")
			charge.excludeFromCommission = true;
		if(!!commission && commission !== "standard")
			charge.forUser = commission;
		onChange(charge); 
	}, [unitPrice, units, note, commission, chargeType]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleDeleteRow = async () => {
		onDelete(true);
		setIsDeleteMode(false);
	}

	const reinstate = async() => {
		onDelete(false);
	}

	const handleFocus = (event) => isNew && event.target.select();

	const handleChargeTypeChange = (ct) => {
		const newMin = getMinByChargeType(ct, job);
		setMin(newMin);
		setChargeType(ct);
		if(newMin > unitPrice)
			setUnitPrice(newMin);
		if(units === 0)
			setUnits(1);
	}

	if(!!isDeleteMode) {
		return(
			<tr>
				<td className="text-center" colSpan={6}>
					<span className="me-1">Delete this row?</span>
					<ConfirmButtons onConfirm={handleDeleteRow} onCancel={() => setIsDeleteMode(false)} />
				</td>
			</tr>
		);
	}

	if(!!deleted) {
		return(
			<tr>
				<td className="text-center" colSpan={6}>
					<Button size="sm" className="ms-2" onClick={reinstate}>Undo</Button>
				</td>
			</tr>
		);
	}

	const amount = unitPrice * units;
	const preventEdit = !!calculator;

	return(
		<tr>
			<td>
				{!!isEditMode && <>
					<InputGroup size="sm">
						{!calculator && <Button title="Delete" variant="outline-danger" size="sm" onClick={() => setIsDeleteMode(true)} style={iconStyle}><FaTimesCircle /></Button>}
						{!!calculator && <InputGroup.Text><CalculatedTooltip /></InputGroup.Text>}
						<FormControl disabled={preventEdit} autoFocus={!!isNew} type="text" value={note} onChange={e => setNote(e.target.value)} onFocus={handleFocus} />
					</InputGroup>
				</>}
				{!isEditMode && !!calculator && <CalculatedTooltip button={true} />}
				{!isEditMode && note}
			</td>
			<td>
				{!!isEditMode && <>
					<Form.Select size="sm" disabled={preventEdit} value={chargeType} onChange={e => handleChargeTypeChange(e.target.value)}>
						<option value="">None</option>
						{!!job?.ancillaries?.stop && <option value="stop">{chargeTypeDisplay["stop"]}</option>}
						{!!job?.ancillaries?.secondStopAirport && <option value="secondStopAirport">{chargeTypeDisplay["secondStopAirport"]}</option>}
						{!!job?.ancillaries?.attempt && <option value="attempt">{chargeTypeDisplay["attempt"]}</option>}
						{!!job?.ancillaries?.vanAttempt && <option value="vanAttempt">{chargeTypeDisplay["vanAttempt"]}</option>}
					</Form.Select>
				</>}
				{!isEditMode && <>{chargeTypeDisplay[chargeType] || ""}</>}
			</td>
			<td>
				{!!isEditMode && !!drivers?.length &&
					(<Form.Control  disabled={preventEdit} value={commission?._id || commission} onChange={e => setCommission(e.target.value)} as="select" size="sm">
						<option>Set commission</option>
						<option value="standard">Standard commission</option>
						<option value="exclude">Exclude from commission</option>
						{ !!drivers?.length && drivers.map((d = {}, i) => (<option key={`key-${i}-${d._id}`} value={d._id}>{d.name}</option>))}
					</Form.Control>)
				}
				{!isEditMode && displayCommission(commission, drivers)}
			</td>
			<td className="text-end">
				{!!isEditMode && <FormControl disabled={preventEdit} size="sm" autoFocus={!isNew} type="number" className="text-end" value={units} onChange={e => setUnits(getNumber(e, 0))} />}
				{!isEditMode && `${units}`}
			</td>
			<td className="text-end">
				{!!isEditMode && <FormControl step={0.01} min={min} disabled={preventEdit} size="sm" type="number" className="text-end" value={unitPrice} onChange={e => setUnitPrice(getNumber(e, 0))} />}
				{!isEditMode && `$${unitPrice.toFixed(2)}`}
			</td>
			<td className="text-end">
				{`$${amount.toFixed(2)}`}
			</td>
		</tr>
	)
}

const ChargeList = ({
	drivers = [],
	charges: initialCharges = [],
	jobId,
	job,
	onChange = () => {},
	canEdit = false
}) => {
	const [edit, setEdit] = useState(false);
	const [error, setError] = useState("");
	const [warning, setWarning] = useState(false);
	const [tempCharges, setTempCharges] = useState(initialCharges);
	const [charges, setCharges] = useState(initialCharges);
	const [total, setTotal] = useState(calculateTotal(initialCharges));

	useEffect(() => {
		onChange({charges, total});
	}, [charges, total]); // eslint-disable-line react-hooks/exhaustive-deps
	
	if(initialCharges === null) {
		return <Alert variant="danger">
			<p>The charges on this job are corrupt. Please contact the adminstrator to have them fixed.</p>
		</Alert>
	}

	const handleChange = (i) => (async (charge) => {
		setTempCharges(c => {
			const newCharges = [...c];
			newCharges[i] = charge;
			setTotal(calculateTotal(newCharges));
			return newCharges;
		});
	});

	const handleDelete = (i) => (async (deleteRow) => {
		setTempCharges(c => {
			const newCharges = [...c];
			newCharges[i].deleted = deleteRow;
			setTotal(calculateTotal(newCharges.filter(c => !c.deleted)));
			return newCharges;
		});
	});

	const save = async () => {
		const job = await jobsApi.updateCharges({ charges: tempCharges.filter(c => !c.deleted && c.units !== 0 && c.unitPrice !== 0), jobId });
		setCharges(job.charges);
		setTempCharges(job.charges);
		setTotal(currency(job.totalCharges).value);
	}

	const addCharge = () => {
		setTempCharges((c) => {
			c.forEach(d => d.isNew = false);
			return [...c, { ...newCharge }];
		});
	}

	const refresh = async () => {
		const response = await jobsApi.recalculateCharges(jobId);
		setCharges(() => response?.changes?.charges || []);
		setTempCharges(() => response?.changes?.charges || []);
		setTotal(() => response?.changes?.totalCharges || 0);
	}
	
	return (
		<Widget canEdit={canEdit} className="charge-list" title="Charges" onSubmit={save} onRefresh={refresh} onEdit={setEdit}>
			<Table hover>
				<thead>
					<tr>
						<th>Description</th>
						<th>Type</th>
						<th>Commission</th>
						<th className="text-end">Units</th>
						<th className="text-end">Unit Price</th>
						<th className="text-end">Amount</th>
					</tr>
				</thead>
				<tbody>
					{!!edit && tempCharges.map((c,i) => <ChargeRow job={job} key={`charges-${i}`} edit={true} {...c} drivers={drivers} commission={getCommission(c)} onChange={handleChange(i)} onDelete={handleDelete(i)} />)}
					{!!edit && !!error && <tr><td colSpan={4}><Alert variant="danger" onClose={() => setError("")} dismissible>{error}</Alert></td></tr>}
					{!!edit && !!warning && <tr><td colSpan={4}><Alert variant="warning" onClose={() => setWarning("")} dismissible>{warning}</Alert></td></tr>}
					{!!edit && <tr><td colSpan={6} className="text-center border-top"><Button size="sm" onClick={addCharge}><FaPlus /> Add charge</Button></td></tr>}
					{!edit && charges.map((c,i) => <ChargeRow job={job} key={`charges-${i}-${c.calculator}`} {...c} commission={getCommission(c)} drivers={drivers} />)}
					{!edit && !charges.length && <tr><td className="text-center" colSpan={6}>No charges.</td></tr>}
				</tbody>
				<tfoot>
					<tr>
							<td colSpan="4"></td>
							<td className="text-end"><strong>Total:</strong></td>
							<td className="text-end"><strong>{`$${(total||0).toFixed(2)}`}</strong></td>
						</tr>
				</tfoot>
			</Table>
		</Widget>
	);
}

export default ChargeList;