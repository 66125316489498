import { generateOptions, API_DOMAIN } from "./api";

export async function generatePdf(html) {
	const response = await fetch(`${API_DOMAIN}/util/generatePdf`, generateOptions(
		{
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/pdf"
			},
			body: JSON.stringify({ html })
		}));
	return response.blob();
}