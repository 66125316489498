import React from "react";
import PropTypes from "prop-types";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { getNumber } from "../../utils/formHelpers";
import { Validation } from "Components/Form";
import InformationTooltip from "./Tooltips/InformationTooltip";

const holidays = [
	`New Year's Day`,
  "Memorial Day",
  "Independence Day",
  "Labor Day",
  "Thanksgiving Day",
  "Christmas Day"
];

const propTypes = {
	onChange: PropTypes.func
}

const FeeInput = ({
	label, onChange, value, validation = [], clearValidation = ()=>{}, prependText = "$", ...props
}) => {
	return (
	<Form.Group size="sm" as={Row}>
		<Form.Label column sm={3}>{label}</Form.Label>
		<Col>
			<InputGroup hasValidation size="sm">
				<InputGroup.Text>{prependText}</InputGroup.Text>
				<Form.Control className="text-end"
					{...props}
					type="number" 
					value={value} 
					onChange={onChange}
					isInvalid={!!validation.find(v => v.param === props.name)}
				/>
				<Validation name={props.name} validation={validation} />
			</InputGroup>
		</Col>		
	</Form.Group>);
}

/**
 * @component
 */
const AncillariesForm = (props) => {
	const { onChange, disabled = false, validation = [], clearValidation = ()=>{}, name, ...charges } = props;

	const handleChange = (c) => {
		const names = Object.keys(c).map(getName);
		clearValidation(names);
		onChange({
			...charges,
			...c
		});
	}

	const getName = (n) => [name,n].filter(s => !!s).join('.');

	return (
		<>					
			<h2>Contract Terms</h2>			
			<FeeInput 
				name={getName("jobMinimum")} 
				validation={validation} 
				clearValidation={clearValidation} 
				step={0.01} 
				label={<>Job minimum <InformationTooltip term="Job minimum" definition="The minimum amount charged for this job." /></>} 
				value={charges?.jobMinimum ?? ""} 
				onChange={e => handleChange({ jobMinimum: getNumber(e) })} 
				disabled={disabled} 
			/>
			<FeeInput 
				name={getName("vanMinimum")} 
				validation={validation} 
				clearValidation={clearValidation} 
				step={0.01} 
				label={<>Van minimum <InformationTooltip term="Van minimum" definition="The minimum charge for jobs 50 lbs. or heavier." /></>}
				value={charges?.vanMinimum ?? ""} 
				onChange={e => handleChange({ vanMinimum: getNumber(e) })} 
				disabled={disabled}
			/>
			<Form.Group size="sm" as={Row}>
				<Form.Label column sm={3}>Mileage <InformationTooltip term="Mileage" definition="The charge per mile after an included number of miles threshold is reached." /></Form.Label>
				<Col className="mb-2">
					<InputGroup size="sm" hasValidation>
						<InputGroup.Text>$/mile</InputGroup.Text>
						<Form.Control className="text-end"
						 	step={0.01}
							type="number" 
							value={charges?.mileageRate ?? ""} 
							onChange={e => { handleChange({ mileageRate: getNumber(e) }) }}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("mileageRate"))}
						/>
						<div className="input-group-prepend input-group-append">
							<InputGroup.Text>after</InputGroup.Text>
						</div>
						<Form.Control className="text-end"
						 	step={1}
							type="number" 
							value={charges?.mileageIncluded ?? ""} 
							onChange={e => handleChange({ mileageIncluded: getNumber(e) })}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("mileageIncluded"))}
						/>
						<InputGroup.Text>mile</InputGroup.Text>
						<Validation validation={validation} names={[getName("mileageRate"), getName("mileageIncluded")]} />
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Form.Label column sm={3}>Van rate <InformationTooltip term="Van rate" definition="The charge per mile after an included number of miles threshold is reached for jobs 50 lbs. or heavier." /></Form.Label>
				<Col className="mb-2">
					<InputGroup size="sm" hasValidation>
						<InputGroup.Text>$/mile</InputGroup.Text>
						<Form.Control className="text-end"
						 	step={0.01}
							type="number" 
							value={charges?.vanRate ?? ""} 
							onChange={e => { handleChange({ vanRate: getNumber(e) }) }}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("vanRate"))}
						/>
						<div className="input-group-prepend input-group-append">
							<InputGroup.Text>after</InputGroup.Text>
						</div>
						<Form.Control className="text-end"
						 	step={1}
							type="number"
							disabled
							value={charges?.mileageIncluded ?? ""}
						/>
						<InputGroup.Text>mile</InputGroup.Text>
						<Validation validation={validation} names={[getName("vanRate")]} />
					</InputGroup>	
				</Col>					
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Form.Label column sm={3}>Wait time <InformationTooltip term="Wait time" definition="The charge per minute the driver must wait after the included threshold is reached." /></Form.Label>
				<Col className="mb-2">
					<InputGroup size="sm" hasValidation>
						<InputGroup.Text>$/min</InputGroup.Text>
						<Form.Control className="text-end"
							type="number" 
							step={0.01}
							value={charges?.waitTime ?? ""} 
							onChange={e => handleChange({ waitTime: getNumber(e) })}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("waitTime"))}
						/>
						<div className="input-group-prepend input-group-append">
							<InputGroup.Text>after</InputGroup.Text>
						</div>
						<Form.Control className="text-end"
							step={1}
							type="number" 
							value={charges?.includedWaitTime ?? ""} 
							onChange={e => handleChange({ includedWaitTime: getNumber(e) })}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("includedWaitTime"))}
						/>
						<InputGroup.Text>min</InputGroup.Text>
						<Validation validation={validation} names={[getName("waitTime"), getName("includedWaitTime")]} />
					</InputGroup>	
				</Col>					
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Form.Label column sm={3}>Weight <InformationTooltip term="Weight" definition="The charge per pound after an the job exceeds the weight threshold." /></Form.Label>
				<Col className="mb-2">
					<InputGroup size="sm" hasValidation>
						<InputGroup.Text>$/lb</InputGroup.Text>
						<Form.Control className="text-end"
							step={0.01}
							type="number" 
							value={charges?.weight ?? ""} 
							onChange={e => handleChange({ weight: getNumber(e) })}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("weight"))}
						/>
						<div className="input-group-prepend input-group-append">
							<InputGroup.Text>after</InputGroup.Text>
						</div>
						<Form.Control className="text-end"
							step={1}
							type="number" 
							value={charges?.includedWeight ?? ""} 
							onChange={e => handleChange({ includedWeight: getNumber(e) })}
							disabled={disabled}
							isInvalid={validation.find(v => v.param === getName("includedWeight"))}
						/>
						<InputGroup.Text>lbs</InputGroup.Text>
						<Validation validation={validation} names={[getName("weight"), getName("includedWeight")]} />
					</InputGroup>		
				</Col>
			</Form.Group>
			<FeeInput 
				validation={validation} 
				name={getName("afterHours")} 
				step={0.01} 
				label={<>After hours <InformationTooltip term="After hours" definition="Additional charge for jobs that occur outside of normal business hours." /></>} 
				value={charges?.afterHours ?? ""} 
				onChange={e => handleChange({ afterHours: getNumber(e) })} 
				disabled={disabled} 
			/>
			<FeeInput 
				validation={validation} 
				name={getName("weekend")} 
				step={0.01}
				label={<>Weekend <InformationTooltip term="Weekend" definition="Additional charge for jobs that occur during the weekend." /></>} 
				value={charges?.weekend ?? ""} 
				onChange={e => handleChange({ weekend: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("holiday")} 
				step={0.01} 
				label={
					<>Holiday <InformationTooltip term="Holiday" 
							definition={<>Additional charge for jobs that occur during a recognized holiday. <ul>{holidays.map((h,i) => (<li key={`holiday-${i}`}>{h}</li>))}</ul></>} /></>}
				value={charges?.holiday ?? ""} 
				onChange={e => handleChange({ holiday: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("hazardous")} 
				step={0.01} 
				label={<>Hazardous material handling <InformationTooltip term="Hazardous material handling" definition="Additional charge for jobs that require handling of hazardous materials." /></>} 
				value={charges?.hazardous ?? ""} 
				onChange={e => handleChange({ hazardous: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("secondMan")} 
				step={0.01} 
				label={<>Second man <InformationTooltip term="Second man" definition="The job required second person to execute. If the package cannot be handled by one person, for example." /></>} 
				value={charges?.secondMan ?? ""} 
				onChange={e => handleChange({ secondMan: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("attempt")} 
				step={0.01} 
				label={<>Attempt <InformationTooltip term="Attempt" definition="Minimum charge for an unsuccessful attempt at pickup or delivery." /></>} 
				value={charges?.attempt ?? ""} 
				onChange={e => handleChange({ attempt: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("vanAttempt")} 
				step={0.01} 
				label={<>Van Attempt <InformationTooltip term="Van Attempt" definition="Minimum charge for an unsuccessful attempt at pickup or delivery for packages 50 lbs. or heavier." /></>} 
				value={charges?.vanAttempt ?? ""} 
				onChange={e => handleChange({ vanAttempt: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("stop")} 
				step={0.01} 
				label={<>Stop <InformationTooltip term="Stop" definition="A stop is any necessary diversion a driver must make to facilitate the pickup. To purchase dry ice, for example." /></>} 
				value={charges?.stop ?? ""} 
				onChange={e => handleChange({ stop: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("secondStopAirport")} 
				step={0.01} 
				label={<>2nd airport stop <InformationTooltip term="2nd airport stop" definition="A charge for making mulitiple, distant stops at the airport." /></>} 
				value={charges?.secondStopAirport ?? ""} 
				onChange={e => handleChange({ secondStopAirport: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("pickAndHold")} 
				step={0.01} 
				label={<>Pick &amp; hold <InformationTooltip term="Pick & hold" definition="A charge for storing a package in the warehouse." /></>} 
				value={charges?.pickAndHold ?? ""} 
				onChange={e => handleChange({ pickAndHold: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("vanPickAndHold")} 
				step={0.01} 
				label={<>Van pick &amp; hold  <InformationTooltip term="Van pick & hold" definition="A charge for storing a package large enough to require van in the warehouse." /></>} 
				value={charges?.vanPickAndHold ?? ""} 
				onChange={e => handleChange({ vanPickAndHold: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("fuelSurchargeBase")} 
				step={0.01} 
				label={<>Fuel surcharge base  <InformationTooltip term="Fuel surcharge base" definition="A flat fee applied to all jobs to account for fluctuating fuel costs." /></>}
				value={charges?.fuelSurchargeBase ?? ""}
				onChange={e => handleChange({ fuelSurchargeBase: getNumber(e) })} 
				disabled={disabled}
			/>
			<FeeInput 
				validation={validation} 
				name={getName("fuelSurcharge")} 
				step={0.01} 
				label={<>Fuel surcharge <InformationTooltip term="Fuel surcharge" definition="An additional charge for fuel applied to all jobs to account for fluctuating fuel costs." /></>}
				value={charges?.fuelSurcharge ?? ""}
				onChange={e => handleChange({ fuelSurcharge: getNumber(e) })}
				disabled={disabled} 
				prependText="$/mile"
			/>
			<FeeInput 
				validation={validation} 
				name={getName("sweepPrice")} 
				step={0.01} 
				label={<>Sweep Price <InformationTooltip term="Sweep Price" definition="A sweep job is a bundled job with a reduced, flat rate. If the job is a sweep job, the value of this field will override all charges." /></>} 
				value={charges?.sweepPrice ?? ""} 
				onChange={e => handleChange({ sweepPrice: getNumber(e) })} 
				disabled={disabled}
			/>
			<Form.Group size="sm" as={Row}>
				<Col className="mb-2">
					<Form.Check 
						checked={charges?.useCustomerMiles ?? ""} 
						onChange={e => handleChange({ useCustomerMiles: e.target.checked })} 
						type="switch" 
						label={<>Use customer mileage <InformationTooltip term="Use customer mileage" definition="When turned on, mileage will be calculated using the value provided by the customer. When turned off, the milage calculated by Google Maps will be used." /></>}
						disabled={disabled} />
				</Col>
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Col className="mb-2">
					<Form.Check 
					checked={charges?.fuelSurchargeEnabled ?? ""} 
					onChange={e => handleChange({ fuelSurchargeEnabled: e.target.checked })} 
					type="switch" 
					label={<>Fuel Surcharge Enabled <InformationTooltip term="Fuel Surcharge Enabled" definition="When turned on, jobs will incur a fuel surcharge. When turned off, no fuel surcharge will be added to the job." /></>}
					disabled={disabled} />
				</Col>
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Col className="mb-2">
					<Form.Check 
					checked={charges?.fuelSurchageUseMinMileage ?? ""} 
					onChange={e => handleChange({ fuelSurchageUseMinMileage: e.target.checked })} 
					type="switch" 
					label={<>Use Minimum Mileage for Fuel Surcharge <InformationTooltip term="Use Minimum Mileage for Fuel Surcharge" definition="When turned on, the fuel surcharge rate will be multiplied by whichever is more: included miles or job miles." /></>}
					disabled={disabled} />
				</Col>
			</Form.Group>
			<Form.Group size="sm" as={Row}>
				<Col className="mb-2">
					<Form.Check checked={charges?.preventDoubleCharge ?? ""} onChange={e => handleChange({ preventDoubleCharge: e.target.checked })} type="switch" label={<>Prevent Double Charge <InformationTooltip term="Prevent Double Charge" definition="Do not charge for both after hours and weekend when the job fulfills both categories." /></>} disabled={disabled} />
				</Col>
			</Form.Group>
		</>
	)
}

AncillariesForm.propTypes = propTypes;

AncillariesForm.defaultProps = {
	mileageRate: "",
	mileageIncluded: "",
	waitTime: "",
	includedWaitTime: "",
	weight: "",
	includedWeight: "",
	afterHours: "",
	weekend: "",
	holiday: "",
	secondMan: "",
	pickAndHold: "",
	jobMinimum: "",
	fuelSurcharge: "",
	useCustomerMiles: "",
	sweepPrice: "",
	onChange: () => { console.log("No on change handler specified.")}
};

export default AncillariesForm;