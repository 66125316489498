import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListGroup, Form, Button, InputGroup } from "react-bootstrap";
import { FaTimes, FaPlus, FaGripVertical } from "react-icons/fa";
import "./SelectAndOrder.css";

const reorder = (list, startIndex, endIndex) => {
	const result = [...list];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
}

const SelectedValue = ({ item, index, renderOption, onRemove = () => {}}) => {
	const id = `item-${index}`;
	return <Draggable key={id} draggableId={id} index={index}>
		{
			provided => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				<ListGroup.Item className="align-middle">
					<FaGripVertical className="text-muted" /> {renderOption(item)}
					<Button className="float-end" size="sm" variant="outline-danger" onClick={onRemove}><FaTimes /></Button>
				</ListGroup.Item>
			</div>)
		}
		</Draggable>;
}

export default ({
	name = "",
	options = [],
	value = [],
	renderOption = o => o,
	onChange = () => {},
	compare = (a, b) => a === b,
	allowDuplicates = true,
	...props
}) => {
	const [adding, setAdding] = useState(false);

	let curatedOptions = options;

	if(!allowDuplicates) {
		curatedOptions = options.filter(o => !value.some(v => compare(o, v)));
	}

	const place = (result) => {
		if(!result.destination || result.destination.index === result.source.index)
			return;
		
			const newValue = reorder(
				value,
				result.source.index,
				result.destination.index
			);
			onChange(newValue);		
	}

	const remove = (i) => () => {
		const newValue = [...value];
		newValue.splice(i, 1);
		onChange(newValue);
	}

	const cancel = () => setAdding(false);

	const add = v => {
		if(!v)
			throw new Error("No value selected");
		onChange([...value, curatedOptions[v]]);
		cancel();
	}

	const addAll = () => {
		onChange([...value, ...curatedOptions]);
		cancel();
	}

	return <div className="select-and-order">
		<ListGroup>
			{!!value.length && <DragDropContext onDragEnd={place}>
				<Droppable droppableId={`droppable-${name}`}>{
					(provided, ...args) => <div ref={provided.innerRef} {...provided.droppableProps}>
							{value.map((item, index) => <SelectedValue key={`item-${index}`} {...{item, index, renderOption, onRemove: remove(index)}} />)}
							{provided.placeholder}
						</div>
					}</Droppable>
				</DragDropContext>
			}
			{adding && !!curatedOptions.length && <div><ListGroup.Item className="add text-center align-middle">
				<InputGroup size="sm">
					<Form.Select size="sm" onChange={e => add(e.target.value)} defaultOpen>
						<option value="">Select which item to add</option>
						{curatedOptions.map((o,i) => <option key={`option-${i}`} value={i}>{renderOption(o)}</option>)}
					</Form.Select>
					<Button onClick={cancel} size="sm" variant="outline-danger"><FaTimes /></Button>
					<Button onClick={addAll} size="sm" variant="primary">Add all</Button>
				</InputGroup>
			</ListGroup.Item></div>}
			{!adding && !!curatedOptions.length && <ListGroup.Item className="add text-center align-middle">
					<Button onClick={() => setAdding(true)} size="sm" variant="primary"><FaPlus /> Add</Button>
				</ListGroup.Item>}
		</ListGroup>
	</div>
}