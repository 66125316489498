import { IPublished } from "api/Types";
import { FullDate } from "Components/Shared/Date";
import React from "react";
import format from "../../utils/format";

type PublishDataProps = IPublished & {
	size?: "sm" | "md"
}

const PublishData = (props:PublishDataProps) => {
	const { createdAt, updatedAt, size = "md" } = props;
	return (
		<>
			{size !== "sm" && <hr />}
			<div className="d-flex justify-content-between text-black-50">
				<small>created: <FullDate date={createdAt} /></small>
				<small>modified: {format.timeElapsed(updatedAt)}</small>
			</div>
		</>
	);
}

export default PublishData;