import React from "react";
import { Outlet } from "react-router-dom";

const JobPage = ({
	activeUser = {}
}) => {
	return (
		<div className="job-page">
			<Outlet />
		</div>
	);
}

export default JobPage;