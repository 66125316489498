import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaMoneyBillAlt, FaMoneyCheckAlt, FaRegEnvelope } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Invoice } from ".";
import invoicesApi from "../../api/invoicesApi";
import * as UtilApi from "../../api/utilApi";
import { LoadingIndicator, AsyncButton } from "../Shared";
import { toDataUrl } from "../../utils/imageHelpers.js";
import { EmailListInput } from "../Form";
import * as ReactDOMServer from 'react-dom/server';
import { isPermitted } from "utils/permissions";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";

const getText = async (url) => {
	const cssRequest = await fetch(url);
	return cssRequest.text();
}

const replaceCss = async (html) => {
	const regex = /<link href="(?<relativePath>.*?)" rel="stylesheet">/mg;
	const replacements = {};
	const requests = [];
	let match;
	while ((match = regex.exec(html)) != null) {
		if(!!requests[match[0]]) continue;
		const path = match.groups.relativePath;
		const needsDomain = !path.startsWith("//") && !path.startsWith("http");
		const domain = needsDomain ? `https://${window.location.host}${path.startsWith("/") ? "" : "/"}` : "";
		requests.push((async () => ([match[0], await getText(`${domain}${path}`) ]))());
	}

	const objects = await Promise.all(requests);
	objects.forEach(v => replacements[v[0]] = v[1]);

	Object.entries(replacements).forEach(([key,value]) => {
		html = html.replace(key, `<style>${value}</style>`);
	});
	return html;
}

const htmlToJsonString = async (html, images = {}) => {
	// replace all images with base64 counter parts
	html = Object.keys(images).reduce((h, k) => h.replace(`src="${k}"`, `src="${images[k]}"`), html);
	html = await replaceCss(html)
	html = html
		.replace(/<script.*?(?:<\/script>)/mg, "") 	// remove all script tags
		.replace(/<iframe.*?(?:<\/iframe>)/mg, "") 	// remove all iframe tags
		.replace(/\/\*[^*]*\*+([^\/*][^*]*\*+)*\//mg, "") // remove all css comments
		.replace(/\\n/mg, "\\n") // escape control characters
		.replace(/\\'/mg, "\\'")
		.replace(/\\"/mg, '\\"')
		.replace(/\\&/mg, "\\&")
		.replace(/\\r/mg, "\\r")
		.replace(/\\t/mg, "\\t")
		.replace(/\\b/mg, "\\b")
		.replace(/\\f/mg, "\\f");
	return html;
}

const getHtml = async (innerHtml = "") => {
	const images = {};
	images["/images/pxdeliverylogo.png"] = await toDataUrl("/images/pxdeliverylogo.png");
	const head = document.getElementsByTagName('head')[0].innerHTML;
	const html = await htmlToJsonString(`<!DOCTYPE html><html lang="en"><head>${head}</head><body>${innerHtml}</body></html>`, images);
	return html;
}

const InvoiceDisplay = ({ invoice: initialInvoice = {}, activeUser}) => {
	const { invoiceId } = useParams();

	const [invoice, setInvoice] = useState(initialInvoice);
	const [loading, setLoading] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [error, setError] = useState();

	useEffect(() => {
		async function fetchData () {
			setLoading(true);
			try {
				const inv = await invoicesApi.read(invoiceId);
				setInvoice(inv);
			}
			catch(err) {
				setError(err);
			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, [invoiceId]);

	if(error instanceof NotAuthorizedErrorType || !isPermitted(activeUser, "viewInvoices"))
		return <NotAuthorizedError redirect="/invoices" redirectMessage="Back to Invoices" />

	const generatePdf = async () => {
		const invoiceHtml = ReactDOMServer.renderToStaticMarkup(Invoice(invoice));
		const html = await getHtml(invoiceHtml);
		const res = await UtilApi.generatePdf(html);
		const data = new Blob([res], {type: 'application/pdf'});
		const pdfUrl = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = pdfUrl;
		tempLink.setAttribute('download', `invoice-${invoice._id}.pdf`);
		tempLink.click();
	}

	const generateCsv = async () => {
		const res = await invoicesApi.downloadCsv(invoice._id);
		const data = new Blob([res], {type: 'text/csv'});
		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', `invoice-${invoice._id}.csv`);
		tempLink.click();
	}

	const generateExcel = async () => {
		const res = await invoicesApi.downloadExcel(invoice._id);
		const data = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', `invoice-${invoice._id}.xlsx`);
		tempLink.click();
	}

	const updateStatus = async (newStatus) => {
		const result = await invoicesApi.update(invoice._id, { status: newStatus });
		if(!!result) {
			setInvoice(i => ({ ...i, status: newStatus }));
		}
	}

	const { status = "new" } = invoice;

	return (<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/invoices`}>Invoices</Breadcrumb.Item>
					<Breadcrumb.Item href={`/invoices/${invoiceId}`}>{invoiceId}</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row className="buttongroups">
			<Col>
				<ButtonGroup className="mb-3 float-end">
					{ status === "paid" && <AsyncButton size="sm" onClick={() => updateStatus("sent")}>Mark as unpaid <FaMoneyBillAlt /></AsyncButton> }
					{ status === "sent" && <AsyncButton size="sm" variant="primary" onClick={() => updateStatus("paid")}>Mark as paid <FaMoneyCheckAlt /></AsyncButton> }
					{ status === "new" && isPermitted(activeUser, "invoiceInteractions") && <Button size="sm" variant="primary" onClick={() => setShowEmailModal(true)}>Send Email <FaRegEnvelope /></Button> }
					{ status === "sent" && isPermitted(activeUser, "invoiceInteractions")  && <Button size="sm" variant="outline-primary" onClick={() => setShowEmailModal(true)}>Resend Email <FaRegEnvelope /></Button> }
					<AsyncButton size="sm" variant="danger" onClick={generatePdf}>Preview PDF <FaFilePdf /></AsyncButton>
					<AsyncButton size="sm" variant="secondary" onClick={generateCsv}>Preview CSV <FaFileCsv /></AsyncButton>
					<AsyncButton size="sm" variant="success" onClick={generateExcel}>Preview Excel <FaFileExcel /></AsyncButton>
				</ButtonGroup>
			</Col>
		</Row>
		<Row>
			<Col>
				{!!loading && <LoadingIndicator />}
				{!loading && !!invoice?._id && <Invoice {...invoice} /> }
			</Col>
		</Row>
		<EmailModal invoice={invoice} showModal={showEmailModal} close={() => setShowEmailModal(false)} />
	</>)
};

export default InvoiceDisplay;

const EmailModal = ({ showModal = false, invoice = { market: { billing: {}}}, close = () => {}}) => {
	const [bcc, setBcc] = useState([]);
	const [includePdf, setIncludePdf] = useState(true);
	const [includeExcel, setIncludeExcel] = useState(false);
	const [body, setBody] = useState(`Please find attached your invoice from Premier Xpress Delivery.\n\nThank you for your business.`);

	if(!invoice || !invoice._id) {
		return (<></>);
	} 

	const handleCheckbox = (func) => ((e) => func(e.target.checked));

	const sendEmail = async () => {
		try {
			const details = {
				invoiceId: invoice._id,
				includePdf,
				includeExcel,
				email: invoice?.market?.billing?.email || [],
				bcc,
				message: body,
				html: includePdf ? await getHtml(ReactDOMServer.renderToStaticMarkup(Invoice(invoice))) : ""
			};
	
			await invoicesApi.sendEmail(details);
	
			setTimeout(close, 1000);
		} catch(err) {
			console.error(err);
			throw err;
		}
	}

	return (
		<Modal show={showModal} onHide={close}>
			<Modal.Header closeButton>
				<Modal.Title>Email invoice</Modal.Title>
			</Modal.Header>
			<Modal.Body>
					<Form.Label>{`${invoice?.market?.name || ""} Billing Contact`}</Form.Label>
					<EmailListInput size="sm" value={invoice?.market?.billing?.email || []} disabled />
					<Form.Label className="mt-3">BCC</Form.Label>
					<EmailListInput value={bcc} onChange={setBcc} />
					<Form.Label className="mt-3">Select attachment types</Form.Label>
					<Form.Check type="checkbox" checked={includePdf} onChange={handleCheckbox(setIncludePdf)} label={<><FaFilePdf /> PDF</>} />
					<Form.Check type="checkbox" checked={includeExcel} onChange={handleCheckbox(setIncludeExcel)} label={<><FaFileExcel /> Excel</>} />
					<Form.Label>Message Body</Form.Label>
					<Form.Control as="textarea" rows={5} onChange={(e) => setBody(e.target.value)} defaultValue={body}></Form.Control>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={close}>Close</Button>
				<AsyncButton variant="primary" onClick={sendEmail}>Send Email</AsyncButton>
			</Modal.Footer>
		</Modal>
	)
}