import { apiFetch } from "./api";
import { filterParameters, updateWindowLocation } from "./helpers";

export async function filter(props) {
	const apiParams = {...props};
	const params = [];
	
	if(!!apiParams?.regions) {
		apiParams.regionIds = apiParams.regions.map(r => r._id);
		delete apiParams.regions;
	}

	Object.keys(apiParams).forEach(k => {
		if(apiParams[k] instanceof Date) {
			apiParams[k] = apiParams[k].toISOString();
			return;
		}
	});

	Object.entries(apiParams)
		.filter(filterParameters)
		.forEach(([key,value]) => params.push([key, value]));

	const qs = new URLSearchParams(params).toString();

	const response = await apiFetch(`/users?${qs}`);

	return response;
}

export async function lookupUsers(text = "", requiresTSANumber = false) {
	const response = await apiFetch(`/users/lookup?text=${text}&requiresTSANumber=${requiresTSANumber}`);
	return response.map(u => ({id : u.value, text : u.text}));
}

export async function getUser({
	userId
}) {
	if(typeof(userId) !== typeof("") || !userId) {
		return Promise.reject("");
	}
	return apiFetch(`/users/${userId}`);
}

export async function updateUser(user) {
	console.log('update user', {user}, typeof(user.role), JSON.stringify(user.role));
	const response = await apiFetch(
		`/users/${user._id}`,
		{
			method: "PUT",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(user)
		}
	)
	return response;
}

export async function deleteUser(userId) {
	const response = await apiFetch(`/users/${userId}`, {
		method: "DELETE",
		cache: "no-cache"
	});

	return response;
}

export async function createUser(user) {
	const response = await apiFetch(
		`/users`,
		{
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(user)
		}
	);

	return response;
}