import React from "react";
import { Outlet, Route } from "react-router-dom";

const JobTemplatePage = ({activeUser = {}}) => {

	return (
		<div className="job-template-page">
			<Outlet />
		</div>
	);
}

export default JobTemplatePage;