import React, { useState, useEffect } from "react";
import { HistoryApi } from "../../api";
import format from "../../utils/format";
import { FaSyncAlt, FaRobot } from "react-icons/fa";
import { CgArrowLongRight } from "react-icons/cg";
import { Button, Badge } from "react-bootstrap";
import { LoadingIndicator } from "../Shared";
import "./ChangeLog.css";

const Difference = ({
	type,
	prevValue = '',
	newValue = '',
	field
}) => {
	switch(type) {
		case "updated": {
			return <li><pre><strong>{field}</strong>: <Badge bg="danger">{prevValue?.toString() || <i>(empty)</i>}</Badge> <CgArrowLongRight /> <Badge bg="success">{newValue?.toString() || <i>(empty)</i>}</Badge></pre></li>
		}
		default:
			return <></>;
	}
}

const HistoryEntry = ({
	note = "",
	timestamp = "",
	diff = [],
	user: {
		name: userName = "",
		picture: userPicture = "",
		_id: userId = ""
	} = {}
}) => {
	const [showDetails, setShowDetails] = useState(false);

	const handleShowDetails = e => {
			e.preventDefault();
			e.stopPropagation();
			setShowDetails(!showDetails)
	}

	return (
	<div className="d-flex">
		<div className="flex-shrink-0">
				{!!userName &&
				<a href={`/users/${userId}`}>
						<img referrerPolicy="no-referrer" className="me-3 rounded-circle" src={userPicture} alt={`${userName}'s picture`} height="40px" />
				</a>}
				{!userName && <FaRobot className="me-3" style={{height: "40px", width: "40px"}} />}
		</div>
		<div className="flex-grow-1 ms-3">
				<div>
						{!!userName && <strong className="mt-0"><a className="text-dark" href={`/users/${userId}`}>{userName}</a></strong>}
						{!userName && <strong className="mt-0">System</strong>}
						<small className="text-secondary ms-2" title={format.fullDate(timestamp)}><strong>{format.timeElapsed(timestamp)}</strong></small>
				</div>
				<p>
						<span>{note}</span> {!!diff?.length && <a href="#" onClick={handleShowDetails}>{showDetails ? "Hide" : "Show"} details</a>}
				</p>
				{showDetails && !!diff?.length &&
					<ul className="diff-list">
						{diff.map((d,i) => <Difference key={`diff-${i}`} {...d} field={d.key} />)}
					</ul>
				}
		</div>
	</div>);
}

const ChangeLog = ({
	id,
	events: initialEvents = [],
	total: initialTotal = 0
}) => {
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState(initialEvents);
	const [total, setTotal] = useState(initialTotal);

	useEffect(async () => {
		setLoading(true);
		const history = await HistoryApi.read({ id, skip: 0, take: 5 });
		setEvents(history?.events || []);
		setTotal(history?.total);
		setLoading(false);
	}, []);

	const resetHistory = async () => {
		setLoading(true);
		setEvents([]);
		setTotal(0);
		const history = await HistoryApi.read({ id, skip: 0, take: 5 });
		setEvents(history.events);
		setTotal(history.total);
		setLoading(false);
	}

	const loadMoreEvents = async () => {
		setLoading(true);
		const nextEventSet = await HistoryApi.read({ id, skip: events.length, take: 5 });
		setEvents((e) => [...e, ...nextEventSet.events]);
		setLoading(false);
	}

	return (
		<div className="changelog">
				<Button variant="light" className="float-end" onClick={resetHistory} disabled={loading}><FaSyncAlt className={loading && `animate-pulse`} /> Refresh</Button>
				<ul className="event-list mt-3">
						{events.map((h, i) => <li key={`history-${i}`}><HistoryEntry {...h} /></li>)}
						{!!loading && <li><LoadingIndicator /></li>}
						{!loading && total === 0 && <li className="text-center">No history</li>}
				</ul>
			<div className="text-center">
				{ events.length < total && <Button onClick={loadMoreEvents}>Load more events</Button> }
			</div>
		</div>
	);
}

export default ChangeLog