import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Validation } from "../Form";
import { getNumber, getValue } from "../../utils/formHelpers";
import { AsyncButton } from "../Shared";
import { FaSave, FaUser, FaUserSlash } from "react-icons/fa";
import intentionalDelay from "../../utils/intentionalDelay";
import { UsersApi } from "api";
import RoleLookup from "Components/Form/RoleLookup";
import { MultipleRoleFilter, TypeaheadMultipleSelect } from "Components/Filter/TypeaheadMultipleFilter";
import InformationTooltip from "Components/Shared/Tooltips/InformationTooltip";

const FormInput = ({ label, name, placeholder, value, onChange, validation = [], append, ...inputProps }) => {			
	return (<Form.Group className="mb-3">
		<Form.Label>{label}</Form.Label>
		<InputGroup hasValidation>
			<Form.Control 
				{...{name, placeholder, value, onChange, ...inputProps}} 
				isInvalid={validation.find(v => v.param === name)}
			/>
			{!!append && <InputGroup.Text>{append}</InputGroup.Text>}
			<Validation validation={validation} names={[name]} />
		</InputGroup>
	</Form.Group>);
}

const UserForm = ({
	activeUser = {},
	user: initialUser = {},
	onChange = () => {},
	onSubmit = async () => {},
	validation = [],
	clearValidation = (names = []) => console.log(`Unable to clear ${names?.join("|")} as no clear validation method was provided.`)
}) => {
	const isEdit = !!initialUser._id;
	const [user, setUser] = useState(initialUser);
	const [foundUser, setFoundUser] = useState({});
	const [errors, setErrors] = useState({});
	const [saveStatus, setSaveStatus] = useState("");
	const changeFields = (val) => setUser((u) => ({...u, ...val}));
	
	useEffect(() => {
		onChange(user);
	}, [user])

	const handleSubmit = async (e) => {
		e.stopPropagation();
		e.preventDefault();
		setErrors([]);
		setFoundUser({});
		setSaveStatus("saving");

		const result = await onSubmit(user);

		intentionalDelay(async () => {
			if(result?.user?._id) {
				setFoundUser(result.user);
				return;
			}
			
			if(Object.keys(result?.validationErrors || {})?.length) {
				setErrors(result.validationErrors);
				setSaveStatus("error");
				setTimeout(() => setSaveStatus(), 2500);
				return;
			}

			setSaveStatus("saved");
			setTimeout(() => setSaveStatus(), 2500);
		});
	}
	
	const handleInputChange = (getVal = getValue) => (e) => {
		clearValidation([e.target.name]);
		setUser((u) => ({...u, [e.target.name]: getVal(e)}));
	};

	const deactivate = async () => {
		if(window.confirm(`Are you sure you want to deactivate ${user.name}?`)) {
			await UsersApi.deleteUser(user?._id);
			setUser(u => ({...u, status: "deactivated"}));
		}
	}

	const activate = async () => {
		if(window.confirm(`Are you sure you want to activate ${user.name}?`)) {
			await UsersApi.updateUser({ ...user, status: "active" });
			setUser(u => ({...u, status: "active"}));
		}
	}

	return (
		<Form onSubmit={handleSubmit}>
			<FormInput
				label="Name"
				name="name"
				plceholder="Enter the user's name"
				value={user?.name || ""}
				onChange={handleInputChange()}
				validation={validation}
			/>
			<Form.Group className="mb-3">
				<Form.Label>Role</Form.Label>
				<TypeaheadMultipleSelect 
					name="role" 
					Typeahead={RoleLookup}
					renderSelection={r => <>{r.name}</>}
					compare={(a, b) => a._id === b._id}
					onChange={v => setUser(u => ({...u, role: v}))}
					value={user.role}
				/> 
				<Validation validation={validation} names={["role"]} />
			</Form.Group>
			<FormInput
				label="Email"
				name="email"
				plceholder="ex: johndoe@pxdelivery.com"
				value={user?.email || ""}
				onChange={handleInputChange()}
				validation={validation}
			/>
			{foundUser._id && <li>Found user: <a href={`/users/${foundUser._id}`} target="_blank">{foundUser.name}</a>.</li>}
			<FormInput
				label="Phone Number"
				name="phone"
				plceholder="ex: (555) 123-4567"
				value={user?.phone || ""}
				onChange={handleInputChange()}
				validation={validation}
				type="tel"
			/>
			<FormInput
				label="TSA Number"
				name="tsaNumber"
				plceholder="ex: 991234567"
				value={user?.tsaNumber || ""}
				onChange={handleInputChange()}
				validation={validation}
			/>
			<FormInput
				label="Job Cut"
				name="jobCut"
				plceholder="ex: 75"
				value={Math.round(user?.jobCut * 100) ?? ""}
				onChange={handleInputChange((e) => getNumber(e) / 100)}
				validation={validation}
				type="number"
				step={1}
				min={0}
				max={100}
				append="%"
			/>
			<AsyncButton onClick={handleSubmit}><FaSave /> {isEdit ? "Save Changes" : "Create User"}</AsyncButton>
			<span className="float-end">
				{ user.status !== "deactivated" && <AsyncButton variant="outline-danger" onClick={deactivate}><FaUserSlash /> Deactivate</AsyncButton> }
				{ user.status === "deactivated" && <AsyncButton variant="outline-success" onClick={activate}><FaUser /> Activate</AsyncButton> }
			</span>
		</Form>
	)
}

export default UserForm;