import React from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { FreeSearchFilter, RadioFilter, SelectFilter } from "../Filter";
import { FilterBar } from "../Shared";
import { Button } from "react-bootstrap";
import { FaUserPlus } from "react-icons/fa";
import { MultipleRegionFilter } from "Components/Filter/TypeaheadMultipleFilter";

const AVAILABLE_STATUSES = [
	"active",
	"deactivated",
	"verified"
];

const UserFilter = ({ 
	filter = {},
	children = [],
	sortOptions = [],
	onChange = () => {}
}) => {
	let status = filter.status;

	if(typeof(status) === typeof("")) {
		status = status.split(',');
	}
	
	const filterKeys = Object.keys(filter)?.filter(k => typeof(filter[k]) !== "undefined");

	return (<>
		<FilterBar>
				<FreeSearchFilter 
					name={<FaSearch />}
					value={filter.text} 
					onChange={(text) => onChange({...filter, text})}
				/>
				<SelectFilter 
					name="Status" 
					value={status} 
					onChange={(s) => onChange({...filter, status: s})} 
					options={AVAILABLE_STATUSES} 
				/>
				<MultipleRegionFilter name="Region" onChange={(regions) => onChange({...filter, regions})} value={filter.regions} />
				<FreeSearchFilter
					name="TSA Number"
					value={filter.tsaNumber}
					onChange={(tsaNumber) => onChange({...filter, tsaNumber})}
				/>
				{!!filterKeys?.length && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
			<div style={{marginLeft: "auto"}}>
				{!!sortOptions.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions} value={filter.sort || sortOptions[0].key} name="Sort" />}
				<Button href="/users/create" size="sm"><FaUserPlus /> Create User</Button>
			</div>
		</FilterBar>
	</>)
}

export default UserFilter;