import { apiFetch } from "./api";

const addressApi = {
    create: async (savedAddress) => {
		const response = await apiFetch(
			`/address`,
			{
				method: "POST",
				cache: "no-cache",
				body: JSON.stringify(savedAddress)
			}
		);

		return response;
	},
	delete: async (savedAddressId) => {
		const response = await apiFetch(
			`/address/${savedAddressId}`,
			{
				method: "DELETE",
				cache: "no-cache"
			}
		);

		return response;
	}
}

export default addressApi
