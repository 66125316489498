import { JobTemplate, Validation as RequestValidation } from "api/Types";
import { JobMetaForm, Validation } from "Components/Form";
import DropoffForm from "Components/Form/DropoffForm";
import PickupForm from "Components/Form/PickupForm";
import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { getNumber, getValue } from "utils/formHelpers";

type JobTemplateFormProps = {
	value?: JobTemplate,
	validation?: RequestValidation[],
	disabled?: boolean,
	onChange?: (template?: JobTemplate) => void
}

const JobTemplateForm = ({
	value = {} as JobTemplate,
	validation = [],
	onChange = () => {},
	disabled = false
}:JobTemplateFormProps) => {
	const handleChange = (changes:JobTemplate) => {
		onChange({ ...value, ...changes });
	}

	return (
		<Form>
			<Form.Group className="mb-3">
				<Form.Label>Template Name</Form.Label>
				<InputGroup size="lg" hasValidation>
					<Form.Control
						placeholder="Name this template"
						type="text"
						name="name"
						value={value?.name || ""}
						onChange={evt => handleChange({ name: getValue(evt) })}
						isInvalid={!!(validation?.find(v => v.param === `name`))}
						disabled={disabled}
					/>
					<Validation validation={validation} names={[`name`]} />
				</InputGroup>
				<Form.Label>Description</Form.Label>
				<InputGroup hasValidation>
					<Form.Control
						placeholder="Describe the purpose of this template."
						as="textarea"
						rows={3}
						name="description"
						value={value?.description || ""}
						onChange={evt => handleChange({ description: getValue(evt) })}
						isInvalid={!!(validation?.find(v => v.param === `description`))}
						disabled={disabled}
					/>
				</InputGroup>
			</Form.Group>
			<>
				<h3>Details</h3>
				<Form.Group as={Row} className="mb-3">
					<Col>
						<Form.Label>Pieces</Form.Label>
						<InputGroup size="sm">
							<Form.Control 
								className="text-end"
								type="text" 
								value={value?.pieces ?? ""}
								min={1}
								name="pieces"
								onChange={(evt) => handleChange({ pieces: getNumber(evt, 1) })}
								placeholder="# of pieces"
								isInvalid={!!validation.find(v => v.param === `pieces`)}
								disabled={disabled}
							/>
							<Validation validation={validation} names={[`pieces`]} />
						</InputGroup>
					</Col>
					<Col>
						<Form.Label>Weight</Form.Label>
						<InputGroup size="sm">
							<Form.Control 
								className="text-end"
								type="text" 
								value={value?.weight ?? ""}
								min={0}
								name="weight"
								onChange={(evt) => handleChange({ weight: getNumber(evt, 1) })}
								placeholder="Total weight in pounds"
								isInvalid={!!validation.find(v => v.param === `weight`)}
								disabled={disabled}
							/>
							<InputGroup.Text>lbs</InputGroup.Text>
							<Validation validation={validation} names={[`weight`]} />
						</InputGroup>		
					</Col>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Extra Details</Form.Label>
					<InputGroup>
						<Form.Control 
							as="textarea"
							name="additionalDetails"
							value={value?.additionalDetails}
							onChange={(evt) => handleChange({additionalDetails: getValue(evt)})}
							placeholder="Add any extra details."
							isInvalid={!!validation.find(v => v.param === `additionalDetails`)}
							rows={5}
							disabled={disabled}
						/>
						<Validation validation={validation} names={[`additionalDetails`]} />
					</InputGroup>
				</Form.Group>
				<JobMetaForm disabled={disabled} value={value?.meta} onChange={(meta) => handleChange({ meta })} />
			</>
			<PickupForm disabled={disabled} name="pickup" value={value?.pickup} onChange={(pickup) => handleChange({ pickup })} validation={validation} />
			<DropoffForm disabled={disabled} readyAt={value?.pickup?.readyAt} name="dropoff" value={value?.dropoff} onChange={(dropoff) => handleChange({ dropoff })} validation={validation} />
		</Form>
	);
}

export default JobTemplateForm;