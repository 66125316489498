export const filterParameters = ([,value])=> {
	const type = typeof(value);
	switch(type) {
		case typeof(""):
			return !!value;
		case typeof([]):
			return (value?.length || 0) > 0;
		case typeof(undefined):
			return false;
		default:
			return true;
	}
}

export const buildUrlParams = (apiParams) => {
	const params = [];
	Object.entries(apiParams)
		.filter(filterParameters)
		.forEach(([key,value]) => params.push([key, value]));

	const qs = new URLSearchParams(params).toString();

	return qs;
}

export const updateWindowLocation = (qs) => {
	if (typeof(qs) === typeof("") && !!window?.history?.pushState) {
		const { location: { protocol, host, pathname, search }} = window;
		if(search !== `?${qs}`) {
			var newurl = `${protocol}//${host}${pathname}${!!qs ? "?" : ""}${qs}`;
			window.history.pushState({path:newurl},'',newurl);
		}
	}
}