import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Header, Footer } from "./Components/Syndicated"
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import SessionsApi from "./api/sessionsApi";
import { HomePage, InvoicePage, JobPage, UsersPageSwitch, NotFoundPage, PayrollPage, ClientPage, MarketPage, RegionPage, JobTemplatePage, RolePage, ReportPage } from "./Pages"
import UsersEditPage from "./Pages/Users/UsersEditPage";
import { ClientForm, ClientProfile, ClientList } from "./Components/Client";
import { PayrollSearch, PayrollForm, PayrollDisplay } from "./Components/Payroll";
import { InvoiceDisplay, InvoiceForm, InvoiceSearch } from "./Components/Invoice";
import { JobBoard, JobDetails, JobSearch, JobCreationWizardPage, JobForm, JobEdit } from "./Components/Job";
import { JobTemplateDetails, JobTemplateList, JobTemplateCreate } from "./Components/JobTemplate";
import { LoginForm, UserList, UserProfile } from "./Components/User";
import { MarketList, MarketProfile, MarketForm, MarketCreationWizard } from "Components/Market";
import { RegionForm, RegionList, RegionProfile } from "./Components/Region";
import build from "./build.json";
import CreateUserPage from "./Pages/Users/CreateUserPage";
import { FaExclamationTriangle, FaSignOutAlt } from "react-icons/fa";
import sessionsApi from "./api/sessionsApi";
import TimezoneProvider from "Components/GlobalState/TimezoneContext";
import { EditRoleForm, RoleDetails, RoleList } from "Components/Role";
import CreateRoleForm from "Components/Role/CreateRoleForm";
import { ReportList, ReportView } from "Components/Reports";

const App = () => {
  const [session, setSession] = useState(SessionsApi.getSession());

	useEffect(() => {
		const session = SessionsApi.getSession();
		if(!!session && !!session.user) {
			setSession(session);
		}
	}, []);

  const logout = () => {
		sessionsApi.logout();
		setSession((s) => ({...s, activeUser:{}}));
	}

  const { user: activeUser } = session;
  const setActiveUser = (user) => setSession((s) => ({...s, activeUser: user }));

  const renderError = (<>
		<Row>
			<Col md={{ span: 8, offset: 2}}>
				<Alert variant="warning">
					<strong className="mb-2 fs-4"><FaExclamationTriangle /> Your account has been deactivated.</strong>
					<p className="mb-0">If you believe this is in error. Please contact an administrator. Once reactivated, you will need to resign in.</p>
				</Alert>
				<Button size="sm" variant="outline-dark" onClick={logout}><FaSignOutAlt /> Sign out</Button>
			</Col>
		</Row>
	</>);

  return (
    <div id="app" className="pulsar">
      <Router>
        <TimezoneProvider>
          <Header activeUser={activeUser} setActiveUser={setActiveUser} />
          <Container fluid="lg">
            { activeUser?.status === "deactivated" ? renderError : <>
            <Routes>
              <Route path="healthcheck" element={<h1>Healthy!</h1>} />
              <Route path="build" element={<pre>{JSON.stringify(build, null, 2)}</pre>} />
              <Route path="clients" element={<ClientPage activeUser={activeUser} />}>
                <Route index element={<ClientList activeUser={activeUser} />} />
                <Route path="add" element={<ClientForm activeUser={activeUser} />} />
                <Route path=":clientId/edit" element={<ClientForm activeUser={activeUser} />} />
                <Route path=":clientId" element={<ClientProfile activeUser={activeUser} />} />
              </Route>
              <Route path="markets" element={<MarketPage activeUser={activeUser} />}>
                <Route index element={<MarketList activeUser={activeUser} />}></Route>
                <Route path="new" element={<MarketCreationWizard activeUser={activeUser} />}></Route>
                <Route path=":marketId/edit" element={<MarketForm activeUser={activeUser} />}></Route>
                <Route path=":marketId" element={<MarketProfile activeUser={activeUser} />}></Route>
              </Route>
              <Route path="payroll" element={<PayrollPage activeUser={activeUser} />}>
                <Route index element={<PayrollSearch activeUser={activeUser} />} />  
                <Route path="new" element={<PayrollForm activeUser={activeUser} />} />
                <Route path=":payrollId" element={<PayrollDisplay activeUser={activeUser} />} />
              </Route>
              <Route path="invoices" element={<InvoicePage activeUser={activeUser} />}>
                <Route index element={<InvoiceSearch activeUser={activeUser} />} />
                <Route path="new" element={<InvoiceForm activeUser={activeUser} />} />
                <Route path=":invoiceId" element={<InvoiceDisplay activeUser={activeUser} />} />
              </Route>
              <Route path="jobs" element={<JobPage activeUser={activeUser} />}>
                <Route path="templates" element={<JobTemplatePage activeUser={activeUser} />}>
                  <Route index element={<JobTemplateList activeUser={activeUser} />} />
                  <Route path="create" element={<JobTemplateCreate activeUser={activeUser} />} />
                  <Route path=":templateId" element={<JobTemplateDetails activeUser={activeUser} />} />
                </Route>
                <Route index element={<JobSearch activeUser={activeUser} />} />
                <Route path="board" element={<JobBoard activeUser={activeUser} />} />
                <Route path="new" element={<JobCreationWizardPage activeUser={activeUser} />} />
                <Route path=":jobId/edit" element={<JobEdit activeUser={activeUser} />} />
                <Route path=":jobId" element={<JobDetails activeUser={activeUser} />} />
              </Route>
              <Route path="users" element={<UsersPageSwitch activeUser={activeUser} />}>
                <Route index element={<UserList activeUser={activeUser} />} />
                <Route path="login" activeUser={activeUser} setActiveUser={setActiveUser} element={<LoginForm />} />
                <Route path="create" element={<CreateUserPage activeUser={activeUser} />} />
                <Route path=":userId/edit" element={<UsersEditPage activeUser={activeUser} />} />
                <Route path=":userId" element={<UserProfile activeUser={activeUser} />} />
              </Route>
              <Route path="regions" element={<RegionPage activeUser={activeUser} />}>
                <Route index element={<RegionList activeUser={activeUser} />} />
                <Route path="new" element={<RegionForm activeUser={activeUser} />} />
                <Route path=":regionId/edit" element={<RegionForm activeUser={activeUser} />} />
                <Route path=":regionId" element={<RegionProfile activeUser={activeUser} />} />
              </Route>
              <Route path="roles" element={<RolePage activeUser={activeUser} />}>
                <Route exact={true} index element={<RoleList activeUser={activeUser} /> } />
                <Route path="new" element={<CreateRoleForm activeUser={activeUser} />} />
                <Route path=":roleId" element={<RoleDetails activeUser={activeUser} />} />
                <Route path=":roleId/edit" element={<EditRoleForm activeUser={activeUser} />} />
              </Route>
              <Route path="reports" element={<ReportPage activeUser={activeUser} />}>
                <Route exact={true} index element={<ReportList activeUser={activeUser} /> } />
                <Route path="view/:reportName" element={<ReportView activeUser={activeUser} />} />
                <Route path="view" element={<ReportView activeUser={activeUser} />} />
              </Route>
              <Route exact={true} path="" element={<HomePage activeUser={activeUser} />} />
              <Route path="*" element={<NotFoundPage activeUser={activeUser} />} />
            </Routes></>}
          </Container>
          <Footer />
        </TimezoneProvider>
      </Router>
    </div>
  );
}

export default App;
