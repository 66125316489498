import React, { useState, ChangeEventHandler } from "react";
import classNames from "classnames";
import "./RolodexSelect.css";
import { useEffect } from "react";

type RolodexSelectProps = {
	options: (string|number)[],
	name: string,
	value: string|number,
	onChange: (value: string|number) => void,
	label: string|null|undefined
};

export const RolodexSelect = (props:RolodexSelectProps) => {
	const { 
		options = [],
		name = "",
		value = "",
		onChange = ()=>{},
		label = ""
	} = props;
	const [index, setIndex] = useState(options.indexOf(value));

	useEffect(() => {
		if(props?.value) {
			setIndex(options.indexOf(value));
		}
	}, [props])

	if(!options.length) {
		console.warn("There were no options provided to the RolodexSelect component.", props)
		return <></>;
	}

	const select = (newIndex:number) => {
		setIndex(newIndex);
		onChange(options[newIndex]);
	}

	const incrementUp = () => select(Math.min(options.length - 1, index + 1));
	const incrementDown = () => select(Math.max(0, index - 1));

	const handleScrollWheel = (event:React.WheelEvent) => {
		if(event.deltaY > 0)
			incrementDown();
		if(event.deltaY < 0)
			incrementUp();
	}

	const handleKeyDown = (evt:React.KeyboardEvent) => {
		switch(evt.key) {
			case "ArrowDown":
				incrementDown();
				break;
			case "ArrowUp":
				incrementUp();
				break;
		}
	}

	const window: number[] = [];
	const append: number[] = [];
	const prepend: number[] = [];

	for(let i = 2; i > -3; i--) {
		const target = index + i;
		if(target < 0 || target >= options.length)
			continue;
		window.push(target);
	}

	for(let i = 1; i < 3; i++) {
		const target = index - i;
		if(target < 0)
			append.push(i);
	}

	for(let i = 1; i < 3; i++) {
		const target = index + i;
		if(target >= options.length)
			prepend.push(i);
	}

	return (
		<div className="rolodex-select" onWheel={handleScrollWheel} onKeyDown={handleKeyDown} tabIndex={0}>
			<select name={name} value={value} onChange={() => {}}>
				{options.map((o,i) => <option value={o} key={`option-${o}`}>{o}</option>)}
			</select>
			{label && <label>{label}</label>}
			<ul>
				{prepend.map((_,i) => <li key={`prepend-${i}`} className="empty"></li>)}
				{window.map((o,i) => {
					const classes = classNames({ 
						selected: o === index,
						smaller: o === index - 1 || o === index + 1,
						smallest: o === index - 2 || o === index + 2
					});
					return <li onClick={() => select(o)} key={`${name}-${i}`}><span className={classes}>{options[o]}</span></li>
				})}
				{append.map((_,i) => <li key={`append-${i}`} className="empty"></li>)}
			</ul>
		</div>
	);
}

type RolodexNumberSelectProps = RolodexSelectProps & {
	min: number,
	max: number,
	step: number
};

export const RolodexNumberSelect = (props: RolodexNumberSelectProps) => {
	const {
		min,
		max,
		step = 1,
		...rolodexProps
	} = props;

	rolodexProps.options = Array.from({ length: (max - min + 1) / step }, (_, i) => min + (i * step));
	return <RolodexSelect {...rolodexProps} />
}