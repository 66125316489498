import React, { useState } from "react";
import RoleForm from "./RoleForm";
import { Role } from "api/Types/Role";
import { RoleApi } from "api";
import { Breadcrumb, Col, Row } from "react-bootstrap";

const CreateRoleForm = () => {
	const [role, setRole] = useState<Role>({});

	const handleSubmit = async () => {
		try {
			const newRole = await RoleApi.create(role);
			console.log(newRole);
			return newRole;
		} catch(error) {
			console.error(error);
		}
	}

	const handleChange = (newRole:Role) => setRole(r => ({...r, ...newRole}));

	return <>
	<Row>
		<Col>
			<Breadcrumb>
				<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
				<Breadcrumb.Item href={`/roles`}>Roles</Breadcrumb.Item>
				<Breadcrumb.Item href={`/roles/new`}>Create Role</Breadcrumb.Item>
			</Breadcrumb>  
		</Col>
	</Row>
	<Row>
		<Col>
			<h1>Create a New Role</h1>
			<hr />
		</Col>
	</Row>
	<Row>
		<Col>
			<RoleForm value={role} disabled={false} isEdit={false} onSubmit={handleSubmit} onChange={handleChange} />
		</Col>
	</Row>
	</>;
}

export default CreateRoleForm;