import RegionLookup from "Components/Form/RegionLookup";
import { AsyncButton } from "Components/Shared";
import React, { useEffect, useState } from "react";
import {Row, Col, Image, Tabs, Tab, Container, Alert, InputGroup, Card, ButtonGroup, Breadcrumb, Dropdown} from "react-bootstrap";
import { FaCog, FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { UsersApi } from "../../api";
import format from "../../utils/format";
import { ChangeLog } from "../History";
import { JobBoard } from "../Job";
import RoleBadge from "../Shared/RoleBadge";
import { PublishData } from "../Syndicated";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const UserProfile = (props) => {
	const params = useParams();

	const {
		userId
	} = params;
	const {
		activeUser = {},
		user: initialUser = {}
	} = props;

	const [user, setUser] = useState(initialUser)
	const [isLoading, setIsLoading] = useState(true);
	const [newRegion, setNewRegion] = useState({});
	const [error, setError] = useState();

	const { regions = [] } = user;

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const userRequest = UsersApi.getUser({ userId });
				const user = await userRequest;
				setUser(user);
			}
			catch(err) {
				console.error(err);
				setError(err);
			}
			finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [userId]);

	if(error instanceof NotAuthorizedErrorType)
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	if(isLoading) {
		return (<p>Fetching user...</p>);
	}

	const assignRegion = async () => {
		if(!newRegion?._id) {
			throw new Error("No valid region selected.");
		}

		if(!!regions.find(r => r._id === newRegion?._id)) {
			throw new Error("This driver is already associated with this region.");
		}

		await UsersApi.updateUser({
			_id: userId,
			addRegion: newRegion._id
		});
		
		setNewRegion({});
		setUser(u => ({...u, regions: [...u.regions, newRegion]}));
	}

	const unassignRegion = async (region) => {
		if(!region?._id) {
			throw new Error(`Unable to unassign ${user.name} from an invalid region.`)
		}
		if(window.confirm(`Are you sure you want to remove "${user.name}" from the "${region.name}" region?`)) {
			await UsersApi.updateUser({ _id: userId, removeRegion: region._id });
			setTimeout(() => {
				setUser((u) => ({...u, regions: u.regions.filter(r => r._id !== region._id) }));
			}, 500);			
			return;
		}
		throw new Error("User cancelled.");
	}

	return (
		<>
			<Row>
				<Col>
					<Dropdown className="float-end">
						<Dropdown.Toggle variant="link" id="options">
							<FaCog />
						</Dropdown.Toggle>
						<Dropdown.Menu align="end">
							<Dropdown.Header><strong>{user?.name}</strong></Dropdown.Header>
							<Dropdown.Divider />
							<Dropdown.Item href={`/users/${user?._id}/edit`}><FaEdit /> Edit</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/users`}>Users</Breadcrumb.Item>
						<Breadcrumb.Item href={`/users/${userId}`}>{user?.name ?? userId}</Breadcrumb.Item>
					</Breadcrumb>  
				</Col>
			</Row>
			<Row>
				<Col>
					<Image referrerPolicy="no-referrer" src={user.picture} />
					<dl>
						<dt>Name</dt>
						<dd>{user.name}</dd>
					</dl>
				</Col>
				<Col>
					<dl>
						<dt>Status</dt>
						<dd>{user.status}</dd>
						<dt>Email</dt>
						<dd>{user.email}</dd>
						<dt>Phone</dt>
						<dd>{user.phone}</dd>
					</dl>
				</Col>
				<Col>
					<dl>
						<dt>Roles</dt>
						<dd><RoleBadge roles={user.role} /></dd>
						<dt>TSA Number</dt>
						<dd>{user.tsaNumber}</dd>
						<dt>Job Cut</dt>
						<dd>{format.toPercent(user.jobCut)}</dd>
					</dl>
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs defaultActiveKey="jobs" id="info" className="mt-3">
						<Tab eventKey="jobs" title="Jobs">
							<JobBoard user={user} filter={{ driver: user }} basic={true} />
						</Tab>
						<Tab eventKey="regions" title={`Regions (${regions.length})`}>
							
							<InputGroup className="mt-2">
								<RegionLookup value={newRegion} onChange={(r) => setNewRegion(r || {})} />
								<AsyncButton disabled={!newRegion?._id} size="sm" onClick={assignRegion}>Assign</AsyncButton>
							</InputGroup>
							{!regions?.length && <>
								<Alert className="mt-2" variant="warning"><FaExclamationTriangle /> No regions assigned. Assign one now.</Alert>
							</>}
							{!!regions?.length && regions.map(region => 
								(<Card className="mt-2">
									<Card.Body>
										<Row className="d-flex align-items-center justify-content-between">
											<Col className="text-start mb-2 mb-md-0">
												<Card.Title className="mb-0"><a className="text-decoration-none" href={`/regions/${region.url}`}>{region.name}</a></Card.Title>
											</Col>
											<Col className="text-center text-md-end">
												<ButtonGroup className="mb-2">
													<AsyncButton onClick={() => unassignRegion(region)} className="mt-1 float-end" size="sm" variant="outline-danger">Unassign</AsyncButton>
												</ButtonGroup>
											</Col>
										</Row>
									</Card.Body>
								</Card>))
							}
						</Tab>
						{/* <Tab eventKey="payroll" title="Payroll">
							Payroll history
						</Tab> */}
						<Tab eventKey="history" title="Change Log">
							<ChangeLog id={userId} />
						</Tab>
					</Tabs>
				</Col>
			</Row>
			<Row>
				<Col>
					<PublishData {...user} />
				</Col>
			</Row>
		</>
	)
}

export default UserProfile;