import RegionLookup from "Components/Form/RegionLookup";
import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Form, Modal, Image } from "react-bootstrap";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import { ConfirmButtons, Typeahead as BasicTypeahead, UserLookup } from "../Form";
import { MarketLookup } from "../Market";
import RoleLookup from "Components/Form/RoleLookup";

const buildText = (name, selections, render) => {
	if(selections.length === 1) {
		return <>{name}: {render(selections[0])}</>;
	}

	let output = name;

	if(selections.length > 1) {
		output += `: ${selections.length}`;
	}

	return output;
}

export function TypeaheadMultipleSelect({
	value = [],
	onChange = (v) => {},
	Typeahead = BasicTypeahead,
	renderSelection = (_, i) => `Selection ${i + 1}`,
	compare = () => false,
	...props
}) {
	const add = (s) => {
		if(value.find(a => compare(a, s)))
			return;

		onChange(Array.from(new Set([...value, s])));
	}
	const remove = (i) => {
		const newSelections = [...value];
		newSelections.splice(i, 1);
		onChange(newSelections);
	}

	return (
		<>
			{<Typeahead {...props} onChange={add} />}
			{!!value?.length && <div className="mt-2">
				{value.map((s, i) => 
					<Badge bg="light" text="dark" size="sm" key={`permission-badge-${i}`}>
						{renderSelection(s, i)}
						<FaTimesCircle className="ms-1" style={{cursor: "pointer", marginTop: "-3px"}} onClick={() => remove(i)} />
					</Badge>
				)}
			</div>}
		</>);
}

export function TypeaheadMultipleFilter({
	name = "TypeaheadMultiple",
	value = [],
	onChange = () => {},
	renderSelection = (_, i) => `Selection ${i + 1}`,
	compare = () => false,
	Typeahead = BasicTypeahead,
	...props
}) {
	const [edit, setEdit] = useState(false);
	const [selections, setSelections] = useState(value);

	const empty = () => setSelections([]);
	const clear = () => { setSelections([]); onChange([]); };
	const reset = () => { setSelections(value); setEdit(false) };
	const submit = () => { setEdit(false); onChange(selections); }

	return <>
		<ButtonGroup>
			<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
				{ buildText(name, value, renderSelection) }
			</Button>
			{ !!value?.length && <Button size="sm" variant="outline-secondary" onClick={clear}><FaTimes /></Button>}
		</ButtonGroup>
		{!!edit && 
		<Modal onHide={reset} show={edit} size="lg" aria-labelledby={`change-{name}-filter`} centered>
			<Modal.Header closeButton>
				<Modal.Title>{name} Filter</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<TypeaheadMultipleSelect compare={compare} Typeahead={Typeahead} {...props} value={selections} autoFocus={true} onChange={setSelections} renderSelection={renderSelection} />
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button className="float-start" variant="link" size="sm" onClick={empty}>Clear</Button>			
				<ConfirmButtons onConfirm={submit} onCancel={reset} />
			</Modal.Footer>
		</Modal>
		}
	</>
}

export function MultipleUserFilter({ name="User", ...props }) {
	return <TypeaheadMultipleFilter 
		name={name} 
		Typeahead={UserLookup}
		renderSelection={u => <>{!!u.picture && <Image referrerPolicy="no-referrer" style={{marginTop: "-3px"}} className="me-1" roundedCircle height="16px" src={u.picture}  />}{u.name}</>}
		compare={(a, b) => a._id === b._id}
		{...props}
	/> 
}

export function MultipleMarketFilter({ name="Market", ...props }) {
	return <TypeaheadMultipleFilter 
		name={name} 
		Typeahead={MarketLookup}
		renderSelection={m => m.name}
		compare={(a, b) => a._id === b._id}
		{...props}
	/> 
}

export const MultipleRegionFilter = ({ name="Region", ...props}) => {
	return <TypeaheadMultipleFilter
		name={name}
		Typeahead={RegionLookup}
		renderSelection={r => r.name}
		compare={(a,b) => a._id === b._id}
		{...props}
	/>
}

export function MultipleRoleFilter({ name="Role", ...props }) {
	return <TypeaheadMultipleFilter 
		name={name} 
		Typeahead={RoleLookup}
		renderSelection={r => <>{r.name}</>}
		compare={(a, b) => a._id === b._id}
		{...props}
	/> 
}
