import React, { useEffect, useState } from "react";
import AutogrowTextarea from "./AutogrowTextarea";
import { Form } from "react-bootstrap";
import { ConfirmButtons } from "../Form";

const NoteForm = ({
	currentUser: {
		name: userName,
		picture: userPicture,
		_id: userId
	},
	setFormOn = () => {},
	formOn = false,
	onChange = () => {},
	onSubmit = async () => {}
}) => {
	const [text, setText] = useState("");

	useEffect(() => {
		onChange(text);
	}, [text])

	const handleConfirm = async () => {
		await onSubmit();
		setText("");
	}

	const handleCancel = () => { 
		setFormOn(false); 
		setText(""); 
	}
	
	return (
		<div className="d-flex">
			<div className="flex-shrink-0">
				<a href={`/users/${userId}`}>
					<img className="me-3 rounded-circle" referrerPolicy="no-referrer" src={userPicture} alt={`${userName}'s picture`} height="40px" />
				</a>
			</div>
			<div className="flex-grow-1 ms-3">
				<Form>
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<AutogrowTextarea
							placeholder="Add a note..."
							text={text}
							onChange={setText}
							onFocus={() => setFormOn(true)} 
						/>
					</Form.Group>
					{
						formOn && 
						<div className="float-end bd-highlight">
							<ConfirmButtons onConfirm={handleConfirm} onCancel={handleCancel} />
						</div>
					}
				</Form>
			</div>
		</div>
	);
}

export default NoteForm;