import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { AsyncButton } from "Components/Shared";

type Props = ButtonProps & {
	onConfirm?: (() => Promise<void>) | (() => void),
	onCancel?: (() => Promise<void>) | (() => void)
}

const ConfirmButtons = (props: Props) => {
	const {
		onConfirm = async () => {},
		onCancel = async () => {},
		...buttonProps
	} = props;
	return (
		<>
			<AsyncButton onClick={onConfirm} size="sm" {...buttonProps} />
			<Button className="ms-1" title="Cancel" size="sm" variant="danger" onClick={onCancel} {...buttonProps}><FaTimes /></Button>
		</>
	)
}

export default ConfirmButtons;