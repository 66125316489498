import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaExclamationTriangle } from "react-icons/fa";

type Props = {};

const TSANumberWarningTooltip = (props:Props) => {
	const popover = <Popover id="popover-basic">
	<Popover.Header as="h3">Verify Driver Has a TSA Number</Popover.Header>
		<Popover.Body>This driver does not have a reported TSA Number but this job requires one. Verify the driver is correctly assigned to this job.</Popover.Body>
	</Popover>;
  return (<OverlayTrigger trigger="click" overlay={popover} rootClose={true}>
	<span><FaExclamationTriangle className="text-warning" style={{cursor:"pointer"}} /></span>
	</OverlayTrigger>);
}

export default TSANumberWarningTooltip;