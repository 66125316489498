import React from "react";
import {Typeahead} from "../Form";
import MarketsApi from "../../api/marketsApi";

const MarketLookup = ({
	markets = [],
	market = { name: "" },
	clientId = "",
	onChange = () => {}
}) => {
	const hasMarkets = markets.length;
	const getSuggestions = hasMarkets
		? () => markets.map(m => ({ id: m._id, text: m.name }))
		: MarketsApi.lookup;
		
	return(
	<Typeahead 
		placeholder="Begin typing a market's name..."
		value={{ id: market._id, text: market.name }}
		fetchAllOnOpen={hasMarkets}
		getDetails={async (id) => await MarketsApi.read(clientId, id, "typeaheadSuggestion")}
		getSuggestions={getSuggestions}
		getDisplayValue={(value) => typeof(value) === typeof({}) ? value.text : value}
		onChange={onChange}
	/>);
};

export default MarketLookup;