import * as Cookies from "js-cookie";
import moment from "moment";
import 'moment-timezone';
const COOKIE_NAME = "pulsar.selectedTimezone"

const multiTimeClockApi = {
	set: (timezone = moment.tz.guess()) => {
		Cookies.remove(COOKIE_NAME);
		Cookies.set(COOKIE_NAME, JSON.stringify({timezone}), { expires: 36500 });
	},
	get: () => {
		const cookie = Cookies.get(COOKIE_NAME);
	
		if (cookie === undefined) {
			return moment.tz.guess();
		}

		const result = JSON.parse(cookie);
		return result.timezone;
	}
}

export default multiTimeClockApi;