import React from "react";
import JobStatus from "./JobStatus";
import format from "../../utils/format";
import shortId from "../../utils/shortId";
import { Address } from "../Shared";

export default {
	_id: { 
		header: (props) => <th {...props}>#</th>, 
		render: ({val, ...props}) => <td {...props}><a href={`/jobs/${val}`}>{shortId(val)}</a></td>,
		name: "Job ID",
		permanent: true
	},
	createdAt: {
		header: (props) => <th {...props}>Created</th>,
		render: ({val, ...props}) => <td {...props}>{format.fullDate(val)}</td>,
		name: "Created"
	},
	status: { 
		header: (props) => <th {...props}>Status</th>, 
		render: ({val, ...props}) => <td {...props}><JobStatus status={val} /></td>,
		name: "Status"
	},
	market: { 
		header: (props) => <th {...props}>Market</th>, 
		render: ({val, ...props}) => <td {...props}>{val.name}</td>,
		name: "Market"
	},
	customerMiles: {
		header: (props) => <th {...props} className="text-end">Cust. Miles</th>, 
		render: ({val, ...props}) => <td {...props} className="text-end">{format.number(val)}</td>,
		name: "Customer Miles"
	},
	calculatedMiles: {
		header: (props) => <th {...props} className="text-end">Calc. Miles</th>, 
		render: ({val, ...props}) => <td {...props} className="text-end">{format.number(val)}</td>,
		name: "Calculated Miles"
	},
	weight: {
		header: (props) => <th {...props} className="text-end">Weight</th>, 
		render: ({val, ...props}) => <td {...props} className="text-end">{format.number(val,1)}</td>,
		name: "Weight"
	},
	jobNumber: {
		header: (props) => <th {...props}>Cust #</th>, 
		render: ({val, ...props}) => <td {...props}>{val}</td>,
		name: "Client Job Number"
	},
	airwayBill: {
		header: (props) => <th {...props}>Airway Bill</th>, 
		render: ({val, ...props}) => <td {...props}>{val}</td>,
		name: "Airway Bill"
	},
	flightNumber: {
		header: (props) => <th {...props}>Flight #</th>, 
		render: ({val, ...props}) => <td {...props}>{val}</td>,
		name: "Flight Number"
	},
	billOfLading: {
		header: (props) => <th {...props}>Bill of Lading</th>, 
		render: ({val, ...props}) => <td {...props}>{val}</td>,
		name: "Bill of Lading"
	},
	pieces: {
		header: (props) => <th {...props} className="text-end">Pieces</th>, 
		render: ({val, ...props}) => <td {...props} className="text-end">{format.number(val,0)}</td>,
		name: "Pieces"
	},
	totalCharges: { 
		header: (props) => <th {...props} className="text-end">Charges</th>, 
		render: ({val, ...props}) => <td {...props} className="text-end">{format.currency(val)}</td>,
		name: "Charges",
		permission: "viewJobMoney"
	},
	dropoff: { 
		header: (props) => <th {...props}>Dropoff</th>, 
		render: ({val, ...props}) => <td {...props}><small><Address {...val} /></small></td>,
		name: "Dropoff"
	},
	pickup: { 
		header: (props) => <th {...props}>Pickup</th>, 
		render: ({val, ...props}) => <td {...props}><small><Address {...val} /></small></td>,
		name: "Pickup"
	},
}