import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Col, Image, Row, Table } from "react-bootstrap";
import { FaExclamationTriangle, FaRegEnvelope, FaUser, FaUserEdit } from "react-icons/fa";
import {UsersApi} from "../../api";
import useQuery from "../../utils/useQuery";
import { Pagination } from "../Shared";
import UserFilter from "./UserFilter";
import { RoleBadge } from "../Shared";
import { isPermitted } from "../../utils/permissions";
import format from "utils/format";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const initialState = {	
	users: [],
	sort: "default", 
	limit: 25, 
	skip: 0, 
	projection: "default",
	activeUser: {}
};

const UserList = (props = initialState) => {
	const { activeUser = {} } = props;
	const query = useQuery();
	const [result, setResult] = useState({ users: [], totalCount: 0, sortOptions: [] });
	const [filter, setFilter] = useState({ ...initialState, ...query });
	const [error, setError] = useState();

	useEffect(() => {
		async function fetchData() {
			try {
				const userResult = await UsersApi.filter(filter);
				setResult(userResult);
			} catch(err) {
				setError(err);
			}
		}

		fetchData();
	}, [filter]);

	if(error instanceof NotAuthorizedErrorType || !isPermitted(activeUser, "admin"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	return (<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/users`}>Users</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<h1>Users {!!result?.totalCount && <>({result?.totalCount || 0})</>}</h1>
				<hr />
			</Col>
		</Row>
		<Row>
			<Col>
				<UserFilter onChange={setFilter} filter={filter} sortOptions={result?.sortOptions || []} />
				<Table size="sm" striped hover className="align-middle">
					<thead>
						<tr>
							<th>Name</th>
							<th>Role</th>
							<th>Status</th>
							<th>Last Logon</th>
							<th className="text-end">Actions</th>
						</tr>
					</thead>
					<tbody>
						{result.users.map(({ _id, status, picture, name, role, email, lastLogon }, i) => <tr key={`user-${i}`}>
							<td>{!!picture && <Image referrerPolicy="no-referrer" roundedCircle height="32px" src={picture} />} <strong>{name}</strong></td>
							<td><RoleBadge roles={role} /></td>
							<td>{status}</td>
							<td>{lastLogon && <span>{format.timeElapsed(lastLogon)}</span>}</td>
							<td className="text-end">
								<Button size="sm" href={`/users/${_id}`}><FaUser /> Profile</Button>
								{isPermitted(activeUser, "editUsers") && <Button className="ms-2" size="sm" href={`/users/${_id}/edit`}><FaUserEdit /> Edit</Button>}
							</td>
						</tr>)}
						{!result?.users?.length && <tr><td colSpan="4" className="text-center text-muted"><FaExclamationTriangle /> No users found</td></tr>}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="4" className="text-center">
								<div style={{display: "inline-block"}}>
									<Pagination
										skip={filter.skip || 0}
										limit={filter.limit || 10}
										totalCount={result?.totalCount}
										setSkip={(s) => setFilter((f) => ({...f, skip: s}))}
									/>
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			</Col>
		</Row>
	</>)
}

export default UserList;