import { apiFetch } from "./api";
import { buildUrlParams } from "./helpers";

export const filter = async (apiParams = {}) => {
	apiParams.limit = Math.max(1, Math.min(apiParams.limit || 500, 500));
	apiParams.skip = Math.max(0, apiParams.skip || 0);
	apiParams.sort = apiParams.sort || "default";
	const qs = buildUrlParams(apiParams);

	const response = await apiFetch(
		`/regions?${qs}`
	);
	return response;
}

export const read = async (regionId) => {
	if(!regionId)
		throw new Error("Invalid region id or url provided.");

	const response = await apiFetch(
		`/regions/${regionId}`
	)

	return response;
}

export const create = async (region) => {
	const response = await apiFetch(
		`/regions`,
		{
			method: "POST",
			cache: "no-cache",
			body: JSON.stringify(region)
		}
	);
	
	return response;
}

export const update = async (regionId, changes) => {
	const response = await apiFetch(
		`/regions/${regionId}`,
		{
			method: "PUT",
			cache: "no-cache",
			body: JSON.stringify(changes)
		}
	)

	return response;
}

export const lookup = async (text = "") => {
	if(text.length < 3) {
		return [];
	}

	return filter({ text, skip: 0, limit: 5, projection: "basic" });
}