import React, { useState } from "react";
import { GoogleMap, Marker,  LoadScript } from "@react-google-maps/api";
import { Button, Row, Col } from "react-bootstrap";

const GOOGLE_MAP_API_KEY = "AIzaSyAc9JxW0n4dQnwekobk2Z_WMiy3juQmj_Q";

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapPinSelector = ({
	callback = () => {},
	initialCenter = { lat: 39.8283, lng: -98.5795 },
	marker: initialMarker,
	zoom = 14
}) => {
	const [marker, setMarker] = useState(initialMarker);

	const onMapClick = (clickEvent) => {
		setMarker({ name: "Market center", position: clickEvent.latLng.toJSON()});
	};
	
	const handleSetLocationClick = () => {
		if(!!marker?.position) {
			callback(marker.position.lat, marker.position.lng);
		} else {
			callback();
		}
	}

  return (<>
		<Row className="mb-3">
			<Col>
				<LoadScript
					googleMapsApiKey={GOOGLE_MAP_API_KEY}
				>
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={initialCenter}
						zoom={zoom}
						onClick={onMapClick}
					>
						{!!marker?.position?.lat && !!marker?.position?.lng && <Marker {...marker} />}
					</GoogleMap>
				</LoadScript>
			</Col>
			
		</Row>
		<Row>
			<Col>
				<Button variant="link" className="float-end" onClick={callback}>Close</Button>
				<Button onClick={handleSetLocationClick}>Set location</Button>
			</Col>
		</Row>
	</>)
}

export default React.memo(MapPinSelector)