import React from "react";
import { SelectFilter, FreeSearchFilter, RangeFilter, TypeaheadFilter, RadioFilter } from "../Filter";
import { FilterBar } from "../Shared";
import { FaSearch, FaTimes } from "react-icons/fa";
import { AVAILABLE_STATUSES } from "./JobFilter";
import { Button } from "react-bootstrap";
import { ClientFilter } from "../Filter/TypeaheadFilter";
import { MultipleMarketFilter, MultipleRegionFilter } from "../Filter/TypeaheadMultipleFilter";
import { getDisplay } from "./JobStatus";
import { isPermitted } from "utils/permissions";

const JobBoardFilter = ({ 
	filter = {},
	sortOptions = [],
	basic = false,
	onChange = () => {},
	activeUser
}) => {
	const filterKeys = Object.keys(filter)?.filter(k => typeof(filter[k]) !== "undefined");
	return (<>
		<FilterBar>
			<FreeSearchFilter 
				name={<FaSearch />}
				value={filter.text} 
				onChange={(text) => onChange({...filter, text})}
			/>
			<SelectFilter 
				name="Status" 
				value={filter.status} 
				onChange={(status) => onChange({...filter, status})} 
				options={AVAILABLE_STATUSES}
				display={getDisplay}
			/>
			<RangeFilter
				name="Miles"
				value={{min: filter.minDistance, max: filter.maxDistance}}
				onChange={({min: minDistance, max: maxDistance}) => onChange({...filter, minDistance, maxDistance})}
			/>
			{!basic && isPermitted(activeUser, "viewJobMoney") && <RangeFilter
				name="Charges"
				value={{min: filter.minCharges, max: filter.maxCharges}}
				onChange={({min: minCharges, max: maxCharges}) => onChange({...filter, minCharges, maxCharges})}
			/>}
			{!basic && <TypeaheadFilter.UserFilter
				name="Driver"
				value={filter.driver}
				onChange={driver => onChange({...filter, driver})}
			/> }
			{!basic && <MultipleRegionFilter onChange={(regions) => onChange({...filter, regions })} value={filter.regions} />}
			{!basic && <ClientFilter name="Client" onChange={(client) => onChange({...filter, client})} value={filter.client} />}
			{!basic && !!filter?.client?.markets?.length && <MultipleMarketFilter value={filter.markets} onChange={(markets) => onChange({...filter, markets})} clientId={filter.client._id} markets={filter.client.markets} />}
			{!basic && !!filterKeys?.length && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
			<span style={{marginLeft: "auto"}}>
				{!!sortOptions?.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions} value={filter.sort || sortOptions[0].key} name="Sort" />}
				{!basic &&<a href="/jobs">Advanced Search</a>}
			</span>
		</FilterBar>
	</>)
}

export default JobBoardFilter;