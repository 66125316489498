import React from "react";

type StatisticProps = {
	value: number,
	label: string
}

export const Statistic = (props:StatisticProps) => {
	return (
		<div className="text-center ms-5">
			<dd className="fs-5 fw-bold mb-0">{props.value}</dd>
			<dt className="fs-6 fw-normal"><small>{props.label}</small></dt>
		</div>
	)
}

type StatisticListProps = {
	statistics: StatisticProps[]
}

export const StatisticList = (props:StatisticListProps) => {
	return (
		<dl className="m-0 p-0">
			<div className="d-flex justify-content-center">
				{props.statistics.map((stat,i) => <Statistic key={`stat-${stat.label}-${i}`} {...stat} />)}
			</div>
		</dl>
	);
}

export default Statistic