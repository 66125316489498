import React from "react";
import "./FilterBar.css";

const FilterBar = (props) => {
	return(
		<div className="filter-bar">
			{props.children}	
		</div>
	);
}

export default FilterBar;