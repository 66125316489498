import React, { ReactNode } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaQuestionCircle } from "react-icons/fa";

type Props = {
  term: string,
  definition: ReactNode
};

const InformationTooltip = (props:Props) => {
  const { term = "Information", definition = "Add a definition."} = props;
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{term}</Popover.Header>
      <Popover.Body>{definition}</Popover.Body>
    </Popover>
  );
  return (<OverlayTrigger trigger="click" overlay={popover} rootClose={true}>
    <span><FaQuestionCircle className="text-secondary" style={{cursor:"pointer"}} /></span>
  </OverlayTrigger>);
}

export default InformationTooltip;