import { JobTemplate, User } from "api/Types";
import { LoadingIndicator, Pagination } from "Components/Shared";
import React, { useState, useEffect } from "react";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import { FaPlus, FaSearch } from "react-icons/fa";
import { JobTemplateApi } from "../../api";
import type { JobTemplateFilter } from "../../api/jobTemplateApi";
import JobTemplateCard from "./JobTemplateCard";

type JobTemplateListProps = {
	filter?: JobTemplateFilter,
	activeUser?: User
};

const JobTemplateList = (props:JobTemplateListProps) => {
	const { 
		filter: initialFilter = { text: "" }
	} = props;

	const [loading, setLoading] = useState<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const [templates, setTemplates] = useState<JobTemplate[]>([]);
	const [filter, setFilter] = useState<JobTemplateFilter>({ ...initialFilter });
	const [searchText, setSearchText] = useState<string>("");

	const fetchData = async () => {
		try {
			setLoading(true);
			const result = await JobTemplateApi.filter(filter);
			setTemplates(result.results || []);
			setCount(result.totalCount || 0);
		} catch(err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filter]);

	const handleSearch = () => {
		setFilter(f => ({ ...f, text: searchText }));
	}

	return (
	<>
		<Row className="mb-3">
			<Col>
				<InputGroup size="lg">
					<Button onClick={handleSearch}><FaSearch /></Button>
					<Form.Control placeholder={"Search for a job template by name"} value={searchText} onChange={e => setSearchText(e.currentTarget.value)} />
				</InputGroup>
			</Col>
		</Row>
		<Row className="mb-3">
			<Col>
				<h2>Job Templates ({count}) <Button className="float-end" size="sm" href="/jobs/templates/create"><FaPlus /> Create Template</Button></h2>
				<hr />
			</Col>
		</Row>
		<Row className="mb-3">
			<Col>
				{ templates.map((t, i) => <JobTemplateCard key={`template-${i}`} template={t} />) }
				{ loading && <p><LoadingIndicator /> fetching jobs...</p>}
			</Col>
		</Row>
		<Row className="mb-3">
			<Col className="d-flex justify-content-center">
				<Pagination skip={filter?.skip || 0} limit={filter?.limit || 10} totalCount={count} setSkip={(s) => setFilter(f => ({...filter, skip: s}))} />
			</Col>
		</Row>
	</>);
};

export default JobTemplateList;