import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Note from "./Note";
import "./NoteList.css";
import NoteForm from "./NoteForm";
import { LoadingIndicator } from ".";

const NoteList = ({
	currentUser = {},
	notes: initialNotes = [],
	notesApi = {}
}) => {
	const [notes, setNotes] = useState(initialNotes);
	const [text, setText] = useState("");
	const [formOn, setFormOn] = useState(false);
	const [skip, setSkip] = useState(0);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(async () => {
		const initialNotes = await notesApi.read({ skip, take: 5 });
		setNotes(initialNotes.notes || []);
		setTotal(initialNotes.totalCount);
	}, []);

	const handleSubmit = async () => {
		const newNote = await notesApi.create({ text })
		setFormOn(false);
		setNotes([{ ...newNote, user: currentUser }, ...notes]);
	}

	const handleEdit = (note) => notesApi.update(note)
	const handleDelete = (noteId) => notesApi.delete({noteId})

	const loadMoreNotes = async () => {
		setLoading(true);
		const newSkip = notes.length;
		const take = Math.min(total - skip - 5, 5);
		const newNotes = await notesApi.read({ skip: newSkip, take });
		setNotes(n => [...n, ...newNotes.notes]);
		setSkip(notes.length);
		setLoading(false);
	}

	return (
		<div className="note-list">
			{ !loading && <p className="mb-4">{total} Notes</p> }
			{ typeof(currentUser._id) !== typeof(undefined) &&
				<NoteForm currentUser={currentUser} onSubmit={handleSubmit} formOn={formOn} onChange={setText} setFormOn={setFormOn} />
			}
			<ul>
				{ notes.map((n) => 
					<li key={`note-${n._id}`}>
						<Note 
							{...n}
							onChange={handleEdit}
							onDelete={handleDelete}
							currentUser={currentUser}
						/>
					</li>
				)}
				{ !!loading && <li><LoadingIndicator /></li> }
			</ul>
			<div className="text-center">
				{ notes.length < total && <Button onClick={loadMoreNotes}>Load more notes</Button> }
			</div>
		</div>
	);
}

export default NoteList;