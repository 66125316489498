import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import SessionsApi from "../../api/sessionsApi";
const GOOGLE_CLIENT_ID = "428945346411-ctbfgb9tj4ehchphg3dm8g3k5gc9ml4g.apps.googleusercontent.com";

const propTypes = {};

const UserBadge = ({ activeUser = {}, setActiveUser = () => {} }) => {
	const login = async (response) => {
		if(response?.error) {
			console.error(response.error, response.details);
			return;
		}
		const userResponse = await SessionsApi.createSession(response.tokenId);
		setActiveUser(userResponse.user);
		window.location.href="/";
	}

	const logout = () => {
		SessionsApi.logout();
		setActiveUser({});
		window.location.href="/";
	}

	if(!activeUser || !activeUser._id) {
		return (
			<>
				<GoogleLogin
					clientId={GOOGLE_CLIENT_ID}
					buttonText="Sign in with Google"
					onSuccess={login}
					onFailure={login}
					cookiePolicy={'single_host_origin'}
				/>
			</>
		)
	}

	return (
		<>
			<Dropdown className="float-end">
				<Dropdown.Toggle id="user-badge-dropdown-toggle">
					<Image alt="User's profile picture." referrerPolicy="no-referrer" roundedCircle height="32" src={activeUser.picture} /> {activeUser.name}
				</Dropdown.Toggle>
				<Dropdown.Menu alignRight={true} align="right" style={{ zIndex: 9999 }}>
					<Dropdown.Item href={`/users/${activeUser._id}`}>Profile</Dropdown.Item>
					<Dropdown.Item>
						<GoogleLogout 
							clientId={GOOGLE_CLIENT_ID}
							buttonText={"Sign out"} 
							onLogoutSuccess={logout} 
						/>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
	
}

UserBadge.propTypes = propTypes;

export default UserBadge;