import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, ListGroup } from "react-bootstrap";
import { LoadingIndicator } from "../Shared";
import { FaExclamationTriangle } from "react-icons/fa";
import "./Typeahead.css";
import escapeRegExp from "utils/escapeRegExp";

const DEFAULT_SUGGESTION_INDEX = -1;
let timeout: ReturnType<typeof setTimeout>;

const filterSuggestions = (suggestions:Suggestion[] = [], val = "", maxSuggestions = 5, applyFilterAndHighlight: Boolean = false) => {
	if(!!applyFilterAndHighlight && !!val) {
		const regex = new RegExp(escapeRegExp(val || ""), "i");

		const textMatches = ({ text = "" }) => (text.toLowerCase().match(regex) || []).length;
		const highlight = (suggestion: Suggestion):Suggestion => ({html: suggestion?.text?.replace(regex, "<strong>$&</strong>"), text: suggestion.text, id: suggestion.id });

		return suggestions.filter(textMatches).map(highlight).slice(0, maxSuggestions);
	}
	return suggestions.slice(0, maxSuggestions);
}

export type Suggestion = {
	text?: string
	id?: string,
	html?: string
}

type TypeaheadProps = {
	fetchAllOnOpen?: Boolean,
	getDetails?: (id?: string) => Promise<any>,
	getSuggestions?: (query?: string) => Promise<Suggestion[] | undefined>,
	inputDelay?: number,
	maxSuggestions?: number,
	onChange?: (value?: Object) => void,
	placeholder?: string,
	value?: Suggestion,
	getDisplayValue?: ((selection?: Suggestion) => string),
	performAction?: (id: string) => void,
	className?: string
}

/**
 * @param {Object} props - typeahead props
 * @param {bool} props.fetchAllOnOpen - indicates all the values should be fetched initially (default: false)
 * @param {func} props.getDetails - function that returns selection details by id
 * @param {func} props.getSuggestions - function that returns selections as [{ text: "", id: "" }]
 * @param {number} props.inputDelay - milliseconds before execution of suggestion retreival (default: 1500)
 * @param {number} props.maxSuggestions - number of suggestions to display (default: 5)
 * @param {func} props.performAction - Callback function call when selection is chosen
 * @param {string} props.onChange - Callback function call when value changes
 * @param {string} props.placeholder - text to display when no input has been provided
 * @param {string} props.value - initial value of the input
 * @returns 
 */
const Typeahead = (props: TypeaheadProps) => {
	const {
		fetchAllOnOpen = false,
		getDetails,
		getSuggestions = async () => ([]),
		getDisplayValue = (suggestion?:Suggestion) => suggestion?.text,
		inputDelay = 500,
		maxSuggestions = 5,
		onChange = () => {},
		performAction,
		placeholder = "Begin typing...",
		value,
		className = "",
		...formControlProps
	} = props;

	const initialSuggestions: Suggestion[] = [];
	const [inputValue, setInputValue] = useState(value?.text);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [suggestionIndex, setSuggestionIndex] = useState<number>(DEFAULT_SUGGESTION_INDEX);
	const [suggestions, setSuggestions] = useState<Suggestion[]>(initialSuggestions);
	const [suggestionsVisible, setSuggestionVisibility] = useState<boolean>(false);
	const [message, setMessage] = useState<JSX.Element|undefined>();

	const filteredSuggestions = filterSuggestions(suggestions, inputValue, maxSuggestions, fetchAllOnOpen);

	const error = (e:JSX.Element) => {
		setIsFetching(false);
		setMessage(e);
		setTimeout(() => setMessage(undefined), 3000);
	}
	
	const retrieveSuggestions = async (input?:string) => {
		try {
			const response = await getSuggestions(input);
			setSuggestions(response || []);
			setIsFetching(false);
		} catch(e) {
			console.error(e);
			error(<div className="text-muted text-center"><FaExclamationTriangle /> Oops, could not get suggestions. Please try again.</div>);
		}
	};

	const loadSuggestions = async (input?: string) => {
			setSuggestionVisibility(true);
			setIsFetching(true);
			!!timeout && clearTimeout(timeout);
			timeout = setTimeout(() => retrieveSuggestions(input), inputDelay);
	}

	const handleInputChange = async (newInputValue: string) => {
		setInputValue(newInputValue)
		if(!fetchAllOnOpen) {
			await loadSuggestions(newInputValue);
		}
	}

	const handleSuggestionClick = async (suggestion:Suggestion) => {
		const { id } = suggestion;
		const displayValue = getDisplayValue(suggestion);
		setInputValue(displayValue);
		if(!!id) {
			setIsFetching(true);

			if(!!getDetails) {
				const selection = await getDetails(id);
				onChange(selection);
			}

			if(!!performAction) {
				performAction(id);
			}

			setInputValue(getDisplayValue(suggestion));
			setSuggestionIndex(DEFAULT_SUGGESTION_INDEX);
			setSuggestionVisibility(false);
			setIsFetching(false);
		}
	}

	const nextIndex = () => {
		let newIndex = suggestionIndex + 1;
		if(newIndex >= suggestions.length) {
			newIndex = 0;
		}
		setSuggestionIndex(newIndex);
	}

	const prevIndex = () => {
		let newIndex = suggestionIndex - 1;
		if(newIndex < 0) {
			newIndex = suggestions.length - 1;
		}
		setSuggestionIndex(newIndex);
	}
	
	const handleKeyDown = (evt:React.KeyboardEvent) => {
		if(!!suggestionsVisible && !!suggestions && !!suggestions.length) {
			switch(evt.key) {
				case "ArrowDown": // down arrow
					nextIndex();
					break;
				case "ArrowUp": // up arrow
					prevIndex();
					break;
				case "Tab": // tab
					evt.preventDefault(); 
					evt.stopPropagation();
					nextIndex();
					break;
				case "Enter": // Enter
					evt.preventDefault(); 
					evt.stopPropagation();
					const suggestion = filteredSuggestions[Math.max(suggestionIndex, 0)];
					handleSuggestionClick(suggestion);
					break;
				case "Escape": // escape
					setSuggestionVisibility(false)
					break;
				default:
					break;
			}
		}
	}

	const handleInputFocus = async () => {
		if(!suggestions.length) {
			loadSuggestions(inputValue || value?.text);
			return;
		}

		if(!!fetchAllOnOpen && !suggestions.length) {
			loadSuggestions("");
			return;
		}

		setSuggestionVisibility(true);
	}

	let display = value;
	if(typeof(inputValue) === typeof("") && !!inputValue) {
		display = { text: inputValue, id: "", html: "" };
	}
	
	return (
		<div className={`typeahead ${className}`}>
			<Form.Control 
				{...formControlProps}
				placeholder={placeholder}
				type="text"
				value={getDisplayValue(display)} 
				onChange={(evt:React.ChangeEvent<HTMLInputElement>) => handleInputChange(evt.target.value)}
				onFocus={handleInputFocus}
				onBlur={() => setSuggestionVisibility(false)}
				onKeyDown={handleKeyDown}
			/>
			{ !!message && <ListGroup><ListGroup.Item>{message}</ListGroup.Item></ListGroup>}
			{ !!suggestionsVisible && 
				<ListGroup>
					{ !!isFetching && <ListGroup.Item className="text-center"><LoadingIndicator /></ListGroup.Item> }
					{ !isFetching && !filteredSuggestions?.length && <ListGroup.Item className="text-muted text-center"><FaExclamationTriangle /> No suggestions found.</ListGroup.Item>}
					{ !isFetching && !!filteredSuggestions?.length && filteredSuggestions.map((s, i) => {
							const className = i === suggestionIndex ? "active" : "";
							return (<ListGroup.Item 
								key={`suggestion-${i}`}
								action 
								onMouseDown={() => handleSuggestionClick(s)}
								className={className}
								dangerouslySetInnerHTML={{__html: (s?.html || s?.text || "")}}
							>
							</ListGroup.Item>) 
						})
					}
				</ListGroup>
			}		
		</div>
	)
}

export default Typeahead;