import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { SaveButton } from "../Form";
import { FaEdit, FaSyncAlt, FaTimes } from "react-icons/fa";
import RefreshButton from "./RefreshButton";

const Widget = (props) => {
	const {
		title,
		onSubmit,
		onError = () => {},
		onEdit = () => {},
		onRefresh,
		children,
		canEdit = true,
		...cardProps
	} = props;

	const [edit, setEdit] = useState(false);

	const onUnload = () => {
		window.alert(`You have unsaved changes to ${title}. Are you sure you want to leave?`);
	}

	useEffect(
		() => {
			window[edit ? "addEventListener" : "removeEventListener"]("beforeunload", onUnload);
			onEdit(edit)
		}, [edit]
	);

	return (
		<Card {...cardProps}>
			<Card.Header>
				{!!title && <span className="h3">{title}</span>}
				{canEdit && typeof(onSubmit) === typeof(()=>{}) && 
					<span className="float-end">
						{typeof(onRefresh) === typeof(()=>{}) && <RefreshButton variant="light" size="sm" className="me-2" onClick={onRefresh} />}
						{!edit && <Button title="Edit widget" variant="light" size="sm" onClick={() => setEdit(true)}><FaEdit /></Button>}
						{!!edit && <SaveButton className="me-1" size="sm" onClick={onSubmit} onError={onError} onComplete={() => setEdit(false)}></SaveButton>}
						{!!edit && <Button title="Cancel" size="sm" variant="danger" onClick={() => setEdit(false)}><FaTimes /></Button>}
					</span>
				}
			</Card.Header>
			<Card.Body>
				{children}
			</Card.Body>
		</Card>
	)
}

export default Widget;