import React from "react";
import { Container, Navbar, Nav, NavDropdown, Form } from "react-bootstrap";
import { GiStarCycle } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { UserBadge } from "../User";
import { isPermitted } from "../../utils/permissions";
import { HiTemplate } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { MdViewList } from "react-icons/md";
import { FaUsersCog, FaUserAlt } from "react-icons/fa";
import TimezoneInput from "Components/Form/TimezoneInput";

const Header = ({activeUser, setActiveUser}) => {
	const JobNav =  (<>
		<NavDropdown title="Jobs">
			<NavDropdown.Item href="/"><MdViewList /> Dashboard</NavDropdown.Item>
			<NavDropdown.Item href="/jobs"><FaSearch /> Search</NavDropdown.Item>
			<NavDropdown.Item href="/jobs/templates"><HiTemplate /> Templates</NavDropdown.Item>
		</NavDropdown>
	</>)

	const AdminNav = (<>
		<NavDropdown title="Admin">
			{ isPermitted(activeUser, "viewUsers") && <NavDropdown.Item href="/users"><FaUserAlt /> Users</NavDropdown.Item> }
			<NavDropdown.Item href="/roles"><FaUsersCog /> Roles</NavDropdown.Item>
		</NavDropdown>
	</>)

  return (
		<Navbar className="header" bg="primary" variant="dark" expand="lg">
				<Container fluid="lg">
					<Navbar.Brand href="/"><GiStarCycle /> Pulsar</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{ isPermitted(activeUser, "viewJobs") && <NavLink className="nav-link" role="button" to={"/"}>Dashboard</NavLink> }
							{ isPermitted(activeUser, "viewJobs") && JobNav }
							{ isPermitted(activeUser, "viewClients") && <NavLink className="nav-link" role="button" to={"/clients"}>Clients</NavLink> }
							{ isPermitted(activeUser, "viewMarkets") && <NavLink className="nav-link" role="button" to={"/markets"}>Markets</NavLink> }
							{ isPermitted(activeUser, "viewRegions") && <NavLink className="nav-link" role="button" to={"/regions"}>Regions</NavLink> }
							{ isPermitted(activeUser, "viewInvoices") && <NavLink className="nav-link" role="button" to={"/invoices"}>Invoices</NavLink> }
							{ isPermitted(activeUser, "viewPayroll") && <NavLink className="nav-link" role="button" to={"/payroll"}>Payroll</NavLink> }
							{ isPermitted(activeUser, "viewReports") && <NavLink className="nav-link" role="button" to={"/reports"}>Reports</NavLink> }
							{ isPermitted(activeUser, "admin") && AdminNav }
						</Nav>
					</Navbar.Collapse>
					<TimezoneInput />
					<UserBadge activeUser={activeUser} setActiveUser={setActiveUser} />
				</Container>
		</Navbar>
  );
}

export default Header;
