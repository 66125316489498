import React from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import format from "../../utils/format";
import { Address } from "../Shared";
import currency from "currency.js";
import "./Invoice.css";
import { abbreviate } from "utils/abbreviate";

const addJobTotals = (acc, j) => acc.add(j.totalCharges || 0);

const Invoice = (invoice) => {
	const {
		_id,
		client,
		market = {},
		jobs = [],
		endDate,
		createdAt
	} = invoice;
	const { contact = {}, billing = {} } = (market ?? {});
	const serialNumber = (_id || "TBD").slice(-8).toUpperCase();
	const invoiceId = invoice?.invoiceId || `${format.invoiceId(endDate)}${market?.code || 'XX'}`
	const viewChargeDetails = () => {
		
	}

	return (
		<Container className="invoice">
			<Row>
				<Col className="col-print-6"><img src="/images/pxdeliverylogo.png" className="mb-3" alt="pxdelivery logo: aircraft circumnavigating a globe" /></Col>
				<Col className="col-print-6"><h1 className="text-end">INVOICE</h1></Col>
			</Row>
			<Row>
				<Col className="col-print-4">
					<strong>{market?.name}</strong><br />
					Attn: {contact.name}<br />
					<Address {...{
						...billing.address
					}} />
				</Col>
				<Col className="col-print-4">
					<p>
						Serial Number: <strong>{serialNumber || "TBD"}</strong><br />
						Invoice Number: <strong>{invoiceId}</strong><br />
						Invoice Date: <strong>{format.date(createdAt)}</strong><br />
						Terms: <strong>{billing.paymentTerm}</strong><br />
					</p>
				</Col>
				<Col className="col-print-4">
					<p>
						<strong>Please Remit Payment To:</strong><br />
						Aguilar Industries, Inc.<br />
						DBA Premier Xpress Delivery<br />
						PO Box 1981<br />
						Arvada, CO 80001<br />
						(855) 793-2497
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<Table size="sm">
						<thead>
							<tr>
								<th><small>Job</small></th>
								<th><small>Pickup</small></th>
								<th><small>Delivery</small></th>
								<th className="text-end"><small>Pcs/Wt</small></th>
								<th className="text-end"><small>Miles</small></th>
								<th className="text-end"><small>Charges</small></th>
							</tr>
						</thead>
						<tbody>
							{!jobs?.length && <tr><td className="text-center" colSpan={7}>There are no jobs associated with this invoice.</td></tr>}
							{!!jobs?.length && jobs.map(job => 
								<tr key={`job-${job._id}`}>
									<td>
										<small>
											<span className="text-decoration-underline">Job #</span><br />
											<a className="text-decoration-none d-print-none" href={`/jobs/${job._id}`} target="_blank" rel="noreferrer"><small>{job._id.slice(-8).toUpperCase()}</small></a>
											<span className="text-decoration-none d-none d-print-inline"><small>{job._id.slice(-8).toUpperCase()}</small></span><br />
											<span className="text-decoration-underline">Cust #</span><br />
											{job.jobNumber}<br />
										</small>
									</td>
									<td>
										<small>
											{job?.pickup?.name && <><strong>{job?.pickup?.name}</strong><br /></>}
											{abbreviate(job.pickup.street1)}<br />
											{!!job.pickup.street2 && <>{abbreviate(job.pickup.street2)}<br /></>}
											{job.pickup.city}, {job.pickup.state} {job.pickup.zip.substring(0,5)}<br />
											<span>{format.fullDate(job.pickup.pickedupAt)}</span><br />
											<span>AWB#: {job.airwayBill}</span>
										</small>
									</td>
									<td>
										<small>
											{job?.dropoff?.name && <><strong>{job?.dropoff?.name}</strong><br /></>}
											{abbreviate(job.dropoff.street1)}<br />
											{!!job.dropoff.street2 && <>{abbreviate(job.dropoff.street2)}<br /></>}
											{job.dropoff.city}, {job.dropoff.state} {job.dropoff.zip.substring(0,5)}<br />
											<span>{format.fullDate(job.dropoff.dropoffAt)}</span><br />
											<span>POD: {job.dropoff.pod}</span>
										</small>
									</td>
									<td className="text-end"><small>{job.pieces}/{job.weight}</small></td>
									<td className="text-end"><small>{Math.ceil(job.calculatedMiles)}</small></td>
									<td className="text-end"><small>{format.currency(job.totalCharges || 0)}</small><p className="screen-only"><small><a target="_blank" href={`/jobs/${job._id}`} onClick={() => viewChargeDetails(job._id)}>View Charges</a></small></p></td>
								</tr>)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={3}>Thank you for your business!</td>
								<td colSpan={2} className="text-end"><strong>Invoice total:</strong></td>
								<td className="text-end">{format.currency(jobs.reduce(addJobTotals, currency(0)).value)}</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}

export default Invoice;