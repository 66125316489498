import React, { useEffect, useState } from "react";
import { Badge, Breadcrumb, Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PayrollFilter } from ".";
import { PayrollApi } from "../../api";
import useQuery from "../../utils/useQuery";
import { LoadingIndicator, Pagination } from "../Shared";
import shortId from "../../utils/shortId";
import format from "../../utils/format";
import { FaPlusSquare } from "react-icons/fa";

const PayrollRow = ({_id, endDate, grossRevenue, total, status}) => {
	return (<tr>
		<td><Link to={`/payroll/${_id}`}>{shortId(_id)}</Link></td>
		<td>{format.date(endDate)}</td>
		<td><Badge size="sm">{status}</Badge></td>
		<td className="text-end">{format.currency(grossRevenue)}</td>
		<td className="text-end">{format.currency(total)}</td>
	</tr>);
}

const PayrollSearch = ({activeUser}) => {	
	const query = useQuery();

	if(!!query.selectedStartDate)
		query.selectedStartDate = new Date(query.selectedStartDate);
	if(!!query.selectedEndDate)
		query.selectedEndDate = new Date(query.selectedEndDate);

	const [result, setResult] = useState([]);
	const [filter, setFilter] = useState({limit: 10, skip: 0, ...query});
	const [isLoading, setIsLoading] = useState(true);

	const updateFilter = (newFilter) => setFilter(f => ({ limit: 10, skip: 0,  ...newFilter }));

	useEffect(() => {
		const fetchData = async () => {
			const r = await PayrollApi.filter(filter);
			setResult(r);
			setIsLoading(false);
		};

		fetchData();
	}, [filter]);

	if(!!isLoading && !!result?.payrolls?.length) {
		return (<LoadingIndicator />);
	}

	return (<>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/payroll`}>Payroll</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row>
			<Col>
				<PayrollFilter onChange={updateFilter} filter={filter} />
				<Button className="float-end" type="primary" size="sm" href={"/payroll/new"}><FaPlusSquare /> Start New Payroll</Button>
				<Table>
					<thead>
						<tr>
							<th>#</th>
							<th>End Date</th>
							<th>Status</th>
							<th className="text-end">Gross Revenue</th>
							<th className="text-end">Total Payout</th>
						</tr>
					</thead>
					<tbody>
						{ !!isLoading && !result?.payrolls?.length && <tr><td className="text-center" colSpan={5}><LoadingIndicator /></td></tr> }
						{ !isLoading && !result?.payrolls?.length && <tr><td className="text-center" colSpan={5}>No payrolls have been defined.</td></tr> }
						{ !isLoading && !!result?.payrolls?.length && result.payrolls.map(p => <PayrollRow {...p} key={`payrollRow-${p._id}`} />) }
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="5" className="text-center">
								<div style={{display: "inline-block"}}>
									<Pagination {...{
										limit: filter.limit, 
										skip: filter.skip, 
										totalCount: (result?.totalCount || 0), 
										setSkip: (s) => updateFilter((f) => ({...f, skip: s}))
									}} />
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			</Col>
		</Row>
	</>);
};

export default PayrollSearch;