import { apiFetch } from "./api";
import { buildUrlParams, filterParameters, updateWindowLocation } from "./helpers";

const reservedCommisions = ["standard", "exclude"];

const setCommission = (c) => ({
	excludeFromCommission: c.excludeFromCommission || c.commission === "exclude",
	forUser: reservedCommisions.indexOf(c.commission) >= 0 || reservedCommisions.indexOf(c.forUser) >= 0
		? undefined 
		: (c.forUser || (!!c.commission ? c.commission : undefined))
});

const jobsApi = {
	updateDrivers: async({jobId, drivers = []}) => {
		const result = await apiFetch(`/Jobs/${jobId}/updateDrivers`, {
			method: "POST",
			cache: "no-cache",
			body: JSON.stringify(drivers)
		});

		return result;
	},
	updateCharges: async({jobId, charges = []}) => {
		charges = charges.map(c => ({ ...c, ...setCommission(c) }));
		const result = await apiFetch(`/Jobs/${jobId}/charges`, {
			method: "POST",
			cache: "no-cache",
			body: JSON.stringify(charges)
		});

		return result;
	},
	getHistory: async({ jobId, skip = 0, take = 5}) => {
		const params = [
			["skip", skip],
			["take", take]
		];
		const qs = new URLSearchParams(params).toString();
		const history = await apiFetch(`/Jobs/${jobId}/getHistory?${qs}`);
		return history;
	},
	updateStatus: async ({ jobId, newStatus, pod, minutesWaiting, timestamp }) => {
		const body = {status: newStatus, timestamp, pod, minutesWaiting};
		const response = await apiFetch(`/Jobs/${jobId}/changeStatus`, {
			method: "PUT",
			cache: "no-cache",
			body: JSON.stringify(Object.keys(body).reduce((acc, v) => {
				if(typeof(body[v]) !== typeof(undefined))
					acc[v] = body[v];
				return acc;
			}, {}))
		});
		return response;
	},
	filter: async ({...apiParams}) => {
		const params = [];

		if(apiParams?.client) {
			apiParams.clientId = apiParams.client._id;
			delete apiParams.client;
		}

		if(apiParams?.markets) {
			apiParams.marketId = apiParams.markets.map(m => m._id);
			delete apiParams.markets;
		}

		if(apiParams?.regions) {
			apiParams.regionId = apiParams.regions.map(r => r._id);
			delete apiParams.regions;
		}

		apiParams.sort = apiParams.sort || "deadline";
		apiParams.projection = apiParams.projection || "detailed";

		Object.keys(apiParams).forEach(k => {
			if(apiParams[k] instanceof Date) {
				apiParams[k] = apiParams[k].toISOString();
				return;
			}
		});

		const qs = buildUrlParams(apiParams);

		const response = await apiFetch(`/Jobs?${qs}`);
		
		return response;
	},
	read: async (jobId) => {
		const response = await apiFetch(`/Jobs?jobId=${jobId}&projection=detailed`);
		return response;
	},
	create: async (clientId, marketId, job) => {
		const response = await apiFetch(			
			`/Jobs`,
			{
				method: "POST",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({clientId, marketId, ...job})
			}
		);
		return response;
	},
	update: async (jobId, changes) => {
		const response = await apiFetch(
			`/Jobs/${jobId}`,
			{
				method: "PUT",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(changes)
			}
		);
		return response;
	},
	recalculateCharges: async (jobId) => {
		const response = await apiFetch(
			`/jobs/${jobId}/actions/recalculateCharges`,
			{
				method: "POST",
				cache: "no-cache",
				headers: {
					"Content-Type": "application/json"
				}
			}
		)

		return response;
	}
}

export default jobsApi;