import React from "react";
import {Form } from "react-bootstrap";
import {Typeahead} from "../Form";
import MarketsApi from "../../api/marketsApi";

const MarketSelector = ({
	markets = [],
	market = { name: "" },
	clientId = "",
	hideLabel = false,
	onChange = () => {},
	...props
}) => {
	return(
		<Form.Group {...props}>
			{!hideLabel && <Form.Label>Market</Form.Label> }
			<Typeahead 
				placeholder="Begin typing a market's name..."
				value={market.name}
				fetchAllOnOpen={true}
				getDetails={async (id) => await MarketsApi.read(clientId, id, "typeaheadSuggestion")}
				getSuggestions={() => markets.map(m => ({ id: m._id, text: m.name }))}
				getDisplayValue={(value) => (typeof(value) === typeof({}) ? value.name : value)}
				inputDelay={0}
				onChange = {onChange}
			/>
		</Form.Group>
	);
}

export default MarketSelector;