import React from "react";
import { Typeahead } from "."
import { UsersApi } from "../../api";

const UserLookup = (props) => {
	const {
		onChange = (u) => { console.log(u) },
		placeholder = "Type to find a user",
		value = {},
		requiresTSANumber = false,
		...typeAheadProps
	} = props;
	return (
		<Typeahead 
			{...typeAheadProps}
			value={{text: value.name, id: value._id}}
			getDetails={async (id) => UsersApi.getUser({ userId: id })}
			getSuggestions={text => UsersApi.lookupUsers(text, requiresTSANumber)}
			onChange={(u) => onChange(u)}
			placeholder={placeholder}
		/>
	)
}

export default UserLookup;