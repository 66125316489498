import { HttpErrorResponse } from "api/Types/HttpErrorResponse";

class BadRequest extends Error
{
	message: string;
	validationErrors: HttpErrorResponse;

	constructor(message: string, validationErrors: HttpErrorResponse)
	{
		super();
		this.message = message;
		this.validationErrors = validationErrors;
	}
}

export default BadRequest;