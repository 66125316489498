import React, { useEffect, useState } from "react";
import clientsApi from "../../api/clientsApi";
import { ClientSelector } from "../Client";
import useQuery from "../../utils/useQuery";
import { Accordion, Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Widget } from "../Shared";
import { DateSelectionCalendar, SaveButton } from "../Form";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import InvoicePreview from "./InvoicePreview";
import { isPermitted } from "utils/permissions";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const InvoiceForm = ({activeUser}) => {
	const { clientid: clientId } = useQuery();
	const [client, setClient] = useState();
	const [stage, setStage] = useState(1);
	const [markets, setMarkets] = useState([]);
	const [cutoffDate, setCutoffDate] = useState();
	const [invoices, setInvoices] = useState([]);


	useEffect(() => {
		async function fetchData () {
			if(!!clientId && !client?._id) {
				const c = await clientsApi.read(clientId);
				setClient(c);
				setStage(2);
			}
		}
		fetchData();
	}, [clientId, client])
	
	console.log({activeUser})

	if(!isPermitted(activeUser, "createInvoices")) {
		return <NotAuthorizedError redirect="/invoices" redirectMessage="Back to Invoices" />
	}

	if(stage > 1 && !client?._id) {
		setStage(1);
	}

	if(stage > 2 && !markets?.length) {
		setStage(2);
	}

	if(stage > 4) {
		setStage(4);
	}

	const handleMarketCheck = (market) => {
		setMarkets(m => {
			const idx = m.findIndex(n => n._id === market._id);
			if(idx >= 0) {
				m.splice(idx, 1);
				return [...m]
			}
			return [...m, market];
		});
	}

	const handlePreviewInvoices = async () => {
		const invs = markets.map(market => ({ market, client }));
		setInvoices(invs);
		setStage(4);
	}

	return (<>
		<h1>Create a new invoice</h1>
		<hr />
		{ stage === 1 && 
			<Widget title="Step 1: Select Client">
				<Container>
					<Row>
						<Col md={{span:6, offset: 3}}>
							<ClientSelector onChange={setClient} />
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button className="float-end" size="sm" onClick={() => setStage(2)} disabled={!client?._id}>Select Markets <FaAngleDoubleRight /></Button>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
		{
			stage === 2 &&
			<Widget title="Step 2: Select Markets">
				<Container>
					<Row>
						<Col md={{span:6, offset: 3}}>
							<Table size="sm">
								<thead>
									<tr><th /><th>Market name</th></tr>
								</thead>
								<tbody>
									{ !!client?.markets?.length && client?.markets.map(m => 
										<tr key={`job-${m._id}`}>
											<td><Form.Check defaultChecked={markets.findIndex(n => n._id === m._id) >= 0} onChange={() => handleMarketCheck(m)} /></td>
											<td>{m.name}</td>
										</tr>)}
									{ !client?.markets?.length && <tr><td colSpan={3}>No markets found</td></tr>}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button variant="link" className="float-left" size="sm" onClick={() => setStage(1)}><FaAngleDoubleLeft /> Select Client</Button>
							<Button className="float-end" size="sm" onClick={() => setStage(3)} disabled={!markets?.length}>Select Date <FaAngleDoubleRight /></Button>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
		{
			stage === 3 &&
			<Widget title="Step 3: Select Date">
				<Container>
					<Row>
						<Col md={{span:6, offset: 3}}>
							<p>Includes all complete jobs prior to and including the selected date.</p>
						</Col>
					</Row>
					<Row>
						<Col md={{span:6, offset: 3}}>
							<DateSelectionCalendar date={cutoffDate} onChange={setCutoffDate} />
						</Col>
					</Row>
					<Row>
						<Col className="mt-2" md={{span:6, offset: 3}}>
							<Button variant="link" className="float-left" size="sm" onClick={() => setStage(2)}><FaAngleDoubleLeft /> Select Markets</Button>
							<Button className="float-end" size="sm" onClick={handlePreviewInvoices} disabled={!markets?.length}>Preview Invoices <FaAngleDoubleRight /></Button>
						</Col>
					</Row>
				</Container>
			</Widget>
		}
		{
			stage === 4 &&
			<Widget title="Step 4: Preview Invoices">
				<Accordion defaultActiveKey={`invoice-preview-0`} flush>
					{ !!invoices.length && invoices.map((inv, i) => <InvoicePreview eventKey={`invoice-preview-${i}`} key={`invoice-preview-${i}`} {...inv} cutoffDate={cutoffDate} />) }
					{/* <SaveButton disabled className="float-end mt-2" variant="primary">Save All</SaveButton> */}
				</Accordion>
			</Widget>
		}
		{ stage === 5 && 
			<Widget title="Successfully created invoices!">

			</Widget>
		}
	</>)
};

export default InvoiceForm;