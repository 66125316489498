import { BadRequest } from "Errors";
import NoApiError from "Errors/NoApiError";
import session from "../utils/session";
import sessionApi from "./sessionsApi";
import NotAuthorizedError from "Errors/NotAuthorizedError";

export const API_DOMAIN = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "/v1/api";

export const commonHeaders = () => {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const token = sessionApi.getSession().token;

	if(!!token) {
		headers["Authorization"] = `bearer ${token}`;
	}

	return headers;
}

export const generateOptions = (options = {}) => {
	return {
		...options,
		headers: {
			...options.headers,
			...commonHeaders()
		}
	};
}

export const apiFetch = async (path = "", options = {}) => {
	let result;
	try {
		result = await fetch(`${API_DOMAIN}${path}`, generateOptions(options));
	} catch(error) {
		if(error.message === "Failed to fetch") {
			throw new NoApiError();
		}
	}
	
	switch(result.status) {
		case 401: {
			session.setCookie("pulsar.location", { href: window.location.href });
			sessionApi.logout();
			window.location.href = "/users/login";
			return Promise.reject(`Invalid credentials for ${path}`);
		}
		case 400: {
			const errorResponse = await result.json();
			console.log({errorResponse});
			throw new BadRequest("There was a problem with the request.", errorResponse);
		}
		case 403: {
			throw new NotAuthorizedError();
		}
		case 500:
		case 404: {
			const message = await result.text();
			throw new Error(message);
		}
		case 204: {
			const message = await result.text();
			return message;
		}
		default: {
			return result.json();
		}
	}
}