const roadMap = {
	"street": "St",
	"drive": "Dr",
	"avenue": "Ave",
	"parkway": "Pkwy",
	"court": "Ct",
	"highway": "Hwy",
	"mount": "Mt",
	"place": "Pl",
	"lane": "Ln"
}

const abbreviateCardinalDirection = (p1 = "", p2, p3) => `${p1}${p2.substring(0,1).toUpperCase()}${p3}`;
const abbreviateCompoundDirection = (p1 = "", p2, p3) => `${p1}${p2.substring(0,1)}${p2.substring(5,6)}${p3}`.toUpperCase();
const abbreviateRoad = (p1 = "", p2, p3) => `${p1}${roadMap[p2.toLowerCase()]}${p3}`;

const abbreviations = {
	"south": abbreviateCardinalDirection,
	"east": abbreviateCardinalDirection,
	"north": abbreviateCardinalDirection,
	"west": abbreviateCardinalDirection,
	"northeast": abbreviateCompoundDirection, 
	"northwest": abbreviateCompoundDirection,
	"southwest": abbreviateCompoundDirection,
	"southeast": abbreviateCompoundDirection,
	"street": abbreviateRoad,
	"drive": abbreviateRoad,
	"avenue": abbreviateRoad,
	"parkway": abbreviateRoad,
	"court": abbreviateRoad,
	"highway": abbreviateRoad,
	"mount": abbreviateRoad,
	"place": abbreviateRoad,
	"lane": abbreviateRoad
}

const abbreviateMatches = (str, p1, p2, p3) => {
	const match = abbreviations[p2.toLowerCase()];
	if(typeof(match) === typeof(() => {}))
		return match(p1, p2, p3);
	return str;
}

export const abbreviate = s => !!s ? s
	.replaceAll(new RegExp(`(^|\\s+)?(${Object.keys(abbreviations).join("|")})(\\s+|$)`, 'gmi'), abbreviateMatches) : s;