import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PayrollApi } from "../../api";
import { AsyncButton, LoadingIndicator } from "../Shared";
import Payroll from "./Payroll";
import { Breadcrumb, Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

const PayrollDisplay = ({activeUser = {}}) => {
	const { payrollId } = useParams();

	const [payroll, setPayroll] = useState({});
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState("");

	useEffect(() => {
		async function fetchData () {
			setLoading(true);
			try {
				const result = await PayrollApi.read(payrollId)

				if(!result?._id) {
					window.location.href = "/payroll";
				}
				setPayroll(result);
				setStatus(result.status);
			}
			catch(err) {
					console.error(err);
			}
			finally {
					setLoading(false);
			}
		}
		fetchData();
	}, [payrollId]);

	const handleStatusChange = async (newStatus) => {
		await PayrollApi.update(payrollId, { status: newStatus });
		setStatus(newStatus)
	}

	if(!!loading) {
		return <LoadingIndicator />;
	}

	return <>
		<Row>
			<Col>
				<Breadcrumb>
					<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
					<Breadcrumb.Item href={`/payroll`}>Payroll</Breadcrumb.Item>
					<Breadcrumb.Item href={`/payroll/${payrollId}`}>{payrollId}</Breadcrumb.Item>
				</Breadcrumb>  
			</Col>
		</Row>
		<Row className="mb-3">
			<Col>
				<ButtonGroup className="float-end">
					{ status === "new" && <AsyncButton size="sm" onClick={() => handleStatusChange("ready")}>Move to Ready status</AsyncButton> }
					{ status === "ready" && <AsyncButton size="sm" onClick={() => handleStatusChange("paid")}>Mark payroll Paid</AsyncButton> }
					{ status === "paid" && <Button size="sm" disabled><FaCheck /> Paid</Button> }
				</ButtonGroup>
			</Col>
		</Row>
		<Row>
			<Col>
				<Payroll payroll={payroll} activeUser={activeUser} />
			</Col>
		</Row>
	</>
}

export default PayrollDisplay;