import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { getValue } from "../../utils/formHelpers";
import { AsyncButton } from "../Shared";

type Props = {
	name?: React.ReactElement | string,
	placeholder?: string,
	value?: string
	onChange?: (value?:string) => Promise<void> | void,
}

const FreeSearchFilter = (props:Props) => {
	const {
		name = "FreeText",
		placeholder = "Start typing...",
		value,
		onChange = () => {}
	} = props;
	const [text, update] = useState(value);
	const [edit, setEdit] = useState<boolean>(false);

	const submit = () => { setEdit(false); onChange(text); }
	const focus = (e:React.FocusEvent<HTMLInputElement>) => e.target.select();
	const cancel = () => { update(value); setEdit(false); };
	const clear = () => { update(undefined); onChange(undefined);}

	useEffect(() => update(value), [value]);

	if(!edit) {
		return (
			<ButtonGroup>
				<Button variant="outline-primary" size="sm" onClick={() => setEdit(true)}>
					<>{ name } { value }</>
				</Button>
				{ !!text && <Button size="sm" variant="outline-secondary" onClick={clear}><FaTimes /></Button>}
			</ButtonGroup>
		)
	}
	
	const onKeyDown = (e:React.KeyboardEvent<HTMLElement>) => {
		switch(e.key) {
			case "Enter": // Enter
				e.preventDefault(); 
				e.stopPropagation();
				submit();
				break;
			case "Escape": // Escape
				cancel();
				break;
			default:
				break;
		}
	}

	return (
		<Form.Group onKeyDown={onKeyDown} className="free-search-filter">
			<InputGroup>
				<Form.Control placeholder={placeholder} onFocus={focus} value={text} autoFocus size="sm" onChange={(e) => update(getValue(e))} />
				<AsyncButton variant="outline-success" size="sm" onClick={submit} />
				<Button variant="outline-danger" size="sm" onClick={cancel}><FaTimes /></Button>
			</InputGroup>
		</Form.Group>
	);
}

export default FreeSearchFilter;