import { JobTemplateApi } from "api";
import { JobTemplate } from "api/Types";
import React from "react";
import Typeahead from "./Typeahead";
import type { Suggestion } from "./Typeahead";

type Props = {
	onChange?: (template?:JobTemplate) => void,
	value?: JobTemplate
}

const getTypeaheadSuggestions = async (query = "") => {
	const response = await JobTemplateApi.filter({ text: query });
	return response?.results?.map(t => ({ text: t.name, id: t._id } as Suggestion))
}

const getTemplateDetails = async (id = "") => {
	const response = await JobTemplateApi.read(id);
	return response;
}

const JobTemplateSelector = (props:Props) => {
	const { 
		onChange = () => console.warn("No on change handler set for JobTemplateSelector component."),
		value = {}
	} = props;
	return <>
		<Typeahead 
			getDetails={getTemplateDetails} 
			placeholder="Start typing a template name..."
			getSuggestions={getTypeaheadSuggestions}
			onChange={onChange}
			value={{ text: value?.name, id: value?._id }}
		/>
	</>
}

export default JobTemplateSelector;