import React from "react";
import { FilterBar } from "../Shared";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { FreeSearchFilter, RadioFilter } from "Components/Filter";
import type { ClientFilter as ClientFilterType, SortOption } from "../../api/Types";

type Props = {
	filter: ClientFilterType,
	sortOptions: SortOption[],
	onChange: (filter?:ClientFilterType) => Promise<void> | void
}

const ClientFilter = (props:Props) => {
	const { 
		filter = {},
		sortOptions = [],
		onChange = () => {} 
	} = props;

	const filterKeys = Object.entries(filter)?.filter(([_, value])=> typeof(value) !== typeof(undefined));
	return (<>
		<FilterBar>
			<FreeSearchFilter 
				name={<FaSearch />}
				value={filter?.query} 
				onChange={(query) => onChange({...filter, query})}
			/>
			{!!filterKeys?.length && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
			<span style={{marginLeft: "auto"}}>
				{!!sortOptions.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions.map(so => ({...so}))} value={filter?.sort || sortOptions[0]?.key || ""} name="Sort" />}
			</span>
		</FilterBar>
	</>)
}

export default ClientFilter;