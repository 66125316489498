import React from "react";
import { Typeahead } from "."
import { RoleApi } from "../../api";

const RoleLookup = (props) => {
	const {
		onChange = (r) => { console.log(r) },
		placeholder = "Type to find a role",
		value = {},
		requiresTSANumber = false,
		...typeAheadProps
	} = props;
	return (
		<Typeahead 
			{...typeAheadProps}
			value={{text: value.name, id: value._id}}
			getDetails={async (id) => RoleApi.read(id)}
			getSuggestions={async (text) => { const result = await RoleApi.filter({ text }); return result.results?.map(r => ({ text: r.name, id: r._id })); }}
			onChange={(r) => onChange(r)}
			placeholder={placeholder}
		/>
	)
}

export default RoleLookup;