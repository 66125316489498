import React from "react";
import { Row, Col } from "react-bootstrap";
import Welcome from "../Components/Welcome";
import JobBoard from "../Components/Job/JobBoard";
import { isPermitted } from "../utils/permissions";

const HomePage = ({ activeUser = {} }) => {
  return (
		<div className="home-page">
		{ (!activeUser?._id || !isPermitted(activeUser, "viewJobs")) && 
			<Row>
				<Col>
					<Welcome />
				</Col>
			</Row>
		}
		{	!!activeUser?._id && isPermitted(activeUser, "viewJobs") &&
			<>
				<Row>
					<Col>
						<JobBoard activeUser={activeUser} infiniteScroll={true} />
					</Col>
				</Row>
			</>
		}
		</div>
  );
};

export default HomePage;
