export const getValue = ({target: { value = '' }}, type = String) => {
	if(value === '') {
		return '';
	}
	return type(value);
}

/**
 * @param {Object} event - the form event
 * @param {Number?} min - the minimum value allowed (default: undefined)
 * @param {Number?} max - the maximum value allowed (default: undefined)
 * @returns the string converted to a number between the optional min and max values
 */
export const getNumber = ({target: { valueAsNumber, value }}, min = null, max = null) => {
	if(value === '') {
		return null;
	}
	if(Number.isNaN(valueAsNumber)) {
		const number = new Number(value);
		if(Number.isNaN(number)) {
			return value;
		}
		return +number;
	}
	if(typeof(min) === typeof(2)) {
		valueAsNumber = Math.max(min, valueAsNumber);
	}
	if(typeof(max) === typeof(2)) {
		valueAsNumber = Math.min(max, valueAsNumber);
	}
	return +valueAsNumber;
}

export const isChecked = ({target: { checked = false }}) => checked;



export const extractFormValues = formElements => {
	const arrElements = Array.prototype.slice.call(formElements);
	
	//we convert elements/inputs into an array found inside form element
	//we need to extract specific properties in Constraint Validation API using this code snippet
	const formValues = arrElements
		.filter(elem => elem.name.length > 0)
		.map(x => {
			const { typeMismatch } = x.validity;
			const { name, type, value } = x;
			return {
				name,
				type,
				typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
				value,
				valid: x.checkValidity()
			};
		})
		.reduce(
			(acc, currVal) => { //then we finally use reduce, ready to put it in our state
				const { value, valid, typeMismatch } = currVal;
				//we'll need to map these properties back to state so we use reducer...
				acc[currVal.name] = {
				value,
				valid,
				typeMismatch
			};
			return acc;
		}, {});

		return formValues;
}