import React, { useEffect, useState } from "react";
import { UserLookup } from "../Form";
import { Widget } from "../Shared";
import { Image, InputGroup, Button, Table, Form, Alert, Popover, OverlayTrigger } from "react-bootstrap";
import { FaTimesCircle, FaTruck, FaPlus, FaExclamationTriangle } from "react-icons/fa";
import "./JobAssignment.css";
import format from "../../utils/format";
import { getNumber } from "../../utils/formHelpers";
import { isPermitted } from "utils/permissions";
import TSANumberWarningTooltip from "Components/Shared/Tooltips/TSANumberWarningTooltip";

const iconStyle = {cursor: "pointer", display: "inline-block"};
// const cannotModifiyStatuses = ["cancelled", "bill", "archive", "complete"];

const JobAssignment = ({
	drivers: initialDrivers = [],
	onChange = async () => {},
	canDeleteDriver = () => { return true; },
	requiresTSANumber = false,
	activeUser,
	canEdit = false
}) => {
	const [drivers, setDrivers] = useState(initialDrivers);
	const [tempDrivers, setTempDrivers] = useState(initialDrivers);
	const [edit, setEdit] = useState(false);
	const [error, setError] = useState("");
	const [warning, setWarning] = useState("");

	const save = async () => {
		const driverSet = new Set(tempDrivers.filter(d => d?.driver?._id).map(d => d?.driver?._id));
		if(driverSet.size !== tempDrivers.length) {
			throw new Error("A driver can only be added once.");
		}
		const drivers = await onChange(tempDrivers);
		setDrivers(drivers);
	}

	const onError = (e) => {
		setError(e.message)
	}

	const addDriver = async () => {
		const isFirst = tempDrivers.length === 0;
		const newDrivers = [
			...tempDrivers, 
			{
				isCurrent: isFirst, 
				percent: isFirst ? 1 : 0 
			}];
		setTempDrivers(newDrivers);
	}

	const changeTempDrivers = index => (driver) => {
		const newDrivers = [...tempDrivers];
		if(!!driver.isCurrent)
			newDrivers.forEach((d) => d.isCurrent = false);
		newDrivers[index] = { ...newDrivers[index], ...driver };

		const completion = newDrivers.reduce((acc, v) => acc+v.percent, 0);
		if(completion > 1) {
			setError("Completion cannot total more than 100%");
		} else {
			setError("");
		}

		if(completion < 1) {
			setWarning("Completion is not 100%.")
		} else {
			setWarning("");
		}

		setTempDrivers(newDrivers);
	}

	const deleteDriver = index => () => {
		if(!canDeleteDriver(tempDrivers[index]?.driver?._id))
		{
			setError(`Charges for ${tempDrivers[index]?.driver?.name} must be removed before they can be unassigned.`);
			return;
		}
		const newDrivers = [...tempDrivers];
		newDrivers.splice(index, 1);
		setTempDrivers(newDrivers);
	}

	return (
		<Widget canEdit={canEdit} className="job-assignment" title={"Assignment"} onSubmit={save} onError={onError} onEdit={setEdit}>
			<Table className="mb-0" borderless size="sm">
				<thead className="border-bottom">
					<tr>
						<th>Driver</th>
						<th></th>
						<th className="text-end">Completion<sup>*</sup></th>
					</tr>
				</thead>
				<tbody>
					{!!edit && tempDrivers.map((d,i) => <DriverRow requiresTSANumber={requiresTSANumber} key={`driver-${i}`} {...d} edit={true} onChange={changeTempDrivers(i)} onDelete={deleteDriver(i)} />)}
					{!!edit && !!error && <tr><td colSpan={3}><Alert variant="danger" onClose={() => setError("")} dismissible>{error}</Alert></td></tr>}
					{!!edit && !!warning && <tr><td colSpan={3}><Alert variant="warning" onClose={() => setWarning("")} dismissible>{warning}</Alert></td></tr>}
					{!!edit && <tr><td colSpan={3} className="text-center border-top"><Button size="sm" onClick={addDriver}><FaPlus /> Add driver</Button></td></tr>}
					{!edit && drivers.map((d,i) => <DriverRow requiresTSANumber={requiresTSANumber} key={`driver-${i}`} {...d} />)}
					{!edit && !drivers.length && <tr><td className="text-center" colSpan={3}>No drivers assigned.</td></tr>}
				</tbody>
				<tfoot className="border-top pt-1">
					<tr><td colSpan="3" className="text-muted border-0 pt-0 pb-0"><small><FaTruck /> currently assigned driver</small></td></tr>
					<tr><td colSpan="3" className="text-muted border-0 pt-0 pb-0"><small><b>*</b> Percent of job completed by this driver</small></td></tr>
				</tfoot>
			</Table>
		</Widget>
	);
}

const DriverRow = ({ 
	driver: initialDriver = {}, 
	percent: initialPercent = 0,
	isCurrent = false, 
	edit = false,
	onChange = () => {},
	onDelete = () => {},
	requiresTSANumber = false
}) => {
	const [driver, setDriver] = useState(initialDriver);
	const [percent, setPercent] = useState(initialPercent);

	useEffect(() => {
		onChange({ driver, percent });
	}, [driver, percent]); // eslint-disable-line react-hooks/exhaustive-deps

	if(!!edit) {
		return (
			<tr key={`driver-${driver._id}`}>
				<td className="d-flex align-middle">
					<Button variant="light" size="sm" onClick={onDelete} style={iconStyle}><FaTimesCircle /></Button>
					<UserLookup requiresTSANumber={requiresTSANumber} className="flex-fill ms-1" placeholder="Find a driver" size="sm" value={driver} onChange={({_id, picture, name}) => setDriver({ _id, picture, name })} />
				</td>
				<td className="text-center">
					<Button size="sm" disabled={isCurrent} onClick={() => onChange({isCurrent: true })}><FaTruck /></Button>
				</td>
				<td className="text-end">
					<InputGroup size="sm">
						<Form.Control className="text-end" value={Math.round(percent * 100)} onChange={e => setPercent(getNumber(e) / 100)} type="number" min={0} max={100} />
						<InputGroup.Text>%</InputGroup.Text>
					</InputGroup>
				</td>
			</tr>
		)
	}

	return (
		<tr key={`driver-${driver._id}`}>
			<td><Image referrerPolicy="no-referrer" roundedCircle height="20px" src={driver.picture} /> {driver.name}</td> 
			<td className="text-center">
				{!!isCurrent && <FaTruck title="Current driver" />}
				{!!requiresTSANumber && !((driver?.tsaNumber ?? "").trim()) && <span className="ms-1"><TSANumberWarningTooltip /></span>
				}
			</td>
			<td className="text-end">{format.toPercent(percent)}</td>
		</tr>
	)
}

export default JobAssignment;