import React from "react";
import { FilterBar } from "../Shared";
import { FaTimes } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { ClientFilter } from "../Filter/TypeaheadFilter";
import { FaPlusSquare } from "react-icons/fa";
import { RadioFilter } from "Components/Filter";
import { MultipleRegionFilter } from "Components/Filter/TypeaheadMultipleFilter";

const MarketFilter = ({ 
	filter = {},
	sortOptions = [],
	onChange = () => {} 
}) => {
	const filterKeys = Object.keys(filter)?.filter(k => typeof(filter[k]) !== "undefined");
	return (<>
		<FilterBar>
			<MultipleRegionFilter name="Region" onChange={(regions) => onChange({...filter, regions})} value={filter.regions} />
			<ClientFilter name="Client" onChange={(client) => onChange({...filter, client})} value={filter.client} />
			{!!filterKeys?.length && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
			<span style={{marginLeft: "auto"}}>
				{!!sortOptions.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions} value={filter.sort || sortOptions[0].key} name="Sort" />}
				<Button className="float-end" href="/markets/new" variant="primary" size="sm"><FaPlusSquare /> Create Market</Button>
			</span>
		</FilterBar>
	</>)
}

export default MarketFilter;