import React from "react";
// import { Placeholder } from "react-bootstrap";
import { LoadingIndicator } from ".";

export default ({rows = 10, columns = 10}) => {
	// const trs = [];
	// for (let i = 0; i < rows; i++) {
	// 	const tds = [];
	// 	for (let j = 0; j < columns; j++) {
	// 		tds.push(<td className="placeholder-glow"><Placeholder className="w-100" /></td>);
	// 	}
	// 	trs.push(<tr key={`tr-${i}`}>{tds}</tr>)
	// }
	// return <>{trs}</>

	return <tr><td colSpan={columns} className="text-center"><LoadingIndicator /></td></tr>
}