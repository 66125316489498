import * as Cookies from "js-cookie";
const COOKIE_NAME = "pulsar.timezones"

const multiTimeClockApi = {
	set: (timezones = ["Local"]) => {
		Cookies.remove(COOKIE_NAME);
		Cookies.set(COOKIE_NAME, timezones, { expires: 36500 });
	},
	get: () => {
		const cookie = Cookies.get(COOKIE_NAME);
	
		if (cookie === undefined) {
			return ["Local", "Denver", "Seattle"];
		}

		return JSON.parse(cookie);
	}
}

export default multiTimeClockApi;