import React from "react";
import { Breadcrumb, Button, Card, Col, Row } from "react-bootstrap";
import { defaultReports } from "./ReportView";
import { isPermitted } from "utils/permissions";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const ReportList = ({ activeUser }:{activeUser?:any}) => {
	if(!isPermitted(activeUser, "viewReports")) {
		return <NotAuthorizedError redirect={`/`} redirectMessage="Back to Home" />
	}

	return (
		<>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/reports`}>Reports</Breadcrumb.Item>
					</Breadcrumb>  
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Button href="/reports/view">Create Custom Report</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<ul className="list-unstyled">
						{Object.entries(defaultReports).map(([key,report]) => 
							<li key={key} className="mb-3">
								<Card>
									<Card.Header>
										<h3>{report.name}</h3>
									</Card.Header>
									<Card.Body>
										{report.description}
									</Card.Body>
									<Card.Footer>
										<Button href={`/reports/view/${key}`} className="stretched-link">View</Button>
									</Card.Footer>
								</Card>
							</li>
						)}
					</ul>
				</Col>
			</Row>
		</>
	);
};

export default ReportList;