import React from "react";
import { Outlet } from "react-router-dom";

const InvoicePage = ({activeUser}) => {

  return (
		<div className="invoice-page">
			<Outlet />
		</div>
  );
};

export default InvoicePage;