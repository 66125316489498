import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import UserForm from "../../Components/User/UserForm";
import { UsersApi } from "../../api";
import { LoadingIndicator } from "../../Components/Shared";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import shortId from "utils/shortId";
import { BadRequest } from "Errors";
import { isPermitted } from "utils/permissions";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const UsersEditPage = ({
	activeUser = {}
}) => {
	const { userId } = useParams();
	const [user, setUser] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [validation, setValidation] = useState([]);

  useEffect(() => {
		const fetchUser = async () => {
			try {
				setFetching(false);
				const user = await UsersApi.getUser({ userId });
				if(typeof(user) === typeof(undefined)) {
					throw new Error("Unable to retreive user");
				}
				
				setUser(user);
				setFetching(false);
			}
			catch(err) {
				console.error(err);
				setFetching(false);
			}
		}

		fetchUser();
  }, [userId]);

	if(!isPermitted(activeUser, "admin"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	const removeValidationError = (names = []) => {
		setValidation((v) => v.filter(e => !names.includes(e.param)));
	}

	const handleUserSubmit = async (user) => {
		try {
			console.log("handleUserSubmit", {user});
			const newUser = await UsersApi.updateUser({ ...user, role: user?.role?.map(r => r?._id) });
			setUser(newUser);
			return true;
		} catch(err) {
			if(err instanceof BadRequest)
			{
				setValidation(err.validationErrors?.errors || []);
			}
			throw err;
		}
	}

	if(!!isFetching || !user._id) {
		return (<LoadingIndicator />);
	}

	if(!isFetching && typeof(user) === typeof(undefined)) {
		return (<Navigate to="/users" />);
	}

	return (
		<>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/users`}>Users</Breadcrumb.Item>
						<Breadcrumb.Item href={`/users/${userId}`}>{user?.name ?? userId}</Breadcrumb.Item>
					</Breadcrumb>  
				</Col>
			</Row>
			<Row className="justify-content-md-center">
				<Col>
					<h1>Editing user <a href={`/users/${userId}`}>{user?.name ?? shortId(userId)}</a></h1>
				</Col>
			</Row>
			<Row className="justify-content-md-center">
				<Col>
					<UserForm onSubmit={handleUserSubmit} {...{user, validation, clearValidation: removeValidationError, activeUser}} />
				</Col>
			</Row>
		</>
	)
}

export default UsersEditPage;