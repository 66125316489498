import React from "react";
import { Outlet } from "react-router-dom";

const MarketPage = ({ activeUser }) => {
  return (
		<div className="market-page">
			<Outlet />
		</div>
  );
};

export default MarketPage;