import React, { useState } from "react";
import { Badge } from "react-bootstrap";


type Props = {
	emails?: string | string[]
	expandable?: boolean
}

const EmailList = ({ emails = [], expandable = true }:Props) => {
	const [isOpen, setIsOpen] = useState(false);

	if(!Array.isArray(emails)) {
		emails = [emails as string]
	}
	
	if(!emails.length) {
		return <></>;
	}

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	const text = isOpen ? emails.join(", ") : emails[0];
	const hiddenCount = emails.length - 1;
	const sharedButtonProps = {
		className: "ms-1",
		style: {cursor:"pointer"},
		pill: true,
		size: "sm"
	}

	const canExpand = !!expandable && emails.length > 1;

	return <>
		{text} 
		{canExpand && !isOpen && <Badge onClick={open} {...sharedButtonProps}>+{hiddenCount}</Badge>}
		{canExpand && isOpen && <Badge onClick={close} {...sharedButtonProps}>hide</Badge>}
	</>;
}

export default EmailList;