import { Permission } from "api/Types/Role";
import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { FaExchangeAlt, FaFilter, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import "./PermissionsSelector.css"
import escapeRegExp from "utils/escapeRegExp";
import InformationTooltip from "Components/Shared/Tooltips/InformationTooltip";
import Validation from "./Validation";
import { Validation as ValidationType } from "api/Types";

type Props = {
	value?: string[],
	options?: Permission[],
	onChange?: (newValue:string[]) => void,
	validation?:ValidationType[]
}

const checkName = (name:string, input:string) => {
	var str = input.toLowerCase().substring(0, 3)
	if(name.length === 0 || str.length === 0 || name.toLocaleLowerCase().includes(str))
		return true;
	const regex = new RegExp(escapeRegExp(input || ""), "i")
	return name.match(regex);
}

const emboldenMatch = (name:string, input: string) => {
	const regex = new RegExp(escapeRegExp(input || ""), "i")
	return name.replace(regex, "<strong>$&</strong>");
}

const PermissionsSelector = (props:Props) => {
	const { options = [], value = [], onChange = () => {}, validation } = props;

	const [availableFilter, setAvailableFilter] = useState("");
	const [setFilter, setSetFilter] = useState("");

	const available = options.filter(o => (value.indexOf(o.key || "") ?? -1) === -1);
	const set = options.filter(o => (value.indexOf(o.key || "") ?? -1) >= 0);

	const removeFromList = (key:string, list:string[]) => {
		const permission = options.find(o => o.key === key);
		const dependents = options.filter(o => o.requires?.includes(key)) || [];
		dependents.forEach(d => list = removeFromList(d.key ?? "", list));
		return list.filter(v => v !== key);
	}

	const add = (key:string) => {
		const newValue = new Set([...value, key, ...((options.find(p => p.key === key) ?? {}).requires ?? [])]);
		onChange([...newValue]);
	};
	const remove = (key:string) => {
		const newList = removeFromList(key, value);
		onChange(newList);
	}
	const handleKeyboardEngage = (evt:React.KeyboardEvent, action:()=>void) => {
		switch(evt.key) {
			case "Enter": // Enter
				evt.stopPropagation();
				evt.preventDefault();
				action();
				break;
		}
	}

	return <>
		<Row className="permissions-selector-wrapper m-0">
			<Col className="border border-danger rounded bg-light pt-2 overflow-auto pb-2">
				<Form.Group className="permissions-filter">
					<InputGroup size="sm" hasValidation>
						<Button variant="secondary"><FaFilter /></Button>
						<Form.Control value={availableFilter} onChange={(v) => setAvailableFilter(v.currentTarget.value)} type="text" placeholder="Filter permissions..." />
						{!!availableFilter && <Button title="clear" onClick={() => setAvailableFilter("")} variant="danger"><FaTimes /></Button>}
					</InputGroup>
					<Validation validation={validation} names={[`dropoff.deliverBy`]} />
				</Form.Group>
				<ListGroup className="mt-2">
					{available.filter(p => !!p?.name && checkName(p.name || "", availableFilter)).map(p => 
						<ListGroup.Item key={p.key} tabIndex={0} onKeyDown={e => handleKeyboardEngage(e, () => add(p.key || ""))}>
							<FaPlus style={{cursor: "pointer"}} onClick={() => add(p.key || "")} /> <span style={{cursor: "pointer"}} onClick={() => add(p.key || "")} dangerouslySetInnerHTML={{__html: emboldenMatch(p?.name || "", availableFilter)}} />
							<span className="float-end"><InformationTooltip term={p.name ?? ""} definition={p.description ?? ""} /></span>
						</ListGroup.Item>)}
				</ListGroup>
			</Col>
			<Col md={1} className="border rounded bg-light d-flex align-items-center justify-content-center mx-2"><FaExchangeAlt /></Col>
			<Col className="border border-success rounded bg-light pt-2 overflow-auto pb-2">
				<Form.Group>
					<InputGroup size="sm">
						<Button variant="secondary"><FaFilter /></Button>
						<Form.Control value={setFilter} onChange={(v) => setSetFilter(v.currentTarget.value)} type="text" placeholder="Filter permissions..." />
						{!!setFilter && <Button title="clear" onClick={() => setSetFilter("")} variant="danger"><FaTimes /></Button>}
					</InputGroup>
				</Form.Group>
				<ListGroup className="mt-2">
					{set.filter(p => !!p?.name && checkName(p.name || "", setFilter)).map(p => 
						<ListGroup.Item key={p.key} tabIndex={0} onKeyDown={e => handleKeyboardEngage(e, () => remove(p.key || ""))}>
							<FaMinus style={{cursor: "pointer"}} onClick={() => remove(p.key || "")} /> <span style={{cursor: "pointer"}} onClick={() => remove(p.key || "")} dangerouslySetInnerHTML={{__html: emboldenMatch(p?.name || "", setFilter)}} /> 
							<span className="float-end"><InformationTooltip term={p.name ?? ""} definition={p.description ?? ""} /></span>
						</ListGroup.Item>)}
				</ListGroup>
			</Col>
		</Row>
	</>;
}

export default PermissionsSelector;