import { apiFetch } from "./api";
import { IApiFilter } from "./Types";
import { Report } from "./Types/Report";

export type ReportFilter = IApiFilter & {
	
};

export const preview = async (report:Report) : Promise<Report> => {
	const response:Report = await apiFetch(`/reports/actions/preview`, {
		method: "POST",
		cache: "no-cache",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(report)
	});
	return response;
}