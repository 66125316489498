import React, { useState } from "react";
import { SelectFilter, FreeSearchFilter, RangeFilter, DateRangeFilter, RadioFilter } from "../Filter";
import { FilterBar } from "../Shared";
import { FaPlusSquare, FaSearch, FaTimes } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { MultipleMarketFilter, MultipleRegionFilter, MultipleUserFilter } from "../Filter/TypeaheadMultipleFilter";
import options from "./JobFields";
import { TableSettingsEditor } from "../Shared";
import { ClientFilter, MarketFilter } from "../Filter/TypeaheadFilter";
import { getDisplay } from "./JobStatus";
import { isPermitted } from "utils/permissions";

export const AVAILABLE_STATUSES = [
	"review",
	"assign",
	"pickup",
	"waiting",
	"deliver",
	"hold",
	"complete",
	"bill",
	"archive",
	"cancelled"
];

const JobFilter = ({ 
	filter = {},
	projection = {},
	order = [],
	sortOptions = [],
	onChange = () => {},
	activeUser
}) => {
	const filterKeys = Object.keys(filter)?.filter(k => typeof(filter[k]) !== "undefined");
	return (<>
		<FilterBar>
			<FreeSearchFilter 
				name={<FaSearch />}
				value={filter.text} 
				onChange={(text) => onChange({...filter, text})}
			/>
			<SelectFilter 
				name="Status" 
				value={filter.status} 
				onChange={(status) => onChange({...filter, status})} 
				options={AVAILABLE_STATUSES}
				display={getDisplay}
			/>
			<RangeFilter
				name="Miles"
				value={{min: filter.minDistance, max: filter.maxDistance}}
				onChange={({min: minDistance, max: maxDistance}) => onChange({...filter, minDistance, maxDistance})}
			/>
			{ isPermitted(activeUser, "viewJobMoney") && <RangeFilter
				name="Charges"
				value={{min: filter.minCharges, max: filter.maxCharges}}
				onChange={({min: minCharges, max: maxCharges}) => onChange({...filter, minCharges, maxCharges})}
			/> }
			<DateRangeFilter 
				name="Delivery Date" 
				value={{start: filter.dropoffAtStartDate, end: filter.dropoffAtEndDate}} 
				onChange={({start: dropoffAtStartDate, end: dropoffAtEndDate}) => onChange({...filter, dropoffAtStartDate, dropoffAtEndDate})}
			/>
			<MultipleUserFilter name="Driver" onChange={drivers => onChange({...filter, drivers })} value={filter.drivers} />
			<MultipleRegionFilter onChange={(regions) => onChange({...filter, regions })} value={filter.regions} />
			<ClientFilter name="Client" onChange={(client) => onChange({...filter, client})} value={filter.client} />
			{!!filter?.client?.markets?.length && <MultipleMarketFilter onChange={(markets) => onChange({...filter, markets})} clientId={filter.client._id} markets={filter.client.markets} />}
			{!!filterKeys?.length && <Button size="sm" variant="outline-secondary" onClick={() => onChange({})}><FaTimes /> Clear</Button>}
			<span style={{marginLeft: "auto"}}>
				{!!sortOptions?.length && <RadioFilter onChange={sort => onChange({...filter, sort})} className="me-2" options={sortOptions} value={filter.sort || sortOptions[0].key} name="Sort" />}
				<Button size="sm" href="/jobs/board" variant="link">Job Board</Button>
				{ isPermitted(activeUser, "createJobs") && <Button size="sm" href="/jobs/new" className="ms-3 me-3"><FaPlusSquare /> Create Job</Button> }
				<TableSettingsEditor 
					onChange={(p) => onChange(filter, p.reduce((a,v) => { a[v.key] = 1; return a; }, {}), p.map(b => b.key))}
					projection={order.filter(key => projection[key] === 1 && isPermitted(activeUser, projection[key].permission)).map(key => ({ key, ...options[key] }))}
					options={Object.keys(options).map(key => ({ key, ...options[key] }))}
				/>
			</span>
		</FilterBar>
	</>)
}

export default JobFilter;