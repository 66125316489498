import React, { useState } from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import {Typeahead} from "../Form";
import ClientApi from "../../api/clientsApi";
import { FaTimes } from "react-icons/fa";
import ClientLookup from "./ClientLookup";

const retrieveClients = async () => {
	const clients = await ClientApi.filter({ projection: "typeahead" });
	return clients.map(c => ({ text: c.name, id: c._id }));
}

const ClientSelector = ({
	hideLabel = true,
	collapsed: initialState = false,
	value = {},
	onChange = () => {},
	...props
}) => {
	const [collapsed, setCollapsed] = useState(initialState);

	const handleChange = (v) => {
		onChange(v);
		setCollapsed(initialState);
	}

	if(collapsed) {
		return (
			<ButtonGroup>
				<Button variant="outline-primary" size="sm" onClick={() => setCollapsed(false)}>
					{ !!value.name ? `Client: ${value.name}` : "Client" }
				</Button>
				{ !!value?._id && <Button size="sm" variant="outline-secondary" onClick={() => handleChange({})}><FaTimes /></Button>}
			</ButtonGroup>
		)
	}

	return(
		<Form.Group {...props}>
			{ !hideLabel && !initialState && <Form.Label>Client</Form.Label> }
			<InputGroup>
				<ClientLookup
					value={value}
					size={initialState ? "sm" : ""}
					fetchAllOnOpen={true}
					onChange={handleChange}
				/>
				{!!initialState && <Button variant="outline-danger" size="sm" onClick={() => setCollapsed(true)}><FaTimes /></Button>}
			</InputGroup>			
		</Form.Group>
	);
}

export default ClientSelector;