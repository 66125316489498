import { AsyncButton, RoleBadge } from "Components/Shared";
import React from "react";
import { Row, Card, Col, Image, Badge, Button, ButtonGroup } from "react-bootstrap";
import { FaRegEnvelope, FaTrash } from "react-icons/fa";
import { GiRobotLeg } from "react-icons/gi";

const UserCard = ({
	_id,
	picture,
	name,
	status,
	email,
	permissions,
	onRemove
}) => {
	return (
		<Card>
			<Card.Header>
				<Row>
					<Col className="text-start">
						{!!picture && <Image referrerPolicy="no-referrer" className="me-2" roundedCircle height="24px" src={picture} />} 
						<a href={`/users/${_id}`} variant="link" size="sm" className="text-decoration-none">{name}</a>
					</Col>
					<Col className="text-end">
							{ !!onRemove && <AsyncButton onClick={onRemove} size="sm" variant="outline-danger"><FaTrash /> Remove</AsyncButton> }
					</Col>
				</Row>
			</Card.Header>
			<Row>
				<Col className="p-2">
					<ul style={{listStyleType: "none"}}>
						<li><RoleBadge permissions={permissions} /></li>
						{!!email && <li style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}><a className="ms-2" href={`mailto:${email}`} className="text-decoration-none"><FaRegEnvelope /> {email}</a> </li>}
					</ul>
				</Col>
			</Row>
		</Card>
	)
}

export default UserCard;