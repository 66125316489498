import { Pagination as BootstrapPagination, Placeholder } from "react-bootstrap"
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";


const getPagination = (skip, limit, totalCount) => ({
	currentPage: calculateCurrentPage(skip, limit),
	totalPages: calculateTotalPages(limit, totalCount),
	pages: buildPages(skip, limit, totalCount)
});

const calculateCurrentPage = (skip, limit) => Math.ceil(skip / limit) + 1;
const calculateTotalPages = (limit, totalCount) => Math.max(1, Math.ceil((totalCount) / (limit || 1)));

const buildPages = (skip, limit, totalCount = 0) => {
	const currentPage = calculateCurrentPage(skip, limit);
	const totalPages = calculateTotalPages(limit, totalCount);
	const count = 5;
	const pages = [];
	let numPages = 0;
	let page = 0;

	let lowerBreak = false;
	let upperBreak = false;

	pages.push(currentPage);
	for(let i = 1; numPages <= count; i++) {
		if(lowerBreak && upperBreak) break;
		if(!lowerBreak) {
			page = currentPage - i;
			if(page > 0) {
				pages.push(page);
				numPages++;
			}
			else {
				lowerBreak = true;
			}
		}
	
		if(!upperBreak) {
			page = currentPage + i;
			if(page <= totalPages)
			{
				pages.push(page);
				numPages++;
			}
			else {
				upperBreak = true;
			}
		}
	}

	return pages.sort((a,b) => a > b ? 1 : -1);
}

const Pagination = ({
	skip = 0, limit = 10, totalCount = 0, setSkip = (s) => {}, loading = false, ...props
}) => {
	if(loading) {
		return (
			<>
				<Placeholder animation="glow" {...props}>
					<BootstrapPagination size="sm">
						<BootstrapPagination.Item disabled><FaAngleDoubleLeft /></BootstrapPagination.Item>
						<BootstrapPagination.Item disabled><FaAngleLeft /></BootstrapPagination.Item>
						{[...Array(3)].map((_,p) => <BootstrapPagination.Item disabled key={`page-${p}`}><Placeholder style={{width:"5Epx"}} size="sm" /></BootstrapPagination.Item>)}
						<BootstrapPagination.Item disabled><FaAngleRight /></BootstrapPagination.Item>
						<BootstrapPagination.Item disabled><FaAngleDoubleRight /></BootstrapPagination.Item>
						<BootstrapPagination.Item disabled><Placeholder size="sm" style={{width:"120px"}} /></BootstrapPagination.Item>
					</BootstrapPagination>
				</Placeholder>
			</>
		)
	}


	const { pages, currentPage, totalPages } = getPagination(skip, limit, totalCount);
	return (
		<BootstrapPagination style={{margin: 0}} size="sm" {...props}>
			<BootstrapPagination.Item onClick={() => setSkip(0)} disabled={currentPage === 1}><FaAngleDoubleLeft /></BootstrapPagination.Item>
			<BootstrapPagination.Item onClick={() => setSkip(skip - limit)} disabled={currentPage === 1}><FaAngleLeft /></BootstrapPagination.Item>
			{pages.map(p => <BootstrapPagination.Item onClick={() => setSkip((p - 1) * limit)} key={`page-${p}`} active={p === currentPage}>{p}</BootstrapPagination.Item>)}
			<BootstrapPagination.Item onClick={() => setSkip(skip + limit)} disabled={currentPage === totalPages}><FaAngleRight /></BootstrapPagination.Item>
			<BootstrapPagination.Item onClick={() => setSkip((totalPages - 1) * limit)} disabled={currentPage === totalPages}><FaAngleDoubleRight /></BootstrapPagination.Item>
			<BootstrapPagination.Item disabled><small>{`showing ${totalCount > 0 ? +skip + 1 : 0} to ${Math.min(skip + limit, totalCount)} of ${totalCount}`}</small></BootstrapPagination.Item>
		</BootstrapPagination>
	);
}

export default Pagination;