import * as Cookies from "js-cookie";
const SESSION_COOKIE_NAME = "pulsar.session"

const session = {
	setSessionCookie: (session) => {
		Cookies.remove(SESSION_COOKIE_NAME);
		Cookies.set(SESSION_COOKIE_NAME, session, { expires: 14 });
	},
	getSessionCookie: () => {
		const sessionCookie = Cookies.get(SESSION_COOKIE_NAME);
	
		if (sessionCookie === undefined) {
			return {};
		}

		return JSON.parse(sessionCookie);
	},
	setCookie: (cookieName, value) => {
		Cookies.set(cookieName, value);
	},
	getCookie: (cookieName) => {
		const cookie = Cookies.get(cookieName);
	
		if (cookie === undefined) {
			return {};
		}

		return JSON.parse(cookie);
	}
}

export default session;