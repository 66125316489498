import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./AutogrowTextarea.css";

const AutogrowTextarea = ({
	text,
	placeholder = "Add text...",
	onFocus,
	onChange
}) => {
	return (
		<div className="autogrow-textarea">
			<Form.Control
				as="textarea" 
				placeholder={placeholder}
				rows={1} 
				value={text} 
				onChange={(e) => onChange(e.currentTarget.value)}
				onFocus={onFocus}
				onInput={(e) => e.currentTarget.parentNode.dataset.replicatedValue = e.currentTarget.value }
			/>
		</div>
	);
}

export default AutogrowTextarea;
						