import { useState } from "react";
import {Button} from "react-bootstrap";
import { FaCheck, FaExclamationTriangle, FaSave } from "react-icons/fa";
import { LoadingIndicator } from "../Shared";

const SaveButton = (props) => {
	const {
		onClick = async () => {},
		onError = () => {},
		onComplete = () => {},
		useButtons = true,
		...additionalProps
	} = props;

	const [state, setState] = useState("ready");

	const handleOnClick = async (e) => {
		e.preventDefault();
		setState("saving");
		try {
			await onClick();
			setState("saved");
			onComplete();
		} catch(e) {
			console.error(e);
			setState("error");
			onError(e);
		} finally {
			setTimeout(() => { setState("ready"); }, 2500);
		}
	}
	
	if(!useButtons) {
		if(state === "saved")
			return (<><FaCheck title="Successfully saved" {...additionalProps} variant="success" disabled={true} />{!!props.children ? " " : ""} {props.children}</>);

			if(state === "error")
				return (<><FaExclamationTriangle title="Unable to save" {...additionalProps} variant="danger" disabled={true} />{!!props.children ? " " : ""}{props.children}</>)
				

		if(state === "saving") 
			return (<><LoadingIndicator title="Saving changes" {...additionalProps} variant="info" disabled={true} />{!!props.children ? " " : ""}{props.children}</>);
			
		return (<>
			<FaSave 
				title="Save changes" 
				style={{cursor: "pointer"}}
				{...additionalProps} 
				onClick={handleOnClick}
			/>{!!props.children ? " " : ""}{props.children}
		</>);
	}

	if(state === "saved")
		return (<Button title="Successfully saved" {...additionalProps} variant="success" disabled={true}>
			<FaCheck />{!!props.children ? " " : ""}{props.children}
		</Button>);

	if(state === "error")
		return (<Button title="Unable to save" {...additionalProps} variant="danger" disabled={true}>
			<FaExclamationTriangle />{!!props.children ? " " : ""}{props.children}
		</Button>)

	if(state === "saving") 
		return (<Button title="Saving changes" {...additionalProps} variant="info" disabled={true}>
			<LoadingIndicator />{!!props.children ? " " : ""}{props.children}
		</Button>);
		
	return (<Button title="Save changes" {...additionalProps} onClick={handleOnClick} type="submit" variant="primary">
		<FaSave />{!!props.children ? " " : ""}{props.children}
		</Button>);
}
			
export default SaveButton;