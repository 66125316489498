import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingIndicator, Widget } from "../Shared";
import * as regionApi from "../../api/regionApi";
import { Alert, Breadcrumb, Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaExclamationTriangle, FaPlus, FaSave, FaTimes } from "react-icons/fa";
import { MarketCard, MarketLookup } from "Components/Market";
import { ConfirmButtons, SaveButton, UserLookup } from "Components/Form";
import { MarketsApi, RegionsApi, UsersApi } from "api";
import { UserCard } from "Components/User";
import marketApi from "api/marketsApi";
import convertToSlug from "utils/convertToSlug";
import { NotFoundPage } from "Pages";

const DriverWidget = ({ regionId }) => {
	const [drivers, setDrivers] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [adding, setAdding] = useState(false);
	const [newDriver, setNewDriver] = useState();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const userResult = await UsersApi.filter({ regionIds: [regionId], limit: 500 });
				setDrivers(userResult.users);
				setCount(userResult?.totalCount || 0);
			}
			catch (error) {
				
			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	const addDriver = async () => {
		const regions = newDriver.regions || [];
		if(regions.find(r => r === regionId)) {
			throw new Error("This driver is already associated with this region.");
		}
		regions.push(regionId);
		const result = await UsersApi.updateUser({
			_id: newDriver._id,
			regions
		});
		setCount(c => c + 1);
		setDrivers(d => [...d, result]);
		setNewDriver({});
		setAdding(false);
	};
	const cancelAddDriver = () => {
		setNewDriver({});
		setAdding(false);
	};

	const removeDriver = async (driver) => {
		if(window.confirm(`Are you sure you want to remove "${driver.name}" from the region?`)) {
			await UsersApi.updateUser({ _id: driver._id, removeRegion: regionId });
			setTimeout(() => {
				setCount(c => c-1);
				setDrivers((driverList) => [...driverList.filter(d => d._id !== driver._id)]);
			}, 500);			
			return;
		}
		throw new Error("User cancelled.");
	}

	return (
		<Widget title={<>Drivers {count > 0 && <>{`(${count})`}</>}</>}>
			{
				!!adding && <>
					<Row className="mb-3">
						<Col>
							<UserLookup user={newDriver} onChange={setNewDriver} />
						</Col>
					</Row>
					<Row className="my-3">
						<Col>
							<ConfirmButtons onConfirm={addDriver} onCancel={cancelAddDriver}	/>
						</Col>
					</Row>
				</>
			}
			{!adding && <div className="d-grid gap2 mb-3"><Button onClick={() => setAdding(true)} size="lg"><FaPlus /> Add Driver</Button></div>}
			{!drivers?.length && loading && <div className="text-center"><LoadingIndicator /></div>}
			{!drivers?.length && !loading && <Alert variant={"warning"}><FaExclamationTriangle /> There are no drivers associated with this region. Add the first one.</Alert>}
			{!!drivers?.length && drivers.map((d, i) => <UserCard {...{...d}} onRemove={() => removeDriver(d)} key={`drivers-${d._id}`} />)}
		</Widget>
	)
}

const MarketWidget = ({regionId}) => {
	const [markets, setMarkets] = useState([]);
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const [adding, setAdding] = useState(false);
	const [newMarket, setNewMarket] = useState();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const marketResult = await MarketsApi.filter({ regionId, projection: "snapshot", limit: 500 });
				setMarkets(marketResult.markets);
				setCount(marketResult?.totalCount || 0);
			}
			catch (error) {
				
			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	const addMarket = async () => {
		if(!!newMarket.region) {
			throw new Error("This market is already associated with a region.");
		}
		setNewMarket({});
		await MarketsApi.update({ marketId: newMarket._id, fields: { region: regionId }});
		setMarkets((marketList) => [...marketList, newMarket]);
		setAdding(false);
	};

	const cancelAddMarket = () => {
		setNewMarket({});
		setAdding(false);
	};

	const removeMarket = async (market) => {
		if(window.confirm(`Are you sure you want to remove the ${market.name} market from the region? It can always be added back.`)) {
			await marketApi.update({ marketId: market._id, fields: { region: "" }});
			setTimeout(() => {
				setCount(c => c-1);
				setMarkets((marketList) => [...marketList.filter(m => m._id !== market._id)]);
			}, 500);			
			return;
		}
		throw new Error("User cancelled.");
	}

	return(
		<Widget title={<>Markets {count > 0 && <>{`(${count})`}</>}</>}>
			{!adding && <div className="d-grid gap2 mb-3"><Button onClick={() => setAdding(true)} size="lg"><FaPlus /> Add Market</Button></div>}
			{
				!!adding && <>
					<Row className="mb-3">
						<Col>
							<MarketLookup onChange={setNewMarket} />
						</Col>
					</Row>
					<Row className="my-3">
						<Col>
							<ConfirmButtons onConfirm={addMarket} onCancel={cancelAddMarket}	/>
						</Col>
					</Row>
				</>
			}
			{!markets?.length && loading && <div className="text-center"><LoadingIndicator /></div>}
			{!!markets && markets.map((m, i) => <MarketCard {...{...m}} onRemove={() => removeMarket(m)} key={`market-${m._id}`} />)}
			{!markets?.length && !loading && <Alert variant={"warning"}><FaExclamationTriangle /> There are no markets associated with this region. Add the first one.</Alert>}
		</Widget>
	);
}

const RegionProfile = ({
	activeUser = {},
	region: initialRegion = {}
}) => {
	const { regionId } = useParams();
	const [region, setRegion] = useState(initialRegion);
	const [name, setName] = useState();
	const [loading, setLoading] = useState(true);
	const [edit, setEdit] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const regionResult = await regionApi.read(regionId);
				setRegion(regionResult);
			}
			catch (error) {

			}
			finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	useEffect(() => {
		if(!region?._id)
			return;
	}, [region]);

	const onError = async () => {
		
	}

	return (
		<>
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href={`/`}>Home</Breadcrumb.Item>
						<Breadcrumb.Item href={`/regions`}>Regions</Breadcrumb.Item>
						<Breadcrumb.Item href={`/regions/${regionId}`}>{region?.name ?? regionId}</Breadcrumb.Item>
					</Breadcrumb>  
				</Col>
			</Row>
			{ !!loading && <LoadingIndicator />}
			{ !loading && !region?._id && <NotFoundPage /> }
			{ !loading && !!region?._id && <>
				<Row>
					<Col>
						<h1>
							{!edit && <>
								{region.name} Region
								<span className="float-end"><Button title="Edit widget" variant="light" size="sm" href={`/regions/${region?.url}/edit`}><FaEdit /></Button></span>
							</> }
						</h1>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col>
						<MarketWidget regionId={region._id} />
					</Col>
					<Col>
						<DriverWidget regionId={region._id} />
					</Col>
				</Row>
			</>}
		</>
	);
};

export default RegionProfile;