import React from "react";
import { Outlet } from "react-router-dom";

const RolePageSwitch = () => {
  return (
		<div className="role-page">
			<Outlet />
		</div>
  );
};

export default RolePageSwitch;