import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Table, Button, Badge } from "react-bootstrap";
import "./DateSelectionCalendar.css";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaCalendarDay, FaCrosshairs, FaTimesCircle } from "react-icons/fa";
import format from "../../utils/format";
import addDays from "utils/addDays";

const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const sameDate = (d1, d2) => {
	if(!d1 || !d2) return false;
	return d1.getDate() === d2.getDate() 
	&& d1.getMonth() === d2.getMonth() 
	&& d1.getFullYear() === d2.getFullYear();
}

const withinRange = (day, range) => {
	if(!range?.start && !range?.end) {
		return false;
	}

	if(!!range?.start && !!range?.end) {
		return day?.getTime() <= range?.end?.getTime() && day?.getTime() >= range?.start?.getTime();
	}

	return sameDate(day, range.start) || sameDate(day, range.end);
}

const propTypes = {
	date: PropTypes.instanceOf(Date),
	onChange: PropTypes.func
};

const DateSelectionCalendar = (props) => {
	let {
		rangeSelection = false,
		date = new Date(),
		range: initialRange = {},
		size = "lg",
		onChange = () => {}
	} = props;
	
	date = date instanceof Date ? date : !!date ? new Date(date) : new Date();
	const [month, setMonth] = useState(date?.getMonth())
	const [year, setYear] = useState(date?.getFullYear())
	const [selectedDate, selectDate] = useState(date);
	const [range, setRange] = useState(initialRange);

	useEffect(() => onChange(rangeSelection ? range : selectedDate), [selectedDate, range]);
	useEffect(() => {
		if(!!props?.date?.getMonth) {
			setMonth(props.date.getMonth());
			setYear(props.date.getFullYear());
		}
	}, [props]);

	const startDate = new Date(year, month, 0);
	const startDay = startDate.getDay();
	const today = new Date();
	const weeks = [];
	const abbrev = size === "sm";

	for(let i = 0; i < 6; i++)
	{
		weeks[i] = weeks[i] || [];
		for(let j = 0; j < 7; j++) {
			weeks[i][j] = addDays(startDate, i * 7 + j - startDay);
		}
	}

	const handleDateSelect = (d) => {
		if(rangeSelection) {
			if((!!range.start && !!range.end) || (!range.start && !range.end) || (!range.start && !!range.end)) {
				setRange({start: d});
				return;
			}
			if(!!range.start && !range.end) {
				if(sameDate(d, range.start)) {
					return;
				}

				// if the new end date is less than the start date, swap them
				if(d < range.start) {
					setRange({start: d, end: range.start});
					return;
				}

				setRange(r => ({...r, end: d}));
				return;
			}
			return;
		}
		setMonth(d.getMonth());
		setYear(d.getFullYear());
		selectDate(d);
	}

	const changeMonth = (months) => {
		if(months === 1 && month === 11) {
			setMonth(0);
			setYear(year + 1);
			return;
		}

		if(months === -1 && month === 0) {
			setMonth(11);
			setYear(year - 1);
			return;
		}

		setMonth(month + months)
	}
	const changeYear = (years) => setYear(year + years);
	const jump = (date) => {
		setMonth(date.getMonth());
		setYear(date.getFullYear());
	}

	const displayMonth = abbrev ? MONTHS[month].slice(0,3) : MONTHS[month];
	const yearText = `${year}`;
	const displayYear = abbrev ? `\`${yearText.slice(2,4)}` : yearText;
	const monthYear = [displayMonth, displayYear].join(abbrev ? '' : ' ');

	return (
		<Table size="sm" bordered className={classNames({"date-selection-calendar": true, [size]: true})}>
			<thead>
				<tr>
					<th className="text-center clickable" onClick={() => changeYear(-1)}><FaAngleDoubleLeft /></th>
					<th className="text-center clickable" onClick={() => changeMonth(-1)}><FaAngleLeft /></th>
					<th colSpan={3} className="text-center">
						<FaCalendarDay title="Jump to today" className="me-2 clickable" onClick={() => jump(today)} />
						{monthYear}
						<FaCrosshairs className="ms-2 clickable" onClick={() => jump(selectedDate || range.start || range.end)} />
					</th>
					<th onClick={() => changeMonth(1)} className="text-center clickable"><FaAngleRight /></th>
					<th onClick={() => changeYear(1)} className="text-center clickable"><FaAngleDoubleRight /></th>
				</tr>
				<tr>
					<th className="text-center">{abbrev ? "U" : "SUN"}</th>
					<th className="text-center">{abbrev ? "M" : "MON"}</th>
					<th className="text-center">{abbrev ? "T" : "TUE"}</th>
					<th className="text-center">{abbrev ? "W" : "WED"}</th>
					<th className="text-center">{abbrev ? "R" : "THU"}</th>
					<th className="text-center">{abbrev ? "F" : "FRI"}</th>
					<th className="text-center">{abbrev ? "S" : "SAT"}</th>
				</tr>
			</thead>
			<tbody>
				{ weeks.map((week, i) => 
					<tr key={`week-${i}`}>
						{week.map((day, j) => {
							const weekday = day.getDay();
							const classes = classNames({
								muted: day.getMonth() !== month,
								range: !!rangeSelection && withinRange(day, range),
								selected: !rangeSelection && sameDate(day, selectedDate),
								today: sameDate(day, today),
								"text-center": 1,
								weekend: weekday === 0 || weekday === 6
							});
							return(
								<td key={`day-${j}`} onClick={() => handleDateSelect(day)} className={classes}>
									{day.getDate()}
								</td>
							)
							
						})}
					</tr>
				)}
			</tbody>
			<tfoot>
				{!!rangeSelection && 
					<tr>
						<td className="text-center" colSpan="7">
							Start Date: {!!range.start ? <Badge bg="secondary" size="sm">{format.date(range.start)} <FaTimesCircle style={{cursor: "pointer"}} onClick={() => setRange(r => ({...r, start: undefined}))} /></Badge> : <span>none</span>}
							&nbsp;
							End Date: {!!range.end ? <Badge bg="secondary" size="sm">{format.date(range.end)}  <FaTimesCircle style={{cursor: "pointer"}} onClick={() => setRange(r => ({...r, end: undefined}))}  /></Badge> : <span>none</span>}
							&nbsp;
							<a className="float-end pe-1" href="#" onClick={(e) => {e.stopPropagation(); e.preventDefault(); setRange({})}}>Clear</a>
						</td>
					</tr>}
			</tfoot>
		</Table>
	)
}

DateSelectionCalendar.propTypes = propTypes;

export default DateSelectionCalendar;