import React, { useState } from "react";
import UserForm from "../../Components/User/UserForm";
import { UsersApi } from "../../api";
import { Col, Row } from "react-bootstrap";
import { BadRequest } from "Errors";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";
import { isPermitted } from "utils/permissions";

const CreateUserPage = ({activeUser = {}}) => {
	const [errors, setErrors] = useState([]);

	if(!isPermitted(activeUser, "admin"))
		return <NotAuthorizedError redirect="/" redirectMessage="Back to home" />

	const handleUserSubmit = async (user) => {
		try {
			// clear any errors
			setErrors([]);
	
			// attempt to create the user
			const result = await UsersApi.createUser(user);
	
	
			// if the user was created, redirect to the new user's profile
			if(result?._id) {
				window.location = `/users/${result._id}`;
				return;
			}
	
			setErrors(result?.validationErrors?.errors || []);
		} catch (error) {
			if(error instanceof BadRequest)
			{
				setErrors(error?.validationErrors?.errors || []);
				throw new Error("Invalid user provided. Please review the submission and try again.");
			}
		}
	}

	const removeValidationError = (names = []) => {
		setErrors((v) => v.filter(e => !names.includes(e.param)));
	}

	return (
		<>
			<Row className="justify-content-md-center">
				<Col xs={10} sm={8}>
					<h1>Add User</h1>
				</Col>
			</Row>
			<Row className="justify-content-md-center">
				<Col xs={10} sm={8}>
					<UserForm onSubmit={handleUserSubmit} validation={errors} clearValidation={removeValidationError} />
				</Col>
			</Row>
		</>
	)
}

export default CreateUserPage;