import React from "react";
import { Outlet } from "react-router-dom";

const ClientsPage = ({ activeUser }) => {
  return (
		<div className="client-page">
			<Outlet />
		</div>
  );
};

export default ClientsPage;