import React from "react";
import {Typeahead} from "../Form";
import ClientApi from "../../api/clientsApi";

const retrieveClients = async () => {
	const clients = await ClientApi.filter({ projection: "typeahead", limit: 500 });
	return clients.results.map(c => ({ text: c.name, id: c._id }));
}

const ClientLookup = ({
	onChange = () => {},
	value = {},
	...props
}) => (
	<Typeahead
		value={{ id: value._id, text: value.name }}
		fetchAllOnOpen={true}
		getDetails={async (id) => await ClientApi.read(id, "typeaheadSelection") }
		getSuggestions={retrieveClients}
		getDisplayValue={(value) => (typeof(value) === typeof({}) ? value.text : value)}
		inputDelay={0}
		placeholder="Begin typing..."
		onChange={onChange}
		{...props}
	/>
);

export default ClientLookup;