import { apiFetch } from "./api";
import { filterParameters, updateWindowLocation } from "./helpers";

export const preview = async (excludeJobs, endDate) => {
	const response = await apiFetch(			
		`/payroll/preview`,
		{
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({excludeJobs, endDate})
		}
	);
	return response;
}

export const update = async (payrollId, body = {}) => {
	const response = await apiFetch(			
		`/payroll/${payrollId}`,
		{
			method: "PUT",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		}
	);
	return response;
}

export const submit = async (excludeJobs, endDate) => {
	const response = await apiFetch(			
		`/payroll`,
		{
			method: "POST",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({excludeJobs, endDate})
		}
	);
	return response;
}

export const read = async (payrollId) => {
	const response = await apiFetch(`/payroll/${payrollId}`);
	return response;
}

export const filter = async (apiParams) => {	
	const params = [];

	Object.keys(apiParams).forEach(k => {
		if(apiParams[k] instanceof Date) {
			apiParams[k] = apiParams[k].toISOString();
			return;
		}
	});

	Object.entries(apiParams)
		.filter(filterParameters)
		.forEach(([key,value]) => params.push([key, value]));

	const qs = new URLSearchParams(params).toString();

	const response = await apiFetch(`/payroll?${qs}`);

	updateWindowLocation(qs);

	return response;
}