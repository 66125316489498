import { kStringMaxLength } from "buffer";

const findDifferences = (changedObject:any = {}, originalObject:any = {}) : Object => {
	const output:any = {};
	if(!changedObject) return {};
	if(!originalObject) return changedObject;
	const changedObjectKeys = Object.keys(changedObject);
	const originalObjectKeys = Object.keys(originalObject);
	const matchedKeys = changedObjectKeys.filter(k => originalObjectKeys.includes(k));

	// add all values that are new
	changedObjectKeys
		.filter(k => !originalObjectKeys.includes(k) || (typeof(originalObject[k]) === typeof(undefined) && typeof(changedObject) !== typeof(undefined)))
		.forEach(k => output[k] = changedObject[k]);

	// add all values that have changed
	matchedKeys.forEach(k => {
		if(typeof(changedObject[k]) === typeof({}) && typeof(originalObject[k]) === typeof({})) {
			const diff = findDifferences(changedObject[k], originalObject[k]);
			if(Object.keys(diff).length) {
				output[k] = diff;
			}
		}

		// update any non-object parameters
		if((typeof(changedObject[k]) !== typeof({}) || changedObject[k] instanceof Date) && changedObject[k] !== originalObject[k]) {
			output[k] = changedObject[k];
		}
	});

	return output;
}

export default findDifferences;