import React from "react";
import { Badge } from "react-bootstrap";

const getType = status => {
	switch(status) {
		case "pickup":
			return "warning";
		case "deliver":
			return "success";
		case "assign":
		case "review":
			return "info";
		case "complete":
		case "bill":
		case "archive":
			return "dark";
		case "hold":
		case "waiting":
			return "danger";
		default:
			return "secondary";
	}
}

const getText = status => {
	switch(status) {
		case "pickup":
			return "dark";
		default: 
			return "";
	}
}

export const getDisplay = status => {
	switch(status) {
		case "pickup":
			return "ready for pickup";
		case "deliver":
			return "out for delivery";
		case "complete":
			return "delivered";
		case "bill": 
			return "billed";
		case "archive":
			return "archived";
		default:
			return status;
	}
}

export default ({ status, workflow={}, ...props}) => {
	return <Badge {...props} text={getText(status, workflow)} bg={getType(status)}>{getDisplay(status)}</Badge>
}