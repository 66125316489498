import { Pickup, Validation as ResultValidation } from "api/Types";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { getValue } from "utils/formHelpers";
import AddressSelector from "./AddressSelector";
import DateTimeSelect from "./DateTimeSelect";
import Validation from "./Validation";

type Props = {
	value?: Pickup,
	onChange?: (pickup?: Pickup) => void,
	validation?: ResultValidation[],
	clearValidation?: (names?:string[]) => void,
	disabled?: boolean,
	name?: string
}

const PickupForm = ({ 
	value = {}, 
	onChange = () => ({} as Pickup),
	validation = [] as ResultValidation[],
	clearValidation = () => {},
	disabled = false,
	name = ""
}:Props) => {
	const handleChange = (p:Pickup) => {
		onChange({...value, ...p});
	}
	const buildName = (n: string) => [name, n].filter(s => !!s).join(".");

	return (<>
		<h3>Pickup</h3>
		<Form.Group className="mb-3">
			<Form.Label>Ready at</Form.Label>
			<InputGroup size="sm" hasValidation>
				<DateTimeSelect 
					value={value?.readyAt} 
					onChange={(date:Date) => handleChange({ readyAt: date })} 
					isInvalid={!!validation?.find(v => v.param === buildName("readyAt"))}
					disabled={disabled}
				/>
				<Validation validation={validation} names={[buildName("readyAt")]} />
			</InputGroup>			
			<Form.Label>Pickup Note</Form.Label>
			<InputGroup size="sm" hasValidation>
				<Form.Control
					placeholder="Add a note specifically for pickup"
					type="text"
					value={value?.note || ""}
					onChange={evt => handleChange({ note: getValue(evt) })}
					isInvalid={!!validation?.find(v => v.param === buildName("note"))}
					disabled={disabled}
				/>
				<Validation validation={validation} names={[buildName("note")]} />
			</InputGroup>
			<AddressSelector {...{
				address: {...value}, 
				validation, 
				name: name || "",
				key: "pickup",
				onChange: a => handleChange({ ...a }),
				disabled
			}} />
		</Form.Group>
	</>)
}

export default PickupForm;