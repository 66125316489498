import React from "react";
import { Outlet } from "react-router-dom";

const RegionPage = ({ activeUser = {} }) => {
  return (
		<div className="region-page">
			<Outlet />
		</div>
  );
};

export default RegionPage;