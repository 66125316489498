const hasPermission = (permissions = [], permission) => {
	if(typeof(permission) !== typeof("") || !permission || typeof(permissions) !== typeof([]))
		return true;

	return permissions.indexOf(permission) > -1;
}

export const isPermitted = (user, action) => {
	if(hasPermission(user?.permissions, "admin"))
		return true;
	return hasPermission(user?.permissions, action);
}

export const canEditJob = (user, jobStatus) => {
	// can the user edit the job if it is "frozen"?
	if(jobStatus === "bill" || jobStatus === "archive")
		return isPermitted(user, "editFrozenJob");
	// can the user edit jobs?
	return isPermitted(user, "editJobs")
}