import React from "react";
import {Typeahead} from "../Form";
import { RegionsApi } from "api";
import type { Region } from "api/Types";

type RegionLookupProps = {
	region: Region,
	onChange: (value?: Object) => void
}

const RegionLookup = (props:RegionLookupProps) => {
	const {
		region = { name: "" },
		onChange = () => {}
	} = props;
	const getSuggestions = async (query?:string) => {
		const response = await RegionsApi.lookup(query);
		return response?.results?.map((r:Region) => ({ id: r._id, text: r.name })) || [];
	}
		
	return(
		<Typeahead 
			placeholder="Begin typing a region's name..."
			value={{ id: region._id, text: region.name }}
			getDetails={async (id) => await RegionsApi.read(id)}
			getSuggestions={getSuggestions}
			onChange={onChange}
		/>
	);
};

export default RegionLookup;