import React, { createContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { ClientSelector } from "Components/Client";
import { MarketForm, MarketSelector } from ".";
import { MarketsApi } from "api";
import { AsyncButton } from "Components/Shared";
import { isPermitted } from "utils/permissions";
import NotAuthorizedErrorType from "Errors/NotAuthorizedError";
import NotAuthorizedError from "Components/Shared/NotAuthorizedError";

const WizardContext = createContext();

const WizardProvider = ({ children }) => {
	const [section, setSection] = useState("path");
	const [client, setClient] = useState();
	const [clonedFromClient, setClonedFromClient] = useState();
	const [clonedFromMarket, setClonedFromMarket] = useState();
	const [history, setHistory] = useState([]);

	const updateSection = (newSection) => {
		setHistory(h => [section, ...h])
		setSection(newSection);
	}

	const back = (evt) => {
		if(evt) { evt.stopPropagation(); evt.preventDefault(); }
		const newSection = history.shift();
		setHistory(history);
		setSection(newSection);
	}

	const state = { 
		section, setSection: updateSection, 
		client, setClient,
		clonedFromClient, setClonedFromClient,
		clonedFromMarket, setClonedFromMarket,
		history, back
	};

	return (
		<WizardContext.Provider value={state}>
			{children}
		</WizardContext.Provider>
	)
} 

const withWizard = (Child) => (props) => (
  <WizardContext.Consumer>
    {(context) => <Child {...props} {...context} />}
  </WizardContext.Consumer>
);

/**
 * A component to guide the user through creating a new market
 * @component
 */
const MarketCreationWizard = ({activeUser = {}}) => {
	console.log("test", isPermitted(activeUser, "createMarkets"), activeUser);
	if(!isPermitted(activeUser, "createMarkets"))
		return <NotAuthorizedError redirect="/markets" redirectMessage="Back to market list" />
		
	return (
		<WizardProvider>
			<h1>Market Creation Wizard</h1>
			<hr />
			<MarketWizardSelection activeUser={activeUser} />
		</WizardProvider>
	)
}

const MarketWizardSelection = withWizard(({ section, history, back, activeUser = {} }) => {
	return (
		<>
			{!!history.length && <Row>
				<Col><a href="#" onClick={back}><FaAngleDoubleLeft /> Back</a></Col>
			</Row>}
			{section === "path" && <MarketPathSelection />}
			{section === "new" && <ClientSelection />}
			{section === "clone" && <CloneMarketClientSelection />}
			{section === "marketSelection" && <MarketSelection /> }
			{section === "createMarket" && <MarketFormSection activeUser={activeUser} /> }
		</>
	)
})

const MarketPathSelection = withWizard(({ setSection }) => {
	return (
		<div className="text-center">
			<h2>What do you want to do?</h2>
			<Row className="mt-5 mb-3">
				<Col><Button variant="primary" size="lg" onClick={() => setSection("new")}>Create a new Market</Button></Col>
			</Row>
			<Row>
				<Col><Button variant="secondary" size="lg" onClick={() => setSection("clone")}>Clone existing Market</Button></Col>
			</Row>
		</div>
	)
});

const ClientSelection = withWizard(({ client, setClient, setSection }) => {
	const [tempClient, setTempClient] = useState(client);

	const handleSetClient = () => {
		setClient(tempClient);
		setSection("createMarket");
	}

	return (<>
		<Row className="justify-content-center mt-3">
			<Col sm="8">
				<h2 className="mb-3">Select the parent client for the new market:</h2>
				<ClientSelector value={tempClient} className="mb-3" onChange={setTempClient} hideLabel />
				{!!tempClient && <Button onClick={handleSetClient}>Set Client</Button>}
			</Col>
		</Row>
		</>
	)
});

const MarketFormSection = withWizard(({client, clonedFromMarket, activeUser = {}}) => {
	const handleMarketCreation = (newMarket) => {
		if(!!newMarket?._id) {
			window.location = `/markets/${newMarket._id}`;
		}
	}

	return(
		<Row className="justify-content-center mt-3">
			<Col md="8">
				<h2 className="mb-3 text-center">{client.name}</h2>
				<MarketForm activeUser={activeUser} market={clonedFromMarket} clientId={client._id} onChange={handleMarketCreation} />
			</Col>
		</Row>
	)
});

const CloneMarketClientSelection = withWizard(({ clonedFromClient, setClonedFromClient, setSection }) => {
	const [tempClient, setTempClient] = useState(clonedFromClient);

	const handleSetClient = () => {
		setClonedFromClient(tempClient);
		setSection("marketSelection");
	}

	return (<>
		<Row className="justify-content-center mt-3">
			<Col sm="8">
				<h2 className="mb-3">Select the parent client of the market you wish to clone:</h2>
				<ClientSelector value={tempClient} className="mb-3" onChange={setTempClient} hideLabel />
				{!!tempClient && <Button onClick={handleSetClient}>Set Client</Button>}
			</Col>
		</Row>
		</>
	)
});

const MarketSelection = withWizard(({clonedFromClient, clonedFromMarket, setClonedFromMarket, setSection}) => {
	const [tempMarket, setTempMarket] = useState(clonedFromMarket);

	const handleSetMarket = async () => {
		const marketDetails = await MarketsApi.read(clonedFromClient._id, tempMarket._id);
		const fields = {...marketDetails};
		delete fields._id;
		setClonedFromMarket({...marketDetails, fields});
		setSection("new");
	}

	return(
		<Row className="justify-content-center mt-3">
			<Col md="8">
				<h2 className="mb-3 text-center">Select the market to clone:</h2>
				<MarketSelector market={tempMarket} className="mb-3" markets={clonedFromClient?.markets} client={clonedFromClient?._id} onChange={setTempMarket} hideLabel />
				{!!tempMarket && <AsyncButton onClick={handleSetMarket}>Set Market</AsyncButton>}
			</Col>
		</Row>
	)
})

export default MarketCreationWizard;